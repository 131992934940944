import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';

const CheckoutStepper = (): JSX.Element => {
  const { t } = useI18n();

  const checkoutStage = useAppSelector((state) => state.checkout.checkoutStage);

  return (
    <ol className="flex items-center mb-[57px] max-w-[308px] mx-auto mt-16 2xl:mt-24">
      <li className="relative">
        {checkoutStage === 'sign-in' ? (
          <span className="peer w-[28px] h-[28px] bg-secondary rounded-full flex items-center justify-center text-base text-white">
            {t('Payment_StepperStage1')}
          </span>
        ) : (
          <span className="w-[28px] h-[28px] border-2 border-neutral bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        )}
        <p className="text-base text-center text-base-dark absolute whitespace-nowrap left-1/2 -translate-x-1/2 peer-first:text-almost-black">
          {t('Payment_StepperStage1Label')}
        </p>
      </li>

      <span
        className={`border flex-grow ${
          checkoutStage === 'payment-billing' ||
          checkoutStage === 'payment-method' ||
          checkoutStage === 'confirmation'
            ? 'border-neutral'
            : 'border-neutral-lighter'
        }`}
      />
      <li className="relative">
        {checkoutStage === 'sign-in' && (
          <span className="w-[28px] h-[28px] bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            {t('Payment_StepperStage2')}
          </span>
        )}

        {(checkoutStage === 'payment-billing' || checkoutStage === 'payment-method') && (
          <span className="peer w-[28px] h-[28px] bg-secondary rounded-full flex items-center justify-center text-base text-white">
            {t('Payment_StepperStage2')}
          </span>
        )}

        {checkoutStage === 'confirmation' && (
          <span className="w-[28px] h-[28px] border-2 border-neutral bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        )}
        <p className="text-base text-center text-base-dark absolute whitespace-nowrap left-1/2 -translate-x-1/2 peer-first:text-almost-black">
          {t('Payment_StepperStage2Label')}
        </p>
      </li>
      <span
        className={`border flex-grow ${
          checkoutStage === 'confirmation' ? 'border-neutral' : 'border-neutral-lighter'
        }`}
      />

      <li className="relative">
        {checkoutStage === 'confirmation' ? (
          <span className="peer w-[28px] h-[28px] bg-secondary rounded-full flex items-center justify-center text-base text-white">
            {t('Payment_StepperStage3')}
          </span>
        ) : (
          <span className="w-[28px] h-[28px] bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            {t('Payment_StepperStage3')}
          </span>
        )}
        <p
          className={`text-base text-center text-base-dark absolute whitespace-nowrap left-1/2 -translate-x-1/2 peer-first:text-almost-black`}
        >
          {t('Payment_StepperStage3Label')}
        </p>
      </li>
    </ol>
  );
};

export default CheckoutStepper;
