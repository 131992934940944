export type ColorClassesType = {
  general: Record<string, string>;
  quantityNudger: Record<string, string>;
  subscriptionPricingCard: Record<string, string>;
  dropDownMenu: Record<string, string>;
};

export const themeColors = {
  white: {
    general: {
      pageBgMain: 'bg-white',
      pageBgLighter: 'bg-white',
      pageBgLight: 'bg-white',
      bgInfo: 'bg-info-light',
      textMainColorClass: 'text-almost-black',
    },
    quantityNudger: {
      textMain: 'bg-almost-black',
      textHover: 'group-hover:bg-primary',
      textActive: 'group-active:bg-primary-dark',
    },
    subscriptionPricingCard: {
      bgFeaturedPrimary: 'bg-secondary',
      bgFeaturedSecondary: 'bg-primary',
      textFeaturedPrimary: 'text-secondary',
      textFeaturedSecondary: 'text-primary',
      btnFeaturedPrimary: 'btn-feature',
      btn: 'btn-primary',
      spinnerColor: '#194BF5',
    },
    dropDownMenu: {
      itemHover: 'hover:bg-primary-lighter',
      itemSelected: 'bg-primary text-white',
      itemNotSelected: '',
    },
  },
  dark: {
    general: {
      pageBgMain: 'bg-mostlyblack',
      pageBgLighter: 'bg-mp-background-card',
      pageBgLight: 'bg-grayishblue',
      bgInfo: 'bg-mp-background-neutralLight',
      textMainColorClass: 'text-mp-txt-neutralLight',
    },
    quantityNudger: {
      textMain: 'bg-grayishblue',
      textHover: 'group-hover:bg-softorange',
      textActive: 'group-active:bg-softorange',
    },
    subscriptionPricingCard: {
      bgFeaturedPrimary: 'bg-mp-background-primary',
      bgFeaturedSecondary: 'bg-mp-background-secondary',
      textFeaturedPrimary: 'text-mp-txt-primary',
      textFeaturedSecondary: 'text-mp-tx-secondary',
      btnFeaturedPrimary:
        'bg-mp-btn-primary hover:bg-mp-btn-light text-lg text-mp-txt-bright font-bold px-4 py-2 rounded',
      btn: 'bg-mp-btn-primary hover:bg-mp-btn-light text-lg text-mp-txt-bright font-bold px-4 py-2 rounded',
      spinnerColor: '#20BBD0',
    },
    dropDownMenu: {
      itemHover: 'hover:bg-softorange hover:text-mp-txt-bright',
      itemSelected: 'bg-darkgrayishblue text-softorange border border-softorange',
      itemNotSelected: 'bg-darkbgrayishblue',
    },
  },
};
