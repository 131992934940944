import { GraphQLClient } from 'lib/common/graphql-client';
import { RowAttribute, TableContentQueryResponse } from './table-content-query-response';
import getTableContentQuery from './table-content-query';

export const getTableData = async (
  datasource: string,
  language: string
): Promise<Array<RowAttribute>> => {
  const graphQLClient = GraphQLClient();

  const results: RowAttribute[] = [];
  let hasNext = true;
  let after = '';

  while (hasNext) {
    const result: TableContentQueryResponse = await graphQLClient.request(getTableContentQuery, {
      datasource: datasource,
      language: language,
      after: after,
    });

    if (result?.datasource.rowAttributes?.results) {
      results.push(...result.datasource.rowAttributes.results);
    }

    hasNext = result.datasource.rowAttributes?.pageInfo.hasNext;
    after = result.datasource.rowAttributes?.pageInfo.endCursor;
  }

  return results;
};
