import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { IconName, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { getCartSummary } from 'components/cart/cart-slice';
import { getLicensingDetails, getOrderDetails } from 'components/order/order-slice';
import { ComponentProps } from 'lib/component-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { stringFormat } from 'lib/utils/string-format';
import { useI18n } from 'next-localization';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import NonProToolsOrderDetails from '../../components/order/shop/NonProToolsOrderDetails';
import { ProToolsActivationStepperItem } from '../../components/order/shop/pro-tools-activation-steps';
import ProToolsOrderDetails from '../../components/order/shop/ProToolsOrderDetails';
import InlineSpinner from 'components/spinner/InlineSpinner';
import OrderWrapper from '../../components/order/shop/OrderWrapper';
import { sendEmail } from 'components/order/order-api';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useSession } from 'next-auth/react';

type OrderDetailsProps = ComponentProps & {
  fields: {
    proToolsActivationStepper: ProToolsActivationStepperItem;
  };
};

const OrderDetails = (props: OrderDetailsProps): JSX.Element => {
  const router = useRouter();
  const { id } = router.query;

  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector((state) => state.order.orderDetailsData);
  const licensingDetails = useAppSelector((state) => state.order.orderLicensingDetailsData);
  const licensingStatus = useAppSelector((state) => state.order.orderLicensingDetailsStatus);
  const notificationEmail = orderDetails?.notificationEmail;
  const userId = orderDetails?.userId;
  const checkIcon = faCheckCircle as unknown as IconName;

  const [licensingLoadingTime, setLicensingLoadingTime] = useState(0);
  const [licensingTimedOut, setLicensingTimedOut] = useState(false);

  const [orderLoadingTime, setOrderLoadingTime] = useState(0);

  const [hasProTools, setHasProTools] = useState(false);
  const [resentMessageVisible, setResentMessageVisible] = useState(false);

  const showLicensingSection =
    licensingStatus == 'loading' ||
    licensingStatus == 'failed' ||
    (licensingStatus == 'succeeded' &&
      licensingDetails != null &&
      licensingDetails.orderItems.filter(
        (x) => x.product.isSubscription && x.licensingComplete && x.redemptionCodes != null
      ).length > 0);

  const { data: session, status } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  useEffect(() => {
    if (id == null || id.length == 0 || status === 'loading') {
      return;
    }

    dispatch(getOrderDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    dispatch(getCartSummary({ bearerToken: bearerToken }));
  }, [dispatch, id, session, status, bearerToken]);

  useEffect(() => {
    if (orderDetails?.orderItems != null) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    if (orderLoadingTime >= 30000) {
      router.push(`/order-processing?id=${id}`);
      return;
    }

    const timeout = 5000;

    setTimeout(() => {
      dispatch(getOrderDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
      setOrderLoadingTime(orderLoadingTime + timeout);
    }, timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, orderDetails, router, bearerToken, status]);

  useEffect(() => {
    if (orderDetails?.orderItems == null) {
      return;
    }

    if (props.fields?.proToolsActivationStepper == null) {
      return;
    }

    const proToolsProductIds = props.fields.proToolsActivationStepper.fields.products.map(
      (item) => item.fields.productId.value
    );

    for (let i = 0; i < orderDetails.orderItems.length; i++) {
      const orderItem = orderDetails.orderItems[i];
      if (proToolsProductIds.includes(orderItem.product.productId)) {
        setHasProTools(true);
        return;
      }
    }
  }, [orderDetails, props.fields.proToolsActivationStepper]);

  useEffect(() => {
    if (orderDetails?.orderItems == null) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    const orderHasSubscriptionProducts =
      orderDetails.orderItems.filter((x) => x.product.isSubscription).length > 0;

    if (!orderHasSubscriptionProducts) {
      return;
    }
    if (licensingStatus == 'succeeded' || licensingStatus == 'loading') {
      return;
    }

    let timeout = 0;
    if (licensingStatus == 'failed') {
      timeout = 5000;
    }

    setTimeout(() => {
      if (licensingLoadingTime > 60000) {
        setLicensingTimedOut(true);
        return;
      }
      dispatch(getLicensingDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    }, timeout);

    setLicensingLoadingTime(licensingLoadingTime + timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails, licensingDetails, licensingStatus, dispatch, id, bearerToken, status]);

  const OrderDetailDescription = (): JSX.Element => {
    const description = t('Order_Details_Description');
    const content = stringFormat({
      message: description,
      0: id as string,
      1: notificationEmail as string,
    });
    return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
  };

  const onClickResendEmailHandler = (): void => {
    sendEmail(id as string, bearerToken);
    setResentMessageVisible(true);
    setTimeout(() => {
      setResentMessageVisible(false);
    }, 5000);
  };

  return (
    <OrderWrapper title={t('Order_Details_ThankYou')}>
      <>
        <div className="text-center">
          <div className="text-success mb-5">
            <FontAwesomeIcon icon={checkIcon} size="3x" />
          </div>
          <div className="mb-4 lg:mb-5 text-3xl lg:text-4xl capitalize font-bold">
            {t('Order_Details_Complete')}
          </div>
          <div className="p-2 pb-0 mb-6">
            <OrderDetailDescription />
            <button
              className="text-primary underline"
              onClick={(): void => onClickResendEmailHandler()}
            >
              {t('Order_Details_ResendEmail')}
            </button>
            {resentMessageVisible && (
              <div className="text-sm">
                <span className="font-bold mr-3">{t('Order_Details_EmailResent')}</span>
                <span className="text-success">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </div>
            )}
          </div>
          {hasProTools && (
            <ProToolsOrderDetails
              orderId={id}
              userId={userId}
              isAccountIncomplete={orderDetails?.guestCheckout ?? true}
              proToolsActivationStepper={props.fields.proToolsActivationStepper}
            />
          )}
          {!hasProTools && (
            <NonProToolsOrderDetails
              orderId={id}
              userId={userId}
              loading={orderDetails?.orderItems == null}
            />
          )}
        </div>
        {showLicensingSection && (
          <>
            <div className="my-8 border-t border-base-dark"></div>
            <div className="relative">
              {licensingTimedOut && <div></div>}
              {(licensingStatus == 'loading' || licensingStatus == 'failed') &&
                !licensingTimedOut && (
                  <div className="text-center">
                    <div className="font-bold mb-4">{t('Order_Licensing_PleaseWait')}</div>
                    <InlineSpinner height={47} width={5} />
                  </div>
                )}
              {licensingStatus == 'succeeded' &&
                licensingDetails?.orderItems
                  .filter(
                    (x) =>
                      x.product.isSubscription && x.licensingComplete && x.redemptionCodes != null
                  )
                  .map((orderItem, index) => {
                    return (
                      <div key={index} className="text-center">
                        <div className="mb-8 font-bold">
                          <div className="text-2xl mb-2">{orderItem.product.name}</div>
                          <div>
                            {t('Order_Licensing_ActivationCode')} {orderItem.redemptionCodes}
                          </div>
                        </div>
                        <div>{orderItem.product.instructionsForEndUser}</div>
                      </div>
                    );
                  })}
            </div>
          </>
        )}

        <Placeholder name="order-confirmation-items" rendering={props.rendering} />
      </>
    </OrderWrapper>
  );
};

export default withDatasourceCheck()(OrderDetails);
