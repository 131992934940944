import { Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  MultipleLanguagePricingCardDataSource,
  PricingCardProps,
  Product,
} from './pricing-card-props';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { addCartItem } from 'components/cart/cart-slice';
import Spinner from 'components/spinner/Spinner';
import {
  getProductCartIndex,
  pushAddToCart,
  pushCoveoAddCartItem,
  pushViewItem,
} from 'lib/google-analytics/commerce';
import BasePricingCard from '../../components/pricing-card/BasePricingCard';
import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import BaseItemQuantityNudger from 'components/cart/shop/BaseItemQuantityNudger';
import { usePrice } from 'lib/commerce/product/price-hook';
import { getProductDetails } from 'lib/commerce/product/product-slice';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

const Default = (props: PricingCardProps<MultipleLanguagePricingCardDataSource>): JSX.Element => {
  const dispatch = useAppDispatch();

  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const [productItem, setProductItem] = useState<Product | null>(
    props.fields?.data?.datasource?.children?.results[0]?.product?.targetItems[0]
  );
  const [productId, setProductId] = useState(productItem?.productId.value ?? '');
  const [quantity, setQuantity] = useState(1);
  const products = props.fields?.data?.datasource?.children?.results ?? null;

  const cart = useAppSelector((state) => state.cart);
  const price = usePrice(productId, currencyIsoCode);

  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);
  const productOptions = products?.map((productOption) => {
    return {
      value: productOption.product.targetItems[0].productId.value ?? '',
      label: productOption.title.jsonValue.value ?? '',
    };
  });

  const defaultProductOption =
    productOptions != null
      ? {
          value: productOptions[0]?.value,
          label: productOptions[0]?.label,
        }
      : null;

  useEffect(() => {
    if (
      price == null ||
      productItem == null ||
      price.productId != productItem.productId.value ||
      props.stopEvents ||
      currencyIsoCode == null
    ) {
      return;
    }

    pushViewItem(
      currencyIsoCode,
      price.unitPrice.toString(),
      {
        id: productItem.productId.value,
        sku: productItem.productCode.value,
        name: productItem.productName.value,
        price: price.listPrice.toString(),
        discount: (price.listPrice - price.unitPrice).toString(),
        brand: productItem.brand.value,
        variant: productItem.term?.value ?? 'N/A',
        itemCategory: productItem.primaryCategory?.targetItems[0]?.categoryName?.value,
        itemCategory2: productItem.additionalCategories?.targetItems[0]?.categoryName?.value,
        itemCategory3: productItem.additionalCategories?.targetItems[1]?.categoryName?.value,
        itemCategory4: productItem.additionalCategories?.targetItems[2]?.categoryName?.value,
        productSku: productItem?.productCode?.value,
      },
      (customerInfo?.contactId as string) || ''
    );
  }, [currencyIsoCode, customerInfo?.contactId, price, productItem, props.stopEvents]);

  const onChangeProductLanguageHandler = (selectedProductId: string): void => {
    const productOption = products.filter(
      (productOption) => productOption.product.targetItems[0].productId.value == selectedProductId
    );

    if (productOption == null || productOption.length == 0) {
      return;
    }

    const product = productOption[0].product.targetItems[0];
    setProductItem(product);
    setProductId(product.productId.value);
  };

  const addToCartHandler = (): void => {
    dispatch(addCartItem({ sku: productId, quantity: quantity, bearerToken: bearerToken }));
    if (
      price == null ||
      currencyIsoCode == null ||
      productItem == null ||
      cart.cartSummaryData == null
    ) {
      return;
    }

    const index = getProductCartIndex(productId, cart.cartItemsData);
    pushAddToCart(currencyIsoCode, price.unitPrice.toString(), {
      id: productId,
      sku: productItem.productCode.value,
      name: productItem.productName.value,
      brand: productItem.brand.value,
      price: price.listPrice.toString(),
      discount: (price.listPrice - price.unitPrice).toString(),
      variant: productItem.term?.value ?? 'N/A',
      index: index.toString(),
      itemCategory: productItem.primaryCategory?.targetItems[0]?.categoryName?.value,
      itemCategory2: productItem.additionalCategories?.targetItems[0]?.categoryName?.value,
      itemCategory3: productItem.additionalCategories?.targetItems[1]?.categoryName?.value,
      itemCategory4: productItem.additionalCategories?.targetItems[2]?.categoryName?.value,
    });
    onClickSendCoveoAdd(productId, productItem.productName.value, price.listPrice, currencyIsoCode);
  };

  const onClickSendCoveoAdd = async (
    sku: string,
    name: string,
    price: number,
    currency: string
  ): Promise<void> => {
    const productDetails = await dispatch(getProductDetails(sku)).unwrap();
    if (productDetails == null) return;

    const categoryPaths = productDetails?.data?.productCategoryPaths?.primary;

    const productCategory =
      categoryPaths != null ? categoryPaths[categoryPaths.length - 1]?.name : '';

    pushCoveoAddCartItem(sku, name, price, currency, productCategory);
  };

  const onClickChangeQuantityHandler = (newQuantity: number): void => {
    setQuantity(newQuantity);
  };

  return (
    <BasePricingCard
      pricingCard={props}
      fullCta={
        <>
          <div className="mb-4 flex justify-center">
            <BaseItemQuantityNudger
              passValueToParentFunction={onClickChangeQuantityHandler}
              disableButton={false}
              quantity={quantity}
            />
          </div>

          <DropdownMenu
            defaultValue={defaultProductOption}
            options={productOptions}
            passValueToParentFunction={onChangeProductLanguageHandler}
            className="w-full mb-3"
          />
          <button
            className="btn-primary ml-auto w-full px-8 relative"
            id={`buyNowBtn_${productId}`}
            data-id={productId}
            onClick={addToCartHandler}
          >
            <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
            {cart.cartAddItemStatus == 'loading' && <Spinner height={15} width={3} margin={3} />}
          </button>
        </>
      }
      miniCta={
        <button className="btn-primary ml-auto px-3 py-1 text-[1rem]" onClick={addToCartHandler}>
          <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
        </button>
      }
      productPricing={price}
      productItem={productItem}
      loading={price == null}
    />
  );
};

export default withDatasourceCheck()(Default);
