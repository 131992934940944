import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const AuthorBlockGrid = (props: ComponentProps): JSX.Element => {
  return (
    <ul className="flex flex-col items-center space-y-10 my-10 xl:my-20">
      <Placeholder name="jss-author-block-grid" rendering={props.rendering} />
    </ul>
  );
};

export default AuthorBlockGrid;
