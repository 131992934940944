import { DotProps } from 'react-multi-carousel';

function CarouselDot(props: DotProps): JSX.Element {
  const index = props.index;
  const indexLabel = index != null ? index + 1 : '';

  return (
    <li>
      <button
        className={`w-4 h-4 rounded-full border-4 border-primary flex items-center justify-center mr-5 ${
          props.active ? 'bg-primary' : ''
        } hover:bg-primary focus:outline-2 focus:outline`}
        aria-label={`Go to slide ${indexLabel}`}
        onClick={(): void => {
          if (props.onClick != null) {
            props.onClick();
          }
        }}
      ></button>
    </li>
  );
}

export default CarouselDot;
