import {
  useSitecoreContext,
  Field,
  LinkField,
  ImageField,
  RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { ContentTypeSelection } from 'components/video/brightcove-video-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import {
  generateEventDateText,
  extractYoutubeVideoId,
} from 'components/search/templates/EventSearchTemplate';
import { YoutubeVideoProps, FieldValue, Fields } from 'components/video/youtube-video-props';
import { youtubeThumbnailProps } from '../VideoBlock';
import { useState, useEffect } from 'react';
import { getYoutubeThumbnail } from 'lib/youtube-thumbnail-fetcher';
import BaseVideoModal from 'components/video/BaseVideoModal';
import VideoPlayerIcon from 'components/video/VideoPlayerIcon';
type EventDetailsProps = {
  fields: {
    eventEndDate: Field<string>;
    eventStartDate: Field<string>;
    eventLocation: Field<string>;
    eventName: Field<string>;
    searchDescription: Field<string>;
    searchImage: ImageField;
    searchTitle: Field<string>;
    eventLink: LinkField;
    eventType: ContentTypeSelection;
    eventRegion: ContentTypeSelection;
  };
};

export type YouTubeVideoThumbnailProps = {
  value: {
    src: string;
  };
};

const Default = (): JSX.Element => {
  let isOnDemandVideo = false;
  let endDate: Date | undefined = undefined;
  let startDate: Date | undefined = undefined;
  const context = useSitecoreContext();
  const props = {} as EventDetailsProps;
  const [youtubeVideoThumbnail, setyoutubeVideoThumbnail] = useState<youtubeThumbnailProps>();

  props.fields = JSON.parse(JSON.stringify(context?.sitecoreContext.route?.fields ?? {}));

  if (
    props.fields.eventStartDate &&
    props.fields.eventStartDate?.value !== '0001-01-01T00:00:00Z'
  ) {
    startDate = props.fields.eventStartDate
      ? new Date((props.fields.eventStartDate.value as string).split('@')[0])
      : undefined;
  }

  if (props.fields.eventEndDate && props.fields.eventEndDate?.value !== '0001-01-01T00:00:00Z') {
    endDate = props.fields.eventEndDate
      ? new Date((props.fields.eventEndDate.value as string).split('@')[0])
      : undefined;
  }

  const eventDateText = generateEventDateText(startDate, endDate);

  const badgeDisplayText =
    eventDateText && props?.fields?.eventLocation
      ? `${eventDateText} | ${props?.fields?.eventLocation?.value}`
      : eventDateText || props.fields.eventLocation.value;

  isOnDemandVideo =
    props?.fields?.eventType?.fields &&
    props?.fields?.eventType?.fields?.termName.value === 'On Demand'
      ? true
      : false;

  const youtubeSourceId = isOnDemandVideo
    ? extractYoutubeVideoId(props?.fields?.eventLink.value.href as string)
    : null;

  const video: YoutubeVideoProps = {
    fields: {
      sourceId: youtubeSourceId
        ? ({ value: youtubeSourceId } as FieldValue)
        : ({ value: '' } as FieldValue),
    } as Fields,
  } as YoutubeVideoProps;

  useEffect(() => {
    if (isOnDemandVideo) {
      getYoutubeThumbnail(youtubeSourceId ?? '').then((data: youtubeThumbnailProps) => {
        setyoutubeVideoThumbnail(data);
      });
    }
  }, [isOnDemandVideo, youtubeSourceId]);

  return (
    <>
      <div className="flex max-w-content mx-auto  bg-slate-300 mb-10 lg:mb-20 px-6 xl:w-8/12 xl:max-w-[800px] justify-center">
        <div className="flex flex-col max-w-content items-start">
          <div className="min-w-full">
            {!isOnDemandVideo && (
              <div className="flex flex-col text-avidpurple font-normal text-base leading-[22px] items-start xl:flex-row lg:text-xl lg:leading-7 gap-1 mb-6 uppercase">
                <span className="flex items-center gap-5 tracking-[3.2px]">{badgeDisplayText}</span>
              </div>
            )}
          </div>
          <div className="bg-primary-lightest text-base me-2 px-2.5 py-0.5 rounded-full mb-6">
            {props?.fields?.eventType?.fields?.termName.value}
          </div>
          <RichText
            field={props?.fields?.eventName}
            tag="h1"
            className="mb-6 items-stretch text-almost-black font-normal uppercase text-32 leading-9.5 tracking-[8.96px] lg:text-42 lg:leading-12.5 mb-10 md:mb-20"
          />

          <div className="nextMedia relative w-full grid mb-10 md:mb-20">
            {isOnDemandVideo && (
              <BaseVideoModal
                brightcoveVideo={false}
                youtubeVideo={true}
                video={video}
                openCtaClassName=""
                openCtaChildren={
                  <div className="w-full grid relative aspect-[2/1]">
                    <div className="before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full">
                      {youtubeVideoThumbnail ? (
                        <NextImage
                          layout="fill"
                          field={youtubeVideoThumbnail}
                          className="w-full object-cover bg-base-lighter relative flex justify-center"
                        />
                      ) : (
                        <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                      )}
                      <VideoPlayerIcon className="text-[80px] text-white border-[5px] border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                    </div>
                  </div>
                }
              />
            )}
            {!isOnDemandVideo && (
              <NextImage field={props?.fields?.searchImage} className="mx-auto" />
            )}
          </div>
          <RichText
            field={props?.fields?.searchDescription}
            tag="p"
            className={`rte richTextBl max-w-content mx-auto text-base-darker  [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:mb-10 [&_a]:text-primary ${
              !isOnDemandVideo ? 'mb-10 md:mb-20' : ''
            }`}
          />
          {!isOnDemandVideo && (
            <NextLink
              className="btn-primary mx-auto px-8 relative text-lg"
              field={props.fields.eventLink}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Default;
