import { Text, Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { useEffect, useState } from 'react';
import { ProductPricingData } from 'lib/commerce/product/model/product-models';
import { useAppSelector } from 'lib/store/hooks';
import { getMultiProductPricing } from 'lib/commerce/product/product-api';
import Carousel from 'components/carousel/Carousel';
import { useCartData } from 'components/cart/cart-hooks';
import MarketplaceCarouselDots from 'components/carousel/marketplace/MarketplaceCarouselDots';
import TrendingProductsList from 'components/trending-products/TrendingProductsList';
import Spinner from 'components/spinner/Spinner';

export type TrendingProduct = {
  url: {
    url: string;
  };
  searchImage: {
    jsonValue: ImageField;
  };
  searchTitle: Field<string>;
  searchDescription: Field<string>;
  company: {
    targetItem: {
      termName: Field<string>;
    };
  };
  parentProduct: {
    targetItems: [
      {
        productId: Field<string>;
      },
    ];
  };
};

export type TrendingProductsProps = {
  fields: {
    data: {
      datasource: {
        title: Field<string>;
        learnMoreText: Field<string>;
        trendingProducts: {
          targetItems: Array<TrendingProduct>;
        };
      };
    };
  };
};

export type TrendingProductData = TrendingProduct & {
  productPricing?: ProductPricingData;
};

const TrendingProducts = (props: TrendingProductsProps): JSX.Element => {
  const cartItemData = useCartData();
  const cartItemsIds = cartItemData?.cartItems.map((item): string => item.cartItem?.productId);
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const [trendingProductsData, setTrendingProductsData] = useState<null | TrendingProductData[]>(
    null
  );

  useEffect(() => {
    const productItems = props.fields.data.datasource.trendingProducts.targetItems;
    const parentProductIds = productItems.map((product) => {
      return product.parentProduct.targetItems[0]?.productId.value;
    });

    if (!currencyIsoCode || parentProductIds.length < 1) {
      return;
    }

    getMultiProductPricing(parentProductIds, currencyIsoCode)
      .then((pricesData) => {
        setTrendingProductsData(
          productItems.map((item) => {
            return {
              ...item,
              productPricing: pricesData.data.find(
                (priceData) =>
                  priceData.productId === item.parentProduct.targetItems[0]?.productId.value
              ),
            };
          })
        );
      })
      .catch((error) => {
        console.log(`Trending Products: >> Can't get prices data: ${error.message}`);
      });
  }, [currencyIsoCode, props.fields.data.datasource.trendingProducts.targetItems]);

  return (
    <div
      id="trending-products--marketplace"
      className={`relative ${trendingProductsData ? '' : 'min-h-[300px]'}`}
    >
      <div className="flex flex-row justify-between mb-5 relative">
        <div className="w-full text-lg font-light text-mp-txt-bright">
          <Text field={props?.fields?.data?.datasource?.title} />
          <hr className="text-mp-txt-neutral" />
        </div>
      </div>
      {!trendingProductsData && <Spinner color="#20BBD0" />}
      {trendingProductsData && (
        <div className="relative after:clear-both whitespace-nowrap border rounded border-mp-txt-neutral pb-3">
          <Carousel
            MaxItemsPerSlideOnDesktop="4"
            MaxItemsPerSlideOnTablet="2"
            MaxItemsPerSlideOnMobile="1"
            tabletSize={470}
            buttonGroup={<></>}
            customDotsGroup={<MarketplaceCarouselDots />}
            carouselWrapperClass="pb-7"
          >
            {trendingProductsData.map((product, index) => {
              return (
                <TrendingProductsList
                  key={index}
                  index={index}
                  learnMoreText={props.fields.data.datasource.learnMoreText}
                  product={product}
                  cartItemsIds={cartItemsIds}
                ></TrendingProductsList>
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
};
export default TrendingProducts;
