import { AtomicNoResults } from '@coveo/atomic-react';

type NoResultsProps = React.ComponentProps<typeof AtomicNoResults>;

const searchTipsTitle = `Search tips:`;
const searchTips = [
  `Check the spelling of your keywords.`,
  `Try using fewer, different or more general keywords.`,
];

const NoResults: React.FC<NoResultsProps> = (props) => {
  const styles = `atomic-no-results::part(cancel-button) {
        color: blue
    }

    atomic-no-results::part(icon) {
        display: none;
    }

    atomic-no-results::part(search-tips) {
        display: none;
    }

    atomic-no-results::part(no-results) {
      align-self: flex-start;
      font-size: 24px;
      line-height: calc(36 / 24 * 1em);
      margin: 0 0 32px;
      text-align: left;
    }
    
    .no-results-tips {
      font-size: 16px;
      line-height: calc(24 / 16 * 1em);
    }
    
    .no-results-tips__header {
      font-weight: 700;
      margin-bottom: 8px;
    }
    
    .no-results-tips__list {
      list-style: disc;
      padding-left: 1.5rem;
    }`;

  return (
    <>
      <style>{styles}</style>

      <AtomicNoResults enableCancelLastAction={false}>
        {props.children}

        {searchTips && (
          <section className="no-results-tips">
            {searchTipsTitle && (
              <header className="no-results-tips__header">{searchTipsTitle}</header>
            )}

            <ul className="no-results-tips__list">
              {searchTips.map((tip, index) => (
                <li key={index} className="no-results-tips__list-item">
                  {tip}
                </li>
              ))}
            </ul>
          </section>
        )}
      </AtomicNoResults>
    </>
  );
};

export default NoResults;
