import {
  Field,
  LinkField,
  withDatasourceCheck,
  RichTextField,
  RichText,
  Text,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { BaseIconGridItemProps } from './BaseIconGridItem';
import BaseIconGridItem from './BaseIconGridItem';
import BaseCommonModal from 'components/general-modal/BaseCommonModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

type IconGridItemLinkProps = BaseIconGridItemProps & {
  fields: {
    primaryLink: LinkField;
    secondaryLink: LinkField;
    noFollow?: Field<boolean>;
    secondaryNoFollow?: Field<boolean>;
    modalHeading: Field<string>;
    modalDescription: RichTextField;
    ctaLabel: Field<string>;
    modalCTA: LinkField;
    containsModal: Field<boolean>;
  };
};

const Default = (props: IconGridItemLinkProps): JSX.Element => {
  const modalContent = (
    <div role="modal">
      <Text tag="h3" field={props.fields.modalHeading} className="h3 mb-6" />
      <RichText tag="div" field={props.fields.modalDescription} className="rte body mb-6" />
      <Link
        field={props.fields.modalCTA}
        linkCustomizations={props.fields}
        className="btn-link-underline"
        hideText
      >
        <span>{props.fields?.modalCTA?.value?.text}</span>
        <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
      </Link>
    </div>
  );
  return (
    <BaseIconGridItem {...props}>
      <div className="flex flex-col">
        {props.fields?.containsModal?.value ? (
          <BaseCommonModal
            modalContent={modalContent}
            openCtaChildren={
              <span className="btn-link-underline items-center btn-link-underline underline p-0 rounded-sm focus-visible:outline-none focus-visible:ring-0">
                <span>{props.fields?.ctaLabel?.value}</span>
              </span>
            }
          ></BaseCommonModal>
        ) : (
          <Link
            field={props.fields?.primaryLink}
            linkCustomizations={{ noFollow: props.fields.noFollow }}
            className="items-center btn-link-underline underline p-0 rounded-sm focus-visible:outline-none focus-visible:ring-0"
          />
        )}

        <Link
          field={props.fields?.secondaryLink}
          linkCustomizations={{ noFollow: props.fields.secondaryNoFollow }}
          className="items-center btn-link-underline underline p-0 rounded-sm focus-visible:outline-none focus-visible:ring-0 mt-2"
        />
      </div>
    </BaseIconGridItem>
  );
};

export default withDatasourceCheck()<IconGridItemLinkProps>(Default);
