import gql from 'graphql-tag';

const getGlobalNavigationQuery = gql`
  query GlobalNavigationDatasourceQuery($datasource: String!, $language: String!) {
    # Datasource query
    # $datasource will always be set to the ID of the rendering's datasource item
    # (as long as the GraphQLData helper is used)
    datasource: item(path: $datasource, language: $language) {
      id
      name
      children(hasLayout: false) {
        results {
          ...NavigationMegaMenuGroupFields
          ...NavigationGroupFields
        }
      }
    }
  }

  fragment NavigationItemBaseFields on Item {
    id
    name
  }

  fragment GlobalNavigationLinkFields on C__NavigationLink {
    ...NavigationItemBaseFields
    ... on Item {
      id
      name
    }
    link {
      value
      jsonValue
    }
  }

  fragment GeoRestrictedGlobalNavigationLinkFields on GeoRestrictedNavigationLink {
    ...NavigationItemBaseFields
    ... on Item {
      id
      name
    }
    link {
      value
      jsonValue
    }
    visibleForCountry {
      value
    }
  }

  fragment NavigationColumnFields on NavigationColumn {
    ...NavigationItemBaseFields
    column {
      value
      jsonValue
    }
    leftBorder {
      value
      jsonValue
    }
    children(hasLayout: false) {
      results {
        ...NavigationGroupFields
        ...NavigationButtonFields
        ...NavigationImageFields
      }
    }
  }
  fragment NavigationImageFields on NavigationImage {
    ...NavigationItemBaseFields
    image {
      jsonValue
    }
    link {
      value
      jsonValue
    }
  }
  fragment NavigationButtonFields on NavigationButton {
    ...NavigationItemBaseFields
    button {
      value
      jsonValue
    }
    link {
      value
      jsonValue
    }
  }
  fragment NavigationGroupFields on NavigationGroup {
    ...NavigationItemBaseFields
    text {
      value
      jsonValue
    }
    group {
      value
    }
    link {
      value
      jsonValue
    }
    children(hasLayout: false) {
      results {
        ...GlobalNavigationLinkFields
        ...GeoRestrictedGlobalNavigationLinkFields
      }
    }
  }
  fragment NavigationMegaMenuGroupFields on NavigationMegaMenuGroup {
    ...NavigationItemBaseFields
    text {
      value
      jsonValue
    }
    heading {
      value
      jsonValue
    }
    mega {
      value
    }
    children(hasLayout: false) {
      results {
        ...NavigationColumnFields
      }
    }
  }
`;
export default getGlobalNavigationQuery;
