import {
  Field,
  ImageField,
  LinkField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { BrightcoveVideoProps } from 'components/video/brightcove-video-props';
import { ComponentProps } from 'lib/component-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dynamic from 'next/dynamic';
import BaseVideoModal from 'components/video/BaseVideoModal';
import { YoutubeVideoProps } from 'components/video/youtube-video-props';
import { youtubeThumbnailProps } from '../VideoBlock';
import { getYoutubeThumbnail } from 'lib/youtube-thumbnail-fetcher';
import VideoPlayerIcon from 'components/video/VideoPlayerIcon';

const BaseEmbededVideo = dynamic(() => import('components/video/BaseEmbededVideo'), {
  ssr: false,
});

type StoryBannerProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    subheading: Field<string>;
    description: Field<string>;
    image: ImageField;
    videos: (BrightcoveVideoProps | YoutubeVideoProps)[];
    ribbonCTA: LinkField;
    noFollow?: Field<boolean>;
  };
  params: {
    PlayInline: boolean;
  };
};

const StoryBannerContent = (props: StoryBannerProps): JSX.Element => {
  let videoId;
  const video = props.fields?.videos?.[0];
  const videoAutoplay = video && props.params?.PlayInline;
  let videoThumbnail = video?.fields?.stillImage?.value?.src != null && video?.fields?.stillImage;
  const isBrightcoveVideo = (video as BrightcoveVideoProps)?.fields?.videoReference != undefined;
  const isYoutubeVideo = (video as YoutubeVideoProps)?.fields?.sourceId?.value != undefined;
  if (isBrightcoveVideo) {
    const thumbnailURL = (video as BrightcoveVideoProps)?.fields?.videoReference[0]?.fields
      ?.ThumbnailURL?.value as string;
    videoId = (video as BrightcoveVideoProps)?.fields?.videoReference[0]?.fields?.ID
      ?.value as string;

    if (!videoThumbnail && thumbnailURL) {
      videoThumbnail = { value: { src: thumbnailURL } };
    }
  }

  const [youtubeVideoThumbnail, setyoutubeVideoThumbnail] = useState<youtubeThumbnailProps>();
  if (isYoutubeVideo) {
    videoId = (video as YoutubeVideoProps)?.fields?.sourceId?.value as string;
  }
  useEffect(() => {
    if (isYoutubeVideo) {
      if (!videoThumbnail) {
        getYoutubeThumbnail(videoId).then((data: youtubeThumbnailProps) => {
          setyoutubeVideoThumbnail(data);
        });
      } else {
        const thumbnailURL = videoThumbnail.value && videoThumbnail.value?.src;
        thumbnailURL && setyoutubeVideoThumbnail({ value: { src: thumbnailURL } });
      }
    }
  }, [isYoutubeVideo, videoId, videoThumbnail]);

  let ribbonClass =
    'bg-neutral-darkest left-0 lg:bg-transparent lg:w-fit lg:left-8 lg:p-0 [&>*]:drop-shadow-[0_2px_10px_rgba(0,0,0,0.85)]';

  if (props.fields.ribbonCTA.value.href !== '' || props.fields.videos.length > 0) {
    ribbonClass = 'bg-secondary lg:w-2/3 lg:right-0 lg:rounded-l lg:rounded-bl lg:px-4';
  }

  return (
    <Fragment>
      <div className="relative aspect-video w-full rounded-t lg:rounded overflow-hidden">
        {videoAutoplay ? (
          <>
            <div className="hidden lg:block">
              <BaseEmbededVideo video={video} autoplay={videoAutoplay} />
            </div>
            <div className="block lg:hidden">
              {isYoutubeVideo ? (
                <div className="w-full grid relative aspect-[4/3]">
                  <div className="before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full">
                    {youtubeVideoThumbnail || props.fields?.image ? (
                      <NextImage
                        className="object-cover -z-10 rounded-t lg:rounded lg:hidden"
                        field={youtubeVideoThumbnail || props.fields?.image}
                        layout="fill"
                      />
                    ) : (
                      <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                    )}
                    <VideoPlayerIcon className="text-80 text-white border-5 border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                  </div>
                </div>
              ) : (
                <div className="w-full grid  relative aspect-4/3 ">
                  <div className="">
                    {videoThumbnail || props.fields?.image ? (
                      <NextImage
                        className="object-cover -z-10 rounded-t lg:rounded lg:hidden"
                        field={videoThumbnail || props.fields?.image}
                        layout="fill"
                      />
                    ) : (
                      <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="">
            {isYoutubeVideo ? (
              <div className="w-full grid relative aspect-[4/3]">
                <div className="">
                  {youtubeVideoThumbnail || props.fields?.image ? (
                    <NextImage
                      className="object-cover -z-10 rounded-t lg:rounded"
                      field={youtubeVideoThumbnail || props.fields?.image}
                      layout="fill"
                    />
                  ) : (
                    <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                  )}
                </div>
              </div>
            ) : (
              <div className="">
                <div className="">
                  {videoThumbnail || props.fields?.image ? (
                    <NextImage
                      className="object-cover -z-10 rounded-t lg:rounded"
                      field={videoThumbnail || props.fields?.image}
                      layout="fill"
                    />
                  ) : (
                    <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className={`flex w-full py-2 px-3 rounded-b bottom-0 lg:absolute lg:rounded-b-none lg:bottom-8 group-hover:bg-secondary-dark group-active:bg-secondary-darker ${ribbonClass}`}
      >
        {video && (
          <div className="flex items-center ml-0.5 mr-14px lg:ml-1 lg:mr-4">
            <FontAwesomeIcon icon="circle-play" className="text-xl lg:text-40 text-white" />
          </div>
        )}
        <div className="flex flex-col text-white text-left">
          <Text
            tag="h4"
            field={props.fields.heading}
            className="featured-user-name tracking-[0.28em] uppercase"
          />
          <Text
            tag="h5"
            field={props.fields.subheading}
            className="featured-user-title tracking-[0.25em] uppercase"
          />
          <RichText tag="div" field={props.fields.description} className="rte body" />
        </div>
      </div>
    </Fragment>
  );
};

const StoryBanner = (props: StoryBannerProps): JSX.Element => {
  const video = props.fields?.videos?.[0];
  const videoAutoplay = video && props.params?.PlayInline;
  let element = <StoryBannerContent {...props} />;

  if (videoAutoplay) {
    element = (
      <>
        <BaseVideoModal
          video={video}
          openCtaClassName="w-full group lg:hidden"
          openCtaChildren={<StoryBannerContent {...props} />}
        />
        <div className="hidden lg:block">
          <StoryBannerContent {...props} />
        </div>
      </>
    );
  } else if (video) {
    element = (
      <BaseVideoModal
        video={video}
        openCtaClassName="w-full group"
        openCtaChildren={<StoryBannerContent {...props} />}
      />
    );
  } else if (props.fields.ribbonCTA.value.href !== '') {
    element = (
      <Link
        hideText={true}
        field={props.fields.ribbonCTA}
        linkCustomizations={props.fields}
        className="w-full group"
      >
        <StoryBannerContent {...props} />
      </Link>
    );
  }

  return (
    <div className="w-full">
      <div className="relative max-w-1208 w-full mx-auto rounded">{element}</div>
    </div>
  );
};

export default withDatasourceCheck()<StoryBannerProps>(StoryBanner);
