import gql from 'graphql-tag';

const getContentBlockQuery = gql`
  query ContentBlockQuery($datasource: String!, $language: String!) {
    datasource: item(path: $datasource, language: $language) {
      ... on _BrightcoveVideoModal {
        videos {
          targetItems {
            ... on BrightcoveVideoWithPlayer {
              player {
                targetItem {
                  ID: field(name: "ID") {
                    value
                  }
                }
              }
              videoReference {
                targetItems {
                  ID: field(name: "ID") {
                    value
                  }
                  ThumbnailURL: field(name: "ThumbnailURL") {
                    value
                  }
                }
              }
              stillImage {
                jsonValue
              }
            }
            ... on BrightcoveExperience {
              videoReference {
                targetItems {
                  ID: field(name: "ID") {
                    value
                  }
                }
              }
              stillImage {
                jsonValue
              }
            }
            ... on YoutubeVideo {
              sourceId {
                value
              }
              stillImage {
                jsonValue
              }
            }
          }
        }
      }

      ... on ContentBlock {
        title {
          jsonValue
        }
        heading {
          jsonValue
        }
        description {
          jsonValue
        }
        image {
          jsonValue
        }
        link {
          jsonValue
        }
        contentBlockIcon {
          targetItem {
            id
            ... on ReferenceStringValue {
              value {
                jsonValue
              }
            }
          }
        }
      }
      ... on _PrimaryLinkCustomizations {
        noFollow {
          jsonValue
        }
      }
    }
  }
`;
export default getContentBlockQuery;
