import { dataLayerPush } from './data-layer-orchestrator';

export type ProductLoadData = {
  product_name: string;
  product_page_url: string;
  payment_term: string;
  user_group: string;
  products_loaded_status: string;
  notification_message?: string;
};

export const pushProductLoad = (eventName: string, loadData: ProductLoadData): void => {
  dataLayerPush({
    dataLayer: {
      event: eventName,
      product_name: loadData.product_name,
      product_page_url: loadData.product_page_url,
      payment_term: loadData.payment_term,
      user_group: loadData.user_group,
      products_loaded_status: loadData.products_loaded_status,
      ...(loadData.notification_message && { notification_message: loadData.notification_message }),
    },
  });
};
