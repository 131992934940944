import { Result, Bindings } from '@coveo/atomic-react';

export const InstantResultsAriaLabelTemplate = ({ i18n }: Bindings, result: Result): string => {
  const information = [result.title];

  if ('ec_rating' in result.raw) {
    information.push(
      i18n.t('stars', {
        count: result.raw.ec_rating as number,
        max: 5,
      })
    );
  } else {
    information.push(i18n.t('no-ratings-available'));
  }
  /*
  if ('ec_price' in result.raw) {
    information.push(
      (result.raw.ec_price as number).toLocaleString(i18n.languages as string[], {
        style: 'currency',
        currency: 'USD',
      })
    );
  }*/

  return information.join(', ');
};

export default InstantResultsAriaLabelTemplate;
