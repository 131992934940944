import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JsonValue } from '../components/rendering-models';

type PageTitleProps = ComponentProps & {
  fields: {
    data: {
      contextItem: {
        pageTitle: JsonValue;
      };
    };
  };
};

const PageTitle = (props: PageTitleProps): JSX.Element => {
  return (
    <div className="text-center">
      <RichText
        field={props.fields?.data?.contextItem?.pageTitle?.jsonValue}
        tag="h1"
        className="rte section-heading text-center py-10 sm:py-20"
      />
    </div>
  );
};

export default PageTitle;
