import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type SubNavigationProps = {
  fields: {
    text: Field<string>;
  };
};

const ProductsSubNavigation = (props: SubNavigationProps): JSX.Element => {
  return (
    <RichText
      field={props?.fields?.text}
      tag="div"
      className="mp-products-subnav text-mp-txt-neutral"
    />
  );
};

export default ProductsSubNavigation;
