export interface AudioProductRegistrationApiProps {
  success: boolean;
  errorCode: string;
  domain: string;
}

export const validateSerialNumber = async (
  serialNumber: string
): Promise<AudioProductRegistrationApiProps> => {
  const apiUrl =
    (((process.env.PUBLIC_URL as string) +
      process.env.NEXT_PUBLIC_AVID_API_AUDIO_PRODUCT_VALIDATION) as string) +
    '?sno=' +
    serialNumber;
  return fetch(apiUrl).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to validate');
    }
    return response.json() as Promise<AudioProductRegistrationApiProps>;
  });
};
