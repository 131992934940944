import { Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ColumnedContentProps } from '../components/content-card/columned-content-props';
import ContentCard from '../components/content-card/ContentCard';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';

const ColumnedContents = (props: ColumnedContentProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource;
  const contentCards = datasource?.children?.results;
  let dynamicGridClass = '';
  if (contentCards?.length > 0) {
    switch (contentCards.length) {
      case 2:
        dynamicGridClass = 'xl:grid-cols-2';
        break;
      case 3:
        dynamicGridClass = 'xl:grid-cols-3';
        break;
      case 4:
        dynamicGridClass = 'xl:grid-cols-4';
        break;
    }
  }
  return (
    <>
      {datasource && (
        <div className="columnContents flex flex-col px-6 py-16">
          <div className="pt-12 flex flex-col items-start xl:flex-row xl:justify-between">
            <Text
              tag="h2"
              field={datasource.heading.jsonValue}
              className="text-left text-almost-black uppercase mb-6 xl:w-9/12"
            />

            <Link
              field={datasource.ctaButton.jsonValue}
              linkCustomizations={datasource}
              className="btn-outline"
            />
          </div>
          {datasource.heading.jsonValue && <span className="border border-base-dark mt-8 mb-2" />}
          {contentCards && contentCards.length > 0 && (
            <div className={`grid grid-cols-1 gap-x-8 md:grid-cols-2 gap-y-16 ${dynamicGridClass}`}>
              {contentCards.map((card, index) => {
                card.columnCount = contentCards.length;
                if (index < contentCards.length - 1) {
                  return (
                    <div key={card.id} className="flex flex-col">
                      <ContentCard {...card} />
                      <span
                        key={index}
                        className="border border-base-dark mt-6 md:hidden w-[100%]"
                      />
                    </div>
                  );
                } else {
                  return <ContentCard key={card.id} {...card} />;
                }
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withDatasourceCheck()<ColumnedContentProps>(ColumnedContents);
