import {
  Field,
  LinkField,
  RichText,
  isEditorActive,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { BaseCarouselProps } from 'components/carousel/BaseCarouselProps';
import Carousel from 'components/carousel/Carousel';
import { ComponentProps } from 'lib/component-props';
import { CoveoArticleSearchResponseModel, getArticles } from 'lib/coveo/coveo-api-search';
import { useEffect, useState } from 'react';
import ArticlesCardCarouselManualCard from './ArticlesCardCarouselManualCard';

type ArticlesCardCarouselProps = ComponentProps &
  BaseCarouselProps & {
    fields: {
      heading: Field<string>;
      link: LinkField;
      linklabel: Field<string>;
      noFollow?: Field<boolean>;
      product: Field<string>;
      anchorLinkId: Field<string>;
    };
  };

const ArticlesCardCarousel = (props: ArticlesCardCarouselProps): JSX.Element => {
  const editorActive = isEditorActive();
  const context = useSitecoreContext();
  const product = props?.fields?.product?.value;
  const [articles, setArticles] = useState<CoveoArticleSearchResponseModel[]>([]);
  const contextLang = context.sitecoreContext.language as string;
  useEffect(() => {
    getArticles(product, contextLang?.toString()).then(
      (response: CoveoArticleSearchResponseModel[]) => {
        setArticles(response);
      }
    );
  }, [contextLang, product]);

  return (
    <div
      className="my-10 xl:my-20"
      {...(props?.fields?.anchorLinkId?.value ? { id: props?.fields?.anchorLinkId?.value } : {})}
    >
      <RichText
        className="rte text-center h2 uppercase tracking-[0.28rem] mb-8"
        field={props.fields?.heading}
        tag="h3"
      />
      <div className="max-w-xl xl:max-w-content mx-3 sm:mx-auto">
        {!editorActive && props.params && (
          <Carousel
            MaxItemsPerSlideOnDesktop={props.params.MaxItemsPerSlideOnDesktop}
            MaxItemsPerSlideOnTablet={props.params.MaxItemsPerSlideOnTablet}
            MaxItemsPerSlideOnMobile={props.params.MaxItemsPerSlideOnMobile}
            desktopSize={1199}
          >
            {articles?.map((item, idx) => {
              return (
                <ArticlesCardCarouselManualCard
                  {...item}
                  linklabel={props.fields.linklabel}
                  key={idx}
                />
              );
            })}
          </Carousel>
        )}
        <div
          className={`text-center  ${
            props.fields?.link?.value?.href != '' ? 'mt-12' : ''
          } flex justify-center`}
        >
          <NextLink
            className={`btn-outline`}
            linkCustomizations={props.fields}
            field={props.fields?.link}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticlesCardCarousel;
