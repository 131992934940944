import { ssrError } from 'lib/component-props';

export class SsrErrorPropsCreator {
  private errorList: ssrError[];

  constructor() {
    this.errorList = [];
  }
  logError(err): void {
    this.errorList.push(err);
  }
  createProperty(componentName: string, propsObj: object): object {
    const ssrErrors = {
      errorList: this.errorList,
      componentName,
    };

    return {
      ...propsObj,
      ssrErrors,
    };
  }
}
