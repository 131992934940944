import {
  withDatasourceCheck,
  Placeholder,
  Field,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

export type IntroBlockContactProps = ComponentProps & {
  fields: {
    overviewHeading: Field<string>;
    overviewDescription: RichTextField;
    sidebarHeading: Field<string>;
  };
};

const IntroBlockContact = (props: IntroBlockContactProps): JSX.Element => {
  return (
    <div className="mx-auto grid grid-cols-1 lg:grid-cols-12 box-content max-w-content px-6 py-10 xl:px-24 lg:py-20 lg:px-8    justify-center">
      <div className=" text-almost-black mr-0 mb-8 lg:mb-0 lg:mr-4 lg:col-span-7 xl:col-start-2 xl:col-span-6">
        <Text
          tag="div"
          className="text-left font-normal  text-xl leading-7.5 lg:leading-9  lg:text-2xl mx-auto pb-3"
          field={props?.fields?.overviewHeading}
        />

        <RichText
          className="rte text-left [&_a]:underline [&_a]:text-primary font-normal text-base"
          field={props?.fields?.overviewDescription}
          tag="div"
        />
      </div>
      <div className=" bg-base-lighter border border-base-light rounded  ml-0 p-6 lg:p-8 lg:ml-4 lg:col-span-5 xl:col-span-4 ">
        <Text tag="h4" className="text-almost-black" field={props?.fields?.sidebarHeading} />
        <Placeholder
          name="jss-intro-blocks-sidebar-section-container"
          rendering={props?.rendering}
        />
      </div>
    </div>
  );
};

export default withDatasourceCheck()<IntroBlockContactProps>(IntroBlockContact);
