import { ComponentProps } from 'lib/component-props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  CategoryType,
  DetailType,
} from '../components/requirements-and-specifications-table/models';
import {
  Text,
  RichText,
  isEditorActive,
  withDatasourceCheck,
  GetStaticComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { getRequirementsAndSpecificationsTableData } from '../components/requirements-and-specifications-table/requirements-and-specifications-table-utils';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { GetClassFromTheme } from '../lib/utils/button-theme';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';

type RequirementsAndSpecificationsTableProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        categoryAttributes: {
          results: Array<CategoryType>;
        };
      };
    };
  };
};

const jsxDetail = (detail: DetailType, columnCountClass: string): JSX.Element => {
  const buttonThemeClass = GetClassFromTheme(
    detail.theme?.jsonValue?.fields?.value?.value ?? 'small primary'
  );
  return (
    <td key={detail.id} className={`${columnCountClass} px-3 py-3.5`}>
      {detail.icon && (
        <>
          {detail?.icon?.targetItem?.value?.jsonValue?.value == 'checkmark' && (
            <FontAwesomeIcon
              className={`${
                detail?.color?.jsonValue?.fields?.value.value == 'primary' ? 'text-avidpurple' : ''
              }`}
              icon={faCheck}
            />
          )}

          {detail?.icon?.targetItem?.value?.jsonValue?.value == 'x' && (
            <FontAwesomeIcon
              className={`${
                detail?.color?.jsonValue?.fields?.value.value == 'primary' ? 'text-avidpurple' : ''
              }`}
              icon={faClose}
            />
          )}
        </>
      )}

      {detail.detail && (
        <>
          <RichText
            className="rte [&>a]:underline [&>a]:text-primary"
            field={detail.detail.jsonValue}
          />
          {detail.link?.jsonValue?.value?.href && (
            <div className="pt-6">
              <NextLink field={detail.link.jsonValue} className={buttonThemeClass} />
            </div>
          )}
        </>
      )}
    </td>
  );
};

const jsxAttributeTable = (category: CategoryType, columnCountClass: string): JSX.Element => {
  return (
    <table className="w-full">
      <tbody>
        {category.attributeDetails?.results?.map((attribute) => {
          return (
            <>
              <tr
                key={attribute.id}
                className="first:font-bold first:border-b-2 text-almost-black border-t border-neutral-lighter"
              >
                <td className={`${columnCountClass} whitespace-nowrap px-3 py-3.5 font-bold`}>
                  <Text field={attribute.title.jsonValue} />
                </td>

                {attribute.details.results.map((detail) => jsxDetail(detail, columnCountClass))}
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};

const jsxCategory = (category: CategoryType, columnCountClass: string): JSX.Element => {
  const editorActive = isEditorActive();
  return (category && category.attributeDetails?.results?.length > 0) || editorActive ? (
    <details
      key={category.id}
      className="flex border border-neutral-lighter border-b-0 last:border-b [&>summary>svg]:open:rotate-180"
    >
      <summary className="px-3 py-3.5 cursor-pointer list-none">
        <FontAwesomeIcon
          icon={faChevronDown}
          size="xs"
          className="mr-3 text-primary transition-transform duration-300"
        />
        <Text
          tag="span"
          className="accent-2 uppercase tracking-[4px]"
          field={category.title.jsonValue}
        />
      </summary>

      <section className="overflow-x-auto">{jsxAttributeTable(category, columnCountClass)}</section>
    </details>
  ) : (
    <></>
  );
};

const Default = (props: RequirementsAndSpecificationsTableProps): JSX.Element => {
  useSSRErrorsHandler(props.ssrErrors);
  const categories = props?.fields?.data?.datasource?.categoryAttributes?.results;
  const editorActive = isEditorActive();
  const editorClass = editorActive && (categories == null || !categories.length) ? '' : 'hidden';
  if (categories != null && categories.length) {
    const columnItems = categories[0]?.attributeDetails?.results[0]?.details?.results?.length;

    const columnCount = columnItems ? columnItems + 1 : 4;
    const columnCountClass = `w-1/${columnCount ? columnCount : 4}`;

    return (
      <div className="reqs-and-specs my-16 px-5">
        <div className="max-w-content mx-auto">
          {categories.map((category) => jsxCategory(category, columnCountClass))}
        </div>
      </div>
    );
  }

  return (
    <p className={`editor-message ${editorClass}`}>
      Please configure your datasource to include categories and attributes.
    </p>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore == undefined ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const language = layoutData?.sitecore?.context?.language;

  const tablesResponse = await getRequirementsAndSpecificationsTableData(
    rendering.dataSource,
    language
  );
  if (!tablesResponse) {
    ssrErrorLogger.logError({
      functionName: 'getRequirementsAndSpecificationsTableData; ',
      errorMessage: `Failed to get response; Datasource: ${JSON.stringify(
        rendering.dataSource
      )}; Langusge: ${language};
      Response: ${JSON.stringify(tablesResponse)}`,
    });
  }

  const propsObj = {
    fields: {
      data: {
        datasource: {
          categoryAttributes: {
            results: tablesResponse,
          },
        },
      },
    },
    ...(rendering.params && { params: rendering.params }),
  };

  return ssrErrorLogger.createProperty('RequirementsAndSpecificationsTable', propsObj);
};

export default withDatasourceCheck()(Default);
