import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import { ProductDownloadOptionItem } from '../../renderings/pricing-card/pricing-card-props';
import { AvidDownloadsLinkData } from './BasePricingCard';
import { useState } from 'react';
import { useI18n } from 'next-localization';

type DownloadSectionProps = {
  DownloadOptions?: Array<ProductDownloadOptionItem>;
  MyAvidDownloadOptions?: Array<AvidDownloadsLinkData>;
  MyAvidLinks: boolean;
};

const Default = (props: DownloadSectionProps): JSX.Element => {
  const { DownloadOptions, MyAvidDownloadOptions, MyAvidLinks } = props;
  const [selectedDownloadLink, setSelectedDownloadLink] = useState('');
  const { t } = useI18n();

  const downloadOptions = MyAvidLinks
    ? MyAvidDownloadOptions?.map((downloadOption) => {
        return {
          value: downloadOption.Link ?? '',
          label: downloadOption.Description ?? '',
        };
      })
    : DownloadOptions?.map((downloadOption) => {
        return {
          value: downloadOption.fileLink.jsonValue.value.href ?? '',
          label: downloadOption.fileLink.jsonValue.value.text ?? '',
        };
      });

  const defaultDownloadOption =
    downloadOptions != null
      ? {
          value: downloadOptions[0]?.value,
          label: downloadOptions[0]?.label,
        }
      : null;

  const onChangeDownloadOptionHandler = (downloadLink: string): void => {
    setSelectedDownloadLink(downloadLink);
  };

  const onClickDownloadHandler = (): void => {
    window.open(selectedDownloadLink, '_blank');
  };

  return (
    <div>
      <div>
        {downloadOptions && (
          <DropdownMenu
            defaultValue={defaultDownloadOption}
            options={downloadOptions}
            passValueToParentFunction={onChangeDownloadOptionHandler}
            className="w-full mb-3"
          />
        )}
      </div>
      <button className="btn-primary ml-auto w-full px-8 relative" onClick={onClickDownloadHandler}>
        {t('PricingCard_DownloadNow')}
      </button>
    </div>
  );
};

export default Default;
