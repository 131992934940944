import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useEffect } from 'react';
import { fetchCartItems, getCartSummary } from './cart-slice';
import { CartSummary, GetCartItemsData } from './cart-models';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

export const useCartData = (): GetCartItemsData | null => {
  const dispatch = useAppDispatch();
  const { data: session, status } = useSession();
  const cartId = useAppSelector((state) => state.cart?.cartSummaryData?.cartId);
  const cartItems = useAppSelector((state) => state.cart?.cartItemsData);
  const cartItemStatus = useAppSelector((state) => state.cart?.cartItemsDataStatus);
  const cartSummaryDataStatus = useAppSelector((state) => state.cart?.cartSummaryDataStatus);
  const currencyIsoCode = useAppSelector((state) => state.cart?.currencyIsoCode);

  useEffect(() => {
    if (cartId == null) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    const bearerToken = getBearerToken(session);

    if (cartSummaryDataStatus == 'succeeded' && cartItemStatus != 'loading' && cartItems == null) {
      dispatch(fetchCartItems({ bearerToken: bearerToken }));
      return;
    }

    if (currencyIsoCode != cartItems?.cartSummary.currencyIsoCode) {
      //currency changed - refetch cart items
      dispatch(fetchCartItems({ bearerToken: bearerToken }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, cartId, currencyIsoCode, cartSummaryDataStatus, session, status]);

  return cartItems;
};

export const useCartSummaryData = (): CartSummary | null => {
  const dispatch = useAppDispatch();
  const { data: session, status } = useSession();
  const cartSummary = useAppSelector((state) => state.cart?.cartSummaryData);

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    const bearerToken = getBearerToken(session);
    dispatch(getCartSummary({ bearerToken: bearerToken }));
  }, [dispatch, session, status]);

  return cartSummary;
};
