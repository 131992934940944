import { Field, ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { useEffect, useState } from 'react';
import { useI18n } from 'next-localization';
import {
  validateSerialNumber,
  AudioProductRegistrationApiProps,
} from 'lib/learn/audio-product-registratio-service';
import Spinner from 'components/spinner/Spinner';

export type AudioProductRegistrationProps = {
  fields: {
    heading: Field<string>;
    image: ImageField;
    source: Field<string>;
    height: Field<string>;
  };
};
function AudioProductRegistration(props: AudioProductRegistrationProps): JSX.Element {
  const { t } = useI18n();
  const [hydrate, setHydrate] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');
  const [showError, setShowError] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setHydrate(true);
  }, []);

  const handleSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setSerialNumber(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setIsLoading(true);
    validateSerialNumber(serialNumber).then((data: AudioProductRegistrationApiProps) => {
      if (data.success === false) {
        setShowError(true);
      } else {
        setIframeSrc(props.fields.source.value.replace('{serialNumber}', serialNumber));
        setShowIframe(true);
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      {hydrate && (
        <div className="bg-slate-300 px-6 sm:my-5 lg:my-10">
          {isLoading && <Spinner />}
          {!showIframe && (
            <form onSubmit={handleSubmit}>
              <NextImage className="mb-8" field={props.fields?.image} />
              <Text
                tag="label"
                field={props.fields.heading}
                className="block mb-2 leading-24 font-bold"
              />
              <input
                name="serialNumber"
                className="block w-full max-w-sm border border-base-darker py-1.5 px-2.5 text-grayblack mb-6"
                type="text"
                placeholder="Serial Number"
                onChange={handleSerialNumberChange}
                autoComplete="false"
              />

              <button
                type="submit"
                className="btn-primary mr-3"
                disabled={serialNumber === '' || isLoading === true}
              >
                {t('LEARN_SUBMIT')}
              </button>
              {showError && <p className="text-danger py-1">{t('LEARN_AUDIO_ERROR_MESSAGE')}</p>}
            </form>
          )}

          {showIframe && (
            <iframe src={iframeSrc} width="100%" height={props.fields.height.value}></iframe>
          )}
        </div>
      )}
    </>
  );
}

export default AudioProductRegistration;
