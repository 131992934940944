import useDigitalRiverInstance from 'components/checkout/dr-elements-hook';
import { getStoredCountry } from 'lib/commerce/countries/countries-provider';
import { useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import { useEffect, useState } from 'react';

const Default = (): JSX.Element => {
  const digitalRiverInstance = useDigitalRiverInstance();
  const country = process.env.NODE_ENV == 'development' ? 'US' : getStoredCountry();
  const { locale } = useI18n();
  const checkoutData = useAppSelector((state) => state.checkout.checkoutData);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [complianceRef, setComplianceRef] = useState<any | null>();

  useEffect(() => {
    const loc = locale();
    if (digitalRiverInstance == null || loc == null || country == null) {
      return;
    }
    const complianceWrapper = document.getElementById('compliance');
    if (complianceWrapper?.hasChildNodes() === true) {
      return;
    }

    const complianceOptions = {
      compliance: {
        entity: 'C5_INC-ENTITY',
        language: locale(),
        country: country,
      },
    };

    const compliance = digitalRiverInstance.createElement('compliance', complianceOptions);
    compliance.mount('compliance');

    setComplianceRef(compliance);
  }, [digitalRiverInstance, locale, country]);

  useEffect(() => {
    if (checkoutData == null || complianceRef == null) {
      return;
    }

    const sellingEntity = checkoutData.digitalRiverResponse?.sellingEntity;
    if (sellingEntity == null) {
      return;
    }

    const complianceOptions = {
      compliance: {
        entity: sellingEntity.id,
      },
    };

    complianceRef.update(complianceOptions);
  }, [checkoutData, complianceRef]);

  return (
    <div
      className={`rte text-center [&_a]:font-bold [&_a]:text-white text-xs [&_span]:mr-2 ${
        digitalRiverInstance ? 'mb-8' : ''
      }`}
    >
      <div id="compliance"></div>
    </div>
  );
};

export default Default;
