import { useI18n } from 'next-localization';
import { ProToolsActivationStepItem } from './pro-tools-activation-steps';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type ActivationStepProps = {
  number: number;
  title: Field<string>;
};

const ActivationStep = (props: ActivationStepProps): JSX.Element => {
  return (
    <div className="flex items-center flex-col max-w-[160px]">
      <div className="h2 text-base-darker border border-base-darker rounded-full w-12 h-12 flex justify-center items-center">
        <span className="">{props.number}</span>
      </div>
      <div className="body-bold mt-4">
        <Text field={props.title} />
      </div>
    </div>
  );
};

type ActivationStepperProps = {
  steps: ProToolsActivationStepItem[];
  orderId: string | string[] | undefined;
  userId: string | undefined;
};

const ActivationStepper = (props: ActivationStepperProps): JSX.Element => {
  const { t } = useI18n();

  return (
    <div className="mt-7">
      <div className="flex justify-center items-center gap-6 mb-8 flex-col md:flex-row">
        {props.steps.map((item, index) => {
          return <ActivationStep key={item.id} number={index + 1} title={item.fields.heading} />;
        })}
      </div>
      <div>
        <a
          className="rounded bg-primary text-white text-lg px-4 py-2 font-bold"
          href={`${process.env.NEXT_PUBLIC_MYAVID_CHECKOUT_RETURN_URL}/completeaccount?orderId=${props.orderId}&verificationcode=${props.userId}`}
        >
          {t('Order_ProToolsActivationStep_GetStarted')}
        </a>
      </div>
    </div>
  );
};

export default ActivationStepper;
