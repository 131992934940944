import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type BadgeSectionProps = {
  field?: Field<string> | null;
  anyVisibleCardHasBadge: boolean;
  className: string;
};

const BadgeSection = (props: BadgeSectionProps): JSX.Element | null => {
  if (!props.anyVisibleCardHasBadge) {
    return null;
  }

  const badgeField = props.field;
  if (badgeField == null) {
    return null;
  }
  return (
    <>
      <p
        className={`text-info text-sm bg-info-light px-2 rounded-[10px] font-bold uppercase min-h-[20px] ${
          !badgeField.value && 'invisible'
        } ${props.className}`}
      >
        {badgeField.value ? <Text tag="span" field={badgeField} /> : 'n/a'}
      </p>
    </>
  );
};

export default BadgeSection;
