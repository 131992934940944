/* eslint-disable @typescript-eslint/no-explicit-any */
import { useScript } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';

declare let DigitalRiver: any;

const useDigitalRiverInstance = (): any => {
  const status = useScript('https://js.digitalriverws.com/v1/DigitalRiver.js');
  const [drInstance, setDrInstance] = useState<any>(null);

  useEffect(() => {
    if (status === 'ready') {
      const digitalriver = new DigitalRiver(process.env.NEXT_PUBLIC_DIGITALRIVER_CLIENTKEY, {
        locale: 'en_US', //TODO: Pull from somewhere
      });

      setDrInstance(digitalriver);
    } else {
      setDrInstance(null);
    }
  }, [status]);

  return drInstance;
};

export default useDigitalRiverInstance;
