import gql from 'graphql-tag';

const getVideoQuery = gql`
  # Write your query or mutation here
  query VideoQuery($path: String!, $language: String!) {
    item(path: $path, language: $language) {
      id
      template {
        id
      }
      ... on _BaseVideo {
        videoTitle {
          value
        }
        shortDescription {
          value
        }
        fullDescription {
          value
        }
      }

      ... on _BrightcoveVideoReference {
        videoReference {
          targetItems {
            id
            brightVideoId: field(name: "ID") {
              value
            }
          }
        }
      }
      ... on _VideoSourceId {
        sourceId {
          value
        }
      }
    }
  }
`;

export default getVideoQuery;
