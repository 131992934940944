import { ComponentProps } from 'lib/component-props';
import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import { useI18n } from 'next-localization';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { getProductTierDetails } from 'lib/commerce/product/product-slice';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { DropdownItem } from './dropdown';
import { AllProductVariationsListItem } from 'src/renderings/MultiTierPricingCard';
import { useSearchParams } from 'next/navigation';

type InlineHeadingAndDropdownProps = ComponentProps & {
  fields: {
    productTableId: Field<string>;
    frequencyTypeOptions: Array<DropdownItem>;
    defaultFrequencyType: Array<DropdownItem>;
    userTypeOptions: Array<DropdownItem>;
    defaultUserType: Array<DropdownItem>;
  };
  headerText: string;
  orientation: 'horizontal' | 'vertical';
  passUserTypeToParentFunction?: (userType: string) => void;
  passFrequencyTypeToParentFunction?: (userType: string) => void;
  allTierData?: AllProductVariationsListItem[];
};

const InlineHeadingAndDropdown = (props: InlineHeadingAndDropdownProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const versionParams = useSearchParams();
  const versionParamsFrequencyType = versionParams.get('show');
  const versionParamsUserType = versionParams.get('pricing');
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode) as string;
  const defaultUserType =
    props.fields?.defaultUserType != null ? props.fields?.defaultUserType[0] : null;
  const defaultFrequencyType =
    props.fields?.defaultFrequencyType != null ? props.fields.defaultFrequencyType[0] : null;

  const defaultFrequencyTypeOptionObject =
    defaultFrequencyType != null
      ? {
          value: defaultFrequencyType.fields.value.value,
          label: defaultFrequencyType.fields.title.value,
        }
      : null;
  const [defaultFrequencyTypeOption, setdefaultFrequencyTypeOption] = useState(
    defaultFrequencyTypeOptionObject
  );

  const [frequencyTypeOptions, setFrequencyTypeOptions] = useState<
    { value: string; label: string }[] | null
  >(null);

  const userTypeOptions =
    props.fields?.userTypeOptions != null
      ? props.fields.userTypeOptions.map((item) => {
          return { value: item.fields.value.value, label: item.fields.title.value };
        })
      : null;
  const defaultUserTypeOptionObject =
    defaultUserType != null
      ? {
          value: defaultUserType.fields.value.value,
          label: defaultUserType.fields.title.value,
        }
      : null;
  const [defaultUserTypeOption, setdefaultUserTypeOption] = useState(defaultUserTypeOptionObject);

  const [selectedFrequencyType, setSelectedFrequencyType] = useState(
    defaultFrequencyType != null ? defaultFrequencyType.fields.value.value : null
  );
  const [selectedUserType, setSelectedUserType] = useState(
    defaultUserType != null ? defaultUserType.fields.value.value : null
  );
  useEffect(() => {
    // setting default values for fetching  details
    const displayUserTypeOption =
      versionParamsUserType &&
      props.fields?.userTypeOptions?.find(
        (type) => type && type.fields.value.value === versionParamsUserType
      );
    props.fields?.defaultUserType &&
      displayUserTypeOption &&
      Object.assign(props.fields?.defaultUserType[0], displayUserTypeOption);

    const displayFrequencyTypeOption =
      versionParamsFrequencyType &&
      props.fields?.frequencyTypeOptions?.find(
        (type) => type && type.fields.value.value === versionParamsFrequencyType
      );
    props.fields?.defaultFrequencyType &&
      displayFrequencyTypeOption &&
      Object.assign(props.fields?.defaultFrequencyType[0], displayFrequencyTypeOption);

    //setting values for dropdown displays

    if (versionParamsUserType) {
      const userTypes = displayUserTypeOption && {
        value: displayUserTypeOption.fields.value.value,
        label: displayUserTypeOption.fields.title.value,
      };
      if (userTypes != null && userTypes != undefined) {
        userTypes && setdefaultUserTypeOption(userTypes);
        setSelectedUserType(versionParamsUserType);
      }
    }
    if (versionParamsFrequencyType) {
      const frequencyTypes = displayFrequencyTypeOption && {
        value: displayFrequencyTypeOption.fields.value.value,
        label: displayFrequencyTypeOption.fields.title.value,
      };
      if (frequencyTypes != null && frequencyTypes != undefined) {
        frequencyTypes && setdefaultFrequencyTypeOption(frequencyTypes);
        setSelectedFrequencyType(versionParamsFrequencyType);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionParamsUserType, versionParamsFrequencyType]);

  useEffect(() => {
    if (
      currencyIsoCode == null ||
      selectedFrequencyType == null ||
      selectedUserType == null ||
      props.fields?.productTableId == null
    ) {
      return;
    }

    dispatch(
      getProductTierDetails({
        productTierId: props.fields.productTableId.value,
        frequencyType: selectedFrequencyType,
        userType: selectedUserType,
        currencyIsoCode: currencyIsoCode,
      })
    );
  }, [dispatch, selectedFrequencyType, selectedUserType, currencyIsoCode, props.fields]);

  useEffect(() => {
    if (!props.allTierData) {
      props.fields?.frequencyTypeOptions != null &&
        setFrequencyTypeOptions(
          props.fields.frequencyTypeOptions.map((item) => {
            return { value: item.fields.value.value, label: item.fields.title.value };
          })
        );
      return;
    }
    const getFrequencyTypesOptions = ():
      | {
          value: string;
          label: string;
        }[]
      | null => {
      if (props.fields?.frequencyTypeOptions == null) {
        return null;
      }
      const filteredOptions = props.fields.frequencyTypeOptions.filter((freqType) => {
        return props.allTierData?.find((tierItem) => {
          const tierItemVars = tierItem.variants.find(
            (variant) => variant.currency === currencyIsoCode
          );
          return (
            tierItem.userType.value.jsonValue.value === selectedUserType &&
            tierItem.frequencyType.value.jsonValue.value === freqType.fields.value.value &&
            tierItemVars?.variants.length &&
            tierItemVars?.variants.length > 0
          );
        });
      });
      return filteredOptions.map((item) => {
        return { value: item.fields.value.value, label: item.fields.title.value };
      });
    };
    const ferquencyTypeOptionSet = getFrequencyTypesOptions();
    setFrequencyTypeOptions(ferquencyTypeOptionSet);
    if (!ferquencyTypeOptionSet?.find((type) => type.value === selectedFrequencyType)) {
      const targetOption = document.getElementById('frequency-type-dropdown-wrapper');
      targetOption?.getElementsByTagName('a')[0]?.click();
    }
  }, [currencyIsoCode, props, selectedFrequencyType, selectedUserType]);

  const onChangeFrequencyTypeHandler = (frequencyType: string): void => {
    setSelectedFrequencyType(frequencyType);
    if (props.passFrequencyTypeToParentFunction)
      props.passFrequencyTypeToParentFunction(frequencyType);
  };

  const onChangeUserTypeHandler = (userType: string): void => {
    setSelectedUserType(userType);
    if (props.passUserTypeToParentFunction) props.passUserTypeToParentFunction(userType);
  };

  if (
    frequencyTypeOptions == null ||
    userTypeOptions == null ||
    defaultFrequencyTypeOption == null ||
    defaultUserTypeOption == null
  ) {
    return <div className="editor-message">Please select frequency type and user type values.</div>;
  }

  return (
    <div
      id="inline-heading-and-dropdown"
      className={`flex flex-col justify-between items-center mb-4 lg:mb-10 ${
        props.orientation == 'horizontal' ? ' lg:flex-row' : ''
      }`}
    >
      <div
        className={`h2 uppercase mb-2 md:mb-5 tracking-widest md:tracking-widest-plus ${
          props.orientation == 'horizontal' ? ' lg:mb-0' : 'lg:mb-7'
        }`}
      >
        <span>{props.headerText}</span>
      </div>
      <div className="px-4 sm:px-0 w-full sm:w-fit flex items-end flex-col sm:flex-row">
        <div
          id="frequency-type-dropdown-wrapper"
          className="w-full flex items-center mb-2 sm:w-fit sm:mb-0 sm:mr-2"
        >
          <p className="hidden sm:block body mr-2">{t('MultiTierPricingCard_Heading_Show')}</p>
          <DropdownMenu
            defaultValue={defaultFrequencyTypeOption}
            options={frequencyTypeOptions}
            passValueToParentFunction={onChangeFrequencyTypeHandler}
            className="w-full md:w-fit"
          />
        </div>

        <div id="user-type-dropdown-wrapper" className="w-full sm:w-fit flex items-center">
          <p className="hidden sm:block body mr-2 whitespace-nowrap">
            {t('MultiTierPricingCard_Heading_PricingFor')}
          </p>
          <DropdownMenu
            defaultValue={defaultUserTypeOption}
            options={userTypeOptions}
            passValueToParentFunction={onChangeUserTypeHandler}
            className="w-full md:w-fit"
          />
        </div>
      </div>
    </div>
  );
};

export default InlineHeadingAndDropdown;
