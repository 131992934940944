/* eslint-disable @typescript-eslint/no-explicit-any */
import { Placeholder, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Modal from '../../components/foundation/non-sitecore/Modal';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { ComponentProps } from 'lib/component-props';

type GeneralModalProps = ComponentProps & {
  fields: {
    title: Field<string>;
    uniqueId: Field<string>;
  };
  isactive: boolean;
  onModalOpen?: (openModalId: string) => void;
};
declare const window: any;

const GeneralModal = (props: GeneralModalProps): JSX.Element => {
  const editorActive = isEditorActive();
  const title = props.fields?.title ?? '';
  useEffect(() => {
    if (window && typeof window != 'undefined' && window.document) {
      window.openClientModal = (openModalId: string): void => {
        props.onModalOpen && props.onModalOpen(openModalId ?? '');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCloseModalHandler = (): void => {
    props.onModalOpen && props.onModalOpen('');
    document.body.style.overflow = 'unset';
  };

  if (editorActive || editorActive == undefined) {
    return (
      <div className={`w-full max-w-content mx-auto p-1 border m-2 border-x-avidgray200 rounded`}>
        <p className="p-2">
          <b>Title :</b> <Text tag="span" field={props.fields?.title} />
        </p>
        <p className="p-2 border-b-2 border-avid-backdrop">
          <b>Unique Id:</b> <Text tag="span" field={props.fields?.uniqueId} />
        </p>

        <Placeholder name="jss-modal-container" rendering={props.rendering} />
      </div>
    );
  } else {
    return (
      <>
        <div className={editorActive ? '' : 'hidden'}>
          <Placeholder name="jss-modal-container" rendering={props.rendering} />
        </div>
        {props.isactive && (
          <div className={`w-full max-w-content`}>
            <Modal
              onClose={onClickCloseModalHandler}
              show={props.isactive}
              className=" w-fit  !block max-h-[calc(100vh_-_40px)] max-w-[calc(100vw_-_40px)] xl:max-w-content xl:max-h-[calc(100vh_-_100px)] overflow-auto"
              useModalBackdrop={true}
              titleClassName="text-almost-black"
              rootId="modal-root"
            >
              <div id="mainVisibleModal">
                <div className={`flex   pb-2 ${title ? 'justify-between' : 'justify-end'}`}>
                  <h4>{title?.value}</h4>
                  <div>
                    <button type="button" onClick={onClickCloseModalHandler}>
                      <FontAwesomeIcon icon={faXmark} className="text-black w-full " />
                      <span className="sr-only">Close</span>
                    </button>
                  </div>
                </div>
                <Placeholder name="jss-modal-container" rendering={props.rendering} />
              </div>
            </Modal>
          </div>
        )}
      </>
    );
  }
};

export default GeneralModal;
