import { stringify } from 'querystring';
import { AvidDownloadsLinkData } from '../../components/pricing-card/BasePricingCard';
export const getLegacyDownloads = async (
  productCode: string,
  language: string
): Promise<AvidDownloadsLinkData[]> => {
  const queryParams: Record<string, string> = {
    productCode,
    language,
  };

  const queryParamsString = stringify(queryParams);
  const apiUrl =
    (((process.env.PUBLIC_URL as string) +
      process.env.NEXT_PUBLIC_AVID_API_PRODUCT_DOWNLOADS) as string) + `?${queryParamsString}`;
  return fetch(apiUrl).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to validate');
    }
    return response.json() as Promise<AvidDownloadsLinkData[]>;
  });
};
