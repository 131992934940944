import gql from 'graphql-tag';

const getTableContentQuery = gql`
  query TableContentQuery($datasource: String!, $language: String!, $after: String) {
    datasource: item(path: $datasource, language: $language) {
      id
      name
      rowAttributes: children(first: 25, after: $after) {
        pageInfo {
          hasNext
          endCursor
        }
        results {
          ... on Row {
            columnAttributes: children {
              results {
                ... on ColumnTextDetails {
                  detail {
                    value
                    jsonValue
                  }
                  link {
                    jsonValue
                  }
                  theme {
                    jsonValue
                  }
                }

                ... on ColumnIconDetails {
                  icon: field(name: "icon") {
                    ... on LookupField {
                      targetItem {
                        ... on ReferenceStringValue {
                          value {
                            value
                            jsonValue
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getTableContentQuery;
