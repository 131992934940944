import {
  AtomicResultSectionExcerpt,
  AtomicResultSectionTitle,
  AtomicResultSectionTitleMetadata,
  AtomicResultSectionBadges,
  Result,
  AtomicResultHtml,
} from '@coveo/atomic-react';
// import SitecoreTaxonomyTags from '../atomic-components/SitecoreTaxonomyTags';
import ClickableLink from '../../atomic-components/ClickableLink';
import SearchTitle from '../../atomic-components/SearchTitle';

const styles = `
    atomic-result-section-badges,
    atomic-result-section-title,
    atomic-result-section-excerpt {
      margin-bottom: 8px!important;      
    }
  
    atomic-result-section-badges {
      overflow: visible !important;
    }
  
    atomic-result-date {
      color: #222222;
      font-size: .75rem;
      letter-spacing: .2em;
      line-height: 1.125rem;
      text-transform: uppercase;
    }
  
    @media (min-width: 992px) {
      atomic-result-date {
        font-size: .8125rem;
        line-height: 1.1875rem;
      }
    }
  
    .intro-text {
      color: #194BF5;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  
    @media (min-width: 992px) {
      .intro-text {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
  
    atomic-html,
    .body {
      color: #222222;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    .body-link {
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .rs-search-desc {
      max-height: 3rem !important;
    }
    .atomic-result-date {
      color: #222222;
      font-size: .75rem;
      letter-spacing: .2em;
      line-height: 1.125rem;
      text-transform: uppercase;
    }
  
    @media (min-width: 992px) {
      .atomic-result-date {
        font-size: .8125rem;
        line-height: 1.1875rem;
      }
    }
  `;

export const T14SitecorePageTemplate: React.FC<{ result: Result }> = ({ result }) => {
  const { clickUri } = result;
  const { searchtitle, articlepublisheddate } = result.raw;
  let formattedDate: string = '';

  if (articlepublisheddate) {
    const date = new Date(parseInt(articlepublisheddate as string, 10));
    if (!isNaN(date.getTime())) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      formattedDate = date.toLocaleDateString('en-US', options);
    }
  }

  return (
    <>
      <style>{styles}</style>
      <AtomicResultSectionBadges>
        <span className="atomic-result-date">{formattedDate}</span>
      </AtomicResultSectionBadges>

      <AtomicResultSectionTitle>
        <SearchTitle searchTitle={searchtitle as string} href={clickUri} />
      </AtomicResultSectionTitle>

      <AtomicResultSectionExcerpt className="rs-search-desc">
        <AtomicResultHtml field="searchdescription" />
      </AtomicResultSectionExcerpt>

      <AtomicResultSectionTitleMetadata>
        <ClickableLink field="clickUri" href={clickUri} />
      </AtomicResultSectionTitleMetadata>
    </>
  );
};

export default T14SitecorePageTemplate;
