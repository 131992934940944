type OrderWrapperProps = {
  title?: JSX.Element | string | null;
  children: JSX.Element | JSX.Element[] | boolean;
};

const OrderWrapper = (props: OrderWrapperProps): JSX.Element => {
  return (
    <div className="bg-base-lighter">
      <div className="w-full max-w-screen-2xl mx-auto py-24 text-almost-black">
        {props.title && (
          <h2 className="text-center uppercase text-3xl lg:text-4xl leading-normal tracking-[0.28rem] mb-16">
            {props.title}
          </h2>
        )}

        <div className="border rounded border-base-normal max-w-screen-lg bg-white px-6 py-12 mx-6 xl:mx-auto relative">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default OrderWrapper;
