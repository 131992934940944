import { Text, Field, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import React from 'react';
import { useMemo } from 'react';
import { useSession } from 'next-auth/react';
import { useAppDispatch } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import { addCartItem } from 'components/cart/cart-slice';
import ProductPromoLabel from './ProductPromoLabel';
import Spinner from 'components/spinner/Spinner';
import { TrendingProductData } from '../../renderings/TrendingProducts';
import { getBearerToken } from 'lib/authentication/account-provider';

type TrendingProductsListProps = {
  learnMoreText: Field<string>;
  index: number;
  cartItemsIds?: string[];
  product: TrendingProductData;
};

const TrendingProductsList = (props: TrendingProductsListProps): JSX.Element => {
  const editorActive = isEditorActive();
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const productPricing = props.product.productPricing;
  const currencyIsoCode = props.product.productPricing?.currencyIsoCode;
  const lowestPrice = productPricing?.lowPrice?.unitPrice;
  const highestPrice = productPricing?.highPrice?.unitPrice;
  const hasPriceRange = lowestPrice && highestPrice && lowestPrice < highestPrice;
  const isDiscounted =
    !hasPriceRange &&
    productPricing?.lowPrice?.listPrice &&
    productPricing?.lowPrice?.unitPrice &&
    productPricing?.lowPrice?.unitPrice < productPricing?.lowPrice?.listPrice;
  const discountValue = Math.round(
    100 -
      ((productPricing?.lowPrice?.unitPrice ?? 1) / (productPricing?.lowPrice?.listPrice ?? 1)) *
        100
  );
  const productId = hasPriceRange ? undefined : productPricing?.lowPrice?.productId;
  const isInCart = !hasPriceRange && props.cartItemsIds?.includes(productId ?? '');
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const buyNowHandler = (event: React.MouseEvent<HTMLElement>): void => {
    const target = event.currentTarget as HTMLButtonElement;
    target.disabled = true;
    productId &&
      dispatch(addCartItem({ sku: productId, quantity: 1, bearerToken: bearerToken })).catch(
        (error) => {
          console.log(
            `Trending Products: >> Can't add to cart, product ${productId}: ${error.message}`
          );
        }
      );
  };

  const renderPrice = (): JSX.Element => {
    if (hasPriceRange) {
      return (
        <div className="inline-block text-mp-txt-bright">
          {t(`Currency_Symbol_${currencyIsoCode}`)}
          {lowestPrice} - {highestPrice}
        </div>
      );
    } else if (!hasPriceRange && isDiscounted) {
      return (
        <div className="inline-block">
          <div className="text-mp-txt-neutral text-xs line-through">
            <span>
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {productPricing?.lowPrice?.listPrice}
            </span>
          </div>
          <div className="text-mp-txt-primary">
            <span>
              {productPricing?.lowPrice?.unitPrice && productPricing?.lowPrice?.unitPrice > 0
                ? t(`Currency_Symbol_${currencyIsoCode}`)
                : ''}
              {productPricing?.lowPrice?.unitPrice && productPricing?.lowPrice?.unitPrice > 0
                ? productPricing?.lowPrice?.unitPrice
                : t('MP_Free_Price')}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="inline-block text-mp-txt-bright">
          {productPricing?.lowPrice?.unitPrice && productPricing?.lowPrice?.unitPrice > 0
            ? t(`Currency_Symbol_${currencyIsoCode}`)
            : ''}
          {productPricing?.lowPrice?.unitPrice && productPricing?.lowPrice?.unitPrice > 0
            ? productPricing?.lowPrice?.unitPrice
            : t('MP_Free_Price')}
        </div>
      );
    }
  };

  return (
    <div key={props?.index} className="trending-product-card relative w-full m-auto">
      {isDiscounted && <ProductPromoLabel promoValue={`${discountValue}% off`} />}
      <div
        className={`inline-block text-mp-txt-bright min-h-300 w-full px-[10px] md:px-2 pt-[14px]
          `}
      >
        <div className="h-34 w-full relative overflow-hidden border border-mp-background-card">
          <a href={props.product.url.url}>
            <NextImage
              field={props.product.searchImage.jsonValue}
              className="absolute h-auto"
              useAvidCdn={true}
            />
          </a>
        </div>
        <div className="py-2 pl-3 pr-4 bg-mp-background-card md:h-43 relative ">
          {(props.product.company.targetItem?.termName?.value || editorActive) && (
            <Text
              tag="p"
              field={props.product.company.targetItem?.termName}
              className="uppercase text-mp-txt-neutral text-xxs mb-2"
            />
          )}

          {(props.product.searchTitle?.value || editorActive) && (
            <a href={props.product.url.url}>
              <Text
                tag="p"
                field={props.product.searchTitle}
                className="text-mp-txt-bright text-base w-full overflow-hidden overflow-ellipsis"
              />
            </a>
          )}
          {props.product.searchDescription?.value || editorActive ? (
            <Text
              tag="p"
              field={props.product.searchDescription}
              className={`text-mp-txt-neutralLight text-sm w-full overflow-hidden overflow-ellipsis ${
                isDiscounted ? 'min-h-[45px]' : 'min-h-[60px]'
              }`}
            />
          ) : (
            <p
              className={`text-mp-txt-neutral text-xxs w-full overflow-hidden overflow-ellipsis ${
                isDiscounted ? 'min-h-[45px]' : 'min-h-[60px]'
              }`}
            >
              no description
            </p>
          )}
          <div className="">
            {productPricing == null && <Spinner color="#20BBD0" />}
            {(productPricing || editorActive) && renderPrice()}
            {productPricing && !hasPriceRange && (
              <div className="float-right inline-block relative">
                {
                  <button
                    className={`${
                      isInCart
                        ? 'bg-mp-btn-disabled text-mp-txt-bright'
                        : 'bg-mp-btn-primary text-mp-txt-bright hover:bg-mp-btn-light hover:text-mp-txt-almostBlack'
                    } ${
                      isDiscounted ? 'mt-4' : ''
                    } text-sm px-2.5 sm:px-1.5 md:px-2 xl:px-2.5 py-1 mr-1 rounded disabled:bg-mp-btn-disabled`}
                    onClick={(e): void => buyNowHandler(e)}
                    disabled={isInCart}
                  >
                    {`${isInCart ? t('MP_In_Cart') : t('MP_Buy_Now')}`}
                  </button>
                }
              </div>
            )}
          </div>
          {productPricing && (
            <div className="">
              <a
                href={props.product.url.url}
                className="text-mp-txt-bright text-sm underline cursor-pointer"
              >
                {t('LearnMore')}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrendingProductsList;
