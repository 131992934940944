import gql from 'graphql-tag';

const getItemIdFromProductIdQuery = gql`
  query GetProductItemId($language: String!, $productId: String!) {
    search(
      where: {
        AND: [
          { name: "_path", value: "{6219196D-1E69-4AC6-8D24-997042832356}", operator: CONTAINS }
          {
            name: "_templates"
            value: "{FB34F438-7127-4086-8A72-E4DCEFA89A11}"
            operator: CONTAINS
          }
          { name: "_language", value: $language }
          { name: "productId", value: $productId }
        ]
      }
    ) {
      results {
        ... on Item {
          id
        }
      }
    }
  }
`;

export default getItemIdFromProductIdQuery;
