/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SearchBox,
  buildSearchBox,
  buildResultList,
  buildFacet,
  buildPager,
  buildInstantResults,
  SortCriterion,
  buildRelevanceSortCriterion,
  buildDateSortCriterion,
  buildFieldSortCriterion,
  SortOrder,
  buildSort,
} from '@coveo/headless';
import {
  buildFrequentlyViewedTogetherList,
  buildCartRecommendationsList,
  buildFrequentlyBoughtTogetherList,
  buildPopularBoughtRecommendationsList,
  buildPopularViewedRecommendationsList,
  buildUserInterestRecommendationsList,
  FrequentlyBoughtTogetherList,
  CartRecommendationsList,
  PopularBoughtRecommendationsList,
  FrequentlyViewedTogetherList,
  PopularViewedRecommendationsList,
  UserInterestRecommendationsList,
} from '@coveo/headless/product-recommendation';

import {
  frequentlyViewedTogetherListPREngine,
  cartRecommendationsListPREngine,
  frequentlyBoughtTogetherListPREngine,
  CoveoRecommendCustomFields,
  popularBoughtRecommendationsListPREngine,
  popularViewedRecommendationsListPREngine,
  userInterestRecommendationsListPREngine,
} from './engine-coveo-recommendations';
import { headlessEngine } from './engine-coveo-recommendations';

export const searchBox: SearchBox = buildSearchBox(headlessEngine);

export const resultList = buildResultList(headlessEngine);
export const instantResults = buildInstantResults(headlessEngine, {
  options: { maxResultsPerQuery: 1 },
});

export const categoryFacet = buildFacet(headlessEngine, {
  options: { field: 'ec_category' },
});
export const colorFacet = buildFacet(headlessEngine, {
  options: { field: 'cat_color' },
});
export const levelFacet = buildFacet(headlessEngine, {
  options: { field: 'cat_level' },
});

export const pager = buildPager(headlessEngine);

export const criteria: [string, SortCriterion][] = [
  ['Relevance', buildRelevanceSortCriterion()],
  ['Date (Ascending)', buildDateSortCriterion(SortOrder.Ascending)],
  ['Size (Ascending)', buildFieldSortCriterion('size', SortOrder.Ascending)],
];
const initialCriterion = criteria[0][1];
export const sort = buildSort(headlessEngine, {
  initialState: { criterion: initialCriterion },
});

let cartRecommendationsListController: CartRecommendationsList | null = null;
export const getCartRecommendationsListController = (maxNumberOfRecommendations: number = 3) => {
  if (!cartRecommendationsListController) {
    cartRecommendationsListController = buildCartRecommendationsList(
      cartRecommendationsListPREngine,
      {
        options: {
          maxNumberOfRecommendations,
          additionalFields: CoveoRecommendCustomFields,
        },
      }
    );
  }
  return cartRecommendationsListController;
};

let frequentlyBoughtTogetherListController: FrequentlyBoughtTogetherList | null = null;
export const getFrequentlyBoughtTogetherListController = (
  sku: any,
  maxNumberOfRecommendations: number = 3
) => {
  if (!frequentlyBoughtTogetherListController) {
    frequentlyBoughtTogetherListController = buildFrequentlyBoughtTogetherList(
      frequentlyBoughtTogetherListPREngine,
      {
        options: {
          sku: sku,
          maxNumberOfRecommendations: maxNumberOfRecommendations,
          additionalFields: CoveoRecommendCustomFields,
        },
      }
    );
  }
  return frequentlyBoughtTogetherListController;
};

let frequentlyViewedTogetherListController: FrequentlyViewedTogetherList | null = null;
export const getFrequentlyViewedTogetherListController = (
  maxNumberOfRecommendations: number = 3
) => {
  if (!frequentlyViewedTogetherListController) {
    frequentlyViewedTogetherListController = buildFrequentlyViewedTogetherList(
      frequentlyViewedTogetherListPREngine,
      {
        options: {
          maxNumberOfRecommendations,
          additionalFields: CoveoRecommendCustomFields,
        },
      }
    );
  }
  return frequentlyViewedTogetherListController;
};

let popularBoughtRecommendationsListController: PopularBoughtRecommendationsList | null = null;
export const getPopularBoughtRecommendationsListController = (
  maxNumberOfRecommendations: number = 3
) => {
  if (!popularBoughtRecommendationsListController) {
    popularBoughtRecommendationsListController = buildPopularBoughtRecommendationsList(
      popularBoughtRecommendationsListPREngine,
      {
        options: {
          maxNumberOfRecommendations,
          additionalFields: CoveoRecommendCustomFields,
        },
      }
    );
  }
  return popularBoughtRecommendationsListController;
};

let popularViewedRecommendationsListController: PopularViewedRecommendationsList | null = null;
export const getPopularViewedRecommendationsListController = (
  maxNumberOfRecommendations: number = 3
) => {
  if (!popularViewedRecommendationsListController) {
    popularViewedRecommendationsListController = buildPopularViewedRecommendationsList(
      popularViewedRecommendationsListPREngine,
      {
        options: {
          maxNumberOfRecommendations,
          additionalFields: CoveoRecommendCustomFields,
        },
      }
    );
  }
  return popularViewedRecommendationsListController;
};

let userInterestRecommendationsListController: UserInterestRecommendationsList | null = null;
export const getUserInterestRecommendationsListController = (
  maxNumberOfRecommendations: number = 3
) => {
  if (!userInterestRecommendationsListController) {
    userInterestRecommendationsListController = buildUserInterestRecommendationsList(
      userInterestRecommendationsListPREngine,
      {
        options: {
          maxNumberOfRecommendations,
          additionalFields: CoveoRecommendCustomFields,
        },
      }
    );
  }
  return userInterestRecommendationsListController;
};
