import { LayoutServiceContextData, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';
import { ContentTypeSelection } from 'components/video/brightcove-video-props';
import { getStoredCountry } from 'lib/commerce/countries/countries-provider';
import { useEffect, useState } from 'react';

type MetaMiscProps = {
  sitecore: LayoutServiceContextData & {
    route: RouteData | null;
  };
};

const MetaMisc = (props: MetaMiscProps): JSX.Element => {
  const url = `${process.env.PUBLIC_URL}${props.sitecore.context.itemPath}`;
  const language = props.sitecore?.context?.language ?? '';
  const [country, setCountry] = useState('US');
  const siteName = process.env.NEXT_PUBLIC_SITE_NAME;
  const contentTypeField = props.sitecore?.route?.fields
    ?.contentTypeSelection as unknown as ContentTypeSelection;

  const contentType = (contentTypeField?.fields?.termName?.value ?? '') as string;

  useEffect(() => {
    setCountry(getStoredCountry());
  }, []);

  return (
    <>
      {contentType && <meta property="og:type" content={contentType} />}

      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content={`${language}_${country}`} />
      <meta property="twitter:site" content={siteName} />
    </>
  );
};

export default MetaMisc;
