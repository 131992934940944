import gql from 'graphql-tag';

const getPricingCardSubscriptionQuery = gql`
  query SubscriptionPricingCardsQuery($datasource: String!, $language: String!) {
    datasource: item(path: $datasource, language: $language) {
      id
      name
      ... on SubscriptionPricingCards {
        productName {
          value
        }
        subheading {
          value
        }
        userTypeOptions {
          targetItems {
            ... on UserType {
              title {
                value
              }
              showTotalLicenses {
                jsonValue
              }
              minCartQuantity {
                value
              }
              maxCartQuantity {
                value
              }
              productSet {
                targetItems {
                  ... on ProductSet {
                    productSetId {
                      value
                    }
                    resellerLink {
                      jsonValue
                    }
                    academicEligibilityLink {
                      jsonValue
                    }
                    textSections {
                      targetItems {
                        ... on ProductTextSection {
                          heading {
                            value
                          }
                          subheading {
                            value
                          }
                          description {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        defaultUserType {
          targetItems {
            ... on UserType {
              title {
                value
              }
              showTotalLicenses {
                jsonValue
              }
              minCartQuantity {
                value
              }
              maxCartQuantity {
                value
              }
              productSet {
                targetItems {
                  ... on ProductSet {
                    productSetId {
                      value
                    }
                    resellerLink {
                      jsonValue
                    }
                    academicEligibilityLink {
                      jsonValue
                    }
                    textSections {
                      targetItems {
                        ... on ProductTextSection {
                          heading {
                            value
                          }
                          subheading {
                            value
                          }
                          description {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getPricingCardSubscriptionQuery;
