/* eslint-disable */
// This file is shipped by Coveo to provide a working example of the headless engine.
import {
  buildSearchEngine,
  buildContext,
  loadFieldActions,
  SearchEngine,
  getOrganizationEndpoints,
} from '@coveo/headless';
import {
  buildProductRecommendationEngine,
  loadClickAnalyticsActions,
  ProductRecommendation,
  ProductRecommendationEngine,
} from '@coveo/headless/product-recommendation';

export const CoveoRecommendCustomFields = [
  'permanentid',
  'ec_images',
  'ec_thumbnails',
  'avid_ext_product_image_thumbnail_url',
  'avid_ext_product_image_url',
  'avid_productid',
  'title',
  'avid_pdp_link',
  'avid_sku',

  'ec_price',
  'avid_listprice',

  'avid_usd_price',
  'avid_usd_listprice',
  'avid_eur_price',
  'avid_eur_listprice',
  'avid_gbp_price',
  'avid_gbp_listprice',
  'avid_cad_price',
  'avid_cad_listprice',
  'avid_jpy_price',
  'avid_jpy_listprice',
];

const registerAdditionalFields = (headlessEngine: SearchEngine | ProductRecommendationEngine) => {
  const fieldActions = loadFieldActions(headlessEngine);
  headlessEngine.dispatch(fieldActions.registerFieldsToInclude(CoveoRecommendCustomFields));
  buildContext(headlessEngine as any);
  return headlessEngine;
};

const createSearchEngine = buildSearchEngine({
  configuration: {
    organizationId:
      process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
        ? process.env.COVEO_ORGANIZATION
        : '',
    organizationEndpoints: getOrganizationEndpoints(
      (process.env.COVEO_ORGANIZATION as string) ?? ''
    ),
    accessToken:
      process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
        ? process.env.COVEO_ACCESS_TOKEN
        : '',
    search: {
      pipeline:
        process.env.COVEO_CART_RECOMMENDER_PIPELINE &&
        process.env.COVEO_CART_RECOMMENDER_PIPELINE.length > 0
          ? process.env.COVEO_CART_RECOMMENDER_PIPELINE
          : '',
      searchHub:
        process.env.COVEO_CART_RECOMMENDER_PIPELINE &&
        process.env.COVEO_CART_RECOMMENDER_PIPELINE.length > 0
          ? process.env.COVEO_CART_RECOMMENDER_PIPELINE
          : '',
    },
  },
});
export const headlessEngine = registerAdditionalFields(createSearchEngine) as SearchEngine;

const createPREngine = () =>
  buildProductRecommendationEngine({
    configuration: {
      organizationId:
        process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
          ? process.env.COVEO_ORGANIZATION
          : '',
      organizationEndpoints: getOrganizationEndpoints(
        (process.env.COVEO_ORGANIZATION as string) ?? ''
      ),
      accessToken:
        process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
          ? process.env.COVEO_ACCESS_TOKEN
          : '',
      searchHub:
        process.env.COVEO_CART_RECOMMENDER_PIPELINE &&
        process.env.COVEO_CART_RECOMMENDER_PIPELINE.length > 0
          ? process.env.COVEO_CART_RECOMMENDER_PIPELINE
          : '',
      preprocessRequest: (request, clientOrigin) => {
        if (clientOrigin == 'searchApiFetch') {
          const body = JSON.parse(request.body?.toString() ?? '');
          if (body.recommendation && body.mlParameters?.itemIds && body.mlParameters?.itemIds.length > 0) {
            const currentContext = body.context ?? {};
            body.context = {
              ...currentContext,
              cartItems: body.mlParameters.itemIds
            };
          }
          request.body = JSON.stringify(body);
        }
        return request;
      },
    },
  });

export const cartRecommendationsListPREngine = registerAdditionalFields(
  createPREngine()
) as ProductRecommendationEngine;

export const frequentlyBoughtTogetherListPREngine = registerAdditionalFields(
  createPREngine()
) as ProductRecommendationEngine;

export const frequentlyViewedTogetherListPREngine = registerAdditionalFields(
  createPREngine()
) as ProductRecommendationEngine;

export const popularBoughtRecommendationsListPREngine = registerAdditionalFields(
  createPREngine()
) as ProductRecommendationEngine;

export const popularViewedRecommendationsListPREngine = registerAdditionalFields(
  createPREngine()
) as ProductRecommendationEngine;

export const userInterestRecommendationsListPREngine = registerAdditionalFields(
  createPREngine()
) as ProductRecommendationEngine;

export const logRecsClick = (
  recommendation: ProductRecommendation,
  engine: ProductRecommendationEngine
) => {
  const { logProductRecommendationOpen } = loadClickAnalyticsActions(engine);
  engine.dispatch(logProductRecommendationOpen(recommendation));
};
