type SearchTitleProps = {
  searchTitle: string;
  href: string;
};

const SearchTitle: React.FC<SearchTitleProps> = (props) => {
  return (
    <a href={props.href} className="intro-text">
      {props.searchTitle}
    </a>
  );
};

export default SearchTitle;
