import { Field, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ProductOverviewDescriptionProps = ComponentProps & {
  fields: {
    description: Field<string>;
  };
  params: {
    marginTop: string;
    marginBottom: string;
  };
};

const GetClassFromMargin = (MarginType: string, Margin: string): string => {
  if (Margin == null) {
    return '';
  }

  switch (Margin.toLowerCase()) {
    case '0px':
      return `${MarginType}0`;
    case '4px':
      return `${MarginType}1`;
    case '8px':
      return `${MarginType}2`;
    case '16px':
      return `${MarginType}4`;
    case '24px':
      return `${MarginType}6`;
    case '32px':
      return `${MarginType}8`;
    case '40px':
      return `${MarginType}10`;
    case '48px':
      return `${MarginType}12`;
    case '64px':
      return `${MarginType}16`;
    case '80px':
      return `${MarginType}20`;
    default:
      return `${MarginType == 'mb-' ? 'mb-20' : ''}`;
  }
};

const ProductOverviewDescription = (props: ProductOverviewDescriptionProps): JSX.Element => {
  const topmargin = props?.params?.marginTop
    ? `${GetClassFromMargin('mt-', props.params.marginTop)}`
    : 'mt-12';
  const bottommargin = props?.params?.marginBottom
    ? `${GetClassFromMargin('mb-', props.params.marginBottom)}`
    : '';
  return (
    <div
      className={`mt-12 pb-6 mx-6 lg:mx-28 xl:mx-[8.0625rem] 2xl:max-w-4xl 2xl:mx-auto ${
        topmargin ? 'lg:' + topmargin : ''
      } ${bottommargin ? 'lg:' + bottommargin : ''}`}
    >
      <RichText field={props?.fields?.description} tag="div" className="rte body text-left" />
    </div>
  );
};

export default withDatasourceCheck()<ProductOverviewDescriptionProps>(ProductOverviewDescription);
