/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import GeneralModal from '../../renderings/general-modal/GeneralModal';

type GeneralModalListProps = {
  children?: JSX.Element | JSX.Element[] | boolean;
};

const GeneralModalList = (props: GeneralModalListProps): JSX.Element => {
  const generalModalList = props.children as JSX.Element[];
  const [activeModal, setActiveModal] = useState('');
  const openModalHandler = (openModalId: string): void => {
    setActiveModal(openModalId);
  };

  return (
    <ul role="generalModalList" className="">
      {generalModalList &&
        generalModalList.map((modal, idx) => (
          <GeneralModal
            {...modal.props}
            key={idx}
            isactive={modal?.props?.fields?.uniqueId?.value == activeModal}
            index={idx}
            onModalOpen={openModalHandler}
          />
        ))}
    </ul>
  );
};

export default GeneralModalList;
