// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function HostingApplicationListener(this: any): void {
  this.supportedCommands = {
    'avid.link.token': function (): boolean {
      return true;
    },
  };

  this.process = (data): boolean => {
    try {
      const handler = this.supportedCommands[data.subject];
      if (handler) {
        handler(data);
        return true;
      }
    } catch (ex) {
      console.warn('HostingApplicationListener can not process message', data);
    }
    return false;
  };
}
