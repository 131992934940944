import { Partners } from './find-partner-model';

export const getAllPartners = async (): Promise<Partners> => {
  const apiUrl = ((process.env.PUBLIC_URL as string) +
    process.env.NEXT_PUBLIC_AVID_API_FIND_PARTNER) as string;
  return fetch(apiUrl).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to get LTOs!');
    }
    return response.json() as Promise<Partners>;
  });
};
