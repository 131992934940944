import gql from 'graphql-tag';

const getMultiTierPricingTableCardQuery = gql`
  # Write your query or mutation here
  query MultiTierPricingTableCardQuery($datasource: String!, $language: String!) {
    datasource: item(path: $datasource, language: $language) {
      id
      name
      ... on MultiTierProductTableCard {
        primaryCta {
          jsonValue
        }
        secondaryCta {
          jsonValue
        }
        description {
          jsonValue
        }
      }
      ... on MultiTierEnterpriseProductTableCard {
        enterpriseDescription {
          jsonValue
        }
      }
      ... on _HasProduct {
        product {
          targetItems {
            ... on _ProductDetails {
              productName {
                jsonValue
              }
              term {
                jsonValue
              }
              shortDescription {
                jsonValue
              }
              additionalDescription {
                jsonValue
              }
              brand {
                jsonValue
              }
              primaryCategory {
                targetIds
              }
              additionalCategories {
                targetIds
              }
              productDetailsPageLink {
                jsonValue
              }
            }
            ... on _SalesforceDetails {
              productId {
                jsonValue
              }
              productCode {
                jsonValue
              }
            }
          }
        }
      }
      ... on _Featurable {
        featuredText {
          jsonValue
        }
        featuredTheme {
          targetItem {
            ... on ReferenceStringValue {
              value {
                jsonValue
              }
            }
          }
        }
      }
      ... on _Badge {
        badgeText {
          jsonValue
        }
      }

      ... on _HasFeatures {
        featuresHeading {
          jsonValue
        }
        features {
          targetItems {
            ... on ProductFeature {
              heading {
                jsonValue
              }
              modalLinkText {
                jsonValue
              }
              modal {
                targetItem {
                  id
                  ... on ProductFeatureModal {
                    heading {
                      jsonValue
                    }
                    body {
                      jsonValue
                    }
                    link {
                      jsonValue
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getMultiTierPricingTableCardQuery;
