import { useState } from 'react';
import { CategoryAttributes } from './table-models';
import AttributeDetailsSection from './AttributeDetailsSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

type CategorySectionProps = {
  categoryAttributes: CategoryAttributes;
};

const CategorySection = (props: CategorySectionProps): JSX.Element => {
  const { categoryAttributes } = props;
  const [open, setOpen] = useState(!props.categoryAttributes.showHeader);

  return (
    <li role="rowgroup">
      {props.categoryAttributes.showHeader && (
        <div
          className="flex border border-neutral-lighter p-3"
          onClick={(): void => setOpen(!open)}
        >
          <div className="mr-3 text-primary">
            {open && <FontAwesomeIcon icon={faChevronUp} size="xs" />}
            {!open && <FontAwesomeIcon icon={faChevronDown} size="xs" />}
          </div>
          <div className="accent-2">{categoryAttributes.category.fields.title.value}</div>
        </div>
      )}
      {open && (
        <section className="lg:[&>*:nth-child(odd)]:bg-base-lightest">
          {categoryAttributes.attributeDetails.map((attribute) => {
            return (
              <AttributeDetailsSection key={attribute.attribute.id} attributeDetails={attribute} />
            );
          })}
        </section>
      )}
    </li>
  );
};

export default CategorySection;
