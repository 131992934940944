import { faChevronRight, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

export type ArticlesCardCarouselManualCardProps = {
  articleLink: string;
  articleTitle: string;
  articleDescripttion: string;
  articleDate: string;
  linklabel: Field<string>;
};

const ArticlesCardCarouselManualCard = (
  props: ArticlesCardCarouselManualCardProps
): JSX.Element => {
  return (
    <div className="carousel-item w-full flex p-4">
      <div className="w-full flex flex-col shadow-card rounded bg-white border border-1 border-base-light overflow-hidden">
        <div className="flex items-center justify-center aspect-[2/1] relative overflow-hidden bg-base-lighter">
          <FontAwesomeIcon icon={faFileLines} className={`text-base-darker text-80`} />
        </div>

        <div className="p-8 flex flex-1 justify-center flex-col">
          <div className="uppercase accent-2 text-secondary tracking-[0.2rem] mb-4">
            {props.articleDate}
          </div>

          <div className="intro-text-bold mb-4">{props.articleTitle}</div>
          <div className="mb-4 flex-1">{props.articleDescripttion}</div>
          {props.linklabel && (
            <div>
              <a
                className="btn-link p-0 hover:bg-transparent focus:bg-transparent focus-visible:pr-4"
                href={props.articleLink}
              >
                <Text field={props?.linklabel} />
                <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticlesCardCarouselManualCard;
