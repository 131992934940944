import { TabProps } from './tabbed-container-props';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface TabComponentProps {
  tab: TabProps;
  active: number;
  className: string;
  index: number;
  source?: string;
  onTabSelection: (index: number) => void;
}

const Tab = (props: TabComponentProps): JSX.Element => {
  const { tab, index, className, active, source } = props;

  const activeClass =
    active === index
      ? 'group active bg-base-lighter xl:border-b-4 xl:border-primary xl:bg-base-white focus-visible:mx-[1px]'
      : '';

  const tabClickHandler = (event: React.UIEvent): void => {
    event.preventDefault();
    props.onTabSelection(index);
  };

  return (
    <li role="presentation" className={`${className} xl:rounded-t-md xl:rounded-b-none`}>
      <a
        id={`tab${source ?? ''}_${index}`}
        aria-controls={`tabpanel_${index}`}
        role="tab"
        href="#"
        className={`${activeClass} tab p-4 focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-1 xl:hover:bg-base-light xl:px-4 xl:py-[8.5px] xl:rounded-t w-full flex justify-between items-center disabled:pointer-events-none`}
        aria-selected={active === index}
        onClick={tabClickHandler}
      >
        <h2
          className={`font-bold text-almost-black text-base group-odd:font-bold group-focus-visible:font-medium xl:font-medium xl:text-base-darkest xl:text-lg xl:tracking-[0.315em] xl:uppercase disabled:text-base-normal disabled:font-bold disabled:pointer-events-none`}
        >
          <Text field={tab.heading.jsonValue} />
        </h2>
        <FontAwesomeIcon
          icon={active === index ? faChevronUp : faChevronDown}
          className="visible text-xs text-primary xl:hidden"
        />
      </a>
    </li>
  );
};

export default Tab;
