/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CartSummary, GetCartItemsData } from 'components/cart/cart-models';
import { saveExpressPayment } from 'components/checkout/checkout-provider';
import { useAppSelector } from 'lib/store/hooks';
import { useEffect, useState, useMemo } from 'react';
import { ExpressPaymentProps } from './ExpressPayments';
import { getCheckoutAuthUrl } from 'lib/authentication/account-provider';

type PayPalSourcePayloadType = {
  env: string | undefined;
  style: {
    label: string;
    size: string;
    color: string;
    shape: string;
    layout: string;
    fundingicons: string;
    tagline: string;
    height: number;
  };
  sourceData: {
    type: string;
    language: string;
    amount: number;
    currency: string;
    payPal?: {
      language: string;
      taxAmount: number;
      payPal: {
        returnUrl: string;
        cancelUrl: string;
        shippingAmount: number;
        amountsEstimated: boolean;
        requestShipping: boolean;
        items: any;
      };
    };
    payPalBilling?: {
      language: string;
      taxAmount: number;
      payPal: {
        returnUrl: string;
        cancelUrl: string;
        shippingAmount: number;
        amountsEstimated: boolean;
        requestShipping: boolean;
        items: any;
      };
    };
  };
};

const ExpressPayPal = (props: ExpressPaymentProps): JSX.Element => {
  const [button, setButton] = useState<any>(null);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.cartItemsData;
  const cartSummary = cart.cartSummaryData;

  const paypalSourcePayload = useMemo(
    () =>
      computedPayPaySoucePayload(
        cartSummary,
        cartItems,
        props.includesSubscriptionProduct as boolean
      ),
    [cartSummary, cartItems, props.includesSubscriptionProduct]
  );

  useEffect(() => {
    const paypalWrapper = document.getElementById('paypal-button');
    if (button == null && paypalWrapper?.hasChildNodes() === false) {
      const payPalButton = props.digitalRiverInstance.createElement('paypal', paypalSourcePayload);

      payPalButton.mount('paypal-button');
      payPalButton.on('source', function (event: any) {
        onSourceHandler(event);
      });

      setButton(payPalButton);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [button, props.digitalRiverInstance, paypalSourcePayload]);

  useEffect(() => {
    if (button != null) {
      button.update(paypalSourcePayload);
    }
  }, [paypalSourcePayload, button]);

  const onSourceHandler = async (event: any): Promise<void> => {
    saveExpressPayment(event.source);
    window.location.href = getCheckoutAuthUrl(`${window.location.origin}/checkout`);
  };

  return (
    <>
      <div id="paypal-button" className="!rounded" />
    </>
  );
};

const computedPayPaySoucePayload = (
  summary: CartSummary | null,
  items: GetCartItemsData | null,
  includesSubscriptionItems: boolean
): any => {
  const payPalSourcePayload: PayPalSourcePayloadType = {
    env: process.env.NEXT_PUBLIC_DIGITALRIVER_ENVIRONMENT,
    style: {
      label: 'paypal',
      size: 'responsive',
      color: 'gold',
      shape: 'rect',
      layout: 'horizontal',
      fundingicons: 'false',
      tagline: 'false',
      height: 44,
    },
    sourceData: {
      type: includesSubscriptionItems ? 'payPalBilling' : 'payPal',
      language: 'en', //TODO: Pull from somewhere
      amount: summary?.grandTotalAmount || 0,
      currency: summary?.currencyIsoCode || 'USD',
      payPal: {
        language: 'en', //TODO: Pull from somewhere
        taxAmount: 0,
        payPal: {
          returnUrl: `${window.location.origin}/cart`,
          cancelUrl: `${window.location.origin}/cart`,
          shippingAmount: 0,
          amountsEstimated: false,
          requestShipping: false,
          items: items?.cartItems.map((item) => {
            return {
              name: item.cartItem.name,
              quantity: item.cartItem.quantity,
              unitAmount: item.cartItem.totalAmount,
            };
          }),
        },
      },
    },
  };

  if (includesSubscriptionItems) {
    payPalSourcePayload.sourceData['payPalBilling'] = payPalSourcePayload.sourceData['payPal'];
    delete payPalSourcePayload.sourceData['payPal'];
  }

  return payPalSourcePayload;
};

export default ExpressPayPal;
