import NextLink from 'components/foundation/non-sitecore/NextLink';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

type SecondaryCtaSectionProps = {
  field?: LinkField | null;
  anyVisibleCardHasSubLink: boolean;
};

const SecondaryCtaSection = (props: SecondaryCtaSectionProps): JSX.Element | null => {
  if (!props.anyVisibleCardHasSubLink) {
    return null;
  }

  const secondaryCtaField = props.field;
  if (secondaryCtaField == null) {
    return null;
  }

  return (
    <>
      {secondaryCtaField.value.href != null && secondaryCtaField.value.href.length > 0 && (
        <NextLink
          className="mt-5 text-primary font-avidwalsheimbold hover:text-primary-dark font-bold cursor-pointer text-center"
          field={secondaryCtaField}
        />
      )}
      {secondaryCtaField.value.href != null && secondaryCtaField.value.href.length == 0 && (
        <button className="mt-5 text-primary hover:text-primary-dark font-bold cursor-pointer invisible">
          .
        </button>
      )}
    </>
  );
};

export default SecondaryCtaSection;
