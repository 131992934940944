import { ScaleLoader } from 'react-spinners';
import { SpinnerProps } from './Spinner';

const InlineSpinner = (props: SpinnerProps): JSX.Element => {
  return (
    <div className="flex justify-center">
      <div className="rgb(139, 141, 143) text-bas">
        <ScaleLoader
          color={props.color ?? '#194BF5'}
          height={props.height ?? 47}
          margin={props.margin ?? 4}
          radius={0}
          width={props.width ?? 5}
        />
      </div>
    </div>
  );
};

export default InlineSpinner;
