import { TargetItemTextSections } from './product-pick-a-plan-props';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

type PickAPlanTextContentProps = {
  textSections?: TargetItemTextSections[];
};

export const PickAPlanTextContent = (props: PickAPlanTextContentProps): JSX.Element => {
  const { textSections } = props;
  const [currentIndexes, setCurrentIndexes] = useState([0]);
  const handleClick = (index: number): void => {
    if (currentIndexes.includes(index)) {
      setCurrentIndexes(currentIndexes.filter((i) => i !== index));
    } else {
      setCurrentIndexes([...currentIndexes, index]);
    }
  };
  return (
    <div className="text-mp-txt-neutral m-auto h-auto bg-mp-background-card mb-10">
      <div id="tab-headings" className="">
        {textSections?.map((item, index) => {
          return (
            <div key={index} className="mp-product-description-item">
              <div
                className={`text-mp-txt-bright bg-mp-background-darker cursor-pointer w-full text-center uppercase pt-2.5 pb-2.5 relative
                ${
                  currentIndexes.includes(index)
                    ? 'font-normal border-solid border-b border-b-mp-txt-primary'
                    : 'text-mp-txt-neutral border-solid border-b border-b-mp-txt-neutral border-opacity-10'
                }
                
                `}
                onClick={(): void => handleClick(index)}
              >
                <Text tag="span" field={item.heading} />
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className={`absolute top-3 right-5 cursor-pointer text-mp-txt-primary
                                ${currentIndexes.includes(index) ? 'rotate-0' : 'rotate-180'}
                              `}
                />
              </div>
              <div
                className={`mp-rich-text-with-links text-mp-txt-neutralLight p-5 ${
                  currentIndexes.includes(index) ? '' : 'hidden'
                }`}
              >
                <RichText field={item.description} className="rte" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
