import { ComponentProps } from 'lib/component-props';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComparisonTableCardItem } from './comparison-table-card-item';
import { DropdownItem } from '../inline-heading-and-dropdown/dropdown';
import { useEffect, useState } from 'react';
import { CategoryAttributes } from './table-models';
import CategorySection from './CategorySection';
import BadgeSection from '../multi-tier-pricing-card/BadgeSection';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import SecondaryCtaSection from '../multi-tier-pricing-card/SecondaryCtaSection';
import { useI18n } from 'next-localization';
import { useInView } from 'react-intersection-observer';
import { ProductVariationsData } from 'lib/commerce/product/model/product-models';
import { generateCategoryViewModel } from 'lib/commerce/product/product-util';

type ComparisonTableDesktopProps = ComponentProps & {
  fields: {
    productTableId: Field<string>;
    cards: Array<ComparisonTableCardItem>;
    frequencyTypeOptions: Array<DropdownItem>;
    defaultFrequencyType: Array<DropdownItem>;
    userTypeOptions: Array<DropdownItem>;
    defaultUserType: Array<DropdownItem>;
  };
  productIdsToCards: Map<string, ComparisonTableCardItem>;
  productTierDetails: ProductVariationsData;
};

const ComparisonTableDesktop = (props: ComparisonTableDesktopProps): JSX.Element => {
  const { t } = useI18n();
  const { productIdsToCards, productTierDetails } = props;
  const [categoryViewModel, setCategoryViewModel] = useState<Array<CategoryAttributes>>([]);

  const [ref, regularProductCardHeadersOnScreen] = useInView({
    threshold: 0.45,
  });

  useEffect(() => {
    if (productIdsToCards == null) {
      return;
    }

    setCategoryViewModel(
      generateCategoryViewModel(props.fields.cards, Array.from(productIdsToCards.values()))
    );
  }, [productIdsToCards, props.fields.cards]);

  const StickyContainer = (): JSX.Element => {
    if (regularProductCardHeadersOnScreen) {
      return <></>;
    }

    return (
      <div className="sticky items-center top-20 lg:top-32 text-almost-black border-b border-base-normal shadow-[0_4px_16px_0px_rgba(0,0,0,0.12)] animate-slide_down z-10">
        {productTierDetails !== null &&
          productIdsToCards !== null &&
          productIdsToCards.size > 0 && (
            <>
              <div className="flex">
                <div className="hidden flex-1 lg:block bg-white"></div>
                {productTierDetails?.variants.map((variation) => {
                  const card = productIdsToCards.get(variation.productId);
                  if (card == null) {
                    return <></>;
                  }

                  const anyVisibleCardsAreFeatured = true;
                  const featuredTheme = card.fields.featuredTheme?.fields.value.value;

                  return (
                    <div key={variation.productId} className="flex-1 bg-white">
                      {anyVisibleCardsAreFeatured && (
                        <div
                          className={`rounded-tr-md rounded-tl-md text-center py-1 text-white accent-3 uppercase tracking-[2.6px] ${
                            anyVisibleCardsAreFeatured &&
                            !card.fields.featuredText.value &&
                            'invisible'
                          } ${featuredTheme == 'primary' && 'bg-secondary'} ${
                            featuredTheme == 'secondary' && 'bg-primary'
                          }`}
                        >
                          <div>
                            <Text field={card.fields.featuredText} />
                            {!card.fields.featuredText.value && 'na'}
                          </div>
                        </div>
                      )}
                      <div className="px-3 py-4 text-center border border-neutral-lighter bg-white">
                        <div className="body-bold">
                          {card.fields.product[0].fields.productName.value}
                        </div>

                        <div className="flex justify-center">
                          <div className="flex flex-col mb-2 mt-3">
                            <NextLink
                              className={`${
                                card.fields.featuredText.value &&
                                card.fields.featuredTheme.fields.value.value === 'primary'
                                  ? 'btn-feature'
                                  : 'btn-primary'
                              } `}
                              field={card.fields.primaryCta}
                            ></NextLink>
                            <SecondaryCtaSection
                              field={card.fields.secondaryCta}
                              anyVisibleCardHasSubLink={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
      </div>
    );
  };

  if (productTierDetails == null || productIdsToCards == null || productIdsToCards.size <= 0) {
    return <></>;
  }

  return (
    <>
      <div ref={ref} className="flex">
        <div className="hidden flex-1 lg:block"></div>
        {productTierDetails?.variants.map((variation) => {
          const card = productIdsToCards.get(variation.productId);
          if (card == null) {
            return <></>;
          }

          const frequencyLabel = t(
            `MultiTierPricingCard_Card_Frequency_${variation.subscriptionType.toUpperCase()}`
          );

          const anyVisibleCardsAreFeatured = true;
          const featuredTheme = card.fields.featuredTheme?.fields.value.value;

          return (
            <div key={`reg_${variation.productId}`} className="flex-1">
              {anyVisibleCardsAreFeatured && (
                <div
                  className={`rounded-tr-md rounded-tl-md text-center py-1 text-white accent-3 uppercase tracking-[2.6px] ${
                    anyVisibleCardsAreFeatured && !card.fields.featuredText.value && 'invisible'
                  } ${featuredTheme == 'primary' && 'bg-secondary'} ${
                    featuredTheme == 'secondary' && 'bg-primary'
                  }`}
                >
                  <div>
                    <Text field={card.fields.featuredText} />
                    {!card.fields.featuredText.value && 'na'}
                  </div>
                </div>
              )}
              <div className="px-3 py-4 text-center border border-neutral-lighter">
                <div className="body-bold">{card.fields.product[0].fields.productName.value}</div>
                <div className="flex justify-center">
                  <BadgeSection
                    className="mt-4 mb-3"
                    anyVisibleCardHasBadge={true}
                    field={card.fields.badgeText}
                  />
                </div>

                <div>
                  <h5
                    className={`text-base-dark line-through text-[1.25rem] font-normal tracking-[0.2em] ${
                      variation.price.unitPrice === variation.price.listPrice && 'invisible'
                    }`}
                  >
                    {t(`Currency_Symbol_${variation.price.currencyIsoCode}`)}
                    {variation.price.listPrice}
                  </h5>
                  <h5
                    className={`accent-1 tracking-[0.2em] ${
                      card.fields.featuredText.value &&
                      card.fields.featuredTheme.fields.value.value === 'primary' &&
                      'text-secondary'
                    }`}
                  >
                    {t(`Currency_Symbol_${variation.price.currencyIsoCode}`)}
                    {variation.price.unitPrice}
                  </h5>
                  <h5 className="accent-3 tracking-[0.2em] uppercase mb-4">
                    {variation.price.currencyIsoCode}
                    {frequencyLabel}
                  </h5>
                </div>

                <div className="flex justify-center">
                  <div className="flex flex-col mb-2">
                    <NextLink
                      className={`${
                        card.fields.featuredText.value &&
                        card.fields.featuredTheme.fields.value.value === 'primary'
                          ? 'btn-feature'
                          : 'btn-primary'
                      } `}
                      field={card.fields.primaryCta}
                    ></NextLink>
                    <SecondaryCtaSection
                      field={card.fields.secondaryCta}
                      anyVisibleCardHasSubLink={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <StickyContainer />

      <ul role="table">
        {categoryViewModel.map((category) => {
          return <CategorySection key={category.category.id} categoryAttributes={category} />;
        })}
      </ul>
    </>
  );
};

export default withDatasourceCheck()(ComparisonTableDesktop);
