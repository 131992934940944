import gql from 'graphql-tag';

const getProductDetailsQuery = gql`
  fragment productDetailsBucketItem on Item {
    id
    marketplaceVariantDescription: field(name: "marketplaceVariantDescription") {
      value
    }
    productDetailsPageLink: field(name: "productDetailsPageLink") {
      value
      jsonValue
    }
    ... on _ProductDetails {
      productName {
        value
      }
      brand {
        value
      }
    }

    ... on _Featurable {
      featuredText {
        value
      }
      featuredTheme {
        targetItem {
          ... on ReferenceStringValue {
            value {
              value
            }
          }
        }
      }
    }

    ... on _Badge {
      badgeText {
        value
      }
    }
    ... on _HasProductOverviewRequirementsAndSpecifications {
      requirementsAndSpecifications {
        targetItems {
          ... on _ProductOverviewRequirementsAndSpecifications {
            systemRequirementsTitle {
              value
            }
            specificationsTitle {
              value
            }
          }
          ... on AccordionProductOverviewRequirementsAndSpecifications {
            systemRequirementsText {
              value
            }
            specificationsText {
              value
            }
          }
          ... on LinkProductOverviewRequirementsAndSpecifications {
            systemRequirementsLink {
              jsonValue
            }
            specificationsLink {
              jsonValue
            }
          }
        }
      }
    }

    ... on _HasFeatures {
      featuresHeading {
        value
      }
      features {
        ...Features
      }
    }
  }

  fragment Features on MultilistField {
    targetItems {
      ... on ProductFeature {
        heading {
          value
        }
        modalLinkText {
          value
        }
        modal {
          targetItem {
            ... on ProductFeatureModal {
              heading {
                value
              }
              body {
                value
              }
              link {
                jsonValue
              }
            }
          }
        }
      }
    }
  }

  query ProductDetailsQuery($language: String!, $productId: String!) {
    search(
      where: {
        AND: [
          { name: "_path", value: "{6219196D-1E69-4AC6-8D24-997042832356}", operator: CONTAINS }
          {
            name: "_templates"
            value: "{FB34F438-7127-4086-8A72-E4DCEFA89A11}"
            operator: CONTAINS
          }
          { name: "_language", value: $language }
          { name: "productId", value: $productId }
        ]
      }
    ) {
      results {
        ...productDetailsBucketItem
      }
    }
  }
`;

export default getProductDetailsQuery;
