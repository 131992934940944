import {
  Field,
  Text,
  LinkField,
  withDatasourceCheck,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type MarketplaceFooterProps = ComponentProps & {
  fields: {
    privacyPolicyLink: LinkField;
    footerText: Field<string>;
  };
};

const MarketplaceFooter = (props: MarketplaceFooterProps): JSX.Element => {
  const editorActive = isEditorActive();
  return (
    <div className="text-right text-[0.43rem] sm:text-xxs leading-normal">
      {(props?.fields?.footerText?.value || editorActive) && (
        <Text tag="p" field={props?.fields?.footerText} className="inline text-white" />
      )}
      {(props?.fields?.privacyPolicyLink?.value || editorActive) && (
        <span
          className="js-external-redirect text-mp-txt-primary cursor-pointer"
          data-appman-link={props?.fields?.privacyPolicyLink.value.href}
          data-appman-target="_blank"
        >
          {` ${props?.fields?.privacyPolicyLink.value.text}`}
        </span>
      )}
    </div>
  );
};

export default withDatasourceCheck()<MarketplaceFooterProps>(MarketplaceFooter);
