import NextLink from 'components/foundation/non-sitecore/NextLink';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Accordion from 'components/foundation/non-sitecore/Accordion';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductOverviewRequirementsAndSpecificationsProps } from 'components/product-overview/product-overview-requirements-and-specifications-props';

const ProductOverviewRequirementsAndSpecifications = (
  props: ProductOverviewRequirementsAndSpecificationsProps
): JSX.Element => {
  const isEditing = isEditorActive();
  const product =
    props.fields?.data?.contextItem?.product?.targetItems != null
      ? props.fields.data.contextItem.product.targetItems[0]
      : null;

  const requirementsAndSpecifications =
    product?.requirementsAndSpecifications != null
      ? product.requirementsAndSpecifications.targetItems[0]
      : null;

  if (requirementsAndSpecifications == null) {
    if (isEditing) {
      return (
        <div className="border-2 border-dashed border-grayblack p-2 m-2 max-w-content mx-auto">
          <span className="font-bold">Product Overview Requirements and Specifications: </span>
          The selected product does not have requirements and specifications set. This component
          will be automatically hidden on the live version of this page.
        </div>
      );
    }

    return <></>;
  }

  const richTextCss =
    'rte p-4 pt-0 body [&>ul]:list-disc [&>ul]:ml-4 [&>ul>li]:marker:text-xs [&>ul]:flex [&>ul]:flex-col [&>ul]:space-y-1 [&>a]:underline [&>a]:text-primary [&>ul>li>a]:underline [&>ul>li>a]:text-primary';

  const accordianTabDetails: { title: JSX.Element; content: JSX.Element }[] = [];
  if (requirementsAndSpecifications?.systemRequirementsText?.value) {
    accordianTabDetails.push({
      title: <Text field={requirementsAndSpecifications.systemRequirementsTitle} />,
      content: (
        <RichText
          field={requirementsAndSpecifications.systemRequirementsText}
          className={richTextCss}
        />
      ),
    });
  }

  if (requirementsAndSpecifications?.specificationsText?.value) {
    accordianTabDetails.push({
      title: <Text field={requirementsAndSpecifications.specificationsTitle} />,
      content: (
        <RichText
          field={requirementsAndSpecifications.specificationsText}
          className={richTextCss}
        />
      ),
    });
  }

  const LinkRequirementsAndSpecifications = (
    <div className="flex flex-col items-start space-y-2">
      {requirementsAndSpecifications?.systemRequirementsLink && (
        <NextLink
          field={requirementsAndSpecifications.systemRequirementsLink.jsonValue}
          className="btn-link-underline"
          hideText
        >
          <span>{requirementsAndSpecifications.systemRequirementsLink.jsonValue?.value?.text}</span>
          <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
        </NextLink>
      )}
      {requirementsAndSpecifications?.specificationsLink && (
        <NextLink
          field={requirementsAndSpecifications.specificationsLink.jsonValue}
          className="btn-link-underline"
          hideText
        >
          <span>{requirementsAndSpecifications.specificationsLink.jsonValue?.value?.text}</span>
          <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
        </NextLink>
      )}
    </div>
  );

  return (
    <div className="mx-6 mb-10 lg:mb-20 lg:mx-28 xl:mx-[8.0625rem] 2xl:max-w-4xl 2xl:mx-auto text-almost-black">
      {(requirementsAndSpecifications?.systemRequirementsLink ||
        requirementsAndSpecifications?.specificationsLink) &&
        LinkRequirementsAndSpecifications}

      {(requirementsAndSpecifications?.systemRequirementsText?.value ||
        requirementsAndSpecifications?.specificationsText?.value) && (
        <Accordion tabs={accordianTabDetails} />
      )}
    </div>
  );
};

export default ProductOverviewRequirementsAndSpecifications;
