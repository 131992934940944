// import { getBearerToken } from 'lib/authentication/account-provider';
import { NonOkResponse } from 'lib/common/model/common-models';
// import { stringify } from 'querystring';

export const myAvidRequest = async <TSuccess>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string,
  body: Record<string, unknown> | null = {}
  // queryParams: Record<string, string> | null = {}
): Promise<TSuccess | NonOkResponse> => {
  // const bearerToken = await getBearerToken();

  // const headers: HeadersInit = {};
  const myAvidBaseUrl = process.env.NEXT_PUBLIC_MYAVID_BASE_URL ?? '';
  // if (bearerToken && bearerToken.length > 0) {
  //   headers.Authorization = `Bearer ${bearerToken}`;
  // }

  // const queryParamsString = stringify(queryParams ?? undefined);
  let response;
  try {
    response = await fetch(`${myAvidBaseUrl}${path}`, {
      method: method,
      credentials: 'include',
      body: method != 'GET' ? JSON.stringify(body) : null,
    });
  } catch {
    const nonOkResponse: NonOkResponse = {
      descriminator: '',
      status: 500,
      data: null,
    };
    return nonOkResponse;
  }

  if (!response.ok) {
    const nonOkResponse: NonOkResponse = {
      descriminator: '',
      status: response.status,
      data: null,
    };
    return nonOkResponse;
  }

  const responseData = await response.text();

  try {
    const data = JSON.parse(responseData);
    const result = data as TSuccess;
    return result;
  } catch {
    return responseData as unknown as TSuccess;
  }
};
