import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const SolutionCalloutBlockContainer = (props: ComponentProps): JSX.Element => {
  return (
    <section className="mx-6 lg:mx-auto max-w-content grid grid-cols-1 lg:grid-cols-12 my-10">
      <div className="lg:col-start-2 lg:col-span-10 xl:col-start-3 xl:col-span-8  p-8  border border-base-light rounded bg-base-lighter">
        <Placeholder name="jss-solution-callout-blocks-container" rendering={props?.rendering} />
      </div>
    </section>
  );
};

export default SolutionCalloutBlockContainer;
