import React, { Fragment } from 'react';
import { TabbedContainerProps } from '../../components/tab/tabbed-container-props';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import TabbedContainer from './TabbedContainer';

type VisualListProps = TabbedContainerProps & {
  fields: {
    data: {
      datasource: {
        heading: Field<string>;
        anchorLinkId: Field<string>;
      };
    };
  };
};

const VisualList = (props: VisualListProps): JSX.Element => {
  return (
    <Fragment>
      <div
        className="mb-10 lg:mb-20"
        {...(props?.fields?.data?.datasource?.anchorLinkId?.value
          ? { id: props?.fields?.data?.datasource?.anchorLinkId?.value }
          : {})}
      >
        <Text
          tag="h2"
          field={props.fields?.data?.datasource?.heading}
          className="text-center uppercase tracking-[0.28em] text-almost-black mt-10"
        />
        <div>
          <TabbedContainer {...props} />
        </div>
      </div>
    </Fragment>
  );
};

export default withDatasourceCheck()<VisualListProps>(VisualList);
