import gql from 'graphql-tag';

const getProductCategoryListingQuery = gql`
  fragment CategoryItemFields on ProductCategory {
    id
    categoryName {
      value
    }
    categoryDescription {
      value
    }
    urlSlug {
      value
    }
  }

  fragment ProductCategoryListingCategoryItem on ProductCategory {
    ...CategoryItemFields
    children {
      results {
        ...CategoryItemFields
        children {
          results {
            ...CategoryItemFields
          }
        }
      }
    }
  }

  query ProductCategoryList($datasource: String!, $language: String!) {
    # Datasource query
    # $datasource will always be set to the ID of the rendering's datasource item
    # (as long as the GraphQLData helper is used)
    datasource: item(path: $datasource, language: $language) {
      id
      name
      ... on ProductCategoryListing {
        templateFilter {
          value
        }
        root {
          value
        }
        categoryFilter {
          targetItems {
            id
            ...ProductCategoryListingCategoryItem
            ancestors {
              ...CategoryItemFields
            }
          }
        }
        linkSectionTitle {
          value
          jsonValue
        }
        links {
          value
          jsonValue
        }
      }
    }
  }
`;

export default getProductCategoryListingQuery;
