import { Text, Field, ImageField, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { getDiscountValue, getPriceRange, PriceDataItem } from 'lib/commerce/product/price-helpers';
import { useI18n } from 'next-localization';
import { getProductVariations } from 'lib/commerce/product/product-api';
import { addCartItem } from 'components/cart/cart-slice';
import ProductPromoLabel from 'components/trending-products/ProductPromoLabel';
import Spinner from 'components/spinner/Spinner';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

type MarketplaceOptionalAddOnsListProps = {
  url: string;
  searchImage: ImageField;
  searchTitle: Field<string>;
  searchDescription: Field<string>;
  companyName: Field<string>;
  index: number;
  parentProductId: Field<string>;
  cartItemsIds?: string[];
};

const MarketplaceOptionalAddOnsList = (props: MarketplaceOptionalAddOnsListProps): JSX.Element => {
  const editorActive = isEditorActive();
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [productPricing, setProductPricing] = useState<PriceDataItem | null>(null);
  const parentProductId = props.parentProductId?.value;
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const { lowestPrice, highestPrice } = getPriceRange(productPricing);
  const hasPriceRange =
    productPricing && productPricing.pricesArray.length > 1 && lowestPrice < highestPrice;
  const isDiscounted =
    productPricing?.discountValue !== undefined && productPricing?.discountValue > 0;
  const isInCart =
    !hasPriceRange && props.cartItemsIds?.includes(productPricing?.pricesArray[0].productId ?? '');

  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const buyNowHandler = (event: React.MouseEvent<HTMLElement>): void => {
    const target = event.currentTarget as HTMLButtonElement;
    target.disabled = true;
    productPricing?.pricesArray[0].productId &&
      dispatch(
        addCartItem({
          sku: productPricing.pricesArray[0].productId,
          quantity: 1,
          bearerToken: bearerToken,
        })
      ).catch((error) => {
        console.log(
          `Trending Products: >> Can't add to cart, product ${productPricing?.pricesArray[0].productId}: ${error.message}`
        );
      });
  };

  const renderPrice = (): JSX.Element => {
    if (hasPriceRange) {
      return (
        <div className="inline-block text-mp-txt-bright">
          {t(`Currency_Symbol_${productPricing?.pricesArray[0].currencyIsoCode}`)}
          {lowestPrice} - {highestPrice}
        </div>
      );
    } else if (!hasPriceRange && isDiscounted) {
      return (
        <div className="inline-block">
          <div className="text-mp-txt-neutral text-xs line-through">
            <span>
              {t(`Currency_Symbol_${productPricing?.pricesArray[0].currencyIsoCode}`)}
              {productPricing?.pricesArray[0].listPrice}
            </span>
          </div>
          <div className="text-mp-txt-primary">
            <span>
              {productPricing?.pricesArray[0].unitPrice > 0
                ? t(`Currency_Symbol_${productPricing?.pricesArray[0].currencyIsoCode}`)
                : ''}
              {productPricing?.pricesArray[0].unitPrice > 0
                ? productPricing?.pricesArray[0].unitPrice
                : t('MP_Free_Price')}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="inline-block text-mp-txt-bright">
          {productPricing?.pricesArray[0].unitPrice && productPricing?.pricesArray[0].unitPrice > 0
            ? t(`Currency_Symbol_${productPricing?.pricesArray[0].currencyIsoCode}`)
            : ''}
          {productPricing?.pricesArray[0].unitPrice && productPricing?.pricesArray[0].unitPrice > 0
            ? productPricing?.pricesArray[0].unitPrice
            : t('MP_Free_Price')}
        </div>
      );
    }
  };

  useEffect(() => {
    if (parentProductId && productPricing === null && currencyIsoCode !== null) {
      getProductVariations({ productId: parentProductId, currencyIsoCode })
        .then((productPricing) => {
          const pricesArray = productPricing.data.variants.map((variant) => variant.price);
          setProductPricing({
            pricesArray,
            discountValue: getDiscountValue(pricesArray),
          });
        })
        .catch((error) => {
          console.log(
            `Marketplace OPtional Addons: >> Can't get getProductVariations for product ${parentProductId}: ${error.message}`
          );
        });
    }
  }, [parentProductId, currencyIsoCode, productPricing]);

  return (
    <div key={props?.index} className="mp-cart-addon-card relative w-full">
      {isDiscounted && <ProductPromoLabel promoValue={`${productPricing?.discountValue}% off`} />}
      <div
        className={`inline-block text-mp-txt-bright min-h-300 w-full px-[10px] md:px-2 pt-[14px]
          `}
      >
        <div className="h-34 w-full relative overflow-hidden border border-mp-background-card">
          <a href={props.url}>
            <NextImage field={props.searchImage} className="absolute h-auto" />
          </a>
        </div>
        <div className="py-2 pl-3 pr-4 bg-mp-background-card h-39 md:h-43 relative ">
          {(props.companyName?.value || editorActive) && (
            <Text
              tag="p"
              field={props.companyName}
              className="uppercase text-mp-txt-neutral text-xxs mb-2"
            />
          )}

          {(props.searchTitle?.value || editorActive) && (
            <a href={props.url}>
              <Text
                tag="p"
                field={props.searchTitle}
                className="text-mp-txt-bright text-base w-full overflow-hidden overflow-ellipsis"
              />
            </a>
          )}
          {props.searchDescription?.value || editorActive ? (
            <Text
              tag="p"
              field={props.searchDescription}
              className={`text-mp-txt-neutralLight text-xxs w-full overflow-hidden overflow-ellipsis ${
                isDiscounted ? 'min-h-[45px]' : 'min-h-[60px]'
              }`}
            />
          ) : (
            <p
              className={`text-mp-txt-neutral text-xxs w-full overflow-hidden overflow-ellipsis ${
                isDiscounted ? 'min-h-[45px]' : 'min-h-[60px]'
              }`}
            >
              no description
            </p>
          )}
          <div className="">
            {productPricing == null && <Spinner color="#20BBD0" />}
            {(productPricing || editorActive) && renderPrice()}
            {productPricing && !hasPriceRange && (
              <div className="float-right inline-block relative">
                {
                  <button
                    className={`${
                      isInCart
                        ? 'bg-mp-btn-disabled text-mp-txt-bright'
                        : 'bg-mp-btn-primary text-mp-txt-bright hover:bg-mp-btn-light hover:text-mp-txt-almostBlack'
                    } ${
                      isDiscounted ? 'mt-4' : ''
                    } text-sm px-2.5 sm:px-1.5 md:px-2 xl:px-2.5 py-1 mr-1 rounded disabled:bg-mp-btn-disabled`}
                    onClick={(e): void => buyNowHandler(e)}
                    disabled={isInCart}
                  >
                    {`${isInCart ? t('MP_In_Cart') : t('MP_Buy_Now')}`}
                  </button>
                }
              </div>
            )}
          </div>
          {productPricing && (
            <div className="">
              <a href={props.url} className="text-mp-txt-bright text-sm underline cursor-pointer">
                {t('LearnMore')}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketplaceOptionalAddOnsList;
