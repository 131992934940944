import ResultImage from './ResultImage';

type YoutubeVideoThumnailProps = {
  alt: string;
  src: string;
  href: string;
  title: string;
  videoId: string;
  className?: string;
};

const YoutubeVideoThumnail: React.FC<YoutubeVideoThumnailProps> = (props) => {
  return (
    <ResultImage
      src={props.src}
      alt={`Thumbnail for "${props.alt}" video`}
      className={props.className}
    />
  );
};

export default YoutubeVideoThumnail;
