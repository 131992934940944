import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ImageCardsProps } from '../../components/image-card/image-cards-props';
import ImageCard from './ImageCard';

const ImageCards = (props: ImageCardsProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource;
  const imageCards = datasource?.children?.results;
  let dynamicGridClass = '';
  if (imageCards?.length > 0) {
    switch (imageCards.length) {
      case 2:
        dynamicGridClass = 'xl:grid-cols-2';
        break;
      case 3:
        dynamicGridClass = 'xl:grid-cols-3';
        break;
    }
  }

  return (
    <>
      {datasource && imageCards && imageCards.length > 0 && (
        <div className={`grid grid-cols-1 gap-x-8 px-5 md:grid-cols-2 ${dynamicGridClass}`}>
          {imageCards.map((card) => {
            return <ImageCard key={card.id} {...card} />;
          })}
        </div>
      )}
    </>
  );
};

export default withDatasourceCheck()<ImageCardsProps>(ImageCards);
