export const resultsStyles = `
  atomic-result-list::part(result-list) {
    margin-top: 1.2rem;
    grid-template-columns: repeat(auto-fill, minmax(294px, 1fr));
  }
  atomic-result-list::part(result-list-grid-clickable-container){
    background-color: var(--atomic-mp-bg-card);
    border-color: var(--atomic-mp-bg-card);
    border-radius: 4px;
    max-width: 350px;
    min-width: 260px;
  }
  atomic-result-list::part(result-list-grid-clickable-container):hover{
    background-color: var(--atomic-mp-bg-card);
    box-shadow: none;
    border-color: var(--atomic-mp-primary);
  }
  atomic-result-list::part(result-list-grid-clickable){
    display: none;
  }
  atomic-no-results{
    color: var(--atomic-mp-neutral);
  }
  atomic-query-error{
    color: var(--atomic-mp-neutral);
  }
  atomic-load-more-results::part(load-more-results-button) {
    background-color: var(--atomic-mp-btn-primary);
  }
  atomic-load-more-results::part(load-more-results-button):focus {
    outline: none;
  }
  @media (max-width: 680px) {
    atomic-result-list::part(result-list) {
      grid-template-columns: repeat(auto-fit, minmax(234px, 1fr));
    }
    atomic-result-list::part(result-list-grid-clickable-container){
      max-width: 300px;
      min-width: 200px;
    }
  }
  @media (max-width: 550px) {
    atomic-result-list::part(result-list) {
      grid-template-columns: none;
    }
  }
  @media (min-width: 691px) {
    atomic-result-list::part(result-list) {
      row-gap: 1rem;
    }
  }
  @media (max-width: 690px) {
    atomic-result-list::part(result-list-grid-clickable-container) {
      margin-top: 20px;
    }
  }
`;

export const searchBoxStyles = `
  atomic-search-box::part(clear-button) {outline:0}
  atomic-search-box::part(wrapper) {
    border-radius: 4px;
    border: 1px solid var(--atomic-mp-neutral-medium);
    background-color: var(--atomic-mp-neutral-medium-dark);
    padding-right: 2px;
    max-height:2.5rem;
  }
  atomic-search-box::part(wrapper):focus-within {
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: none;
    box-shadow: none;
  }
  atomic-search-box::part(input) {
    background-color: var(--atomic-mp-neutral-medium-dark);
    color: var(--atomic-mp-txt-bright);
  }
  atomic-search-box::part(input)::placeholder {
    color: var(--atomic-mp-txt-bright);
  }
  atomic-search-box::part(loading)::after {
    background-color: var(--atomic-mp-neutral-medium-dark);
  }
  atomic-search-box::part(submit-button) {
    background-color: var(--atomic-mp-neutral-medium-dark);
    color: var(--atomic-mp-neutral);
    border: none;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  atomic-search-box::part(submit-button):focus {
    outline: none;
  }
  atomic-search-box::part(submit-button):hover {
    color: var(--atomic-mp-primary);
  }
  atomic-search-box::part(suggestions-wrapper) {
    border-color: var(--atomic-mp-bg-darker);
    background-color: var(--atomic-mp-bg-darker);
    border-radius: 0;
    margin-top:2px;
  }
  atomic-search-box::part(suggestions-right) {
    border-left: 1px solid var(--atomic-mp-primary);
  }
  atomic-search-box::part(instant-results-show-all) {
    border-top: 1px solid var(--atomic-mp-primary);
    border-radius: 0;
    background-color: var(--atomic-mp-bg-darker);
  }
  atomic-search-box::part(suggestion):hover {
    background-color: var(--atomic-mp-neutral-dark);
    color: var(--atomic-mp-txt-bright);
    border-radius: 0;
  }
  atomic-search-box::part(active-suggestion) {
    background-color: var(--atomic-mp-primary);
    color: var(--atomic-mp-txt-bright);
    border-radius: 0;
  }
  atomic-search-box::part(instant-results-show-all-button) {
    background-color: var(--atomic-mp-neutral-dark);
    color: var(--atomic-on-primary);
    padding: 3px 10px;
  }
  atomic-search-box::part(instant-results-show-all-button):hover {
    background-color: var(--atomic-mp-neutral-dark);
    color: var(--atomic-on-primary);
  }
  atomic-query-summary::part(container){
    color: var(--atomic-mp-neutral);
  }
  atomic-sort-dropdown::part(label){
    color: var(--atomic-mp-neutral);
    font-size: var(--atomic-text-lg);
  }
  atomic-sort-dropdown::part(select) {
    border-color: var(--atomic-mp-neutral-medium-dark);
    background-color: var(--atomic-mp-neutral-dark);
    color: var(--atomic-mp-txt-bright);
  }
  atomic-sort-dropdown::part(select):hover + div {
    color: var(--atomic-mp-txt-bright);
  }
  atomic-sort-dropdown::part(select) option {
    border-color: var(--atomic-mp-bg-darker);
    background-color: var(--atomic-mp-neutral-dark);
    color: var(--atomic-mp-txt-bright);
    border: none;
  }
  atomic-sort-dropdown::part(select) option:hover {
    background-color: var(--atomic-mp-neutral-dark);
  }
  atomic-sort-dropdown::part(select-separator) {
    border-color: var(--atomic-mp-neutral-dark);
  }
  atomic-sort-dropdown::part(select):focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  atomic-sort-dropdown {
    margin-top: 5px;
  }
  .atomic-search-interface-no-results atomic-layout-section#facetsPanel {
    display:none;
  }
  .atomic-search-interface-no-results atomic-layout-section[section=status] {
    display:none;
  }
`;

export const facetStyles = `
  atomic-facet {
    width: 100%;
    margin-bottom: 5px!important;
  }
  atomic-facet::part(values) {
    width: 100%;
    background-color: var(--atomic-mp-neutral-dark);
    padding-left: 15px;
    color: var(--atomic-mp-txt-bright);
    z-index: 50;
    position: relative;
    margin: 0;
    left: -13px;
    bottom: -7px;
    top: 8px;
}
  atomic-facet::part(facet) {
    width: 100%;
    height: fit-content;
    border: none;
    background-color: var(--atomic-mp-neutral-dark);
    margin-bottom: 0;
    padding: 6px 12px;
  }
  atomic-facet::part(parents) {
    width: 350px;
  }
  atomic-facet::part(clear-button) {
    display: none;
  }
  atomic-facet::part(label-button) {
    color: var(--atomic-mp-txt-bright);
    background-color: var(--atomic-mp-neutral-medium-dark);
    padding: 5px 10px;
  }
  atomic-facet::part(label-button-icon) {
    color: var(--atomic-mp-primary);
  }
  atomic-facet::part(value-checkbox-label):hover {
    background-color: var(--atomic-mp-bg-card);
  }
  atomic-facet::part(value-checkbox):focus {
    outline: none;
  }
  atomic-facet::part(label-button):hover {
    color: var(--atomic-mp-neutral);
  }
  atomic-facet::part(label-button):focus {
    outline: none;
  }
  atomic-facet::part(show-more) {
    background-color: var(--atomic-mp-neutral-medium);
    color: var(--atomic-mp-primary);
  }
  atomic-facet::part(show-more):hover {
    background-color: var(--atomic-mp-neutral-medium-dark);
  }
  atomic-facet::part(show-less) {
    background-color: var(--atomic-mp-neutral-medium);
    color: var(--atomic-mp-primary);
    margin-bottom: 5px;
  }
  atomic-facet::part(show-less):hover {
    background-color: var(--atomic-mp-neutral-medium-dark);
  }
  atomic-layout-section::-webkit-scrollbar {
    width: 4px;
    background-color: #1f2127;
  }
  atomic-layout-section::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
  }
  atomic-layout-section::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  @media (min-width: 576px) {
    atomic-facet { width: auto }
    atomic-facet::part(facet) {
      border: 1px solid var(--atomic-mp-neutral-medium);
      background-color: var(--atomic-mp-neutral-medium-dark);
      border-radius: 2px;
      padding: 6px 12px;
      width: 135px;
      height: 38px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    atomic-facet::part(facet):hover {
      border-color: var(--atomic-mp-primary);
    }
    atomic-facet::part(values) {
      background-color: var(--atomic-mp-neutral-medium);
      width: 155px;
      padding:0;
    }
    atomic-facet::part(label-button) {
      padding: 0;
      font-weight: var(--atomic-font-normal);
    }
    atomic-facet::part(label-button-icon) {
      color: var(--atomic-mp-text-bright);
    }
    atomic-facet::part(parents) {
      background-color: var(--atomic-mp-bg-darker);
      color: var(--atomic-mp-txt-bright);
      z-index: 50;
      position: relative;
      width: 155px;
      margin: 0;
      left: -12px;
    }
    atomic-facet::part(node-value) {
      background-color: var(--atomic-mp-bg-darker);
      color: var(--atomic-mp-txt-bright);
    }
    atomic-facet::part(all-categories-button) {
      background-color: var(--atomic-mp-bg-darker);
      color: var(--atomic-mp-primary);
    }
    atomic-facet::part(all-categories-button):hover {
      background-color: var(--atomic-mp-primary);
      color: var(--atomic-mp-txt-bright);
      border-radius: 0;
    } 
    atomic-facet::part(clear-button) {
      display:flex;
      position: absolute;
      left: 1px;
      bottom: 5px;
      border: 1px solid var(--atomic-mp-primary);
      height: 0px;
      padding: 0;
      width: 133px;
      overflow: hidden;
    }
    atomic-facet::part(active-parent):hover {
      background-color: var(--atomic-mp-bg-card);
      border-radius: 0;
    }
    atomic-facet::part(value-link):hover > .value-label {
      color: var(--atomic-mp-txt-bright);
    }
   }  
`;

export const breadCrumbsStyles = `
  atomic-breadbox::part(breadcrumb-button) {
    border-color: var(--atomic-mp-neutral-medium);
    background-color: var(--atomic-mp-neutral-medium-dark);
    padding: 0px 14px;
    color: var(--atomic-mp-neutral);
    flex-direction: row-reverse;
    font-size: var(--atomic-text-sm);
  }
  atomic-breadbox::part(breadcrumb-button):hover {
    border-color: var(--atomic-mp-primary);
  }
  atomic-breadbox::part(breadcrumb-button):focus {
    outline: none;
  }
  atomic-breadbox::part(breadcrumb-clear) {
    margin-left: 0;
    margin-right: 14px;
  }
  atomic-breadbox::part(breadcrumb-value) {
    padding-left: 14px;
    border-left: 1px solid var(--atomic-mp-neutral-medium);
  }
  atomic-breadbox::part(breadcrumb-button):hover atomic-breadbox::part(breadcrumb-value) {
    border-left: 1px solid var(--atomic-mp-neutral-medium-dark);
  }
  atomic-breadbox::part(clear) {
    border: 1px solid var(--atomic-mp-neutral-medium);
    background-color: var(--atomic-mp-neutral-medium-dark);
    padding: 0px 14px;
    color: var(--atomic-mp-neutral);
    font-size: var(--atomic-text-sm);
  }
  atomic-breadbox::part(clear):hover {
    background-color: var(--atomic-mp-neutral-medium);
    border: 1px solid var(--atomic-mp-primary);
  }
  atomic-breadbox::part(clear):focus {
    outline: none;
  }
  atomic-breadbox::part(show-less) {
    border-color: var(--atomic-mp-neutral-medium);
    background-color: var(--atomic-mp-neutral-medium-dark);
    padding: 0px 14px;
    color: var(--atomic-mp-neutral);
    font-size: var(--atomic-text-sm);
  }
  atomic-breadbox::part(show-less):hover {
    background-color: var(--atomic-mp-neutral-medium);
    border: 1px solid var(--atomic-mp-neutral);
  }
  atomic-breadbox::part(show-more) {
    border-color: var(--atomic-mp-neutral-medium);
    background-color: var(--atomic-mp-neutral-medium-dark);
    padding: 0px 14px;
    color: var(--atomic-mp-neutral);
    font-size: var(--atomic-text-sm);
  }
  atomic-breadbox::part(show-more):hover {
    background-color: var(--atomic-mp-neutral-medium);
    border: 1px solid var(--atomic-mp-neutral);
  }
  atomic-breadbox::part(show-more):focus {
    outline: none;
  }
  atomic-breadbox::part(label) {
    color: var(--atomic-mp-txt-bright);
    font-size: var(--atomic-text-lg);
    font-weight: var(--atomic-font-normal);
    padding: 0px 8px;
  }
  atomic-breadbox::part(breadcrumb-label) {
    display: none;
  }
`;
