import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState } from 'react';

type CheckboxProps = {
  sitecoreLabel: Field<string>;
  passValueToParentFunction: (value: boolean) => void;
  defaultChecked?: boolean;
  className?: string;
};

const MarketplaceCheckbox = (props: CheckboxProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(props.defaultChecked || false);

  const onChangeToggleCheckboxHandler = (): void => {
    if (isChecked) {
      setIsChecked(false);
      props.passValueToParentFunction(false);
    } else {
      setIsChecked(true);
      props.passValueToParentFunction(true);
    }
  };

  return (
    <div className={`${props.className} relative`}>
      <label className="flex pointer-events-none">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChangeToggleCheckboxHandler}
          className={`peer pointer-events-auto appearance-none min-w-[20px] h-[20px] border-2 rounded-sm cursor-pointer border-mp-txt-neutral focus:outline-none focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-mp-txt-primary focus-visible:outline-2 ${
            isChecked
              ? 'bg-mp-txt-primary relative border-none before:absolute before:content-[""] hover:shadow-[0_0_0_2px_rgba(209,219,253,1)]'
              : 'hover:shadow-[0_0_0_2px_rgba(209,219,253,1)] hover:border-0 disabled:border-base-normal disabled:bg-transparent'
          }`}
        />
        <RichText
          field={props.sitecoreLabel}
          className="rte ml-[10px] text-base pointer-events-auto [&>*>a]:text-mp-txt-primary hover:[&>*>a]:underline"
        />
        <div
          className={`pointer-events-none text-white peer-hover:text-primary-lighter ${
            isChecked ? 'block' : 'hidden'
          }`}
        >
          <FontAwesomeIcon
            size="1x"
            icon={faCheck}
            className="absolute top-0.5 font-bold text-base left-[3px] cursor-pointer"
          />
        </div>
      </label>
    </div>
  );
};

export default MarketplaceCheckbox;
