import { useCartData } from 'components/cart/cart-hooks';
import { addCartItem, applyCoupon } from 'components/cart/cart-slice';
import Spinner from 'components/spinner/Spinner';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useAppDispatch } from 'lib/store/hooks';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

type productListItem = {
  productId: string;
  quantity: number;
};

const AddToCart = (): JSX.Element => {
  const router = useRouter();
  const { query } = router;
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const dispatch = useAppDispatch();
  const cartItemData = useCartData();
  const cartId = cartItemData?.cartSummary.cartId as string;
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (!router.isReady || !cartId || isDone || session === undefined) {
      return;
    }
    const productString = query.id as string;
    const redirect = !query.redirect || query.redirect === 'true';
    const couponCode = query.promo as string;
    const getProductList = (productString: string): productListItem[] => {
      return productString?.split(',').map((item) => {
        const [productId, quantity] = item.split(':');
        return {
          productId,
          quantity: quantity ? parseInt(quantity) : 1,
        };
      });
    };
    const products = getProductList(productString);
    let timer = 1;
    products?.forEach((product) => {
      setTimeout(() => {
        // we need timeouts here because addCartItem method is wrapped into
        // createDebouncedAsyncThunk function and without timeouts only last item would be added in fact
        dispatch(
          addCartItem({
            sku: product.productId,
            quantity: product.quantity,
            bearerToken: bearerToken,
          })
        );
      }, timer);
      timer = timer + 250;
    });
    setIsDone(true);

    setTimeout(async () => {
      couponCode && (await dispatch(applyCoupon({ couponCode, bearerToken: bearerToken })));
      redirect && router.push('/cart');
    }, timer);
  }, [bearerToken, cartId, dispatch, isDone, query, router, session]);

  return (
    <div>
      <div className={`w-full relative`} id={'add-to-cart-page'}>
        <Spinner />
      </div>
      <div className="h-96"></div>
    </div>
  );
};

export default AddToCart;
