import { isEditorActive, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ContentCardProps } from './columned-content-props';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ContentCard = (props: ContentCardProps): JSX.Element => {
  const editorActive = isEditorActive();
  const twoColLayout = (props?.columnCount ?? 0) === 2 || (props?.columnCount ?? 0) > 4;
  return (
    <div className="contentCard mt-6">
      <Text tag="h3" field={props.title.jsonValue} className="text-left text-primary mb-4" />
      <RichText
        tag="div"
        field={props.text.jsonValue}
        className="rte text-left mb-6 text-almost-black"
      />

      <div
        className={`flex items-start flex-col md:flex-row md:justify-start md:items-baseline ${
          twoColLayout ? 'xl:flex-row' : 'xl:flex-col'
        }`}
      >
        {props?.ctaPrimaryButton && props?.ctaPrimaryButton.jsonValue && (
          <Link
            field={props.ctaPrimaryButton.jsonValue}
            linkCustomizations={{ noFollow: props.noFollow }}
            className="btn-primary"
          />
        )}

        {((props?.ctaSecondaryButton && props?.ctaSecondaryButton.jsonValue?.value?.href) ||
          editorActive) && (
          <Link
            field={props.ctaSecondaryButton.jsonValue}
            linkCustomizations={{ noFollow: props.secondaryNoFollow }}
            className={`btn-link mt-2 md:mt-0 md:ml-6 px-0 ${twoColLayout ? 'xl:ml-6' : 'xl:ml-0'}`}
            hideText
          >
            <span>{props.ctaSecondaryButton.jsonValue?.value?.text}</span>
            <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default ContentCard;
