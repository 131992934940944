import { useEffect } from 'react';
import { resetEditorChromes } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { ProductCategoryHandpickedListingProps } from '../../components/category-listing-search/product-category-listing-models';
import ProductListItem from '../../components/product-list-item-search/ProductListItem';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
const ProductListItemSearchHandPicked = (
  props: ProductCategoryHandpickedListingProps
): JSX.Element => {
  useEffect(() => {
    resetEditorChromes();
  }, []);
  const datasource = props?.fields?.data?.datasource;

  if (datasource == null) {
    return <div className="editor-message">Please select a datasource</div>;
  }

  const { products, title, description } = datasource;
  return products && products.targetItems.length > 0 ? (
    <>
      <section className="mb-10 max-w-content mx-auto text-center xl:text-left">
        <h2 className="text-almost-black leading-9 uppercase mb-4 tracking-widest-plus">
          {title.value}
        </h2>
        <p className="text-almost-black">{description.value}</p>
      </section>
      <div className="flex justify-center">
        <ul className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8">
          {products.targetItems.map((product, index) => (
            <li key={index} className="cols-span-1 border border-base-normal rounded">
              <ProductListItem key={index} {...product} />
            </li>
          ))}
        </ul>
      </div>
    </>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<ProductCategoryHandpickedListingProps>(
  ProductListItemSearchHandPicked
);
