import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, RichText, RichTextField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export type AccordionProps = {
  fields: {
    heading: Field<string>;
    content: RichTextField;
  };
  isactive?: boolean;
  index?: number;
  onAccordionSelection?: (index: number) => void;
};

const AccordionTab = (props: AccordionProps): JSX.Element => {
  const { isactive, index } = props;
  const editorActive = isEditorActive();
  const accordionClickHandler = (event: React.UIEvent): void => {
    event.preventDefault();
    props?.onAccordionSelection && props?.onAccordionSelection(index ?? 0);
  };
  return editorActive ? (
    <li className="  lg:mx-auto max-w-content list-none border-b border-primary px-6 ">
      <a
        role="accordion"
        href="#"
        className={`max-w-full p-4 flex justify-between  text-almost-black font-bold text-base bg-white
        `}
      >
        <Text tag="span" field={props?.fields?.heading} />
        <FontAwesomeIcon icon={faChevronUp} className="text-sm text-primary font-bold order-1" />
      </a>
      <RichText
        className="max-w-full p-4 body text-almost-black bg-base-lighter rte richTextBl [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:mb-10 "
        tag="div"
        field={props?.fields?.content}
      />
    </li>
  ) : (
    <li
      className={` lg:mx-auto max-w-content list-none  hover:shadow-card border-b  ${
        isactive ? 'bg-base-lighter border-primary' : 'bg-white border-neutral-light'
      } disabled:[&>a]:text-base-normal`}
    >
      <a
        id={`accordion_${index}`}
        aria-controls={`accordionpanel_${index}`}
        role="accordion"
        aria-selected={isactive}
        href="#"
        className={`max-w-content p-4 flex items-center justify-between  text-almost-black font-bold text-base 
        }`}
        onClick={accordionClickHandler}
      >
        <Text tag="div" field={props?.fields?.heading} />
        <FontAwesomeIcon
          icon={isactive ? faChevronUp : faChevronDown}
          className="text-sm text-primary font-bold order-1"
        />
      </a>
      {isactive && (
        <RichText
          className="max-w-full p-4 body text-almost-black bg-base-lighter richTextBl rte [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:mb-10 "
          tag="div"
          aria-labelledby={`accordion_${index}`}
          id={`accordionpanel_${index}`}
          field={props?.fields?.content}
        />
      )}
    </li>
  );
};

export default AccordionTab;
