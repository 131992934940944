import { AtomicResultLink, AtomicResultSectionTitle } from '@coveo/atomic-react';

export const InstantResultsTemplate = (): JSX.Element => {
  return (
    <>
      <AtomicResultSectionTitle>
        <AtomicResultLink />
      </AtomicResultSectionTitle>
    </>
  );
};

export default InstantResultsTemplate;
