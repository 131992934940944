import {
  Field,
  LinkField,
  withDatasourceCheck,
  Text,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import NextLink from 'components/foundation/non-sitecore/NextLink';

type IframeSectionProps = ComponentProps & {
  fields: {
    title: Field<string>;
    sourceLink: LinkField;
    width: Field<number>;
    height: Field<number>;
  };
};

const IframeSection = (props: IframeSectionProps): JSX.Element => {
  const editorActive = isEditorActive();
  const height = props.fields?.height.value != null ? `${props.fields?.height.value}` : '100%';
  const width = props.fields?.width.value != null ? `${props.fields?.width.value}` : '100%';
  return (
    <div className="flex flex-col sm:my-5 lg:my-10">
      <Text
        tag="h2"
        field={props.fields.title}
        className="font-bold text-lg mb-2h2 uppercase text-almost-black tracking-[0.28em] mb-2"
      />
      {editorActive && (
        <>
          <NextLink field={props.fields.sourceLink} />
          <span>Iframe will be visible in Preview</span>
        </>
      )}
      {!editorActive && (
        <div className={`${props.fields?.height?.value ?? 'min-h-screen'} flex w-full`}>
          <div className="relative flex flex-grow">
            <iframe
              src={props.fields.sourceLink.value?.href}
              width={width}
              height={height}
              className="max-w-content mx-auto"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withDatasourceCheck()<IframeSectionProps>(IframeSection);
