import { useState } from 'react';
import { TabsProps } from './tabbed-container-props';
import RenderTabbedPlaceholder from './RenderTabbedPlaceholder';
import Tab from './Tab';

const Tabs = (props: TabsProps): JSX.Element => {
  const { tabs, placeholderRendering } = props;
  const [activeTab, setActiveTab] = useState(0);

  const tabSelectionHandler = (index: number): void => {
    setActiveTab(index);
  };

  return (
    <div className="hidden xl:block">
      <ul
        role="tablist"
        className={`flex flex-wrap justify-center xl:space-x-6 xl:items-end ${
          tabs && tabs.length > 0 ? 'xl:mt-8 xl:mb-16' : 'xl:mt-10'
        } `}
      >
        {tabs &&
          tabs.map((tab, idx) => (
            <Tab
              key={idx}
              source=""
              tab={tab}
              active={activeTab}
              index={idx}
              onTabSelection={tabSelectionHandler}
              className={''}
            />
          ))}
      </ul>
      <RenderTabbedPlaceholder
        name="jss-tab-contents"
        rendering={placeholderRendering}
        activeTab={activeTab}
      />
    </div>
  );
};

export default Tabs;
