import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';

type IconLinkProps = ComponentProps & {
  fields: {
    icon: ReferenceStringItem;
    link: LinkField;
    noFollow?: Field<boolean>;
  };
};

const IconLink = (props: IconLinkProps): JSX.Element => {
  return (
    <li>
      <Link
        hideText={true}
        field={props.fields?.link}
        linkCustomizations={props.fields}
        className="group flex items-center gap-x-3 lg:flex-col lg:items-center lg:gap-x-0 lg:gap-y-2 btn-link-underline underline p-0 rounded-sm focus-visible:outline-none focus-visible:ring-0"
      >
        {props.fields.icon?.fields?.value?.value && (
          <div className="w-6 h-6 lg:w-20 lg:h-20 flex items-center justify-center">
            <FontAwesomeIcon
              icon={props.fields.icon.fields.value.value as IconProp}
              className="text-almost-black text-base lg:text-[3rem] focus-visible:outline-none"
            />
          </div>
        )}
        <div className="rounded-sm group-focus-visible:ring-3 font-avidwalsheimbold ring-primary ring-offset-3">
          {props.fields?.link?.value.text}
        </div>
      </Link>
    </li>
  );
};

export default withDatasourceCheck()<IconLinkProps>(IconLink);
