import {
  ImageField,
  LinkField,
  NextImage as NextImageSitecore,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { ComponentProps } from 'lib/component-props';
import Carousel from 'components/carousel/Carousel';
import { useState } from 'react';
import MarketplaceCarouselDots from 'components/carousel/marketplace/MarketplaceCarouselDots';

export type BannerProps = {
  bannerImage: {
    jsonValue: ImageField;
  };
  bannerLink: {
    jsonValue: LinkField;
  };
};

type BannerResults = {
  results: BannerProps[];
};

type DataSource = {
  children: BannerResults;
};

type BannerCarouselProps = ComponentProps & {
  fields: {
    data: {
      datasource: DataSource;
    };
  };
};

const BannerCarousel = (props: BannerCarouselProps): JSX.Element => {
  const datasource = props?.fields?.data?.datasource;
  const bannerItems = datasource?.children?.results;
  const [fullSizeImgs, setFullSizeImgs] = useState(false);
  const switchImgSize = (): void => {
    setFullSizeImgs(!fullSizeImgs);
  };

  return (
    <div>
      {datasource && bannerItems && bannerItems.length > 0 && (
        <div className="hero-banner-carusel--marketplace mb-2">
          <div className="sm:mt-0">
            <Carousel
              MaxItemsPerSlideOnDesktop="1"
              MaxItemsPerSlideOnTablet="1"
              MaxItemsPerSlideOnMobile="1"
              autoPlay={true}
              autoPlaySpeed={6000}
              infinite={true}
              buttonGroup={<></>}
              itemClass="justify-center"
              customDotsGroup={<MarketplaceCarouselDots />}
              carouselWrapperClass="pb-5"
            >
              {bannerItems.map((item, index) => {
                return (
                  <div key={index}>
                    {item.bannerLink?.jsonValue.value.href?.length !== undefined &&
                    item.bannerLink?.jsonValue.value.href?.length > 0 ? (
                      <NextLink
                        field={item.bannerLink?.jsonValue || null}
                        className={`animate-slide_in lg:pl-[2px] ${
                          item.bannerLink?.jsonValue.value.class ?? ''
                        }`}
                        hideText={true}
                      >
                        <NextImage
                          field={item.bannerImage.jsonValue}
                          className="rounded object-cover object-left min-h-[130px] aspect-[148/34]"
                          useAvidCdn={true}
                        />
                      </NextLink>
                    ) : (
                      <NextImageSitecore
                        field={item.bannerImage.jsonValue}
                        className={`animate-slide_in lg:pl-[2px] rounded object-cover object-left min-h-[130px] ${
                          fullSizeImgs
                            ? 'aspect-auto lg:w-5/6 m-auto'
                            : 'max-h-[32vh]  aspect-[148/34]'
                        } cursor-pointer mb-2`}
                        onClick={switchImgSize}
                      />
                    )}
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
};

export default withDatasourceCheck()<BannerCarouselProps>(BannerCarousel);
