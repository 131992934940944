import TagManager from 'react-gtm-module';

export const pushFormStartEvent = (
  eventName: string,
  formName: string,
  formId: string,
  formDestination: string
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      form_name: formName,
      form_id: formId,
      form_destination: formDestination,
    },
  });
};

export const pushFormSubmitEvent = (
  eventName: string,
  formName: string,
  formId: string,
  formDestination: string,
  formSubmitText: string
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      form_name: formName,
      form_id: formId,
      form_destination: formDestination,
      form_submit_text: formSubmitText,
    },
  });
};

export const pushFormAbandonedEvent = (
  eventName: string,
  formName: string,
  formId: string,
  formDestination: string,
  formField: string
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      form_name: formName,
      form_id: formId,
      form_destination: formDestination,
      form_field: formField,
    },
  });
};
