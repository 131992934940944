import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { PickPlanPricingCardDataSource, PricingCardProps } from './pricing-card-props';
import { useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import BasePricingCard from '../../components/pricing-card/BasePricingCard';
import { usePrice } from 'lib/commerce/product/price-hook';
import { useEffect } from 'react';
import { pushViewItem } from 'lib/google-analytics/commerce';

const PickPlanPricingCard = (
  props: PricingCardProps<PickPlanPricingCardDataSource>
): JSX.Element => {
  const { t } = useI18n();

  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const productItem = props.fields?.data?.contextItem?.product?.targetItems[0] ?? null;
  const productId = productItem?.productId.value ?? '';
  const price = usePrice(productId, currencyIsoCode);
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);
  useEffect(() => {
    if (price == null || productItem == null || props.stopEvents || currencyIsoCode == null) {
      return;
    }

    pushViewItem(
      currencyIsoCode,
      price.unitPrice.toString(),
      {
        id: productItem.productId.value,
        sku: productItem.productCode.value,
        name: productItem.productName.value,
        price: price.listPrice.toString(),
        discount: (price.listPrice - price.unitPrice).toString(),
        brand: productItem.brand.value,
        variant: productItem.term?.value ?? 'N/A',
        itemCategory: productItem.primaryCategory?.targetItems[0]?.categoryName?.value,
        itemCategory2: productItem.additionalCategories?.targetItems[0]?.categoryName?.value,
        itemCategory3: productItem.additionalCategories?.targetItems[1]?.categoryName?.value,
        itemCategory4: productItem.additionalCategories?.targetItems[2]?.categoryName?.value,
        productSku: productItem?.productCode?.value,
      },
      (customerInfo?.contactId as string) || ''
    );
  }, [currencyIsoCode, customerInfo?.contactId, price, productItem, props.stopEvents]);

  return (
    <BasePricingCard
      pricingCard={props}
      fullCta={
        <NextLink
          className="btn-primary ml-auto w-full px-8 relative text-lg"
          field={props.fields?.data?.datasource?.buttonLink?.jsonValue}
          hideText
        >
          <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
        </NextLink>
      }
      miniCta={
        <NextLink
          className="btn-primary ml-auto px-3 py-1 text-[1rem]"
          field={props.fields?.data?.datasource?.buttonLink?.jsonValue}
          hideText
        >
          <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
        </NextLink>
      }
      frequencyText={<>/{t(`PricingCard_Year`)}</>}
      productPricing={price}
      productItem={productItem}
      loading={price == null}
    />
  );
};

export default PickPlanPricingCard;
