import ReactPlayerLoader from '@brightcove/react-player-loader';
import { useInView } from 'react-intersection-observer';
import { useEffect, useRef } from 'react';
import { pushVideoEvent } from 'lib/google-analytics/brightcove-analytics';

type BrightcoveEmbededVideoProps = {
  videoId: string;
  playerId?: string;
  playlistId?: string;
  autoplay?: boolean;
  loop?: boolean;
  hideControls?: boolean;
};

const BrightcoveEmbededVideo = (props: BrightcoveEmbededVideoProps): JSX.Element => {
  const [ref, videoOnScreen] = useInView({
    threshold: 0.75,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mediaRef = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessHandler = (success: any): void => {
    if (props.autoplay) {
      success.ref.player().on('loadedmetadata', () => {
        success.ref.player().pause();
      });
      if (!props.loop) {
        success.ref.player().on('ended', () => {
          success.ref.player().controls(true);
        });

        success.ref.player().play(() => {
          success.ref.player().controls(false);
        });
      }
    }
    if (props.hideControls) {
      success.ref.player().controls(false);
    }
    if (props.loop) {
      success.ref.player().loop(true);
    }
    initAnalytics(success.ref.player());
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initAnalytics = (player: any): void => {
    let ev25 = false;
    let ev50 = false;
    let ev75 = false;
    player.on('play', function () {
      sendVideoEvent(player, 'video_start');
    });
    player.on('ended', function () {
      sendVideoEvent(player, 'video_complete');
    });
    player.on('timeupdate', function () {
      const videoPercent = getVideoPercent(player);
      switch (true) {
        case !ev25 && videoPercent >= 25:
          sendVideoEvent(player, 'video_progress');

          ev25 = true;
          break;
        case !ev50 && videoPercent >= 50:
          sendVideoEvent(player, 'video_progress');
          ev50 = true;
          break;
        case !ev75 && videoPercent >= 75:
          sendVideoEvent(player, 'video_progress');

          ev75 = true;
          break;
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendVideoEvent = (player: any, eventName: string): void => {
    const videoPercent = getVideoPercent(player);

    pushVideoEvent(
      eventName,
      player.mediainfo.name,
      'brightcove',
      player.currentSrc(),
      props.autoplay ? 'auto' : 'click',
      player.duration(),
      videoPercent,
      player.currentTime(),
      player.currentTime()
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getVideoPercent = (player: any): number => {
    if (player != null) {
      return Math.floor((player.currentTime() / player.duration()) * 100);
    }
    return 0;
  };
  useEffect(() => {
    if (mediaRef.current?.player?.ended() && !props.loop) {
      return;
    }
    if (mediaRef.current) {
      videoOnScreen ? mediaRef.current?.player?.play() : mediaRef.current?.player?.pause();
    }
  }, [videoOnScreen, props.loop]);

  return (
    <>
      {props.playlistId && (
        <div ref={ref}>
          <ReactPlayerLoader
            accountId={process.env.BRIGHTCOVE_ACCOUNT_ID}
            playlistId={props.playerId}
            attrs={{
              className:
                'aspect-video [&>.video-js]:aspect-video [&>.video-js]:w-full [&>.video-js]:h-full',
            }}
          />
        </div>
      )}
      {!props.playlistId && (
        <div ref={ref}>
          <ReactPlayerLoader
            ref={mediaRef}
            accountId={process.env.BRIGHTCOVE_ACCOUNT_ID}
            videoId={props.videoId}
            playerId={props.playerId}
            attrs={{
              className:
                'aspect-video [&>.video-js]:aspect-video [&>.video-js]:w-full [&>.video-js]:h-full',
            }}
            onSuccess={onSuccessHandler}
          />
        </div>
      )}
    </>
  );
};

export default BrightcoveEmbededVideo;
