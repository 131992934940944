import { Text, Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useState } from 'react';
import React from 'react';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { toSlug } from 'lib/utils/string-format';

export type AnchorItems = {
  name: string;
  id: string;
  anchorLinkText: {
    jsonValue: Field<string>;
  };
  anchorLink: {
    jsonValue: LinkField;
  };
};

type AnchorLinksBarProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        id: string;
        name: string;
        jumpToText: {
          jsonValue: Field<string>;
        };
        anchorLinks: {
          id: string;
          name: string;
          anchorLinks: Array<AnchorItems>;
        };
      };
    };
  };
};

export const AnchorLinksBar = (props: AnchorLinksBarProps): JSX.Element => {
  const [display, setDisplay] = useState(false);
  const handleDisplay = (): boolean => {
    return !display;
  };

  return (
    <div className="bg-neutral-darkest p-4">
      <ul className="text-left lg:text-right">
        <li
          className="cursor-pointer lg:cursor-auto lg:absolute lg:left-10 text-white p-3"
          onClick={(): void => setDisplay(handleDisplay)}
        >
          <span className="font-bold">
            <Text field={props.fields?.data?.datasource?.jumpToText?.jsonValue} />
          </span>
          {!display && (
            <span
              id="toggleIconOff"
              className="absolute right-5 lg:hidden cursor-pointer
              before:content-[' ']
              before:w-[8px]
              before:h-[8px]
              before:mt-[6.5px]
              before:inline-block
              before:rotate-45
              before:border-r-2 before:border-r-white before:border-solid
              before:border-b-2 before:border-r-white before:border-solid
              before:absolute
              before:right-[23px]
              after:content-[' ']
              after:w-[25px]
              after:h-[25px]
              after:border-2 after:border-white  after:border-solid
              after:rounded-full
              after:absolute
              after:m-auto
              after:bottom-0
              after:right-[15px]
              after:top-[12px]
              "
            ></span>
          )}
          {display && (
            <span
              id="toggleIconOn"
              className="absolute right-5 lg:hidden cursor-pointer
              before:content-[' ']
              before:w-[8px]
              before:h-[8px]
              before:mt-[9.5px]
              before:inline-block
              before:rotate-[225deg]
              before:border-r-2 before:border-r-white before:border-solid
              before:border-b-2 before:border-r-white before:border-solid
              before:absolute
              before:right-[23px]
              after:content-[' ']
              after:w-[25px]
              after:h-[25px]
              after:border-2 after:border-white  after:border-solid
              after:rounded-full
              after:absolute
              after:m-auto
              after:bottom-0
              after:right-[15px]
              after:top-[12px]
              "
            ></span>
          )}
        </li>
        {display && (
          <li className="text-white p-3 md:hidden">
            <hr className="text-[#272C38]" />
          </li>
        )}
        {props.fields?.data?.datasource?.anchorLinks?.anchorLinks &&
          props.fields?.data?.datasource?.anchorLinks?.anchorLinks?.map(
            (item: AnchorItems, index: number) => {
              return (
                <li
                  key={index}
                  className={
                    display
                      ? 'md:inline-block pt-3 pb-3 pr-4 pl-4'
                      : 'hidden lg:inline-block pt-3 pb-3 pr-4 pl-4'
                  }
                >
                  {!item.anchorLink && (
                    <a
                      href={`#${toSlug(item.anchorLinkText.jsonValue.value)}`}
                      className="cursor-pointer underline text-white"
                    >
                      <Text field={item.anchorLinkText.jsonValue} />
                    </a>
                  )}
                  {item.anchorLink && (
                    <Link
                      field={item.anchorLink.jsonValue}
                      className="cursor-pointer underline text-white"
                    />
                  )}
                </li>
              );
            }
          )}
      </ul>
    </div>
  );
};

export default withDatasourceCheck()<AnchorLinksBarProps>(AnchorLinksBar);
