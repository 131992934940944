import {
  Text,
  Field,
  RichText,
  Placeholder,
  withDatasourceCheck,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { t14TemplateIdVal } from 'lib/constants';

type PageIntroProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    description: Field<string>;
    text: Field<string>;
    alignment: Field<string>;
    headingTag: Field<string>;
  };
};

function PageIntro(props: PageIntroProps): JSX.Element {
  const innerContainerClass =
    props.fields?.alignment?.value == 'Left' ? 'items-start text-left' : 'items-center text-center';

  const outerContainerClass =
    props.fields?.alignment?.value == 'Left' ? 'justify-start' : 'justify-center';
  const context = useSitecoreContext();
  const t14TemplateId = t14TemplateIdVal;
  const pageTemplateId = context?.sitecoreContext.route?.templateId;
  const headingTag = props?.fields?.headingTag?.value || 'h1';
  const headingTagClassNames =
    'mb-6 items-stretch text-almost-black font-normal uppercase text-32 leading-9.5 tracking-8.96';
  const addWidthforT14 =
    t14TemplateId === pageTemplateId ? 'xl:w-8/12 xl:max-w-800 lg:mb-10 [&_h1]:mb-0' : 'lg:mb-20';
  return (
    <div
      className={`page-intro max-w-content mx-auto flex bg-slate-300 mb-10 px-6 ${addWidthforT14} ${outerContainerClass}`}
    >
      <div className={`${innerContainerClass} flex flex-col max-w-content`}>
        <div className="min-w-full">
          <Placeholder name="pageintro-metadata" rendering={props.rendering} />
        </div>
        <Text
          tag={headingTag}
          className={`${headingTagClassNames} ${
            headingTag === 'h1' ? 'lg:text-42 lg:leading-12.5' : ''
          }`}
          field={props?.fields?.heading}
        />
        <RichText
          tag="p"
          className="rte [&_a]:underline text-almost-black [&_a]:text-primary mb-6 font-normal text-xl leading-7.5 lg:text-2xl lg:leading-9"
          field={props.fields.text}
        />
        <RichText
          tag="p"
          className="rte [&_a]:underline  text-almost-black [&_a]:text-primary font-normal text-text-base leading-6"
          field={props.fields.description}
        />
      </div>
    </div>
  );
}

export default withDatasourceCheck()(PageIntro);
