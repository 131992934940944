import Image from 'next/image';
import Link from 'next/link';
import { ProductResult } from './product-category-listing-product-results';

type ProductCategoryListingProductProps = {
  productResult: ProductResult;
};

const ProductCategoryListingProduct = (props: ProductCategoryListingProductProps): JSX.Element => {
  const { productResult } = props;
  return (
    <div className="product-item flex flex-row items-start gap-6">
      <div className="w-fit">
        {productResult.productImage?.jsonValue?.value?.src && (
          <figure className="list-item-thumbnail">
            <Link href={productResult.productDetailsPageLink.url} passHref legacyBehavior>
              <Image
                src={productResult.productImage?.jsonValue?.value?.src}
                width={145}
                height={108}
                alt={productResult.productName.value}
                layout="responsive"
              />
            </Link>
          </figure>
        )}
      </div>

      <section className="w-auto">
        <Link href={productResult.productDetailsPageLink.url} passHref legacyBehavior>
          <a className="intro-text text-primary">{productResult.productName.value}</a>
        </Link>
        <p className="body text-almost-black">{productResult.shortDescription.value}</p>
      </section>
    </div>
  );
};

export default ProductCategoryListingProduct;
