import { GraphQLClient } from 'lib/common/graphql-client';
import {
  TableAttributeData,
  TableCategoryData,
  TableDetailData,
} from './requirements-and-specifications-table-response';
import getRequirementsAndSpecificationsTableCategoriesQuery from './requirements-and-specifications-table-categories-query';
import getRequirementsAndSpecificationsTableAttributesQuery from './requirements-and-specifications-table-attributes-query';
import getRequirementsAndSpecificationsTableDetailsQuery from './requirements-and-specifications-table-details-query';
import { AttributeType, CategoryType } from './models';

export const getRequirementsAndSpecificationsTableData = async (
  datasource: string,
  language: string
): Promise<Array<CategoryType>> => {
  const graphQLClient = GraphQLClient();

  const results: CategoryType[] = [];
  let categoriesHasNext = true;
  let categoriesAfter = '';

  while (categoriesHasNext) {
    const categoriesResult: TableCategoryData = await graphQLClient.request(
      getRequirementsAndSpecificationsTableCategoriesQuery,
      {
        datasource: datasource,
        language: language,
        after: categoriesAfter,
      }
    );

    results.push(...(categoriesResult?.datasource?.categoryAttributes?.results as CategoryType[]));

    categoriesHasNext = categoriesResult?.datasource?.categoryAttributes?.pageInfo?.hasNext;
    categoriesAfter = categoriesResult?.datasource?.categoryAttributes?.pageInfo?.endCursor;
  }

  if (results && results.length > 0) {
    for (let i = 0; i < results.length; i++) {
      let attributesHasNext = true;
      let attributesAfter = '';
      const attributeResultsArray: AttributeType[] = [];

      const categoryDatasource = results[i].id;

      while (attributesHasNext) {
        const attributesResult: TableAttributeData = await graphQLClient.request(
          getRequirementsAndSpecificationsTableAttributesQuery,
          {
            datasource: categoryDatasource,
            language: language,
            after: attributesAfter,
          }
        );

        attributesHasNext = attributesResult?.datasource?.attributeDetails?.pageInfo?.hasNext;
        attributesAfter = attributesResult?.datasource?.attributeDetails?.pageInfo?.endCursor;

        attributeResultsArray.push(
          ...(attributesResult?.datasource?.attributeDetails?.results as AttributeType[])
        );
      }

      results[i] = {
        ...results[i],
        attributeDetails: {
          results: attributeResultsArray,
        },
      };

      if (attributeResultsArray && attributeResultsArray.length > 0) {
        for (let j = 0; j < attributeResultsArray.length; j++) {
          const attributesDatasource = attributeResultsArray[j].id;

          const detailsResult: TableDetailData = await graphQLClient.request(
            getRequirementsAndSpecificationsTableDetailsQuery,
            {
              datasource: attributesDatasource,
              language: language,
            }
          );

          results[i].attributeDetails.results[j] = {
            ...results[i].attributeDetails.results[j],
            details: {
              results: detailsResult.datasource.details.results,
            },
          };
        }
      }
    }
  }

  return results;
};
