import gql from 'graphql-tag';

const getProductCategoryListingProductQuery = gql`
  query ProductCategoryListingProductQuery(
    $searchRoot: String!
    $templateId: String!
    $category: String!
    $pageSize: Int = 10
    $after: String
  ) {
    search(
      where: {
        AND: [
          { name: "_path", value: $searchRoot, operator: CONTAINS }
          { name: "_templates", value: $templateId, operator: CONTAINS }
          {
            OR: [
              { name: "primaryCategory", value: $category, operator: CONTAINS }
              { name: "additionalCategories", value: $category, operator: CONTAINS }
            ]
          }
        ]
      }
      after: $after
      first: $pageSize
    ) {
      total
      pageInfo {
        endCursor
        hasNext
      }
      results {
        id
        ... on _ProductDetails {
          productName {
            value
          }
          shortDescription {
            value
          }
          productDetailsPageLink {
            url
            targetItem {
              id
            }
          }
        }
        ... on _ProductImages {
          productImage {
            jsonValue
          }
        }
      }
    }
  }
`;
export default getProductCategoryListingProductQuery;
