import { Field, ImageField, LinkField, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { BrightcoveVideoProps } from 'components/video/brightcove-video-props';
import { YoutubeVideoProps } from 'components/video/youtube-video-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import VideoPlayerIcon from 'components/video/VideoPlayerIcon';
import BaseVideoModal from 'components/video/BaseVideoModal';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { youtubeThumbnailProps } from '../VideoBlock';
import { getYoutubeThumbnail } from 'lib/youtube-thumbnail-fetcher';

export type TeaserProps = {
  id: string;
  fields: {
    title: Field<string>;
    image: ImageField;
    text: Field<string>;
    subheading: Field<string>;
    link: LinkField;
    introductoryText: Field<string>;
    videos: (BrightcoveVideoProps | YoutubeVideoProps)[];
    noFollow?: Field<boolean>;
  };
  columns: number;
};

const Teaser = (props: TeaserProps): JSX.Element => {
  const teaserSize =
    props.columns === 2 ? { width: 900, height: 400 } : { width: 600, height: 300 };
  const video = props.fields?.videos?.[0];
  let videoThumbnail = video?.fields?.stillImage?.value?.src != null && video?.fields?.stillImage;
  const isYoutubeVideo = (video as YoutubeVideoProps)?.fields?.sourceId?.value != undefined;
  const isBrightcoveVideo = (video as BrightcoveVideoProps)?.fields?.videoReference != undefined;
  let videoId, playerId;

  if (isYoutubeVideo) {
    videoId = (video as YoutubeVideoProps)?.fields?.sourceId?.value;
  } else if (isBrightcoveVideo) {
    const thumbnailURL = (video as BrightcoveVideoProps)?.fields?.videoReference[0]?.fields
      ?.ThumbnailURL?.value as string;
    videoId = (video as BrightcoveVideoProps)?.fields?.videoReference[0]?.fields?.ID
      ?.value as string;
    playerId = (video as BrightcoveVideoProps)?.fields?.player?.fields?.ID?.value as string;

    if (!videoThumbnail && thumbnailURL) {
      videoThumbnail = { value: { src: thumbnailURL } };
    }
  }

  const [youtubeVideoThumbnail, setyoutubeVideoThumbnail] = useState<youtubeThumbnailProps>();
  useEffect(() => {
    if (isYoutubeVideo) {
      if (!videoThumbnail) {
        videoId &&
          getYoutubeThumbnail(videoId).then((data: youtubeThumbnailProps) => {
            setyoutubeVideoThumbnail(data);
          });
      } else {
        const thumbnailURL = videoThumbnail?.value && videoThumbnail.value?.src;
        thumbnailURL && setyoutubeVideoThumbnail({ value: { src: thumbnailURL } });
      }
    }
  }, [isYoutubeVideo, videoId, videoThumbnail]);

  return (
    <li className="teaser col-span-1 flex flex-col divide-gray-200 bg-white my-4 pb-5 md:border-none border-b md:py-30 md:my-0 text-center items-start">
      <div className="pb-6">
        {isYoutubeVideo ? (
          <BaseVideoModal
            brightcoveVideo={isBrightcoveVideo}
            youtubeVideo={isYoutubeVideo}
            video={video}
            openCtaClassName=""
            openCtaChildren={
              <div className="relative  ">
                <div
                  className={`before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:bottom-30 before:top-0 before:left-0 before:w-full before:h-full`}
                >
                  {youtubeVideoThumbnail ? (
                    <NextImage
                      field={youtubeVideoThumbnail}
                      className={`object-cover mb-12 h-full w-full`}
                      width={teaserSize.width}
                      height={teaserSize.height}
                    />
                  ) : (
                    <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                  )}
                  <VideoPlayerIcon className="text-80 text-white border-5 border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                </div>
              </div>
            }
          />
        ) : isBrightcoveVideo ? (
          <BaseVideoModal
            video={video}
            brightcoveVideo={isBrightcoveVideo}
            youtubeVideo={isYoutubeVideo}
            playerId={isBrightcoveVideo ? playerId : undefined}
            openCtaClassName=""
            openCtaChildren={
              <div className="relative  ">
                <span
                  className={`${videoThumbnail}, ${teaserSize.width}, ${teaserSize.height}`}
                ></span>
                <div className=" before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:bottom-30 before:top-0 before:left-0 before:w-full before:h-full">
                  {videoThumbnail ? (
                    <NextImage
                      field={videoThumbnail}
                      className="object-cover mb-12"
                      width={teaserSize.width}
                      height={teaserSize.height}
                    />
                  ) : (
                    <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                  )}
                  <VideoPlayerIcon className="text-80 text-white border-5 border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                </div>
              </div>
            }
          />
        ) : (
          <NextImage
            field={props.fields.image}
            className="pointer-events-none object-cover group-hover:opacity-75 mb-12"
            width={teaserSize.width}
            height={teaserSize.height}
          />
        )}
      </div>

      <RichText
        field={props.fields.subheading}
        className="rte accent-2 text-secondary text-left align-top leading-[1.375rem] uppercase tracking-[0.2em] mb-2 lg:leading-[1.75rem]"
      />
      <Text
        tag="h3"
        field={props.fields.title}
        className="text-almost-black text-left align-top mb-10"
      />
      <RichText
        field={props.fields.introductoryText}
        className="rte font-bold text-xl text-almost-black text-left align-top leading-[1.875rem] mb-3 lg:text-2xl lg:leading-9"
      />
      <RichText
        field={props.fields.text}
        className="rte text-xl text-almost-black text-left align-top leading-[1.875rem] mb-3 lg:text-2xl lg:leading-9"
      />
      <Link
        field={props.fields.link}
        linkCustomizations={props.fields}
        className="btn-link-underline"
        hideText
      >
        <span>{props.fields?.link?.value?.text}</span>
        <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
      </Link>
    </li>
  );
};

export default Teaser;
