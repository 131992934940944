import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';

const SubNavigationDesktop = dynamic(
  () => import('../components/sub-navigation/SubNavigationDesktop'),
  {
    ssr: false,
  }
);
const SubNavigationMobile = dynamic(
  () => import('../components/sub-navigation/SubNavigationMobile'),
  {
    ssr: false,
  }
);

export type SubNavigationItem = {
  id: string;
  title: string;
  url: string;
  children: Array<SubNavigationItem>;
};

export type SubNavigationRoot = SubNavigationItem & {
  productName: string;
  currentPageId: string;
};

export type SubNavigationProps = {
  fields: SubNavigationRoot;
  mini: boolean;
};

export const SubNavigation = (props: SubNavigationProps): JSX.Element => {
  const editorActive = isEditorActive();

  if (!props.mini) {
    if (editorActive) {
      return (
        <div>
          Sub-navigation is hidden in Experience Editor.
          {props?.fields == null && (
            <div className="text-red max-w-xs">
              Warning: This sub-navigation will not display on the live site beacuse you are missing
              a root page. Ensure that the parent product page has the Is Sub-Navigation Root
              checkbox selected.
            </div>
          )}
        </div>
      );
    }
    return <></>;
  }

  if (props?.fields == null) {
    return <></>;
  }

  return (
    <div className="flex items-center flex-1 pl-6">
      <p className="h6 md:h5 mr-7">{props.fields.productName}</p>
      <SubNavigationDesktop {...props} />
      <SubNavigationMobile {...props} />
    </div>
  );
};

export default SubNavigation;
