import {
  AtomicResultSectionExcerpt,
  AtomicResultSectionTitle,
  Result,
  AtomicResultHtml,
  AtomicResultSectionVisual,
} from '@coveo/atomic-react';
import { default as NextImage } from 'next/image';
import { absoluteMediaUrl } from 'lib/utils/sitecore-media-utils';
import SearchTitle from '../../atomic-components/SearchTitle';

// import { getProductPricing } from '../../../lib/commerce/product/product-api';
// import { useEffect, useState } from 'react';

const styles = `
    .result-root.with-sections.display-list.image-small {
      grid-template-areas:
      "visual"
      "title"
      "excerpt"
      "bottom-metadata"!important;
      grid-template-columns: auto!important;
    }
  
    @media (min-width: 768px) {
      .result-root.with-sections.display-list.image-small {
        grid-template-areas:
        "title title title visual"
        "excerpt excerpt excerpt visual"
        "bottom-metadata bottom-metadata bottom-metadata visual"!important;
        grid-template-columns: minmax(0px, min-content) auto 1fr minmax(0px, min-content)!important;
        grid-template-rows: auto auto auto!important;
      }
    }
  
    atomic-result-section-title,
    atomic-result-section-excerpt {
      margin-top: 0!important;
      margin-bottom: .5rem!important;
    }
  
    atomic-result-text {
      color: #194BF5;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  
    @media (min-width: 992px) {
      atomic-result-text {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
  
    atomic-html,
    .body {
      color: #222222;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    atomic-result-section-excerpt {
      max-height: 3rem!important;
    }
  
    .price-text {
      color: var(--Almost-Black, #222);
      font-size: 16px;
      line-height: calc(24 / 16 * 1em);
    }
  
    .price-text-strikethrough {
      font-size: 14px;
      text-decoration: line-through;
    }
  
    .bottom-metadata {
      display: flex;
      flex-direction: column;
      gap: 24px;
      grid-area: bottom-metadata / bottom-metadata / bottom-metadata / bottom-metadata!important;
      margin: 0!important;
      max-height: none!important;
      overflow: unset!important;
    }
  
    .result-links {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      margin-top: .24px;
      max-height: none!important;
    }
  
    @media (min-width: 1200px) {
      .result-links {
        flex-direction: row;
        gap: 32.72px;
      }
    }
  
    .result-links > a {
      align-items: center;
      color: var(--atomic-pill-text);
      display: flex;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 18px;
      font-weight: 500;
      gap: 4px;
      line-height: calc(27 / 18 * 1em);
    }
  
    .result-links > a > .icon {
      display: inline-block;
    }
  
    .result-links > a {
      align-items: center;
      color: var(--atomic-pill-text);
      display: flex;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 18px;
      font-weight: 500;
      gap: 4px;
      line-height: calc(27 / 18 * 1em);
    }
  
    .result-links > a > .icon {
      display: inline-block;
    }
  
    .result-description {
      color: #222222;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 16px;
      line-height: calc(24 / 16 * 1em);
    }
  
    .result-media {
      aspect-ratio: 4/3;
      grid-area: visual;
      height: auto!important;
      margin: 0 0 24px;
      max-width: 100%;
    }
  
    @media (max-width: 767px) {
      .result-media {
        width: 100%!important;
      }
    }
  
    @media (min-width: 768px) {
      .result-media {
        margin: 0 0 0 1rem;
        max-width: 278px;
      }
    }
  
    .result-media > span {
      height: 100%!important;
      width: 100%!important;
    }
  
    .result-thumbnail {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    .intro-text {
      color: #194BF5;
      font-family: var(--font-gtWalshiemAvidRegular);
      font-size: 1.5rem;
      line-height: 2.25rem;
      font-weight: 400;
    }
  `;

export const PluginsTemplate: React.FC<{
  result: Result;
}> = ({ result }) => {
  // const { searchimageurl, productid } = result.raw;
  const { searchimageurl, searchtitle } = result.raw;
  const { clickUri } = result;
  // const [salesPrice, setSalesPrice] = useState(0);

  const absoluteSearchImageUrl =
    searchimageurl !== undefined ? `${absoluteMediaUrl(searchimageurl as string)}` : null;
  // const pricingLabel = 'Price: ';
  // const currencyIsoCode = 'USD';
  // useEffect(() => {
  //   const getPrice = async (): Promise<void> => {
  //     const params = { productId: productid, currencyIsoCode: currencyIsoCode };
  //     const response = getProductPricing(params);
  //     console.log('RESPOSNE', response);
  //     setSalesPrice(10);
  //   };
  //   getPrice();
  // }, [currencyIsoCode, productid]);

  return (
    <>
      <style>{styles}</style>

      {absoluteSearchImageUrl && (
        <AtomicResultSectionVisual className="result-media">
          <NextImage
            src={absoluteSearchImageUrl}
            priority={false}
            width={400}
            height={300}
            alt=""
          />
        </AtomicResultSectionVisual>
      )}
      <AtomicResultSectionTitle>
        <SearchTitle searchTitle={searchtitle as string} href={clickUri} />
      </AtomicResultSectionTitle>
      <AtomicResultSectionExcerpt>
        <AtomicResultHtml field="searchdescription" />
      </AtomicResultSectionExcerpt>
      {/* <AtomicResultSectionBottomMetadata className="bottom-metadata">
        {salesPrice !== 0 && (
          <div className="price-text">
            {pricingLabel} <strong>${(Math.round(salesPrice * 100) / 100).toFixed(2)} USD</strong>
          </div>
        )}
      </AtomicResultSectionBottomMetadata> */}
    </>
  );
};

export default PluginsTemplate;
