import { withDatasourceCheck, isEditorActive, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { MarketoProps, useMarketo } from 'lib/hooks/use-marketo';

const MarketoForm = (props: MarketoProps): JSX.Element => {
  const editorActive = isEditorActive();
  const formVal = `mktoForm_${props.fields.formId.value}`;
  useMarketo(props);
  const formAlignClass = props.fields.isCenterAligned.value ? 'lg:col-start-2' : '';
  return (
    <>
      <div className="py-2 px-6 max-w-content mx-auto ">
        <section className="w-full grid grid-cols-1 lg:grid-cols-3 ">
          {editorActive && (
            <div className={formAlignClass}>
              <p className="p-2">
                <b>Form Id:</b> <Text tag="span" field={props.fields.formId} />
              </p>
              <p className="p-2">
                <b>Munchkin Id:</b> <Text tag="span" field={props.fields.munchkinId} />
              </p>
              <p className="p-2">
                <b>Center Aligned?:</b> <Text tag="span" field={props.fields.isCenterAligned} />
              </p>
            </div>
          )}
          {!editorActive && (
            <div className={formAlignClass}>
              <form id={formVal}></form>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default withDatasourceCheck()<MarketoProps>(MarketoForm);
