import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const ProductCategoryHandpickedListingContainer = (props: ComponentProps): JSX.Element => {
  return (
    <div className="flex justify-center mt-12 px-6 lg:mt-20 lg:px-8">
      <div className="max-w-content">
        <Placeholder name="jss-clp" rendering={props.rendering} />
      </div>
    </div>
  );
};

export default ProductCategoryHandpickedListingContainer;
