import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { getCartSummary } from 'components/cart/cart-slice';
import { getOrderDetails } from 'components/order/order-slice';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useAppDispatch } from 'lib/store/hooks';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const ProToolsActivationStepper = (): JSX.Element => {
  const router = useRouter();
  const { id } = router.query;

  const dispatch = useAppDispatch();
  const { data: session, status } = useSession();

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    const bearerToken = getBearerToken(session);
    dispatch(getOrderDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    dispatch(getCartSummary({ bearerToken: bearerToken }));
  }, [dispatch, id, session, status]);

  return (
    <div>
      <div className="my-8 border-t border-base-dark"></div>
    </div>
  );
};

export default withDatasourceCheck()(ProToolsActivationStepper);
