import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

export type IntroBlockContactSidebarSectionProps = ComponentProps & {
  fields: {
    value: Field<string>;
  };
};

export type IntroBlockContactSidebarProps = ComponentProps & {
  fields: {
    sidebarSectionTitle: Field<string>;
    sidebarContacts: IntroBlockContactSidebarSectionProps[];
  };
};
const IntroBlockContactSidebarSection = (props: IntroBlockContactSidebarProps): JSX.Element => {
  const {
    fields: { sidebarContacts },
  } = props;
  return (
    <section className="py-6 border-b border-neutral-light  last:border-none last:pb-0">
      <Text
        tag="div"
        className="font-bold tracking-average uppercase text-almost-black text-lg"
        field={props?.fields?.sidebarSectionTitle}
      />

      <ul>
        {sidebarContacts &&
          sidebarContacts.map((sidebarContact, index) => {
            return (
              <li key={index} className="mt-2">
                <FontAwesomeIcon icon={faEnvelope} className="text-base-medium-dark mr-2" />
                <label className="text-base text-primary underline">
                  {sidebarContact.fields?.value?.value}
                </label>
              </li>
            );
          })}
      </ul>
    </section>
  );
};

export default withDatasourceCheck()<IntroBlockContactSidebarProps>(
  IntroBlockContactSidebarSection
);
