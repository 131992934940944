import { pushVideoEvent } from 'lib/google-analytics/brightcove-analytics';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import YouTube from 'react-youtube';

type YoutubeEmbededVideoProps = {
  videoId: string;
  playlistId?: string;
  autoplay?: boolean;
  loop?: boolean;
  hideControls?: boolean;
};

const YoutubeEmbededVideo = (props: YoutubeEmbededVideoProps): JSX.Element => {
  const [ref, videoOnScreen] = useInView({
    threshold: 0.75,
  });

  let videoProgress;
  let ev25 = false;
  let ev50 = false;
  let ev75 = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mediaRef = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPlayerStateChangeHandler = (player: any): void => {
    initAnalytics(player);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initAnalytics = (player: any): void => {
    const playerState = player.data;
    const playerData = player.target;

    switch (playerState) {
      case 0:
        sendVideoEvent(playerData, 'video_complete');
        break;
      case 1:
        sendVideoEvent(playerData, 'video_start');
        break;
    }

    const getVideoProgress = (): void => {
      const videoPercent = getVideoPercent(playerData);
      switch (true) {
        case !ev25 && videoPercent >= 25:
          sendVideoEvent(playerData, 'video_progress');
          ev25 = true;
          break;
        case !ev50 && videoPercent >= 50:
          sendVideoEvent(playerData, 'video_progress');
          ev50 = true;
          break;
        case !ev75 && videoPercent >= 75:
          sendVideoEvent(playerData, 'video_progress');
          ev75 = true;
          break;
      }
    };

    if (playerState === 1) {
      videoProgress = setInterval(getVideoProgress, 100);
    } else {
      clearInterval(videoProgress);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendVideoEvent = (player: any, eventName: string): void => {
    const videoPercent = getVideoPercent(player);

    pushVideoEvent(
      eventName,
      player.playerInfo.videoData.title,
      'youtube',
      player.getVideoUrl(),
      props.autoplay ? 'auto' : 'click',
      player.getDuration(),
      videoPercent,
      player.getCurrentTime(),
      player.getCurrentTime()
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getVideoPercent = (player: any): number => {
    if (player != null) {
      return Math.floor((player.getCurrentTime() / player.getDuration()) * 100);
    }
    return 0;
  };

  useEffect(() => {
    const playVideoOnScreen = async (): Promise<void> => {
      const internalPlayer = await mediaRef.current.internalPlayer;

      if ((internalPlayer.getPlayerState() === 0 && !props.loop) || !props.autoplay) {
        return;
      }
      if (internalPlayer) {
        videoOnScreen ? internalPlayer.playVideo() : internalPlayer.pauseVideo();
      }
    };

    playVideoOnScreen();
  }, [videoOnScreen, props.loop, props.autoplay]);

  const opts = {
    playerVars: {
      controls: !props.hideControls,
      loop: props.loop,
      playlist: props.loop && !props.playlistId ? props.videoId : props.playlistId,
      rel: 0,
      autoplay: props.autoplay,
    },
  };

  return (
    <div ref={ref}>
      <YouTube
        ref={mediaRef}
        opts={opts}
        videoId={props.videoId}
        iframeClassName="aspect-video w-full h-full"
        onStateChange={onPlayerStateChangeHandler}
        loading="lazy"
      />
    </div>
  );
};

export default YoutubeEmbededVideo;
