import { RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React from 'react';

type ParagraphProps = ComponentProps & {
  fields: {
    paragraph: RichTextField;
  };
  params: {
    styles: string;
    margintop: string;
    marginbottom: string;
  };
};

const GetClassFromStyles = (style: string): string => {
  switch (style.toLowerCase()) {
    case 'body':
      return 'body';
    case 'extra small text':
      return 'small-text';
    case 'intro text':
      return 'intro-text';
    default:
      return 'body';
  }
};

const GetClassFromMargin = (Margin: string): string => {
  if (Margin == null) {
    return '';
  }

  switch (Margin.toLowerCase()) {
    case '4px':
      return '1';
    case '8px':
      return '2';
    case '16px':
      return '4';
    case '24px':
      return '6';
    case '32px':
      return '8';
    case '40px':
      return '10';
    case '48px':
      return '12';
    case '64px':
      return '16';
    case '80px':
      return '20';
    default:
      return '';
  }
};

const Paragraph = (props: ParagraphProps): JSX.Element => {
  const topmargin = props?.params?.margintop
    ? `mt-${GetClassFromMargin(props.params.margintop)}`
    : '';
  const bottommargin = props?.params?.marginbottom
    ? `mb-${GetClassFromMargin(props.params.marginbottom)}`
    : 'mb-10';
  const styleClass = props?.params?.styles ? GetClassFromStyles(props.params.styles) : 'body';
  return (
    <RichText
      tag="p"
      field={props?.fields?.paragraph}
      className={`rte max-w-content paragraph text-almost-black mx-auto w-full [&>strong]:font-bold [&>*>strong]:font-bold ${topmargin} ${bottommargin} ${styleClass}`}
    ></RichText>
  );
};

export default Paragraph;
