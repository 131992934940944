import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useEffect, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
const AlertBox = dynamic(() => import('components/alert/AlertBox'));

const GetClassFromAlertType = (alertType: string): string => {
  if (alertType == null) {
    return 'bg-info-light border-info text-info [&>svg]:text-almost-black';
  }

  switch (alertType.toLowerCase()) {
    case 'info':
      return 'bg-info-light border-info text-info [&>div>.dismisable-icon]:text-almost-black';
    case 'warning':
      return 'bg-warning-light border-warning text-warning [&>div>.dismisable-icon]:text-almost-black';
    case 'danger':
      return 'bg-danger-light border-danger text-danger [&>div>.dismisable-icon]:text-almost-black';
    case 'success':
      return 'bg-success-light border-success text-success [&>div>.dismisable-icon]:text-almost-black';
    case 'promotional':
      return 'bg-secondary border-secondary text-white [&>svg]:text-white [&>span>p]:body-bold';
    default:
      return 'bg-info-light border-info text-info [&>div>.dismisable-icon]:text-almost-black';
  }
};

type SitewideAlertBannerProps = ComponentProps & {
  fields: {
    alertMessage: Field<string>;
    link: LinkField;
    displayIcon: Field<boolean>;
    isDismissible: Field<boolean>;
    noFollow: Field<boolean>;
  };
  params: {
    alertType: string;
  };
};

const SitewideAlertBanner = (props: SitewideAlertBannerProps): JSX.Element => {
  const alertTypeClass = GetClassFromAlertType(props.params?.alertType);
  const [showAlertText, setShowAlertText] = useState(true);
  const [scrollDir, setScrollDir] = useState<'scrolling up' | 'scrolling down'>('scrolling up');
  const ref = useRef<HTMLDivElement | null>(null);

  const onClickDismissAlertHandler = (): void => {
    setShowAlertText(false);
  };

  const animateSlideUp = (element: Element, slideHeight: number): void => {
    element.animate(
      [{ transform: 'translateY(0px)' }, { transform: `translateY(-${slideHeight}px)` }],
      {
        duration: 150,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    );
  };

  const animateSlideDown = (element: Element, slideHeight: number): void => {
    element.animate(
      [{ transform: `translateY(-${slideHeight}px)` }, { transform: 'translateY(0px)' }],
      {
        duration: 150,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    );
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    const stickyheader = document.querySelector('.sticky');
    const banner = ref.current;

    if (stickyheader && banner && showAlertText) {
      scrollDir === 'scrolling down'
        ? animateSlideUp(stickyheader, banner.scrollHeight)
        : animateSlideDown(stickyheader, banner.scrollHeight);
    }

    if (stickyheader && banner && !showAlertText) {
      animateSlideUp(stickyheader, banner.scrollHeight);
      banner.style.visibility = 'hidden';
      return;
    }

    const updateScrollDir = (): void => {
      const scrollY = window.scrollY;
      setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = (): void => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return (): void => window.removeEventListener('scroll', onScroll);
  }, [scrollDir, showAlertText]);

  return (
    <>
      <div ref={ref} className="sitewide-banner relative bottom-full flex w-full transition-all">
        <AlertBox
          containerClass={`${alertTypeClass} py-3 px-4 lg:px-8 flex justify-center items-center space-x-2 w-full`}
          alertMessage={props.fields.alertMessage}
          showAlertBox={true}
          isDismissible={props.fields.isDismissible.value}
          dismissibleIconContainerClass="flex-grow text-right"
          iconContainerClass="xl:flex-grow text-right"
          displayIcon={props.fields.displayIcon.value}
          alertType={props.params.alertType}
          link={props.fields.link}
          onClickDismissAlertHandler={onClickDismissAlertHandler}
          noFollow={props.fields?.noFollow}
        />
      </div>
    </>
  );
};

export default withDatasourceCheck()<SitewideAlertBannerProps>(SitewideAlertBanner);
