import { isEditorActive, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { ImageCardProps } from '../../components/image-card/image-cards-props';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImageCard = (props: ImageCardProps): JSX.Element => {
  const editorActive = isEditorActive();
  return (
    <div className="image-card mt-6 relative hover:drop-shadow-[0_4px_16px_rgba(34,34,34,34.16)]">
      <div className="grid">
        <NextImage field={props?.image?.jsonValue} className="rounded" />
      </div>
      <div className="z-10 w-full h-full bottom-0 absolute flex flex-col justify-end">
        <div className="h-full bg-gradient-to-t from-neutral-darkest to-transparent" />
        <div className="bg-neutral-darkest pb-[40px] px-[40px] rounded-b">
          <Text
            tag="h4"
            field={props?.title?.jsonValue}
            className="text-left font-bold text-white text-2xl leading-[1.8rem] lg:text-[1.75rem] lg:leading-[2.1rem] mb-2"
          />
          <RichText
            tag="div"
            field={props?.description?.jsonValue}
            className="rte text-left text-[1rem] mb-6 text-white"
          />

          <div className="flex justify-start items-baseline">
            {props?.ctaPrimaryButton && props?.ctaPrimaryButton.jsonValue && (
              <Link
                field={props.ctaPrimaryButton.jsonValue}
                linkCustomizations={{ noFollow: props.noFollow }}
                className="btn-primary-dark"
              />
            )}

            {((props?.ctaSecondaryButton && props?.ctaSecondaryButton.jsonValue?.value?.href) ||
              editorActive) && (
              <Link
                field={props.ctaSecondaryButton.jsonValue}
                linkCustomizations={{ noFollow: props.secondaryNoFollow }}
                className="btn-link-dark ml-6"
                hideText
              >
                <span>{props.ctaSecondaryButton.jsonValue?.value?.text}</span>
                <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
