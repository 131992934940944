import { RichText, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { faCheck, faCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichTextField, TextField, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

export type BulletdListItem = {
  heading: {
    value: string;
    jsonValue: TextField;
  };
  text: {
    value: string;
    jsonValue: RichTextField;
  };
};

export type BulletdListColumn = {
  children: { results: BulletdListItem[] };
};

export type BulletedListProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        heading: {
          value: string;
          jsonValue: TextField;
        };
        children: { results: BulletdListColumn[] };
      };
    };
  };
  params: {
    ColumnType: string;
    BulletType: string;
  };
};

const getBulletType = (bulletType: string): IconDefinition => {
  switch (bulletType?.trim().toLowerCase()) {
    case 'default check':
      return faCheck;
    case 'circle':
      return faCircle;

    default:
      return faCheck;
  }
};

const getBulletClass = (bulletType: string): string => {
  switch (bulletType?.trim().toLowerCase()) {
    case 'default check':
      return 'bg-white rounded-2xl w-5 py-2';
    case 'circle':
      return 'w-10 py-1 text-lg';

    default:
      return 'bg-white rounded-2xl  w-5 py-2';
  }
};

const BulletedList = (props: BulletedListProps): JSX.Element => {
  const bulletType = props?.params?.BulletType;
  const editorActive = isEditorActive();
  const columns = props.fields?.data?.datasource?.children?.results;
  return (columns && columns.length > 0 && columns[0].children?.results.length > 0) ||
    editorActive ? (
    <div className="text-almost-black text-center px-6 2xl:px-0 max-w-content mx-auto bg-white my-5 xl:my-10">
      <div className="rounded-lg bg-base-lighter py-6 xl:py-10">
        <Text
          field={props.fields?.data?.datasource?.heading?.jsonValue}
          tag="h2"
          className="tracking-[0.28em] uppercase px-4 lg:px-10 mb-6 lg:mb-10"
        />
        <div
          className={`grid xl:gap-x-8 ${
            props.params.ColumnType == 'Single Column' ? 'xl:grid-cols-1' : 'xl:grid-cols-2'
          }`}
        >
          {columns &&
            columns.map((column, index) => (
              <ul
                key={index}
                className={`text-left px-4 xl:px-10 ${index < 2 ? 'mt-1 xl:mt-5' : ''}`}
                role="list"
              >
                {column.children &&
                  column.children?.results &&
                  column.children.results.map((listItem, listIndex) => (
                    <li key={listIndex} className="flex items-start mb-6" role="listitem">
                      <span
                        className={`text-avidpurple px-4 flex items-center justify-center mr-4 ${getBulletClass(
                          bulletType
                        )}`}
                      >
                        <FontAwesomeIcon icon={getBulletType(bulletType)} className="" />
                      </span>

                      <div>
                        <Text
                          field={listItem.heading?.jsonValue}
                          tag="h4"
                          className="text-[1rem] mb-1"
                        />
                        <RichText field={listItem.text?.jsonValue} tag="div" className="rte " />
                      </div>
                    </li>
                  ))}
              </ul>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<BulletedListProps>(BulletedList);
