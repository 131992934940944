import { ProductCategoryListingCategoryFields } from './product-category-listing-models';
import {
  ProductCategoryListingProductResponse,
  ProductResult,
} from './product-category-listing-product-results';
import getProductCategoryListingProductQuery from './product-category-listing-product-query';
import { GraphQLClient } from 'lib/common/graphql-client';

export const SearchProductsInCategoryIds = async (
  categoryIds: string[],
  searchRoot: string
): Promise<ProductResult[]> => {
  const finalResults: ProductResult[] = [];
  const graphQLClient = GraphQLClient();
  for (let i = 0; i < categoryIds.length; i++) {
    const category = categoryIds[i];

    let hasNext = true;
    let after = '';

    while (hasNext) {
      const productResults = await graphQLClient.request<ProductCategoryListingProductResponse>(
        getProductCategoryListingProductQuery,
        {
          category: category,
          searchRoot: searchRoot,
          templateId: '{FB34F438-7127-4086-8A72-E4DCEFA89A11}',
          after: after,
        }
      );

      finalResults.push(...productResults.search.results);

      hasNext = productResults.search?.pageInfo.hasNext;
      after = productResults.search?.pageInfo.endCursor;
    }
  }
  return finalResults;
};

export const SearchProductsInCategories = async (
  categories: ProductCategoryListingCategoryFields[],
  searchRoot: string
): Promise<ProductResult[]> => {
  const finalResults: ProductResult[] = [];
  const graphQLClient = GraphQLClient();

  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];

    let hasNext = true;
    let after = '';

    while (hasNext) {
      const productResults = await graphQLClient.request<ProductCategoryListingProductResponse>(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getProductCategoryListingProductQuery,
        {
          category: category.id,
          searchRoot: searchRoot,
          templateId: '{FB34F438-7127-4086-8A72-E4DCEFA89A11}',
          after: after,
        }
      );

      finalResults.push(...productResults.search.results);

      hasNext = productResults.search?.pageInfo.hasNext;
      after = productResults.search?.pageInfo.endCursor;
    }
  }
  return finalResults;
};
