import JSXColumn from './JSXColumn';
import { RowProps } from './jsxtablemodels';

const JSXRow = (row: RowProps): JSX.Element => {
  return (
    <tr
      className={`first:font-bold last:border-b first:border-b-2 text-almost-black border-t border-neutral-lighter ${
        row.Style === 'Bordered' && '[&>td]:border [&>td]:border-neutral-lighter'
      } ${row.Style === 'Stripped' && 'even:bg-base-lighter'}`}
    >
      {row?.columnAttributes?.results.map((column, index) => (
        <JSXColumn {...column} key={index} columnCount={row.columnCount} />
      ))}
    </tr>
  );
};

export default JSXRow;
