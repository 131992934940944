import { GraphQLClient } from 'lib/common/graphql-client';
import React from 'react';
import { LanguagesData } from '../components/href-lang/language-query-models';
import getLanguageQuery from '../components/href-lang/language-query';
import Head from 'next/head';
import {
  Field,
  GetStaticComponentProps,
  LayoutServiceData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

type HrefLangProps = {
  languageVersions: LanguagesData;
  layoutData: LayoutServiceData;
};

export const getLanguageVersions = async (pathId: string): Promise<LanguagesData> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<LanguagesData>(getLanguageQuery, {
    pathId: pathId,
  });
};

const HrefLang = (props: HrefLangProps): JSX.Element => {
  const isEditing = isEditorActive();
  if (isEditing) {
    return <></>;
  }

  const canonicalOverrideField = props.layoutData?.sitecore?.route?.fields
    ?.canonicalOverride as Field<string>;

  const getHrefLangValues = (language: string): JSX.Element => {
    const urlPath = canonicalOverrideField?.value
      ? canonicalOverrideField.value
      : props.layoutData.sitecore.context.itemPath;
    if (language === 'en') {
      return (
        <>
          <link
            key={`${language}_canonical`}
            rel="canonical"
            href={`${process.env.PUBLIC_URL}${urlPath}`}
          />
          <link
            key={`${language}_alternate-x`}
            rel="alternate"
            hrefLang="x-default"
            href={`${process.env.PUBLIC_URL}${urlPath}`}
          />
          <link
            key={`${language}_alternate`}
            rel="alternate"
            hrefLang="en"
            href={`${process.env.PUBLIC_URL}${urlPath}`}
          />
        </>
      );
    } else {
      return (
        <link
          key={language}
          rel="alternate"
          hrefLang={language}
          href={`${process.env.PUBLIC_URL}/${language}${urlPath}`}
        />
      );
    }
  };

  if (props.languageVersions?.results?.languages == null) {
    return <></>;
  }

  return (
    <Head>
      {props.languageVersions.results.languages.map((result) =>
        getHrefLangValues(result.language.name)
      )}
    </Head>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const languageVersions = await getLanguageVersions(layoutData.sitecore.route?.itemId as string);

  return {
    languageVersions,
    layoutData,
    rendering,
  };
};

export default HrefLang;
