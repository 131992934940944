import {
  Field,
  LinkField,
  isEditorActive,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { BaseCarouselProps } from 'components/carousel/BaseCarouselProps';
import Carousel from 'components/carousel/Carousel';
import { ComponentProps } from 'lib/component-props';
import StoryBannerCarouselButtonGroup from '../../components/banner/StoryBannerCarouselButtonGroup';

type StoryBannerCarouselProps = ComponentProps &
  BaseCarouselProps & {
    fields: {
      ctaButton: LinkField;
      noFollow?: Field<boolean>;
    };
    name: string;
    storyBannerCarouselItems: JSX.Element;
  };

const StoryBannerCarousel = (props: StoryBannerCarouselProps): JSX.Element => {
  const editorActive = isEditorActive();
  return (
    <div className="relative px-6  lg:px-8 mb-10 lg:mb-20 2xl:px-24 box-content mx-auto max-w-content">
      <div className={editorActive ? 'flex flex-wrap [&>.carousel-item]:w-1/3' : 'hidden'}>
        {props.storyBannerCarouselItems}
      </div>
      {!editorActive && props.params && (
        <Carousel
          MaxItemsPerSlideOnDesktop={props.params.MaxItemsPerSlideOnDesktop}
          MaxItemsPerSlideOnTablet={props.params.MaxItemsPerSlideOnTablet}
          MaxItemsPerSlideOnMobile={props.params.MaxItemsPerSlideOnMobile}
          desktopSize={1400}
          tabletSize={960}
          buttonGroup={<StoryBannerCarouselButtonGroup />}
        >
          {props.storyBannerCarouselItems}
        </Carousel>
      )}
      <div className={`text-center ${props.fields?.ctaButton ? 'mt-12' : ''} flex justify-center`}>
        <NextLink
          className="btn-outline"
          field={props.fields?.ctaButton}
          linkCustomizations={props.fields}
        />
      </div>
    </div>
  );
};

export default withPlaceholder({
  placeholder: 'story-banner-carousel-items',
  prop: 'storyBannerCarouselItems',
})(StoryBannerCarousel);
