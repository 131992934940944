import { AtomicResultText } from '@coveo/atomic-react';

type ClickableLinkProps = React.ComponentProps<typeof AtomicResultText> & {
  href: string;
};

const ClickableLink: React.FC<ClickableLinkProps> = (props) => {
  return (
    <a href={props.href} className="body-link">
      <AtomicResultText {...props}>{props.children}</AtomicResultText>
    </a>
  );
};

export default ClickableLink;
