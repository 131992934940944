import {
  IconDefinition,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import React from 'react';

type AlertBoxProps = {
  alertMessage: string | Field<string>;
  isDismissible: boolean;
  showAlertBox: boolean;
  displayIcon: boolean;
  alertType: string;
  link?: LinkField;
  containerClass?: string;
  iconClass?: string;
  iconContainerClass?: string;
  dismissibleIconClass?: string;
  dismissibleIconContainerClass?: string;
  onClickDismissAlertHandler?: () => void;
  noFollow?: Field<boolean>;
};

const getIconFromAlertType = (alertType: string): IconDefinition => {
  switch (alertType.toLowerCase()) {
    case 'info':
      return faInfoCircle;
    case 'warning':
      return faExclamationTriangle;
    case 'danger':
      return faExclamationCircle;
    case 'success':
      return faCheckCircle;
    case 'promotional':
      return faInfoCircle;
    default:
      return faInfoCircle;
  }
};

const AlertBox = (props: AlertBoxProps): JSX.Element => {
  const {
    alertMessage,
    isDismissible,
    showAlertBox,
    containerClass,
    iconClass,
    iconContainerClass,
    dismissibleIconClass,
    dismissibleIconContainerClass,
    displayIcon,
    alertType,
    link,
    onClickDismissAlertHandler,
  } = props;
  const editorActive = isEditorActive();

  return (
    <>
      {showAlertBox && (
        <div className={`${containerClass}`}>
          {displayIcon && (
            <div
              className={`w-6 h-6 text-center align-middle flex-shrink-0 text-lg leading-6 ${iconContainerClass}`}
            >
              <FontAwesomeIcon icon={getIconFromAlertType(alertType)} className={`${iconClass}`} />
            </div>
          )}

          <span>
            {typeof alertMessage === 'object' && (
              <RichText tag="p" field={alertMessage} className="rte body inline" />
            )}

            {typeof alertMessage === 'string' && <p>{alertMessage}</p>}

            {((link && link.value?.href) || editorActive) && (
              <Link field={link} linkCustomizations={props} className="inline underline" />
            )}
          </span>

          {isDismissible && (
            <div
              className={`w-6 h-6 text-center align-middle flex-shrink-0 leading-6 ${dismissibleIconContainerClass}`}
            >
              <FontAwesomeIcon
                icon={faTimes}
                className={`hover:cursor-pointer dismisable-icon text-lg ${dismissibleIconClass}`}
                onClick={onClickDismissAlertHandler}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AlertBox;
