import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroupProps } from 'react-multi-carousel';

function CarouselButtonGroupNoInfinate(props: ButtonGroupProps): JSX.Element {
  const currentSlide = props.carouselState?.currentSlide ?? 0;
  const slidesToShow = props.carouselState?.slidesToShow ?? 0;
  const totalItems = props.carouselState?.totalItems ?? 0;
  const needsNavigation = totalItems > slidesToShow;
  const atEnd = currentSlide === totalItems - slidesToShow;

  return (
    <div className={`w-full absolute ${needsNavigation ? 'top-[40%]' : 'top-1/2'}`}>
      {currentSlide != 0 && (
        <button
          aria-label="Go to previous slide"
          className="w-11 h-11 border-2 border-base-dark rounded-full absolute -left-1 sm:left-20 xl:-left-2 2xl:-left-11 bg-white flex justify-center items-center hover:bg-primary-lighter hover:border-primary-lighter focus:bg-primary-lighter focus:border-primary active:bg-primary-light"
          onClick={(): void => {
            if (props.previous != null) {
              props?.previous();
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      )}
      {needsNavigation && !atEnd && (
        <button
          aria-label="Go to next slide"
          className="z-10 w-11 h-11 border-2 border-base-dark rounded-full absolute -right-1 sm:right-20 xl:-right-2 2xl:-right-11 bg-white flex justify-center items-center hover:bg-primary-lighter hover:border-primary-lighter focus:bg-primary-lighter focus:border-primary active:bg-primary-light"
          onClick={(): void => {
            if (props.next != null) {
              props?.next();
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
    </div>
  );
}

export default CarouselButtonGroupNoInfinate;
