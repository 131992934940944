import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

function ProductListItemSearchContainer(props: ComponentProps): JSX.Element {
  return (
    <div className="max-w-content mx-auto px-8 py-20">
      <Placeholder name="product-list-item-search" rendering={props.rendering} />
    </div>
  );
}

export default ProductListItemSearchContainer;
