import { useI18n } from 'next-localization';
import MPPTActivationStepper from './MPPTActivationStepper';
import { ProToolsActivationStepperItem } from 'components/order/shop/pro-tools-activation-steps';

type ProToolsOrderDetailsProps = {
  isAccountIncomplete: boolean;
  orderId: string | string[] | undefined;
  userId: string | undefined;
  proToolsActivationStepper: ProToolsActivationStepperItem;
  setShowSpinnerCallback: (state: boolean) => void;
};

const MPProToolsOrderDetails = (props: ProToolsOrderDetailsProps): JSX.Element => {
  const { t } = useI18n();
  const steps = props.isAccountIncomplete
    ? props.proToolsActivationStepper.fields.stepsForIncompleteAccount
    : props.proToolsActivationStepper.fields.stepsForCompleteAccount;

  return (
    <>
      <div className="my-8 border-t border-mp-txt-neutral"></div>
      <div>
        <h3>{t('Order_ProToolsActivationStep_NextSteps')}</h3>
        <MPPTActivationStepper
          steps={steps}
          userId={props.userId}
          orderId={props.orderId}
          setShowSpinnerCallback={props.setShowSpinnerCallback}
        />
      </div>
      <div className="mt-8">{t('Order_ProToolsActivationStep_InYourAvidAccount')}</div>
    </>
  );
};

export default MPProToolsOrderDetails;
