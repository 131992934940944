import { GraphQLRequestClient } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import config from 'temp/config';
import { VideoDetailsResponse } from '../components/video-standalone/video-details-response';
import InlineSpinner from 'components/spinner/InlineSpinner';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import getVideoQuery from '../components/video-standalone/video-query';
import YoutubeEmbededVideo from 'components/video/youtube/YoutubeEmbededVideo';
import { youtubeVideoTemplateId } from 'lib/constants';

const BrightcoveEmbededVideo = dynamic(
  () => import('components/video/brightcove/BrightcoveEmbededVideo'),
  {
    ssr: false,
  }
);

const VideoStandAlone = (): JSX.Element => {
  const router = useRouter();
  const videoId = router.query.videoId as string;
  const [video, setVideo] = useState<VideoDetailsResponse | null>();

  useEffect(() => {
    if (!videoId) {
      return;
    }

    const fetchVideoData = async (): Promise<void> => {
      const graphQLClient = new GraphQLRequestClient(
        process.env.NEXT_PUBLIC_GRAPH_QL_ENDPOINT ?? '',
        {
          apiKey: config.sitecoreApiKey,
        }
      );

      const result = await graphQLClient.request<VideoDetailsResponse>(getVideoQuery, {
        path: videoId,
        language: 'en',
      });

      setVideo(result);
    };

    fetchVideoData();
  }, [videoId]);

  useEffect(() => {
    console.log(video);
  }, [video]);

  console.log('ran');

  if (!videoId) {
    return <div className="p-5 text-center">No video ID provided.</div>;
  }

  if (!video) {
    return <InlineSpinner />;
  }

  if (video && !video.item) {
    const simplifiedID = videoId.replace('{', '').replace('}', '');

    return (
      <div className="p-5 text-center">
        <p>
          Sorry, we were unable to locate a video with an ID of <strong>{simplifiedID}</strong>.
        </p>

        <p>Please double check the URL and try again.</p>
      </div>
    );
  }

  // set the title we'll be using
  const title = video.item?.videoTitle.value as string;

  const isYoutubeVideo = video?.item?.template?.id == youtubeVideoTemplateId;

  // set the brightcove video id
  const sourceId = isYoutubeVideo
    ? video?.item?.sourceId?.value
    : (video.item?.videoReference.targetItems[0]?.brightVideoId.value as string);

  // set the description we'll be using; full if available, short if not
  const description = video.item?.fullDescription.value
    ? (video.item?.fullDescription.value as string)
    : (video.item?.shortDescription.value as string);

  return (
    <>
      <Head>
        <meta name="title" content={title} />
        <meta name="description" content={video.item?.shortDescription.value} />
      </Head>
      <div className="video-stand-alone mt-10 mb-8">
        <div className="max-w-content mx-auto px-5">
          <header>
            <h1>{title}</h1>
          </header>
          {isYoutubeVideo ? (
            <YoutubeEmbededVideo
              videoId={sourceId as string}
              playlistId=""
              autoplay={false}
              loop={true}
              hideControls={false}
            />
          ) : (
            <BrightcoveEmbededVideo videoId={sourceId as string} />
          )}

          <footer>
            <p>{description}</p>
          </footer>
        </div>
      </div>
    </>
  );
};

export default VideoStandAlone;
