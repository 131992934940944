import gql from 'graphql-tag';

const getRequirementsAndSpecificationsTableAttributesQuery = gql`
  query ProductOverviewRequirementsAndSpecificationsAttributesQuery(
    $datasource: String!
    $language: String!
    $after: String!
  ) {
    datasource: item(path: $datasource, language: $language) {
      attributeDetails: children(first: 25, after: $after) {
        pageInfo {
          hasNext
          endCursor
        }
        results {
          id
          ... on _Attribute {
            title {
              jsonValue
            }
          }
        }
      }
    }
  }
`;
export default getRequirementsAndSpecificationsTableAttributesQuery;
