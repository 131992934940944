import gql from 'graphql-tag';

const getTabbedContainerDatasourceQuery = gql`
  query TabbedContainerDatasourceQuery($datasource: String!, $language: String!) {
    # Datasource query
    # $datasource will always be set to the ID of the rendering's datasource item
    # (as long as the GraphQLData helper is used)
    datasource: item(path: $datasource, language: $language) {
      id
      name
      children(hasLayout: false) {
        results {
          ... on Tab {
            id
            name
            heading {
              value
              jsonValue
            }
          }
        }
      }
    }
  }
`;

export default getTabbedContainerDatasourceQuery;
