import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Teaser, { TeaserProps } from './Teaser';
import { ComponentProps } from 'lib/component-props';

type TeasersProps = ComponentProps & {
  fields: {
    selectedTeasers: Array<TeaserProps>;
  };
};

const Teasers = (props: TeasersProps): JSX.Element => {
  const numOfTeasers = props.fields?.selectedTeasers?.length;
  let columns: string;
  switch (numOfTeasers) {
    case 2:
      columns = 'xl:grid-cols-2';
      break;
    case 3:
      columns = 'xl:grid-cols-3';
      break;
    default:
      columns = 'xl:grid-cols-4';
  }

  return (
    <ul
      className={`teasers grid grid-cols-1 gap-x-grid px-5 md:grid-cols-2 ${columns} py-16 max-w-content mx-auto`}
    >
      {props.fields &&
        props.fields.selectedTeasers &&
        props.fields.selectedTeasers.length > 0 &&
        props.fields.selectedTeasers.map((teaser) => {
          return <Teaser key={teaser.id} {...teaser} columns={numOfTeasers} />;
        })}
    </ul>
  );
};

export default withDatasourceCheck()<TeasersProps>(Teasers);
