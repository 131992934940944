import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { default as NextLink } from 'components/foundation/non-sitecore/NextLink';
import { useI18n } from 'next-localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useRouter } from 'next/router';
import {
  setCheckoutStage,
  placeOrder,
  InitialStateModel as InitialStateModelCheckout,
  removeCheckoutSession,
} from 'components/checkout/checkout-slice';
import {
  changeCurrency,
  InitialStateModel as InitialStateModelCart,
} from 'components/cart/cart-slice';
import { GetCartItemsData } from 'components/cart/cart-models';
import Spinner from 'components/spinner/Spinner';
import InlineSpinner from 'components/spinner/InlineSpinner';
import MarketplaceCartItem from '../../cart/marketplace/MarketplaceCartItem';
import MarketplaceCheckbox from 'components/checkbox/marketplace/MarketplaceCheckbox';
import AlertBox from 'components/alert/AlertBox';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { isFeatureEnabled } from 'lib/feature-flags/feature-flags';
import useDigitalRiverInstance from '../dr-elements-hook';
import { getCartId } from 'components/cart/cart-provider';
import { SfResponse } from 'lib/common/model/common-models';
import { PlaceOrderData } from '../checkout-models';

export type MPCheckoutSummaryProps = {
  cart: InitialStateModelCart;
  checkout: InitialStateModelCheckout;
  cartItemData: GetCartItemsData | null;
  continueShoppingLink: LinkField;
  eula: Field<string>;
  thirdPartyTerms: Field<string>;
  link: LinkField;
  orderProcessingLink: LinkField;
};

const MarketplaceCheckoutSummary = (props: MPCheckoutSummaryProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { cart, cartItemData, checkout } = props;
  const { checkoutData, checkoutDataStatus, selectedSource, checkoutId, billingAddress } = checkout;
  const userData = selectedSource?.source.owner;
  const cartItems = cartItemData?.cartItems;
  const cartSummary = cartItemData?.cartSummary;
  const currencyIsoCode = cart?.currencyIsoCode;
  const countries = useAppSelector((state) => state.countries.countriesData);
  const router = useRouter();
  const drInstance = useDigitalRiverInstance();
  const thirdPartyTermsList = cartItems
    ?.filter((item) => {
      if (
        !item.cartItem.productDetails.fields.VendorName__c ||
        !item.cartItem.productDetails.fields.ProductTerms__c
      ) {
        return false;
      }
      return item.cartItem.productDetails.fields.VendorName__c.toLowerCase() !== 'avid';
    })
    .map((item) => {
      return {
        label: `3rd-party EULA - ${item.cartItem.productDetails.fields.VendorName__c}: ${item.cartItem.productDetails.name}`,
        link: item.cartItem.productDetails.fields.ProductTerms__c,
      };
    });
  const includesThirdPartyProducts = !!thirdPartyTermsList?.length;

  const [acceptedEula, setAcceptedEula] = useState(false);
  const [acceptedThirdParty, setAcceptedThirdParty] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(t('checkout_error'));
  const [showOrderSpinner, setShowOrderSpinner] = useState<boolean>(false);
  const isProceedDisabled =
    checkoutDataStatus != 'succeeded' ||
    !acceptedTerms ||
    selectedSource == null ||
    checkoutData?.digitalRiverResponse?.items == null ||
    checkoutData.digitalRiverResponse.items.length == 0;

  const dataExists = cart.cartItemsDataStatus === 'succeeded';
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const customerBillingCountry = billingAddress?.country;

  const editPaymentMethods = (): void => {
    dispatch(setCheckoutStage('payment-method'));
  };

  const onChangeEulaHandler = (isChecked: boolean): void => {
    setAcceptedEula(isChecked);
    const acceptedAll = includesThirdPartyProducts ? isChecked && acceptedThirdParty : isChecked;
    setAcceptedTerms(acceptedAll);
  };

  const onChangeThirdPartyHandler = (isChecked: boolean): void => {
    setAcceptedThirdParty(isChecked);
    setAcceptedTerms(acceptedEula && isChecked);
  };

  const sendPlaceOrderRequest = async (): Promise<SfResponse<PlaceOrderData> | null> => {
    return await dispatch(
      placeOrder({
        checkoutId: checkout?.checkoutId ?? '',
        sourceId: selectedSource?.source.id ?? '',
        cartId: getCartId(),
        bearerToken: bearerToken,
      })
    )
      .unwrap()
      .then((resp) => resp)
      .catch(() => {
        setOrderError(true);
        return null;
      });
  };
  const handleDRSCARequest = async (
    placeOrderResp: SfResponse<PlaceOrderData> | null
  ): Promise<SfResponse<PlaceOrderData> | null> => {
    const DRpaymentActionRequired = placeOrderResp?.data?.digitalRiverResponse?.errors
      ?.map((error) => error.code)
      .some((code) => code === 'additional_payment_action_required');
    if (!placeOrderResp || placeOrderResp?.success || !DRpaymentActionRequired) {
      return placeOrderResp;
    }

    const drAuthResponse = await drInstance?.handleNextAction({
      action: 'sca_required',
      data: {
        sessionId: checkout.paymentSessionId,
      },
    });

    if (!drAuthResponse?.status || drAuthResponse?.status !== 'complete') {
      setOrderError(true);
      setAlertMessage(t('checkout_payment_sca_error'));
      return null;
    }
    return await sendPlaceOrderRequest();
  };

  const onClickPlaceOrderHandler = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    const isDRSourceAutenticationEnabled = isFeatureEnabled('DR-source-autentication-SITECORE-33');

    if (selectedSource == null || checkoutId == null) {
      return;
    }
    setShowOrderSpinner(true);
    orderError && setOrderError(false);

    const placeOrderResp = await sendPlaceOrderRequest();
    const response = isDRSourceAutenticationEnabled
      ? await handleDRSCARequest(placeOrderResp)
      : placeOrderResp;

    if (response == null || !response.data) {
      setOrderError(true);
      return;
    }

    if (!response.success && response.data.uimessage) {
      setOrderError(true);
      setAlertMessage(response.data.uimessage);
      return;
    }

    if (response.data.digitalRiverResponse.fraudState != 'passed') {
      router.push(`${props.orderProcessingLink.value.href}?id=${response.data.orderId}`);
      setShowOrderSpinner(false);
      return;
    }

    router.push(`${props.link.value.href}?id=${response.data.orderId}`);
    setShowOrderSpinner(false);
  };

  useEffect(() => {
    const isMultiCurrenciesPaymentMethodsEnabled = isFeatureEnabled(
      'multi-currency-payment-methods-SITECORE-34'
    );
    const updateCurrency = async (): Promise<void> => {
      const countryCurrency = countries.find(
        (countryData) => customerBillingCountry === countryData.countryCode
      )?.currencyISoCode;
      if (countryCurrency && countryCurrency !== currencyIsoCode) {
        await dispatch(
          changeCurrency({ currencyIsoCode: countryCurrency, bearerToken: bearerToken })
        );
        await dispatch(removeCheckoutSession());
      }
    };
    if (isMultiCurrenciesPaymentMethodsEnabled && customerBillingCountry && countries?.length > 0) {
      updateCurrency();
    }
  }, [customerBillingCountry, dispatch, currencyIsoCode, bearerToken, countries]);

  return (
    <div id="checkout-summary--marketplace" className={`${!dataExists ? 'h-96' : ''} px-1 text-sm`}>
      {!dataExists && <Spinner color="#20BBD0" />}
      <div className="w-full flex flex-row justify-between">
        <NextLink
          field={props.continueShoppingLink}
          className="text-mp-txt-neutralLight text-sm px-1 py-2 uppercase"
        >
          {t('mp_cancel')}
        </NextLink>
      </div>
      <div className="relative w-full text-mp-txt-neutralLight m-auto p-2.5 bg-mp-background-card uppercase">
        <div className="flex flex-row justify-between w-full text-mp-txt-neutral">
          <div className="font-medium">{t('MPCheckout_User')}:</div>
          <div className="font-medium">
            <FontAwesomeIcon
              icon={faPencil}
              className="inline-block text-mp-txt-neutralLight cursor-pointer"
              onClick={editPaymentMethods}
            />
          </div>
        </div>
        <div className="w-full flex flex-col sm:flex-row justify-between my-2 uppercase">
          <div className="w-1/2">
            <div className="text-mp-txt-neutral">{t('MP_Checkout_Buyer_Info')}</div>
            <div className="mt-1 mb-2.5">
              {userData?.firstName || ''} {userData?.lastName || ''}
            </div>
          </div>
          <div className="w-1/2">
            <div className="text-mp-txt-neutral">{t('MP_Checkout_Billing_Addr')}</div>
            <div className="mt-1">{userData?.address.line1 || ''}</div>
            {userData?.address.line2 && <div className="mt-1">{userData.address.line2}</div>}
            <div className="mt-1">{`${userData?.address.city || ''}, ${
              userData?.address.state || ''
            }`}</div>
            <div className="mt-1">{userData?.address.postalCode || ''}</div>
            <div className="mt-1">{userData?.address.country || ''}</div>
          </div>
        </div>
      </div>
      <div className="relative w-full text-mp-txt-neutralLight mt-3.5 p-2.5 bg-mp-background-card">
        <div className="mb-2 font-medium text-mp-txt-neutral uppercase">{t('MP_Checkout')}</div>
        <div className="uppercase text-mp-txt-neutral border-b border-mp-txt-neutral mb-2">
          <div className="inline-block w-1/2 md:w-2/3 lg:w-3/4">
            {t('Marketplace_Cart_Product')}
          </div>
          <div className="inline-block  w-1/2 md:w-1/3 lg:w-1/4">
            <div className="w-2/3 text-start hidden md:inline-block">
              {t('Marketplace_Cart_Quantity')}
            </div>
            <div className="w-1/3 text-end hidden md:inline-block">
              {t('Marketplace_Cart_Price')}
            </div>
          </div>
        </div>
        <div className="border-b border-mp-txt-neutral pb-5">
          {cartItems?.map((item) => {
            return (
              <MarketplaceCartItem
                key={item.cartItem.cartItemId}
                name={item.cartItem.name}
                totalAmount={+item.cartItem.totalAmount}
                totalListPrice={+item.cartItem.totalListPrice}
                quantity={+item.cartItem.quantity}
                cartItemId={item.cartItem.cartItemId}
                productDetails={item.cartItem.productDetails}
                currencySymbol={t(`Currency_Symbol_${currencyIsoCode}`) || ''}
                actionsAllowed={false}
              />
            );
          })}
        </div>
        <div className="py-5 text-base">
          <div className="flex flex-row justify-between w-full">
            <div className="font-medium text-mp-txt-neutralLight text-sm uppercase">
              {t('Marketplace_Cart_Discount')}:
            </div>
            <div className="font-medium text-mp-txt-primary">
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {cartSummary?.totalPromotionalAdjustmentAmount ?? 0}
            </div>
          </div>
          <div className="pt-2 flex flex-row justify-between w-full">
            <div className="font-medium text-mp-txt-neutralLight text-sm uppercase">
              {t('MP_Payment_SubTotal')}:
            </div>
            <div className="font-medium text-mp-txt-primary">
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {checkoutData?.digitalRiverResponse?.subtotal ?? 0}
            </div>
          </div>
          <div className="pt-2 flex flex-row justify-between w-full">
            <div className="font-medium text-mp-txt-neutralLight text-sm uppercase">
              {t('MP_Taxes')}:
            </div>
            <div className="font-medium text-mp-txt-primary">
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {checkoutData?.digitalRiverResponse?.totalTax ?? 0}
            </div>
          </div>
          <div className="pt-2 flex flex-row justify-between w-full">
            <div className="font-medium text-mp-txt-neutralLight text-sm uppercase">
              {t('MP_Shipping')}:
            </div>
            <div className="font-medium text-mp-txt-primary">
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {checkoutData?.digitalRiverResponse?.totalShipping ?? 0}
            </div>
          </div>
          <div className="pt-2 flex flex-row justify-between w-full">
            <div className="font-medium text-mp-txt-neutralLight text-sm uppercase">
              {t('MP_Grand_Total')}:
            </div>
            <div className="font-medium text-mp-txt-primary">
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {checkoutData?.digitalRiverResponse?.totalAmount ?? 0}
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full text-mp-txt-neutralLight mt-3.5 px-3.5 pt-2.5 pb-3.5 mb-16 md:mb-8 bg-mp-background-card">
        <div className="mb-2 font-medium text-mp-txt-neutral uppercase">
          {t('MP_Terms_Conditions')}
        </div>
        <MarketplaceCheckbox
          sitecoreLabel={props.eula}
          passValueToParentFunction={onChangeEulaHandler}
          className="mt-4 mb-6 whitespace-normal"
        />
        {includesThirdPartyProducts && (
          <>
            <MarketplaceCheckbox
              sitecoreLabel={props.thirdPartyTerms}
              passValueToParentFunction={onChangeThirdPartyHandler}
              className="mt-4 mb-1 whitespace-normal"
            />
            {thirdPartyTermsList?.map((termItem, i) => {
              return (
                <div className="pl-7 whitespace-normal" key={i}>
                  <span className="text-base">{i + 1}. </span>
                  <a
                    className="js-external-redirect external-redirect text-base text-mp-txt-primary hover:border-b border-mp-txt-primary"
                    rel="noreferrer"
                    href={termItem?.link}
                    target="_blank"
                  >
                    {termItem?.label}
                  </a>
                </div>
              );
            })}
          </>
        )}
        <div className={`${orderError ? '' : 'hidden'} my-2 w-full`}>
          <AlertBox
            containerClass="m-auto bg-danger-light border-danger text-danger [&>div>.dismisable-icon]:text-almost-black p-3 flex w-fit justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 col-span-12 lg:col-span-8 lg:col-start-3"
            alertMessage={alertMessage}
            alertType="danger"
            isDismissible={false}
            showAlertBox={true}
            displayIcon={true}
          />
        </div>
        <div className="text-center">
          <button
            className={`${
              isProceedDisabled ? 'pointer-events-none bg-mp-btn-disabled' : 'bg-mp-btn-primary'
            } hover:bg-mp-btn-light hover:text-mp-txt-almostBlack text-base text-mp-txt-bright px-3.5 py-1 rounded md:w-1/3`}
            onClick={(e): Promise<void> => onClickPlaceOrderHandler(e)}
            disabled={isProceedDisabled}
          >
            {showOrderSpinner ? (
              <InlineSpinner width={3} height={15} color="#20BBD0" />
            ) : (
              t('MP_Checkout_Proceed')
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceCheckoutSummary;
