import React from 'react';

export type ProductPromoLabelProps = {
  promoValue: string;
};

const ProductPromoLabel = (props: ProductPromoLabelProps): JSX.Element => {
  return (
    <div className="absolute top-0 right-0 z-10">
      <label>
        <div>
          <div className="w-0 h-0 border-t-[14px] border-r-[14px] border-b-[14px] border-solid border-transparent border-r-mp-background-primary absolute top-0 right-[130px]"></div>
          <div className="bg-mp-background-primary text-center text-mp-txt-bright text-xxs leading-7 font-semibold uppercase tracking-[1px] w-[130px] h-[28px]">
            {props.promoValue}
          </div>
          <div className="w-0 h-0 border-t-[10px] border-r-[10px] border-b-[10px] md:border-t-[8px] md:border-r-[8px] md:border-b-[8px] border-solid border-transparent border-t-mp-background-primary absolute top28 right-0"></div>
        </div>
      </label>
    </div>
  );
};

export default ProductPromoLabel;
