import gql from 'graphql-tag';

const getVideoGridQuery = gql`
  query VideoGridQuery($datasource: String!, $language: String!) {
    datasource: item(path: $datasource, language: $language) {
      ... on VideoGrid {
        selectedVideos {
          targetItems {
            id
            name
            template {
              id
            }
            ... on BrightcoveVideoWithPlayer {
              player {
                targetItem {
                  ID: field(name: "ID") {
                    value
                  }
                }
              }
              videoReference {
                targetItems {
                  ID: field(name: "ID") {
                    value
                  }
                  ThumbnailURL: field(name: "ThumbnailURL") {
                    value
                  }
                }
              }
              videoTitle {
                value
              }
              shortDescription {
                value
              }
              stillImage {
                jsonValue
              }
              fullDescription {
                value
              }
            }
            ... on YoutubeVideo {
              sourceId {
                value
              }
              videoTitle {
                value
              }
              shortDescription {
                value
              }
              stillImage {
                jsonValue
              }
            }
          }
        }
      }
    }
  }
`;
export default getVideoGridQuery;
