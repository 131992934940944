import { useEffect, useState } from 'react';
import { useI18n } from 'next-localization';
import ReactDOM from 'react-dom';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const closeIcon = (): JSX.Element => {
  return (
    <div className={`absolute right-3 top-3 lg:right-4 lg:top-4`}>
      <div>
        <button type="button">
          <FontAwesomeIcon
            icon={faXmark}
            className="text-black w-full text-xl lg:text-2xl font-extrabold"
          />
          <span className="sr-only">Close</span>
        </button>
      </div>
    </div>
  );
};

type BaseCommonModalProps = {
  openCtaClassName?: string;
  openCtaChildren?: JSX.Element | JSX.Element[];
  modalContent?: JSX.Element | JSX.Element[];
};

const BaseCommonModal = (props: BaseCommonModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null);

  const i18n = useI18n();
  const closeLabel = i18n ? i18n.t('Close') : 'Close';

  useEffect(() => {
    setModalRoot(document.getElementById('modal-root'));

    const close = (e): void => {
      console.log(e);
      if (e.keyCode === 27) {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const modal =
    isOpen && modalRoot
      ? ReactDOM.createPortal(
          <div className="bc-modal fixed flex items-center justify-center top-0 left-0 w-full h-screen z-50">
            <div
              className="modal-backdrop fixed top-0 bg-almost-black h-screen opacity-80 w-full z-10"
              onClick={(): void => setIsOpen(false)}
            />
            <div className="modal-core modalShadow bg-white relative flex flex-col m-6  justify-center z-50 rounded max-w-850 max-h-[calc(100vh-48px)] overflow-auto">
              <button
                type="button"
                onClick={(): void => setIsOpen(false)}
                className="modal-close-btn  hover:cursor-pointer"
              >
                {closeIcon()}
                <span className="sr-only">{closeLabel}</span>
              </button>
              <div className="inline-block overflow-auto px-6 py-10 lg:p-10">
                {props.modalContent}
              </div>
            </div>
          </div>,
          modalRoot
        )
      : null;

  return (
    <>
      <button className={props.openCtaClassName} onClick={(): void => setIsOpen(true)}>
        {props.openCtaChildren}
      </button>

      {modal}
    </>
  );
};

export default BaseCommonModal;
