import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import {
  pushFormStartEvent,
  pushFormSubmitEvent,
  pushFormAbandonedEvent,
} from 'lib/google-analytics/form-analytics';
import React, { useEffect } from 'react';
import { withRouter, NextRouter } from 'next/router';
import config from 'temp/config';

/* eslint-disable-next-line  @typescript-eslint/explicit-function-return-type */
const SitecoreForm = (params: {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */ /* eslint-disable-next-line react-hooks/exhaustive-deps */
  fields: any;
  router: NextRouter;
}): JSX.Element => {
  useEffect(() => {
    let startForm = false;
    let formAbandoned = false;
    let formAbandonedSent = false;
    let formSubmitted = false;
    let formSubmittedSent = false;
    let lastField = '';
    let abandonedCheck: NodeJS.Timeout;
    const formFields = params.fields;
    const formName = formFields.metadata?.name;
    const formId = formFields.formItemId?.value;
    const formSubmit = formFields.fields.filter(
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
      (item: { model: any; hasOwnProperty: (arg0: string) => any }) => {
        return item.hasOwnProperty('buttonField') && item.model.hasOwnProperty('submitActions');
      }
    );
    const formDestination = formSubmit[0]?.model?.submitActions[0]?.description;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    formFields.fields.forEach(function (formField: any) {
      const inputID = formField?.fieldIdField?.id.replace('__ItemId', '__Value');
      if (typeof window !== 'undefined') {
        const container = document?.getElementById(inputID);
        container?.addEventListener('change', function (e) {
          if (e.target && e.target instanceof HTMLElement) {
            const targetId = e.target.id;
            if (targetId === inputID) {
              lastField = formField.model.title;
            }
          }
        });
      }
    });

    //const
    const handleInputChange = (): void => {
      setFormAbandoned(false);
      if (!startForm) {
        pushFormStartEvent('form_start', formName, formId, formDestination);
        startForm = true;
      }
      clearInterval(abandonedCheck);
      abandonedCheck = setInterval(handleUserInactive, 60000);
    };
    const handleFormSubmit = (): void => {
      // Handle form submission
      formSubmitted = true;
      setFormAbandoned(false);
      clearInterval(abandonedCheck);
      if (!formSubmittedSent) {
        pushFormSubmitEvent(
          'form_submit',
          formName,
          formId,
          formDestination,
          formSubmit[0]?.model?.title
        );
        formSubmittedSent = true;
      }
    };

    const handleUserInactive = (): void => {
      console.log('checking inactive');
      if (startForm) {
        if (formAbandoned && !formAbandonedSent && !formSubmitted) {
          console.log('form abandoned');
          pushFormAbandonedEvent('form_abandonment', formName, formId, formDestination, lastField);
          formAbandonedSent = true;
          clearInterval(abandonedCheck);
        }
        setFormAbandoned(true);
      }
    };
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const handleAbandonOnLinkExit = (e: any): void => {
      const clickedLink = e.target.href;
      if (isValidUrl(clickedLink)) {
        setFormAbandoned(true);
        handleUserInactive();
      }
    };
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const setFormAbandoned = (abandoned: any): void => {
      formAbandoned = abandoned;
    };
    // Set up event listeners
    const links = document.querySelectorAll('a');
    links.forEach((link) => {
      link.addEventListener('click', handleAbandonOnLinkExit);
    });

    //functions
    function isValidUrl(url: string): boolean {
      try {
        new URL(url);
        return true;
      } catch (error) {
        return false;
      }
    }
    //Listners
    document.addEventListener('input', handleInputChange);
    document.addEventListener('submit', handleFormSubmit);
    document.addEventListener('mousemove', () => setFormAbandoned(false));
    document.addEventListener('beforeunload', () => handleUserInactive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Form
        form={params.fields}
        sitecoreApiHost={''}
        sitecoreApiKey={config.sitecoreApiKey}
        /* eslint-disable-next-line  @typescript-eslint/explicit-function-return-type */
        onRedirect={(url) => params.router.push(url)}
      />
    </div>
  );
};

export default withRouter(SitecoreForm);
