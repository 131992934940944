import { ImageField, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';

export type ProductProps = {
  productImage: {
    jsonValue: ImageField;
    value: string;
  };
  productLink: {
    jsonValue: LinkField;
    value: string;
  };
  productName: {
    jsonValue: LinkField;
    value: string;
  };
};

type FeaturedProductsProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        children: {
          results: ProductProps[];
        };
      };
    };
  };
};

const FeaturedProducts = (props: FeaturedProductsProps): JSX.Element => {
  const datasource = props?.fields?.data?.datasource;
  const productItems = datasource?.children?.results;

  return (
    <div id="marketplace-featured-products">
      {datasource && productItems && productItems.length > 0 && (
        <div className="flex flex-col sm:flex-row justify-between h-auto sm:h-36 md:h-52 xl:h-60 mb-6 xl:mb-8">
          {productItems.map((product, index) => {
            return (
              <a
                key={index}
                href={product.productLink.jsonValue?.value?.href}
                className="mb-4 h-20 sm:w-32p sm:h-36 md:h-52 xl:h-60 max-h-180 xl:max-h-60 relative cursor-pointer overflow-hidden border border-mp-background-card rounded"
              >
                <div className="h-full">
                  <NextImage
                    field={product.productImage.jsonValue}
                    className="absolute top-3 left-2 w-14 h-14 sm:w-10 sm:h-10 md:w-12 md:h-12"
                    useAvidCdn={true}
                  />
                  <h3 className="text-mp-txt-bright text-center w-full absolute top-1/2 -translate-y-1/2">
                    {product.productName?.value}
                  </h3>
                </div>
                <button className="hidden sm:block w-full absolute left-0 bottom-0 text-white text-center bg-mp-btn-primary hover:bg-mp-btn-light hover:text-mp-txt-almostBlack h-8.5 rounded-b">
                  {product.productLink.jsonValue.value.text}
                </button>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withDatasourceCheck()<FeaturedProductsProps>(FeaturedProducts);
