import { faCirclePause } from '@fortawesome/free-regular-svg-icons';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCartSummary } from 'components/cart/cart-slice';
import { getOrderDetails } from 'components/order/order-slice';
import { ComponentProps } from 'lib/component-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { stringFormat } from 'lib/utils/string-format';
import { useI18n } from 'next-localization';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import Spinner from 'components/spinner/Spinner';
import OrderWrapper from '../../components/order/shop/OrderWrapper';
import { LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import NonProToolsOrderDetails from '../../components/order/shop/NonProToolsOrderDetails';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useSession } from 'next-auth/react';

type OrderProcessingProps = ComponentProps & {
  fields: {
    orderDetailsLink: LinkField;
  };
};

const OrderProcessing = (props: OrderProcessingProps): JSX.Element => {
  const router = useRouter();
  const { id } = router.query;

  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector((state) => state.order.orderDetailsData);
  const orderStatus = useAppSelector((state) => state.order.orderDetailsStatus);
  const notificationEmail = orderDetails?.notificationEmail;
  const userId = orderDetails?.userId;
  const icon = faCirclePause as unknown as IconName;
  const { data: session, status } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  useEffect(() => {
    if (id == null || id.length == 0 || status === 'loading') {
      return;
    }

    dispatch(getOrderDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    dispatch(getCartSummary({ bearerToken: bearerToken }));
  }, [dispatch, id, session, status, bearerToken]);

  useEffect(() => {
    if (orderDetails?.orderItems == null) {
      return;
    }

    router.push(`${props.fields.orderDetailsLink.value.href}?id=${id}`);
  });

  const OrderDetailDescription = (): JSX.Element => {
    const description = t('Order_Processing_Description');
    const content = stringFormat({
      message: description,
      0: id as string,
      1: notificationEmail as string,
    });
    return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
  };

  return (
    <OrderWrapper title={t('Order_Processing_ThankYou')}>
      <>
        {orderStatus != 'succeeded' && <Spinner />}
        <div className="text-center">
          <div className="text-secondary mb-5">
            <FontAwesomeIcon icon={icon} size="3x" />
          </div>
          <div className="mb-4 lg:mb-5 text-3xl lg:text-4xl capitalize font-bold">
            {t('Order_Processing_OrderProcessing')}
          </div>
          <div className="p-2 pb-0 mb-6">
            <OrderDetailDescription />
          </div>
          <div className="my-8 border-t border-base-dark"></div>
          <NonProToolsOrderDetails userId={userId} orderId={id} loading={false} />
        </div>
      </>
    </OrderWrapper>
  );
};

export default withDatasourceCheck()(OrderProcessing);
