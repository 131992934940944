/* eslint-disable @typescript-eslint/no-explicit-any */

import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useState, useEffect, SetStateAction } from 'react';

declare const window: any;
const appendScript = (
  baseUrl: string,
  setScriptLoaded: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }
): void => {
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement('script');
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.id = 'marketoformsscript';
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
};

const getBaseUrl = (): string => {
  return process.env.NEXT_PUBLIC_MARKETO_BASE_URL ?? '//connect.avid.com';
};

export type MarketoProps = ComponentProps & {
  fields: {
    formId: Field<string>;
    munchkinId: Field<string>;
    isCenterAligned: Field<string>;
  };
};

export const useMarketo = (props: MarketoProps): void => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const marketoForms = document.querySelector(
      `#mktoForm_${props.fields.formId.value} .mktoFormRow`
    );
    if (scriptLoaded && marketoForms == null) {
      window.MktoForms2.loadForm(
        getBaseUrl(),
        props.fields.munchkinId.value,
        props.fields.formId.value
      );

      window.MktoForms2.whenRendered((mktoForm: any) => {
        //styling marketoForm
        const formElement = mktoForm.getFormElem()[0];
        Array.from(formElement.querySelectorAll('[style]'))
          .concat(formElement)
          .forEach((element: any) => {
            element.removeAttribute('style');
          });

        //   /** Remove <span /> from DOM */
        Array.from(formElement.querySelectorAll('.mktoInstruction')).forEach((element: any) => {
          element.remove();
        });

        /** Remove <style /> from DOM */
        Array.from(formElement.children).forEach((element: any) => {
          if (element.type && element.type === 'text/css') {
            element.remove();
          }
        });
      });

      return;
    }
    appendScript(getBaseUrl(), setScriptLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);
};
