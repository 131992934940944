import AlertBox from 'components/alert/AlertBox';
import { useCartData } from 'components/cart/cart-hooks';
import { applyCoupon, removeCoupon } from 'components/cart/cart-slice';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useSession } from 'next-auth/react';
import { useI18n } from 'next-localization';
import React, { useMemo, useState } from 'react';

type CouponDetailsProps = {
  isEditable: boolean;
};

const CouponDetails = (props: CouponDetailsProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const cartItemData = useCartData();
  const cart = useAppSelector((state) => state.cart);
  const cartSummary = cartItemData?.cartSummary;
  const couponData = cartSummary?.coupons;
  const applyCouponStatus = cart?.applyCouponStatus;
  const cartHasCoupon = cartSummary?.coupons && cartSummary?.coupons.length > 0 ? true : false;
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const [couponCode, setCouponCode] = useState('');

  const onClickApplyCouponHandler = (): void => {
    dispatch(applyCoupon({ couponCode, bearerToken: bearerToken }));
  };

  const parsePromoCodeMessage = (message: string): string => {
    return message.replace('{promocode}', couponData?.[0].couponCode as string);
  };

  const onClickRemoveCouponHandler = (cartCouponId: string): void => {
    dispatch(removeCoupon({ cartCouponId, bearerToken: bearerToken }));
    setCouponCode('');
  };

  return (
    <>
      <div className="flex flex-col w-full">
        {props.isEditable && (
          <>
            {(!cartSummary?.coupons || cartSummary?.coupons.length <= 0) && (
              <>
                <label htmlFor="coupon-code" className="small-text mb-1">
                  {t('Cart_Coupon')}
                </label>
                <div className="flex justify-between space-x-6">
                  <input
                    id="coupon-code"
                    name="coupon-code"
                    placeholder="Coupon Code"
                    type="text"
                    className="w-full input-text-no-validation text-opacity-60"
                    value={couponCode}
                    onChange={(e): void => setCouponCode(e.target.value)}
                    required
                  />
                  <button
                    className="btn-primary btn-small"
                    disabled={couponCode === ''}
                    onClick={onClickApplyCouponHandler}
                  >
                    {t('Cart_PromoApply')}
                  </button>
                </div>
              </>
            )}
            {(applyCouponStatus === 'failed' || cartHasCoupon) && (
              <AlertBox
                containerClass={`w-full px-4 py-3 border rounded body flex items-center space-x-2 ${
                  cartHasCoupon
                    ? 'bg-success-light border-success text-success'
                    : 'mt-4 bg-danger-light border-danger text-danger'
                }`}
                alertMessage={
                  cartHasCoupon
                    ? parsePromoCodeMessage(t('Cart_CouponSuccessMessage'))
                    : t('Cart_CouponFailureMessage')
                }
                showAlertBox={true}
                displayIcon={true}
                iconClass="text-xl"
                alertType={cartHasCoupon ? 'success' : 'danger'}
                isDismissible={false}
                link={{ value: {} }}
              />
            )}
            <span className="w-full h-[1px] bg-base-normal my-4 block" />
          </>
        )}
      </div>
      {cartHasCoupon && (
        <>
          <div className="flex justify-between w-full mb-4 body">
            <p className="body">{t('Checkout_SubTotal')}</p>
            <p className="body">
              {t(`Currency_Symbol_${cartSummary?.currencyIsoCode}`)}
              {cartSummary != null && cartSummary.totalProductAmount}
            </p>
          </div>

          <div className="flex justify-between w-full mb-4 body">
            <p className="body">{cartSummary?.coupons[0].couponCode}</p>
            {props.isEditable && (
              <button
                className="text-sm text-primary hover:cursor-pointer underline flex items-center"
                onClick={(): void =>
                  onClickRemoveCouponHandler(cartSummary?.coupons[0].cartCouponId as string)
                }
              >
                {t('Cart_Remove')}
              </button>
            )}
          </div>

          <div className="flex justify-between w-full mb-4 body">
            <p className="body">{t('Checkout_DiscountTotal')}</p>
            <p className="body">
              -{t(`Currency_Symbol_${cartSummary?.currencyIsoCode}`)}
              {cartSummary != null &&
                cartSummary.totalPromotionalAdjustmentAmount.toString().replace('-', '')}
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default CouponDetails;
