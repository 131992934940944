import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { t14TemplateIdVal, t5TemplateIdVal } from 'lib/constants';

function ContentArea(props: ComponentProps): JSX.Element {
  const t5TemplateId = t5TemplateIdVal;
  const context = useSitecoreContext();
  const t14TemplateId = t14TemplateIdVal;
  const pageTemplateId = context?.sitecoreContext.route?.templateId;
  const phName = t5TemplateId === pageTemplateId ? 'content-area-t5' : 'content-area';
  const addWidthforT14 = t14TemplateId === pageTemplateId ? 'xl:w-8/12 xl:max-w-[800px]' : '';
  return (
    <div className={`max-w-full lg:max-w-content mx-auto sm:my-5 lg:my-10 ${addWidthforT14}`}>
      <Placeholder name={phName} rendering={props.rendering} />
    </div>
  );
}

export default ContentArea;
