import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroupProps } from 'react-multi-carousel';

function SubscriptionPricingCardCarouselButtons(
  props: ButtonGroupProps & { btnsColorClass: string }
): JSX.Element {
  const currentSlide = props.carouselState?.currentSlide ?? 0;
  const slidesToShow = props.carouselState?.slidesToShow ?? 0;
  const totalItems = props.carouselState?.totalItems ?? 0;
  const needsNavigation = totalItems > slidesToShow;
  const atEnd = currentSlide === totalItems - slidesToShow;

  return (
    <div className={`w-full absolute ${needsNavigation ? 'top-[37%]' : 'top-1/2'}`}>
      {currentSlide != 0 && (
        <button
          className={`w-11 h-11 border-2 border-base-dark rounded-full absolute -left-[5px] md:-left-[43px] xl:-left-[5px] 2xl:-left-[43px] ${props.btnsColorClass} flex justify-center items-center hover:bg-primary-lighter hover:border-primary-lighter focus:bg-primary-lighter focus:border-primary active:bg-primary-light`}
          onClick={(): void => {
            if (props.previous != null) {
              props?.previous();
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      )}
      {needsNavigation && !atEnd && (
        <button
          className={`z-10 w-11 h-11 border-2 border-base-dark rounded-full absolute -right-[5px] md:-right-[43px] xl:-right-[5px] 2xl:-right-[43px] ${props.btnsColorClass} flex justify-center items-center hover:bg-primary-lighter hover:border-primary-lighter focus:bg-primary-lighter focus:border-primary active:bg-primary-light`}
          onClick={(): void => {
            if (props.next != null) {
              props?.next();
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
    </div>
  );
}

export default SubscriptionPricingCardCarouselButtons;
