import { Field, LinkField, withPlaceholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { BaseCarouselProps } from 'components/carousel/BaseCarouselProps';
import Carousel from 'components/carousel/Carousel';
import { ComponentProps } from 'lib/component-props';
import ImageCarouselButtonGroup from '../../components/image-video-carousel/ImageCarouselButtonGroup';
import VideoCarouselButtonGroup from '../../components/image-video-carousel/VideoCarouselButtonGroup';

type ImageVideoCarouselProps = ComponentProps &
  BaseCarouselProps & {
    fields: {
      heading: Field<string>;
      link: LinkField;
    };
    name: string;
    cardCarouselItems: JSX.Element;
  };

const ImageVideoCarousel = (props: ImageVideoCarouselProps): JSX.Element => {
  const editorActive = isEditorActive();
  const leftrail = props?.params?.isleftrail; //s props.params.isleftrail;
  const componentName = props?.cardCarouselItems[0]?.props?.rendering?.componentName;

  return (
    <div className="mb-10 lg:mb-20">
      <div
        className={
          leftrail
            ? 'mx-auto max-w-full px-20'
            : 'max-w-xl sm:max-w-md md:max-w-2xl xl:max-w-4xl  mx-3 px-3 sm:px-2 md:px-9 xl:px-10 sm:mx-auto'
        }
      >
        <div className={editorActive ? 'flex flex-wrap [&>.carousel-item]:w-1/3' : 'hidden'}>
          {props.cardCarouselItems}
        </div>

        {!editorActive && (
          <Carousel
            MaxItemsPerSlideOnDesktop="1"
            MaxItemsPerSlideOnTablet="1"
            MaxItemsPerSlideOnMobile="1"
            desktopSize={1199}
            buttonGroup={
              componentName == 'ImageCarouselCard' ? (
                <ImageCarouselButtonGroup />
              ) : (
                <VideoCarouselButtonGroup />
              )
            }
            hideDots={true}
          >
            {props.cardCarouselItems}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default withPlaceholder({
  placeholder: 'image-video-carousel-items',
  prop: 'cardCarouselItems',
})(ImageVideoCarousel);
