import { useEffect, useState } from 'react';

export const useExternalScript = (scriptString: string, scriptId: string): string => {
  const [state, setState] = useState(scriptString ? 'loading' : 'idle');

  const addScriptToDom = (scriptString: string, scriptId: string): HTMLScriptElement => {
    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.innerHTML = scriptString;
    script.async = true;
    script.defer = true;
    if (scriptId) {
      script.id = scriptId;
    }
    document.body.appendChild(script);
    return script;
  };
  useEffect(() => {
    if (!scriptString) {
      setState('idle');
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleScript = (e: any): void => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };
    let script;
    const scriptElement = document.getElementById(scriptId);
    if (scriptId == null || scriptElement == null) script = addScriptToDom(scriptString, scriptId);
    if (script) {
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);

      return (): void => {
        script!.removeEventListener('load', handleScript);
        script!.removeEventListener('error', handleScript);
      };
    }
    return (): void => {
      setState('idle');
    };
  }, [scriptId, scriptString]);

  return state;
};
