import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getProductVariations, getSitecoreProductDetails } from 'lib/commerce/product/product-api';
import {
  ProductPickAPlanProps,
  TargetItem,
} from '../../components/pick-a-plan/marketplace/product-pick-a-plan-props';
import { useAppSelector } from 'lib/store/hooks';
import { ProductVariationsData } from 'lib/commerce/product/model/product-models';
import { Product } from 'src/pages/api/product/models/product';
import Spinner from 'components/spinner/Spinner';
import SelectYourPlanSingle from 'components/pick-a-plan/marketplace/SelectYourPlanSingle';
import SelectYourPlanMulti from '../../components/pick-a-plan/marketplace/SelectYourPlanMulti';

const ProductPickAPlan = (props: ProductPickAPlanProps): JSX.Element => {
  const pricingOptions =
    props.fields?.data?.datasource?.userTypeOptions != null
      ? props.fields?.data?.datasource?.userTypeOptions.targetItems?.map((item) => {
          return {
            value: item.productSet.targetItems[0].productSetId.value,
            label: item.title.value,
          };
        })
      : [
          {
            value:
              props?.fields?.data?.contextItem?.parentProduct?.targetItems[0]?.productId?.value,
            label: '',
          },
        ];

  const [productVariations, setProductVariations] = useState<ProductVariationsData | null>(null);
  const [productItems, setProductItems] = useState<Product[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [productSetData, setProductSetData] = useState(
    props.fields?.data?.datasource?.defaultUserType != null
      ? props.fields?.data?.datasource?.defaultUserType?.targetItems[0]
      : null
  );
  const contextLanguage = 'en';
  const router = useRouter();
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode) || 'USD';

  const productSetId = productSetData
    ? productSetData.productSet.targetItems[0].productSetId.value
    : props?.fields?.data?.contextItem?.parentProduct?.targetItems[0]?.productId?.value;

  const onChangePricingHandler = (pricingOptionId: string): void => {
    if (props.fields?.data?.datasource?.userTypeOptions == null) {
      return;
    }
    setIsLoading(true);

    const newProductSet = props.fields.data.datasource.userTypeOptions.targetItems.find(
      (item) => item.productSet.targetItems[0].productSetId.value === pricingOptionId
    );
    setProductSetData(newProductSet as TargetItem);
  };

  useEffect(() => {
    setIsLoading(true);
    getProductVariations({
      productId: productSetId,
      currencyIsoCode: currencyIsoCode,
    }).then((productVariations) => {
      setProductVariations(productVariations.data);
    });
  }, [currencyIsoCode, productSetData, productSetId]);

  useEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      if (productVariations === null) return;

      const sitecoreProductItemIds: Product[] = [];
      for (let i = 0; i < productVariations.variants.length; i++) {
        const productId = productVariations?.variants[i].productId;
        const result = await getSitecoreProductDetails(productId, contextLanguage);

        if (result == null || result.length == 0) {
          continue;
        }

        sitecoreProductItemIds.push(result[0]);
      }

      if (sitecoreProductItemIds.length > 0) {
        setIsLoading(false);
      }
      setProductItems(sitecoreProductItemIds);
    };

    fetchProducts();
  }, [productVariations]);

  useEffect(() => {
    setProductSetData(
      props.fields?.data?.datasource?.defaultUserType != null
        ? props.fields?.data?.datasource?.defaultUserType?.targetItems[0]
        : null
    );
    setProductItems(null);
    setProductVariations(null);
  }, [props.fields?.data?.datasource?.defaultUserType, router.asPath]);

  return (
    <div
      className={`${
        isLoading ? 'h-96' : ''
      } product-pick-a-plan--marketplace mx-auto text-mp-txt-neutral pb-1 mb-10`}
    >
      {isLoading && <Spinner color="#20BBD0" />}
      {!isLoading && (
        <div className="">
          {pricingOptions && pricingOptions.length > 1 && (
            <div className="w-full m-auto h-auto bg-mp-background-card pb-1">
              <div className="flex flex-row justify-between">
                {pricingOptions.map((optionItem, index) => {
                  return (
                    <div
                      key={index}
                      className={`cursor-pointer w-full mx-1 text-center uppercase pt-2.5 pb-2.5 bg-mp-background-darker
                      ${
                        productSetId === optionItem.value &&
                        productSetData?.title.value === optionItem.label
                          ? 'font-normal border-solid border-b border-b-mp-txt-primary text-mp-txt-bright'
                          : 'text-mp-txt-neutral border-solid border-b border-b-mp-txt-neutral border-opacity-10'
                      }
                      
                      `}
                      onClick={(): void => onChangePricingHandler(optionItem.value)}
                    >
                      {optionItem.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {productVariations &&
            productVariations.variants.length > 1 &&
            productItems &&
            productItems.length == productVariations.variants.length && (
              <SelectYourPlanMulti
                productVariations={productVariations}
                productItems={productItems}
                contextItem={props.fields?.data?.contextItem}
                productSet={productSetData?.productSet.targetItems[0]}
                imageSet={
                  props.fields?.data?.contextItem.productImages.targetItems.length > 0
                    ? props.fields?.data?.contextItem.productImages.targetItems
                    : []
                }
              />
            )}
          {!isLoading &&
            productVariations &&
            productVariations.variants.length == 1 &&
            productItems &&
            productItems.length == productVariations.variants.length && (
              <SelectYourPlanSingle
                productVariations={productVariations}
                productItems={productItems}
                contextItem={props.fields?.data?.contextItem}
                productSet={productSetData?.productSet.targetItems[0]}
                imageSet={
                  props.fields?.data?.contextItem.productImages.targetItems.length > 0
                    ? props.fields?.data?.contextItem.productImages.targetItems
                    : []
                }
              ></SelectYourPlanSingle>
            )}
        </div>
      )}
    </div>
  );
};

export default ProductPickAPlan;
