import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { ComponentProps } from 'lib/component-props';
import { GetClassFromTheme } from '../../lib/utils/button-theme';

type CallToActionProps = ComponentProps & {
  fields: {
    link: LinkField;
    icon: ReferenceStringItem;
    noFollow?: Field<boolean>;
  };
  params: {
    Theme: string;
    Width: string;
    IconPlacement: string;
  };
};

const GetClassFromWidth = (width: string): string => {
  if (width == null) {
    return '';
  }

  switch (width.toLowerCase()) {
    case 'fit content':
      return 'w-fit';
    case 'full':
      return 'flex-1';
    default:
      return 'w-fit';
  }
};

const GetClassFromIconPlacement = (iconPlacement: string): string => {
  if (iconPlacement == null) {
    return '';
  }

  switch (iconPlacement.toLowerCase()) {
    case 'left':
      return 'flex-row';
    case 'right':
      return 'flex-row-reverse';
    default:
      return 'flex-row';
  }
};

const CallToAction = (props: CallToActionProps): JSX.Element => {
  const themeClass = GetClassFromTheme(props.params?.Theme);
  const widthClass = GetClassFromWidth(props.params?.Width);
  const iconPlacementClass = GetClassFromIconPlacement(props.params?.IconPlacement);
  return (
    <Link
      field={props.fields.link}
      linkCustomizations={props.fields}
      className={`flex justify-center items-center flex-nowrap gap-x-4 mb-10 md:mb-20 mx-6 ${widthClass} ${themeClass} ${iconPlacementClass}`}
    >
      {props.fields.icon?.fields?.value?.value && (
        <FontAwesomeIcon
          icon={props.fields.icon.fields.value.value as IconProp}
          className="text-base"
        />
      )}
    </Link>
  );
};

export default withDatasourceCheck()<CallToActionProps>(CallToAction);
