import { useEffect, useState } from 'react';
import { useCartData } from 'components/cart/cart-hooks';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import OptionalAddOnsCarousel from 'components/optional-add-ons/OptionalAddOnsCarousel';
import { useAppSelector } from 'lib/store/hooks';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export type OptionalAddOnsProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        title: Field<string>;
        useCoveoRecommendations: {
          jsonValue: Field<boolean>;
        };
        children: {
          results: [
            {
              product: {
                targetItems: [
                  {
                    productId: {
                      value: string;
                    };
                  },
                ];
              };
            },
          ];
        };
      };
    };
  };
};

const OptionalAddOns: React.FC<OptionalAddOnsProps> = (props) => {
  const useCoveoRecommendations =
    props.fields.data.datasource.useCoveoRecommendations?.jsonValue.value ?? true;
  const cartItemData = useCartData();
  const cartItems = cartItemData?.cartItems;
  const coveoTargetItem = cartItems?.at(-1);
  const productID = coveoTargetItem?.cartItem.productId;
  const [coveoRecomendationsIds, setCoveoRecomendationsIds] = useState<string[] | null>(null);
  const isEditing = isEditorActive();

  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);

  useEffect(() => {
    if (!useCoveoRecommendations) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let controller: any | null = null;
    if (cartItemData !== null && cartItemData?.cartItems !== undefined && productID === undefined) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      controller = null;
      setCoveoRecomendationsIds([]);
    }

    const setRecommendations = async (): Promise<void> => {
      if (
        cartItemData !== null &&
        cartItemData?.cartItems !== undefined &&
        productID !== undefined
      ) {
        if (controller === null && !isEditing) {
          const CartRecommendationsController = (
            await import('../../components/optional-add-ons/shop/controllers')
          ).cartRecommendations;
          controller = CartRecommendationsController;
          controller.subscribe(() => {
            const receivedRecommendations =
              controller?.state.recommendations.map((entry): string => {
                const returnedValue = entry.additionalFields
                  .sfproductvariantattributesproductid as string;
                if (returnedValue == null) {
                  return '';
                }
                return returnedValue.split(';').at(-1) || '';
              }) || [];
            if (
              coveoRecomendationsIds == null ||
              (receivedRecommendations.length > 0 &&
                receivedRecommendations.some((elem) => !coveoRecomendationsIds?.includes(elem)))
            ) {
              setCoveoRecomendationsIds(receivedRecommendations);
            }
          });
        }
        controller?.setSkus([productID]);
        controller?.refresh();
      }
    };

    setRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCoveoRecommendations, productID, cartItemData]);

  const defaultRecomendationsIds = props.fields.data.datasource.children.results
    .map((entry): string => {
      return entry.product.targetItems[0]?.productId.value;
    })
    .filter((entry): boolean => entry != null);

  return (
    <>
      {(!useCoveoRecommendations || coveoRecomendationsIds !== null) &&
        currencyIsoCode !== null &&
        cartItemData !== null && (
          <div className="optionalAddons relative">
            <Text
              tag="h2"
              field={props.fields.data.datasource.title}
              className="font-bold text-2xl leading-[29.05px] mb-4 pt-3 text-center"
            />
            <OptionalAddOnsCarousel
              coveoRecomendationsIds={coveoRecomendationsIds}
              defaultRecomendationsIds={defaultRecomendationsIds}
              currencyIsoCode={currencyIsoCode ?? ''}
            />
          </div>
        )}
    </>
  );
};

export default withDatasourceCheck()<OptionalAddOnsProps>(OptionalAddOns);
