import { Field, Text, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import NextImage from 'components/foundation/non-sitecore/NextImage';

type ImageCarouselCardProps = {
  fields: {
    image: ImageField;
    caption: Field<string>;
  };
};

const ImageCarouselCard = (props: ImageCarouselCardProps): JSX.Element => {
  const editorActive = isEditorActive();
  return (
    <section className={editorActive ? 'carousel-item p-4 ' : 'w-full '}>
      <div className={`w-full relative aspect-[2/1] ${editorActive ? 'overflow-hidden' : ''}`}>
        <NextImage field={props?.fields?.image} layout="fill" />
      </div>
      <div className="w-full mt-4 font-normal text-sm leading-5.3 text-center">
        <Text field={props?.fields?.caption} />
      </div>
    </section>
  );
};

export default ImageCarouselCard;
