import { Text, Field, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { toLocalDateString } from 'lib/utils/date-utils';
export type PageIntroMetadataProps = {
  fields: {
    date: Field<string>;
    time: Field<string>;
    location: Field<string>;
    applyPadding: boolean;
  };
};

function PageIntroMetadata(props: PageIntroMetadataProps): JSX.Element {
  const editorActive = isEditorActive();
  const dateString = toLocalDateString(new Date(props.fields.date?.value))?.toUpperCase();
  const paddingClass = props.fields?.applyPadding == true ? 'lg:px-8 p-6 2xl:px-24' : '';
  const showTimeSeparator = props.fields.time?.value != '' ? 'xl:block' : '';
  const showLocationSeparator = props.fields.location?.value != '' ? 'xl:block' : '';
  return (
    <div
      className={`flex 
      flex-col 
      text-avidpurple
      font-normal
      text-base
      leading-22
      items-start     
      xl:flex-row       
      lg:text-xl
      lg:leading-7 
      gap-1    
      mb-6
      uppercase
      ${paddingClass}
      `}
    >
      {props.fields.date && editorActive && (
        <>
          <Text field={props.fields?.date} />
        </>
      )}
      {props.fields.date && !editorActive && (
        <span className="flex items-center gap-5 tracking-3.2">{dateString}</span>
      )}

      <div className={`mx-4 hidden text-neutral-light ${showTimeSeparator}`}>|</div>
      <span className="flex items-center gap-5 tracking-3.2">
        <Text field={props.fields?.time} />
      </span>
      <div className={`mx-4 hidden text-neutral-light ${showLocationSeparator}`}>|</div>
      <span className="flex items-center gap-5 tracking-3.2 ">
        <Text field={props.fields?.location} />
      </span>
    </div>
  );
}

export default PageIntroMetadata;
