import { getCookie } from 'cookies-next';

export const getStoredCountry = (): string => {
  const country = getCookie('COUNTRY');
  if (country == null) {
    return '';
  }

  return country as string;
};
