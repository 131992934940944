import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'next-localization';
import { TargetItemFeaturesValue } from 'src/pages/api/product/models/product';
import MPSubscriptionPricingCardFeatureModal from './MPSubscriptionPricingCardFeatureModal';

type MPSubscriptionPricingCardFeaturesProps = {
  features: TargetItemFeaturesValue[];
  isFeatured?: boolean;
};

const MPSubscriptionPricingCardFeatures = (
  props: MPSubscriptionPricingCardFeaturesProps
): JSX.Element => {
  const { t } = useI18n();

  return (
    <div
      className={`h-1/3 w-full space-y-2 text-xs flex flex-col bg-mp-background-card px-4 py-3 mt-2 text-mp-txt-neutralLight flex-grow`}
    >
      <h5 className="text-center small-navigation-bold">
        {t('SubscriptionPricingCard_KeyFeaturesTitle')}
      </h5>
      {props.features.map((feature, index) => (
        <div key={index} className="flex items-center">
          <FontAwesomeIcon
            icon={faCheck}
            className={`mr-1 ${props.isFeatured ? 'text-mp-txt-primary' : 'text-base-darker'}`}
          />
          <p className="small-text">
            {feature.heading.value.split(/({modalLinkText})/g).map((featureText, index) => {
              return featureText === '{modalLinkText}' ? (
                <MPSubscriptionPricingCardFeatureModal
                  key={index}
                  modalLinkText={feature.modalLinkText}
                  {...feature.modal}
                />
              ) : (
                featureText
              );
            })}
          </p>
        </div>
      ))}
    </div>
  );
};

export default MPSubscriptionPricingCardFeatures;
