import {
  Field,
  ImageField,
  RichTextField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { ComponentProps } from 'lib/component-props';

export type IntroBlockImageProps = ComponentProps & {
  fields: {
    overviewHeading: Field<string>;
    overviewDescription: RichTextField;
    image: ImageField;
  };
};

const IntroBlockImage = (props: IntroBlockImageProps): JSX.Element => {
  return (
    <section className="mx-auto grid grid-cols-1 md:grid-cols-12 box-content max-w-content px-6 py-10 xl:px-24 md:py-10 md:px-6    justify-center">
      <div className="xl:col-start-2 md:col-span-5 xl:col-span-4 mr-0 md:mr-3 xl:mr-4 ">
        <NextImage className="h-auto rounded " field={props.fields.image} />
      </div>
      <div className="md:col-span-7 xl:col-span-6 ml-0 mt-8 md:mt-0 md:ml-3 xl:ml-4 text-almost-black">
        <Text
          tag="div"
          className="text-left font-normal text-xl leading-7.5 lg:leading-9  lg:text-2xl mx-auto pb-3"
          field={props.fields?.overviewHeading}
        />

        <RichText
          className="rte text-left [&_a]:underline [&_a]:text-primary font-normal lg:stext-base"
          field={props.fields?.overviewDescription}
          tag="div"
        />
      </div>
    </section>
  );
};

export default withDatasourceCheck()<IntroBlockImageProps>(IntroBlockImage);
