import { DotProps } from 'react-multi-carousel';

function InAppCarouselDots(props: DotProps): JSX.Element {
  return (
    <button
      className={`cursor-pointer inline-block w-3.5 h-3.5 mr-1.5 ml-1.5
                            rounded-full opacity-75 ${props.active ? `bg-secondary` : 'bg-white'}`}
      onClick={(): void => {
        if (props.onClick != null) {
          props.onClick();
        }
      }}
    ></button>
  );
}

export default InAppCarouselDots;
