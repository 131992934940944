import ResultImage from 'components/atomic-components/ResultImage';
import React from 'react';
import { CoveoRecommendedProductDetails } from './coveo-recommendations';

const CoveoProductImage: React.FC<{ product: CoveoRecommendedProductDetails }> = ({ product }) => {
  const { externalProductImageThumbnailUrl, productimageThumbnail, productName } = product;

  const imageSrc =
    externalProductImageThumbnailUrl ||
    (!productimageThumbnail
      ? 'https://cdn.avid.com/avidcom/images/search/default-product-search-image.jpg'
      : productimageThumbnail);

  return (
    <ResultImage
      src={imageSrc}
      alt={productName}
      className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
    />
  );
};

export default CoveoProductImage;
