import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const TwoColumnVerticalContainer = (props: ComponentProps): JSX.Element => {
  return (
    <div className="grid grid-cols-1 px-5 lg:gap-x-24 py-16 lg:grid-cols-2">
      <div>
        <Placeholder name="jss-left" rendering={props.rendering} />
      </div>
      <div className="pt-6 lg:pt-0">
        <Placeholder name="jss-right" rendering={props.rendering} />
      </div>
    </div>
  );
};

export default TwoColumnVerticalContainer;
