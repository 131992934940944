import { absoluteMediaUrl } from 'lib/utils/sitecore-media-utils';

type ResultImageProps = {
  alt: string;
  src: string;
  onClick?: () => void;
  className?: string;
};

const ResultImage: React.FC<ResultImageProps> = (props) => {
  const imageSrc = absoluteMediaUrl(props.src);
  return <img src={imageSrc} alt={props.alt} onClick={props.onClick} className={props.className} />;
};

export default ResultImage;
