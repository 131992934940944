import { dataLayerPush } from './data-layer-orchestrator';

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const pushCoveoSearchEvent = (coveoAnalyticsEventData: any): void => {
  dataLayerPush({
    dataLayer: {
      event: 'CoveoSearchEvent',
      coveoAnalyticsEventData,
    },
  });
};
