import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { AttributeDetails } from './table-models';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type AttributeDetailsSectionProps = {
  attributeDetails: AttributeDetails;
};

const AttributeDetailsSection = (props: AttributeDetailsSectionProps): JSX.Element => {
  const { attributeDetails } = props;
  return (
    <div className="flex flex-row flex-wrap" role="row">
      <div
        className="w-full lg:flex-1 text-center flex items-center justify-center lg:justify-end p-2 lg:p-3 border border-neutral-lighter bg-base-lightest lg:bg-transparent font-bold"
        role="rowheader"
      >
        {attributeDetails.attribute.fields.title.value}
      </div>
      {attributeDetails.details.map((details, index) => {
        if (details.type == 'text') {
          if (details.detail?.value?.length == 0) {
            return (
              <div
                className="flex-1 flex items-center justify-center text-center p-3 border border-neutral-lighter"
                role="cell"
                key={index}
              >
                --
              </div>
            );
          }
          return (
            <div
              className="flex-1 flex items-center justify-center text-center p-3 border border-neutral-lighter"
              role="cell"
              key={index}
            >
              <RichText className="rte [&>a]:underline [&>a]:text-primary" field={details.detail} />
            </div>
          );
        }

        return (
          <div
            className={`flex-1 flex items-center justify-center text-center px-3 py-2 border border-neutral-lighter text-2xl ${
              details.color == 'primary' ? 'text-avidpurple' : 'text-base-dark'
            }`}
            role="cell"
            key={index}
          >
            {details.icon == 'checkmark' && <FontAwesomeIcon icon={faCheck} />}
            {details.icon == 'x' && <FontAwesomeIcon icon={faClose} />}
          </div>
        );
      })}
    </div>
  );
};

export default AttributeDetailsSection;
