import React, { useEffect } from 'react';
import Head from 'next/head';
import { Placeholder, LayoutServiceData, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  gtWalshiemAvidRegular,
  gtWalshiemAvidLight,
  gtWalshiemAvidMedium,
  gtWalshiemAvidBold,
  gtWalshiemAvidRegularOblique,
} from 'lib/fonts/font-loader';

interface InAppLayoutProps {
  layoutData: LayoutServiceData;
}

const InAppLayout = ({ layoutData }: InAppLayoutProps): JSX.Element => {
  const { route } = layoutData.sitecore;

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT !== undefined &&
      process.env.NEXT_PUBLIC_CLIENT_APM_DISABLED !== 'true'
    ) {
      const apmInit = async (): Promise<void> => {
        const apm = await import('@elastic/apm-rum');
        apm.init({
          serviceName: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME,
          serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
          environment: process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT,
          transactionSampleRate: process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            ? +process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            : 1,
        });
      };

      apmInit();
    }
  });

  return (
    <>
      <Head>
        <title>{((route?.fields?.pageTitle as Field)?.value as string) || 'Page'}</title>
        <meta charSet="UTF-8" />
      </Head>
      <div
        className={`flex flex-col justify-between relative ${gtWalshiemAvidRegular.variable} ${gtWalshiemAvidLight.variable} ${gtWalshiemAvidMedium.variable} ${gtWalshiemAvidBold.variable} ${gtWalshiemAvidRegularOblique.variable}`}
      >
        <main className="mb-auto min-h-[100vh]">
          {route && <Placeholder name="jss-main" rendering={route} />}
        </main>
      </div>
    </>
  );
};

export default InAppLayout;
