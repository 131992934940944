import { ComponentProps } from 'lib/component-props';
import {
  Field,
  PlaceholderComponentProps,
  Text,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type IconGridProps = ComponentProps &
  PlaceholderComponentProps & {
    fields: {
      heading: Field<string>;
      anchorLinkId: Field<string>;
    };
    iconGridItems: JSX.Element;
  };

const Default = (props: IconGridProps): JSX.Element => {
  if (props.fields?.heading == null) {
    return <div>Select Datasource</div>;
  }

  return (
    <div
      className="my-10 px-6 lg:my-20 lg:px-8 xl:px-0 max-w-content mx-auto"
      {...(props?.fields?.anchorLinkId?.value ? { id: props?.fields?.anchorLinkId?.value } : {})}
    >
      <Text
        tag="h2"
        field={props.fields.heading}
        className="text-almost-black text-center uppercase tracking-[0.28em]"
      />
      <ul className="w-fit mx-auto flex flex-wrap flex-col lg:w-full lg:flex-row lg:justify-around">
        {props.iconGridItems}
      </ul>
    </div>
  );
};

export default withPlaceholder({ placeholder: 'jss-icon-grid', prop: 'iconGridItems' })(Default);
