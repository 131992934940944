import Carousel from 'components/carousel/Carousel';
import { Variant } from 'lib/commerce/product/model/product-models';
import MultiProductTableCarouselButtonGroup from './MultiProductTableCarouselButtonGroup';
import { PropsWithChildren } from 'react';

type MultiProductTableCarouselProps = {
  variants: Array<Variant>;
  children?: JSX.Element | JSX.Element[] | boolean;
};

const MultiProductTableCarousel = (
  props: PropsWithChildren<MultiProductTableCarouselProps>
): JSX.Element => {
  return (
    <>
      <div className="multi-product-table-carousel hidden relative mx-auto xl:max-w-content md:block">
        <Carousel
          MaxItemsPerSlideOnDesktop={props.variants.length < 5 ? '4' : '3'}
          MaxItemsPerSlideOnTablet={props.variants.length === 2 ? '2' : '1'}
          MaxItemsPerSlideOnMobile={'1'}
          desktopSize={1199}
          tabletSize={767}
          ssgDeviceType="desktop"
          buttonGroup={<MultiProductTableCarouselButtonGroup />}
          carouselWrapperClass={`mx-auto sm:max-w-[350px] xl:max-w-none 
                  ${props.variants.length === 2 && 'md:max-w-[800px]'} `}
          sliderClass={`flex flex-grow
                  ${
                    props.variants.length < 5
                      ? '[&>li:first-child>div]:border-l-1 [&>li:first-child>div]:rounded-l [&>li:last-child>div]:rounded-r xl:[&>li:not(:first-child)>div]:border-l-0'
                      : 'xl:[&>li>div]:mr-8 xl:[&>li:last-child]:pr-4 xl:!p-4'
                  }
                  ${
                    props.variants.length === 2 &&
                    'md:max-w-[800px] md:[&>li:first-child>div]:ml-auto md:[&>li:not(:first-child)>div]:border-l-0'
                  }`}
        >
          {props.children}
        </Carousel>
      </div>
      <div className="multi-product-table-carousel relative mx-auto xl:max-w-content md:hidden">
        <Carousel
          MaxItemsPerSlideOnDesktop={props.variants.length < 5 ? '4' : '3'}
          MaxItemsPerSlideOnTablet={props.variants.length === 2 ? '2' : '1'}
          MaxItemsPerSlideOnMobile={'1'}
          desktopSize={1199}
          tabletSize={767}
          ssgDeviceType="mobile"
          buttonGroup={<MultiProductTableCarouselButtonGroup />}
          carouselWrapperClass={`mx-auto sm:max-w-[350px] xl:max-w-none 
                  ${props.variants.length === 2 && 'md:max-w-[800px]'} `}
          sliderClass={`flex flex-grow
                  ${
                    props.variants.length < 5
                      ? '[&>li:first-child>div]:border-l-1 [&>li:first-child>div]:rounded-l [&>li:last-child>div]:rounded-r xl:[&>li:not(:first-child)>div]:border-l-0'
                      : 'xl:[&>li>div]:mr-8 xl:[&>li:last-child]:pr-4 xl:!p-4'
                  }
                  ${
                    props.variants.length === 2 &&
                    'md:max-w-[800px] md:[&>li:first-child>div]:ml-auto md:[&>li:not(:first-child)>div]:border-l-0'
                  }`}
        >
          {props.children}
        </Carousel>
      </div>
    </>
  );
};

export default MultiProductTableCarousel;
