import { RouteData } from '@sitecore-jss/sitecore-jss-nextjs';
import DefaultLayout from 'src/layouts/DefaultLayout';
import MarketplaceLayout from 'src/layouts/MarketplaceLayout';
import InAppLayout from 'src/layouts/InAppLayout';

const layoutMap = new Map();
layoutMap.set('{FF90B506-2D35-4984-8CF3-FF778800F6F9}', MarketplaceLayout);
layoutMap.set('{535DDF14-B4D9-476A-920D-3F66119FF97F}', InAppLayout);
layoutMap.set('default', DefaultLayout);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function resolveLayout(routeData: RouteData) {
  const layoutId = `{${routeData?.layoutId?.toUpperCase()}}`;
  const layout = layoutMap.get(layoutId);
  return layout || layoutMap.get('default');
}
