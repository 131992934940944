import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

function BannerContainer(props: ComponentProps): JSX.Element {
  const phName = 'banner-container';
  return (
    <div className="w-full">
      <Placeholder name={phName} rendering={props.rendering} />
    </div>
  );
}
export default BannerContainer;
