import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { BaseIconGridItemProps } from './BaseIconGridItem';
import BaseIconGridItem from './BaseIconGridItem';

type IconGridButtonItemProps = BaseIconGridItemProps & {
  fields: {
    primaryLink: LinkField;
    secondaryLink: LinkField;
    noFollow?: Field<boolean>;
  };
};

const Default = (props: IconGridButtonItemProps): JSX.Element => {
  return (
    <BaseIconGridItem {...props}>
      <Link
        field={props.fields?.primaryLink}
        linkCustomizations={{ noFollow: props.fields.noFollow }}
        className="items-center btn-primary after:hidden focus-visible:outline-none focus-visible:ring-0"
      />
    </BaseIconGridItem>
  );
};

export default withDatasourceCheck()<IconGridButtonItemProps>(Default);
