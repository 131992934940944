import { DotProps } from 'react-multi-carousel';

function MarketplaceCarouselDots(props: DotProps): JSX.Element {
  return (
    <button
      className={`w-3 h-3 border-2 rounded-full border-mp-txt-neutralLight flex items-center justify-center mr-5 ${
        props.active ? `bg-mp-txt-neutralLight shadow-mp_dot_outline` : ''
      } hover:bg-mp-txt-neutralLight hover:shadow-mp_dot_outline`}
      onClick={(): void => {
        if (props.onClick != null) {
          props.onClick();
        }
      }}
    ></button>
  );
}

export default MarketplaceCarouselDots;
