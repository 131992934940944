import { withPlaceholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import AccordionTabList from '../../components/accordion/AccordionTabList';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

type AccordionProps = ComponentProps & {
  name: string;
  accordiontabs: JSX.Element;
};
const AccordionContainer = (props: AccordionProps): JSX.Element => {
  const editorActive = isEditorActive();
  return (
    <>
      <div className={editorActive ? '' : 'hidden'}>{props?.accordiontabs}</div>
      {!editorActive && <AccordionTabList>{props?.accordiontabs}</AccordionTabList>}
    </>
  );
};

export default withPlaceholder({ placeholder: 'jss-accordion-container', prop: 'accordiontabs' })(
  AccordionContainer
);
