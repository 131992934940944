const cadDefaultCountries = ['CA'];
const jpyDefaultCountries = ['JP'];
const gbpDefaultCountries = ['GB'];
const usdDefaultCountries = [
  'TO',
  'BS',
  'PK',
  'TW',
  'VE',
  'VN',
  'CL',
  'CN',
  'NP',
  'CO',
  'MM',
  'GF',
  'PF',
  'NC',
  'CR',
  'NZ',
  'NI',
  'DO',
  'EC',
  'SV',
  'GP',
  'GT',
  'PA',
  'PY',
  'PE',
  'GY',
  'HT',
  'HN',
  'PY',
  'HK',
  'IN',
  'PR',
  'KR',
  'ID',
  'LC',
  'AR',
  'AU',
  'BB',
  'JM',
  'SG',
  'LA',
  'LK',
  'SR',
  'BZ',
  'TH',
  'BO',
  'MO',
  'BR',
  'MY',
  'MV',
  'BN',
  'MQ',
  'US',
  'UY',
  'VI',
  'KH',
  'MX',
  'MN',
  'TT',
  'GU',
  'BD',
  'BM',
];
const eurDefaultCountries = [
  'EE',
  'CD',
  'ST',
  'MK',
  'RS',
  'TZ',
  'EH',
  'ZM',
  'CF',
  'TD',
  'ER',
  'EF',
  'ZW',
  'KM',
  'ET',
  'FI',
  'MA',
  'MZ',
  'NA',
  'FR',
  'GA',
  'NL',
  'HR',
  'CZ',
  'DK',
  'GM',
  'GE',
  'DJ',
  'CY',
  'EG',
  'GL',
  'DE',
  'GH',
  'NE',
  'NG',
  'GR',
  'YE',
  'NO',
  'GN',
  'OM',
  'GQ',
  'GW',
  'PL',
  'HU',
  'IS',
  'PT',
  'QA',
  'RO',
  'AL',
  'DZ',
  'IQ',
  'IE',
  'IL',
  'RU',
  'RW',
  'SA',
  'AD',
  'AO',
  'AM',
  'AT',
  'BH',
  'IT',
  'CI',
  'SN',
  'SC',
  'SL',
  'JO',
  'KZ',
  'KE',
  'KW',
  'SI',
  'KG',
  'SK',
  'SO',
  'ZA',
  'ES',
  'LV',
  'LB',
  'LS',
  'LR',
  'LI',
  'SZ',
  'SE',
  'CH',
  'BY',
  'BE',
  'TG',
  'BJ',
  'LT',
  'LU',
  'LY',
  'BA',
  'BW',
  'MG',
  'MW',
  'TN',
  'TR',
  'UG',
  'UA',
  'BG',
  'BF',
  'BI',
  'ML',
  'MT',
  'MR',
  'AE',
  'CM',
  'CV',
  'MU',
  'MC',
  'CG',
  'MD',
  'FO',
  'ME',
  'GI',
  'RE',
  'SM',
];

const currencyToCountries = [
  { currency: 'CAD', countries: cadDefaultCountries },
  { currency: 'JPY', countries: jpyDefaultCountries },
  { currency: 'GBP', countries: gbpDefaultCountries },
  { currency: 'USD', countries: usdDefaultCountries },
  { currency: 'EUR', countries: eurDefaultCountries },
];

export const getCountryCurrency = (country: string): string => {
  for (let i = 0; i < currencyToCountries.length; i++) {
    for (let j = 0; j < currencyToCountries[i].countries.length; j++) {
      if (currencyToCountries[i].countries[j] == country) {
        return currencyToCountries[i].currency;
      }
    }
  }
  return '';
};

const availableLanguages: { [id: string]: string } = {};
availableLanguages['FR'] = 'FR';
availableLanguages['ES'] = 'ES';
availableLanguages['DE'] = 'DE';
availableLanguages['CN'] = 'ZH';
availableLanguages['JP'] = 'JP';

export const getCountryLanguage = (country: string): string => {
  return availableLanguages[country] ? availableLanguages[country] : 'EN';
};
