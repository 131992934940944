import { ProductRecommendation } from '@coveo/headless/dist/definitions/product-listing.index';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { Price } from 'lib/commerce/product/model/product-models';
import { ComponentProps } from 'lib/component-props';

export type CoveoRecommendationsProps = ComponentProps & {
  fields: {
    title: Field<string>;
    description: Field<string>;
    maxResultsToShow: Field<number>;
    fetchPriceFromSalesforce: Field<boolean>;
  };
};

export type CoveoRecommendedProductDetails = {
  productId: string;
  externalProductImageThumbnailUrl?: string;
  productimageThumbnail?: string;
  productUrl: string;
  productName: string;
  productSku: string;
  primaryCategory: string;
  additionalCategory1: string;
  additionalCategory2: string;
  additionalCategory3: string;
  price: CoveoPrice;
  priceSF?: Price;
};

export const mapRecommendationsToProducts = (
  recommendations: ProductRecommendation[],
  currencyIsoCode: string
): CoveoRecommendedProductDetails[] => {
  return recommendations.map((recommendation) => {
    return {
      productName: recommendation.additionalFields.title,
      productUrl: recommendation.additionalFields.avid_pdp_link,
      productId: recommendation.permanentid,
      productSku: recommendation.additionalFields.avid_sku,
      price: GetPriceByCurrency(recommendation, currencyIsoCode),
      additionalCategory1: '',
      additionalCategory2: '',
      additionalCategory3: '',
      externalProductImageThumbnailUrl:
        recommendation.additionalFields.avid_ext_product_image_thumbnail_url,
      productimageThumbnail: recommendation.additionalFields.ec_thumbnails,
    };
  }) as CoveoRecommendedProductDetails[];
};

export type CoveoPrice = {
  price: number;
  listPrice: number;
};

export const GetPriceByCurrency = (
  product: ProductRecommendation,
  currencyCode: string
): CoveoPrice => {
  switch (currencyCode.toUpperCase()) {
    case 'USD':
      return {
        price: product.additionalFields.avid_usd_price as number,
        listPrice: product.additionalFields.avid_usd_listprice as number,
      };
    case 'EUR':
      return {
        price: product.additionalFields.avid_eur_price as number,
        listPrice: product.additionalFields.avid_eur_listprice as number,
      };
    case 'GBP':
      return {
        price: product.additionalFields.avid_gbp_price as number,
        listPrice: product.additionalFields.avid_gbp_listprice as number,
      };
    case 'CAD':
      return {
        price: product.additionalFields.avid_cad_price as number,
        listPrice: product.additionalFields.avid_cad_listprice as number,
      };
    case 'JPY':
      return {
        price: product.additionalFields.avid_jpy_price as number,
        listPrice: product.additionalFields.avid_jpy_listprice as number,
      };
    default:
      return { price: 0, listPrice: 0 }; // Fallback for unsupported currencies
  }
};
