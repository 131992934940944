import { RichText, resetEditorChromes } from '@sitecore-jss/sitecore-jss-nextjs';
import { ProductsInCategoryProps } from './product-category-listing-models';
import { useEffect } from 'react';
import ProductItem from './ProductItem';

const ProductsInCategory = (props: ProductsInCategoryProps): JSX.Element => {
  useEffect(() => {
    resetEditorChromes();
  }, []);

  return props.products && props.products.length > 0 ? (
    <>
      <section className="mb-10 text-center sm:text-left">
        <RichText
          tag="h2"
          field={props.productCategoryTitle}
          className="rte text-almost-black leading-9 uppercase mb-4 tracking-widest-plus"
        />

        <RichText
          tag="p"
          field={props.productCategoryDescription}
          className="rte text-almost-black"
        />
      </section>

      <ul className="grid grid-cols-1 2xl:grid-cols-2 gap-6">
        {props.products?.map((product, index) => (
          <li key={index} className="cols-span-1">
            <ProductItem key={index} {...product} />
          </li>
        ))}
      </ul>
    </>
  ) : (
    <></>
  );
};

export default ProductsInCategory;
