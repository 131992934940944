import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { getStoredCountry } from 'lib/commerce/countries/countries-provider';
import { useEffect, useState } from 'react';
import { AlertTextProps } from './AlertText';

import dynamic from 'next/dynamic';
const AlertText = dynamic(() => import('./AlertText'));

type GeoRestrictedAlertTextProps = AlertTextProps & {
  fields: {
    visibleForCountry: Field<string>;
  };
};

const LocalizedContent = (props: GeoRestrictedAlertTextProps): JSX.Element => {
  const [visible, setIsVisible] = useState(false);
  const isEditing = isEditorActive();
  useEffect(() => {
    const country = getStoredCountry();
    if (country == null) {
      return;
    }

    if (country != props.fields?.visibleForCountry?.value) {
      return;
    }

    setIsVisible(true);
  }, [props.fields]);

  if (props.fields?.visibleForCountry?.value == null) {
    return <></>;
  }

  if (!visible && !isEditing) {
    return <></>;
  }
  return (
    <div className={`${isEditing ? 'border-2 border-dashed' : ''}`}>
      {isEditing && (
        <div>
          This will only display for users whose geo location country is:
          {props.fields.visibleForCountry.value}
        </div>
      )}
      <AlertText {...props} />
    </div>
  );
};

export default LocalizedContent;
