/* eslint-disable @typescript-eslint/no-explicit-any */

export type CoveoArticleSearchResponseModel = {
  articleLink: string;

  articleTitle: string;

  articleDescripttion: string;

  articleDate: string;
};

export const getArticles = async (
  productName: string,

  language: string
): Promise<CoveoArticleSearchResponseModel[]> => {
  const coveoEndpoint = `${process.env.COVEO_CLOUD_PLATFORM_URL}/${process.env.COVEO_SEARCH_ENDPOINT}avidtechnologyinc`;
  const coveoAPIKey = process.env.COVEO_ACCESS_TOKEN as string;
  const articleproductCategory = getProductCategory(productName);
  const fullLang = getFullLanguage(language);
  const facetsData = [{}];
  const cachKey = 'coveo-articles-support';
  const cacheTime: number = 86400000;
  const cachedArticles = getArticlesFromCache(cachKey, cacheTime);

  if (cachedArticles) return cachedArticles;
  if (language !== '') {
    facetsData.pop();
    facetsData.push({
      facetId: '@commonlanguage',

      field: 'commonlanguage',

      type: 'specific',

      injectionDepth: 1000,

      filterFacetCount: true,

      currentValues: [
        {
          value: fullLang,

          state: 'selected',

          preventAutoSelect: false,
        },
      ],

      numberOfValues: 8,
      freezeCurrentValues: false,
      preventAutoSelect: false,
      isFieldExpanded: false,
    });
  }

  const query = {
    referrer: 'https://www.avid.com/',

    visitorId: '',

    q: '',

    cq: articleproductCategory,

    searchHub: 'AvidPublicSearch',

    tab: 'KB',

    locale: 'en',

    firstResult: '0',

    numberOfResults: 3,

    excerptLength: '200',

    filterField: '@foldingcollection',

    filterFieldRange: 5,

    parentField: '@foldingchild',

    childField: '@foldingparent',

    enableDidYouMean: true,

    sortCriteria: 'date descending',

    queryFunctions: [],

    rankingFunctions: [],

    facets: facetsData,

    facetOptions: {},

    categoryFacets: [],

    retrieveFirstSentences: true,

    enableQuerySyntax: false,

    enableDuplicateFiltering: false,

    enableCollaborativeRating: false,

    debug: false,

    context: { hostname: 'www.avid.com' },

    allowQueriesWithoutKeywords: true,

    dictionaryFieldContext: {
      pricedictionary: 'USD',

      pricedecimaldictionary: 'USD',

      priceconverteddictionary: 'USD',

      productnamedictionary: 'en_US',

      shortdeschtmlstrippeddictionary: 'en_US',

      shortdescdictionary: 'en_US',
    },
  };

  const response = await fetch(coveoEndpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${coveoAPIKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  const responseData = await response.json();
  const customArrayModel = processResponseData(responseData);
  setArticlesInCache(cachKey, customArrayModel);
  return customArrayModel;
};

const getProductCategory = (productName: string): string => {
  const productNameLowerCase = productName ? productName.toLowerCase().replace(/\s+/g, '') : '';

  switch (productNameLowerCase) {
    case 'protools':
      return '@sfkbid AND (@sfdatacategoryproduct_level_1="Artist Suite - (DAW)")';

    case 'sibelius':
      return '@sfkbid AND (@sfdatacategoryproduct_level_1="Artist Suite – Notation & Scoring")';

    case 'mediacomposer':
      return '@sfkbid AND (@sfdatacategoryproduct_level_1="Artist Suite – Video NLE")';

    default:
      return '@sfkbid AND (@sfdatacategoryproduct_level_1="Artist Suite – Video NLE" OR @sfdatacategoryproduct_level_1="Artist Suite – Notation & Scoring" OR @sfdatacategoryproduct_level_1="Artist Suite - (DAW)")';
  }
};

const getFullLanguage = (language: string): string => {
  switch (language) {
    case 'en':

    case 'en-En':

    case 'zh':

    case 'zh-CN':
      return 'English';

    case 'fr':

    case 'fr-FR':
      return 'France';

    case 'de':

    case 'de-DE':
      return 'German';

    case 'ja':

    case 'ja-JP':
      return 'Japanese';

    case 'es':

    case 'es-ES':
      return 'Spanish';

    default:
      return '';
  }
};

const processResponseData = (data: any): CoveoArticleSearchResponseModel[] => {
  if (Array.isArray(data.results)) {
    return data.results.map((result: any) => ({
      articleLink: getCoveoUrl(result.parentResult),

      articleTitle: result.parentResult.raw.systitle,

      articleDescripttion: result.parentResult.excerpt,

      articleDate: convertUTCDateToLocalDate(new Date(result.parentResult.raw.date)).toLocaleString(
        [],

        { year: 'numeric', month: 'numeric', day: 'numeric' }
      ),
    }));
  } else {
    return [];
  }
};

const getCoveoUrl = (result: any): string => {
  if (result.raw.sfisvisibleinpkb.toLowerCase() == 'true') {
    const re = /articletype:([^\/]*).*language:([^\/]*)/gi;

    let m;

    let articletype;

    let language;

    while ((m = re.exec(result.uri)) !== null) {
      if (m.index === re.lastIndex) {
        re.lastIndex++;
      }

      articletype = m[1] ? m[1] : '';

      language = m[2] ? m[2] : '';
    }

    result.clickUri = result.ClickUri =
      'http://avid.force.com/pkb/articles/' +
      language +
      '/' +
      articletype +
      '/' +
      result.raw.sfurlname;
  } else if (result.raw.objecttype == 'ACECollectionC') {
    result.clickUri = result.ClickUri =
      'ACE_ArtistLibrary?collectionId=' + result.raw.sfacecollectioncid + '&id=' + result.raw.sfid;
  } else if (result.raw.objecttype == 'ACETrackC') {
    result.clickUri = result.ClickUri =
      'ACE_ArtistLibrary?trackid=' +
      result.raw.sfacetrackcid +
      '&collectionId=' +
      result.raw.sfcollectionid +
      '&id=' +
      result.raw.sfid +
      '&trackDetail=true';
  } else if (result.raw.objecttype == 'User') {
    result.clickUri = result.ClickUri = 'ACEProfile?uid=' + result.raw.sfid;
  }

  return result.clickUri;
};

const convertUTCDateToLocalDate = (date: any): Date => {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate;
};

const getArticlesFromCache = (
  cacheKey: string,
  duration: number
): CoveoArticleSearchResponseModel[] | null => {
  const cachedData = localStorage.getItem(cacheKey);
  const cachedTime = localStorage.getItem(`${cacheKey}_time`);

  if (cachedData && cachedTime) {
    const age = Date.now() - parseInt(cachedTime, 10);
    if (age < duration) {
      return JSON.parse(cachedData) as CoveoArticleSearchResponseModel[];
    }
  }
  return null;
};

const setArticlesInCache = (
  cacheKey: string,
  caheData: CoveoArticleSearchResponseModel[]
): void => {
  localStorage.setItem(cacheKey, JSON.stringify(caheData));
  localStorage.setItem(`${cacheKey}_time`, Date.now().toString());
};
