import { useI18n } from 'next-localization';
import { useEffect, useRef } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ModalProps = {
  children?: JSX.Element;
  title?: string;
  onOkAction: () => void;
  onCloseAction: () => void;
};

const MarketplaceModal = ({
  title,
  children,
  onOkAction,
  onCloseAction,
}: ModalProps): JSX.Element => {
  const { t } = useI18n();
  const scollToRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (scollToRef.current) {
      scollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });

  return (
    <>
      <div className="marketplace-modal absolute top-0 left-0 right-0 bottom-0 bg-avid-backdrop opacity-50 z-50"></div>
      <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-full max-w-lg px-3 z-[55]">
        <div
          ref={scollToRef}
          className="flex justify-between bg-mp-background-primary text-white uppercase py-2 pl-5 pr-2"
        >
          <h4>{title}</h4>
          <div>
            <button
              type="button"
              onClick={(): void => {
                onCloseAction();
              }}
            >
              <FontAwesomeIcon icon={faXmark} className="text-white w-6" />
              <span className="sr-only">{t('Close')}</span>
            </button>
          </div>
        </div>
        <div className="bg-mp-background-card text-white px-5">
          <div className="py-5">{children}</div>
          <div className="py-5 w-full text-center">
            <button
              className="border-2 border-mp-txt-primary text-mp-txt-primary rounded w-1/4 hover:bg-mp-background-primary hover:text-white py-1.5 text-center uppercase inline-block mr-5"
              onClick={(): void => {
                onOkAction();
              }}
            >
              {t('mp_ok')}
            </button>
            <button
              className="border-2 border-mp-txt-primary text-mp-txt-primary rounded w-1/4 hover:bg-mp-background-primary hover:text-white py-1.5 text-center uppercase inline-block"
              onClick={(): void => {
                onCloseAction();
              }}
            >
              {t('mp_cancel')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketplaceModal;
