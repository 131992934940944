import { SsrErrorsObject } from 'lib/component-props';
import { useEffect } from 'react';
import { sendElasticSSRLogs } from 'src/opentelemetry/elastic-rum-config';

export const useSSRErrorsHandler = (ssrErrors: SsrErrorsObject | undefined): void => {
  useEffect(() => {
    if (ssrErrors === undefined || ssrErrors.errorList.length < 1) {
      return;
    }
    ssrErrors.page = window?.location.href;
    sendElasticSSRLogs(ssrErrors);
  }, [ssrErrors]);
};
