import NextLink from 'components/foundation/non-sitecore/NextLink';
import { useI18n } from 'next-localization';
import { Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { ProductSet } from './pricing-card-subscription-response';

type SubscriptionPricingCardsContentProps = ComponentProps & {
  productSet?: ProductSet;
  linkColorClass: string;
};

const SubscriptionPricingCardsContent = (
  props: SubscriptionPricingCardsContentProps
): JSX.Element => {
  const { t } = useI18n();
  const { productSet, linkColorClass } = props;

  if (productSet == null) {
    return <div>Product set is null</div>;
  }

  return (
    <>
      <div className="text-center small-text mt-6 md:mt-4">
        <div>
          {`${t('SubscriptionPricingCard_AuthorizedReseller')} `}
          <NextLink
            field={productSet.resellerLink.jsonValue}
            className={`underline ${linkColorClass}`}
          />
        </div>
        {productSet.academicEligibilityLink.jsonValue.value.href && (
          <div className="mt-2">
            {`${t('SubscriptionPricingCard_AcademicEligibility')} `}
            <NextLink
              field={productSet.academicEligibilityLink.jsonValue}
              className={`underline ${linkColorClass}`}
            />
          </div>
        )}
      </div>

      <Placeholder name="subscription-pricing-cards-content" rendering={props.rendering} />

      <div className="rte px-6 mx-auto my-16 md:my-20  xl:px-0 xl:max-w-[794px]">
        {productSet.textSections.targetItems.map((textSection, index) => {
          return (
            <div key={index}>
              <div>
                <Text tag="h3" className="mb-2" field={textSection.heading} />
                <Text tag="p" className="mb-1 intro-text" field={textSection?.subheading} />
                <div>
                  <RichText
                    className="body [&>ul]:list-disc [&>ul]:ml-4 [&>ul>li]:marker:text-xs [&>ul]:flex [&>ul]:flex-col [&>ul]:space-y-1"
                    field={textSection.description}
                  />
                </div>
              </div>
              {productSet.textSections.targetItems.length !== index + 1 && (
                <div className="w-full h-[1px] bg-base-dark my-10" />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SubscriptionPricingCardsContent;
