import { useState } from 'react';
import AccordionTab from '../../renderings/accordion/AccordionTab';

type AccordionListProps = {
  children?: JSX.Element | JSX.Element[] | boolean;
};
const AccordionTabList = (props: AccordionListProps): JSX.Element => {
  const accordionList = props.children as JSX.Element[];
  const [activeTab, setActiveTab] = useState(-1);
  const accordionHandler = (index: number): void => {
    index == activeTab ? setActiveTab(-1) : setActiveTab(index);
  };

  return (
    <ul role="accordionList" className="">
      {accordionList &&
        accordionList.map((tab, idx) => (
          <AccordionTab
            {...tab.props}
            key={idx}
            isactive={idx == activeTab}
            index={idx}
            onAccordionSelection={accordionHandler}
          />
        ))}
    </ul>
  );
};

export default AccordionTabList;
