import { Text, Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import MarketplaceOptionalAddOnsList from 'components/optional-add-ons/marketplace/MarketplaceOptionalAddOnsList';
import Carousel from 'components/carousel/Carousel';
import MarketplaceCarouselDots from 'components/carousel/marketplace/MarketplaceCarouselDots';
import { useCartData } from 'components/cart/cart-hooks';

export type TrendingProduct = {
  url: {
    url: string;
  };
  searchImage: {
    jsonValue: ImageField;
  };
  searchTitle: Field<string>;
  searchDescription: Field<string>;
  company: {
    targetItem: {
      termName: Field<string>;
    };
  };
  parentProduct: {
    targetItems: [
      {
        productId: Field<string>;
      },
    ];
  };
};

export type MarketplaceOptionalAddOnsProps = {
  fields: {
    data: {
      datasource: {
        title: Field<string>;
        learnMoreText: Field<string>;
        addonProducts: {
          targetItems: Array<TrendingProduct>;
        };
      };
    };
  };
};

const MarketplaceOptionalAddOns = (props: MarketplaceOptionalAddOnsProps): JSX.Element => {
  const cartItemData = useCartData();
  const cartItemsIds = cartItemData?.cartItems.map((item): string => item.cartItem?.productId);

  return (
    <div id="marketplace--cart-addons" className="my-12">
      <div className="flex flex-row justify-between m-auto mt-10 mb-1 relative text-center">
        <span className="block w-full text-xl font-light text-mp-txt-neutralLight">
          <Text field={props?.fields?.data?.datasource?.title} />
        </span>
      </div>
      <div className="relative m-auto after:clear-both whitespace-nowrap border rounded border-mp-txt-neutralLight py-5">
        <Carousel
          MaxItemsPerSlideOnDesktop="3"
          MaxItemsPerSlideOnTablet="2"
          MaxItemsPerSlideOnMobile="1"
          buttonGroup={<></>}
          customDotsGroup={<MarketplaceCarouselDots />}
          carouselWrapperClass="pb-7"
        >
          {props.fields.data.datasource.addonProducts?.targetItems?.map((product, index) => {
            return (
              <MarketplaceOptionalAddOnsList
                key={index}
                index={index}
                companyName={product.company.targetItem?.termName}
                searchDescription={product.searchDescription}
                searchTitle={product.searchTitle}
                searchImage={product.searchImage.jsonValue}
                url={product.url.url}
                parentProductId={product.parentProduct?.targetItems[0]?.productId}
                cartItemsIds={cartItemsIds}
              ></MarketplaceOptionalAddOnsList>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
export default MarketplaceOptionalAddOns;
