import {
  Field,
  GetStaticComponentProps,
  ImageField,
  LinkField,
  isEditorActive,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { VideoManualCardVideo } from '../../components/card-carousel/VideoHeader';
import { ManuCardResponse } from '../../components/card-carousel/manul-card-response';
import { GraphQLClient } from 'lib/common/graphql-client';
import {
  BrightcoveVideoItem,
  ManualCardBrightcoveVideoResponse,
  YoutubeVideoItem,
} from '../../components/card-carousel/manual-card-brightcove-video-response';
import getManualCardBrightcoveVideoQuery from '../../components/card-carousel/manual-card-brightcove-video-query';
import getManualCardQuery from '../../components/card-carousel/manual-card-query';

import dynamic from 'next/dynamic';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';
const ImageHeader = dynamic(() => import('../../components/card-carousel/ImageHeader'));
const IconHeader = dynamic(() => import('../../components/card-carousel/IconHeader'));
const VideoHeader = dynamic(() => import('../../components/card-carousel/VideoHeader'));
const StandardManualCard = dynamic(
  () => import('../../components/card-carousel/StandardManualCard')
);
const FeatureManualCard = dynamic(() => import('../../components/card-carousel/FeatureManualCard'));

export type CardCarouselManualCardProps = ComponentProps & {
  fields: {
    image?: ImageField;
    icon?: Field<string>;
    videos?: VideoManualCardVideo[];
    title: Field<string>;
    categorySubheading: Field<string>;
    description: Field<string>;
    link: LinkField;
    noFollow?: Field<boolean>;
    byPassNextJsImageOptimization?: Field<boolean>;
    imageCDNUrl?: Field<string>;
    anchorLinkId: Field<string>;
  };
  params: {
    Style: string; //comes from parent container
  };
  contentType?: string;
};

const CardCarouselManualCard = (props: CardCarouselManualCardProps): JSX.Element => {
  useSSRErrorsHandler(props.ssrErrors);
  const editorActive = isEditorActive();
  const hasCategorySubheader =
    props?.fields?.categorySubheading?.value != null &&
    props.fields.categorySubheading.value.length > 0;

  if (props == null) {
    if (editorActive) {
      return <div className="editor-message">Please select a valid datasource</div>;
    }
    return <></>;
  }

  const headerComponent = (cardType: string): JSX.Element | null => {
    switch (props?.contentType?.toLowerCase()) {
      case 'image':
        return props.fields.image ? (
          <ImageHeader
            image={props.fields.image}
            byPassNextJsImageOptimization={props.fields?.byPassNextJsImageOptimization}
            imageCDNUrl={props.fields?.imageCDNUrl}
            cardtype={cardType}
          />
        ) : null;
      case 'video':
        return props.fields.videos ? (
          <VideoHeader videos={props.fields.videos} cardtype={cardType} />
        ) : null;
      case 'icon':
        return props.fields.icon ? (
          <IconHeader icon={props.fields.icon} cardtype={cardType} />
        ) : null;
      default:
        return null;
    }
  };
  return (
    <div
      className={`${props?.params?.Style === 'standard' ? 'carousel-item' : ''} w-full flex p-4`}
    >
      {props.params.Style === 'standard' && (
        <StandardManualCard
          {...props}
          headerComponent={headerComponent('standard')}
          editorActive={editorActive}
          hasCategorySubheader={hasCategorySubheader}
        />
      )}

      {props.params.Style === 'feature' && (
        <FeatureManualCard
          {...props}
          headerComponent={headerComponent('feature')}
          editorActive={editorActive}
          hasCategorySubheader={hasCategorySubheader}
        />
      )}
    </div>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const language = layoutData.sitecore.context.language;
  const manualCardData = await getManualCardData(rendering.dataSource, language);

  if (!manualCardData || manualCardData?.datasource == null) {
    ssrErrorLogger.logError({
      functionName: 'getManualCardData; ',
      errorMessage: `Failed to get response or response datasource is null; Datasource: ${JSON.stringify(
        rendering.dataSource
      )}; Langusge: ${language}; Response: ${JSON.stringify(manualCardData)}`,
    });
  }

  if (manualCardData?.datasource == null) {
    return {};
  }

  if (manualCardData?.datasource?.videos?.targetIds?.length < 1) {
    ssrErrorLogger.logError({
      functionName: 'getManualCardData; ',
      errorMessage: `Got empty userTypeOptions.targetIds; Datasource: ${JSON.stringify(
        rendering.dataSource
      )}; Langusge: ${language}; Response: ${JSON.stringify(manualCardData)}`,
    });
  }

  const videos: Array<BrightcoveVideoItem | YoutubeVideoItem> = [];
  if (manualCardData?.datasource?.videos?.targetIds?.length) {
    for (let i = 0; i < manualCardData.datasource.videos.targetIds.length; i++) {
      const videoId = manualCardData.datasource.videos.targetIds[i];
      const videoData = await getVideoData(videoId, language);
      if (!videoData || videoData?.datasource == null) {
        ssrErrorLogger.logError({
          functionName: 'getVideoData; ',
          errorMessage: `Failed to get response or response datasource is null;
            videoId: ${videoId};
            Langusge: ${language};
            Response: ${JSON.stringify(videoData)}`,
        });
      }
      if (videoData?.datasource != null) {
        const video = videoData.datasource;
        if (
          (video as BrightcoveVideoItem)?.videoReference?.targetItems?.length > 0 ||
          (video as YoutubeVideoItem).sourceId?.value
        ) {
          videos.push(video);
        }
      }
    }
  }

  const propsObj = {
    fields: {
      image: manualCardData?.datasource?.image?.jsonValue ?? null,
      icon: manualCardData?.datasource?.icon?.targetItem?.value?.jsonValue ?? null,
      videos: videos.length
        ? videos.map((x) => {
            if ((x as YoutubeVideoItem).sourceId?.value) {
              return {
                sourceId: (x as YoutubeVideoItem)?.sourceId?.value,
                stillImage: x?.stillImage?.jsonValue,
              };
            } else {
              return {
                videoId: (x as BrightcoveVideoItem)?.videoReference?.targetItems[0]?.ID.value,
                playerId: (x as BrightcoveVideoItem)?.player?.targetItem?.ID.value ?? null,
                stillImage: x?.stillImage?.jsonValue,
                ThumbnailURL: (x as BrightcoveVideoItem)?.videoReference?.targetItems[0]
                  ?.ThumbnailURL?.value,
              };
            }
          })
        : null,
      title: manualCardData?.datasource?.title?.jsonValue,
      categorySubheading: manualCardData?.datasource?.categorySubheading?.jsonValue,
      description: manualCardData?.datasource?.description?.jsonValue,
      link: manualCardData?.datasource?.link?.jsonValue,
      noFollow: manualCardData?.datasource?.noFollow?.jsonValue,
      byPassNextJsImageOptimization:
        manualCardData?.datasource?.byPassNextJsImageOptimization?.jsonValue,
      imageCDNUrl: manualCardData?.datasource?.imageCDNUrl?.jsonValue,
      anchorLinkId: manualCardData?.datasource?.anchorLinkId?.jsonValue,
    },
    contentType: rendering?.params?.ContentType ?? null,
  };
  return ssrErrorLogger.createProperty('CardCarouselManualCard', propsObj);
};

export const getManualCardData = async (
  datasource: string,
  language: string
): Promise<ManuCardResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<ManuCardResponse>(getManualCardQuery, {
    datasource: datasource,
    language: language,
  });
};

export const getVideoData = async (
  datasource: string,
  language: string
): Promise<ManualCardBrightcoveVideoResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<ManualCardBrightcoveVideoResponse>(
    getManualCardBrightcoveVideoQuery,
    {
      datasource: datasource,
      language: language,
    }
  );
};

export default withDatasourceCheck()(CardCarouselManualCard);
