import { Text, LinkField, withDatasourceCheck, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { ComponentProps } from 'lib/component-props';

export type CategoryLinkProps = {
  categoryLink: {
    jsonValue: LinkField;
    value: string;
  };
};

type CategoryLinksProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        categoryLinksTitle: {
          value: string;
          jsonValue: Field<string>;
        };
        children: {
          results: CategoryLinkProps[];
        };
      };
    };
  };
};

const CategoryLinks = (props: CategoryLinksProps): JSX.Element => {
  const datasource = props?.fields?.data?.datasource;
  const categoryLinks = datasource?.children?.results;

  if (datasource == null) {
    return <div className="editor-message">Please select a datasource</div>;
  }

  return (
    <div className="my-5" id="marketplace-category-links">
      <div className="flex flex-row justify-between mt-5">
        <div className="block w-full text-lg font-light text-mp-txt-bright">
          <Text field={datasource.categoryLinksTitle?.jsonValue} />{' '}
          <hr className="text-grayishblue" />
        </div>
      </div>
      {datasource && categoryLinks && categoryLinks.length > 0 && (
        <div className="flex flex-row flex-wrap justify-between w-full mb-2.5">
          {categoryLinks.map((categoryLink, index) => {
            return (
              <NextLink
                key={index}
                className="sm:inline-block text-mp-txt-bright bg-mp-background-card w-[calc(50%-4px)] h-12 mt-2.5 text-center leading-12 cursor-pointer"
                field={categoryLink.categoryLink.jsonValue}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withDatasourceCheck()<CategoryLinksProps>(CategoryLinks);
