export const stringFormat = ({ message, ...rest }: Record<string, string>): string => {
  const args = rest;
  return message.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
};

export const toSlug = (input: string): string => {
  return input
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/[  }{]/g, '-');
};

export const isEmpty = (input: string): boolean => {
  return input == null || (typeof input === 'string' && input.trim().length === 0);
};

export const formatAsGuid = (input: string): string => {
  const formattedGuid = `{${input.slice(0, 8)}-${input.slice(8, 12)}-${input.slice(
    12,
    16
  )}-${input.slice(16, 20)}-${input.slice(20)}}`;
  return formattedGuid;
};

export const toKeyValuePair = (queryString: string = ''): { [key: string]: string } => {
  const pairs = queryString.split('&');
  const result = {};
  if (pairs && pairs.length > 0) {
    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      result[key] = decodeURIComponent(value);
    });
  }
  return result;
};
