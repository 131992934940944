import { GetStaticComponentProps, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useEffect, useState } from 'react';
import {
  NavigationFolder,
  NavigationItem,
} from '../components/global-navigation/shop/navigation-props';
import dynamic from 'next/dynamic';
import { GraphQLClient } from 'lib/common/graphql-client';
import { getStoredCountry } from 'lib/commerce/countries/countries-provider';
import { geoRestrictData } from 'lib/navigation/navigation-utils';
import getGlobalNavigationQuery from '../components/global-navigation/shop/global-navigation-query';

const NavigationGlobalNavigationMobile = dynamic(
  () => import('../components/global-navigation/shop/NavigationGlobalNavigationMobile')
);

const NavigationGlobalNavigationDesktop = dynamic(
  () => import('../components/global-navigation/shop/NavigationGlobalNavigationDesktop')
);

type GlobalNavigationProps = ComponentProps & {
  fields: {
    data: NavigationFolder;
  };
  navigationFolder: NavigationFolder | null;
};

const GlobalNavigation = (props: GlobalNavigationProps): JSX.Element => {
  const menuItems = props.navigationFolder?.datasource?.children?.results;
  const [activeIndex, setActiveIndex] = useState(-1);
  const [cleanMenuItems, setCleanMenuItems] = useState<NavigationItem[]>(
    JSON.parse(JSON.stringify(menuItems))
  );

  useEffect(() => {
    const country = getStoredCountry();
    if (!country) {
      return;
    }
    if (menuItems == null) {
      return;
    }

    setCleanMenuItems(geoRestrictData(country, menuItems));
  }, [menuItems]);

  if (cleanMenuItems == null) {
    return <></>;
  }

  const onMenuClickHandler = (index: number): void => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    } else {
      setActiveIndex(-1);
    }
  };

  return (
    <>
      <NavigationGlobalNavigationDesktop
        menuItems={cleanMenuItems}
        activeIndex={activeIndex}
        onMenuClick={(index): void => onMenuClickHandler(index)}
        renderingId={props.rendering.uid}
      />
      <NavigationGlobalNavigationMobile
        menuItems={cleanMenuItems}
        activeIndex={activeIndex}
        onMenuClick={(index): void => onMenuClickHandler(index)}
        renderingId={props.rendering.uid}
      />
    </>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (rendering?.dataSource == null || layoutData?.sitecore?.context?.language == null) {
    return {};
  }
  const navigationFolder = await getNavigationData(
    rendering.dataSource,
    layoutData.sitecore.context.language
  );

  return {
    navigationFolder,
    rendering,
  };
};

export const getNavigationData = async (
  datasource: string,
  language: string
): Promise<NavigationFolder> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<NavigationFolder>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getGlobalNavigationQuery,
    {
      datasource: datasource,
      language: language,
    }
  );
};

export default withDatasourceCheck()<GlobalNavigationProps>(GlobalNavigation);
