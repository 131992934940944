import TagManager from 'react-gtm-module';

export const pushVideoEvent = (
  eventName: string,
  videoTitle: string,
  videoProvider: string,
  videoUrl: string,
  videoPlayType: string,
  videoDuration: number,
  videoPercent: number,
  videoCurrentTime: number,
  videoWatchTime: number
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      video_title: videoTitle,
      video_provider: videoProvider,
      video_url: videoUrl,
      video_play_type: videoPlayType,
      video_duration: videoDuration,
      video_percent: videoPercent,
      video_current_time: videoCurrentTime,
      video_watch_time: videoWatchTime,
    },
  });
};
