import MarkeplaceSubscriptionPricingCard from './MarkeplaceSubscriptionPricingCard';
import { ProductVariationsData } from 'lib/commerce/product/model/product-models';
import Carousel from 'components/carousel/Carousel';
import { Product } from 'src/pages/api/product/models/product';
import Image from 'next/image';
import MarketplaceCarouselDots from 'components/carousel/marketplace/MarketplaceCarouselDots';
import {
  ProductPickAPlanContextItem,
  ProductPickAPlanImageSetItem,
  TargetItemProductSet,
} from './product-pick-a-plan-props';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { PickAPlanTextContent } from './PickAPlanTextContent';
import { useState } from 'react';

type SelectYourPlanProps = {
  productVariations: ProductVariationsData | null;
  productItems: Product[] | null;
  contextItem: ProductPickAPlanContextItem;
  productSet: TargetItemProductSet | undefined;
  imageSet: ProductPickAPlanImageSetItem[];
};

export const SelectYourPlanMulti = (props: SelectYourPlanProps): JSX.Element => {
  const { productVariations, productItems } = props;
  const cartPagePath = '/marketplace/cart';
  const [fullSizeImgs, setFullSizeImgs] = useState(false);
  const switchImgSize = (): void => {
    setFullSizeImgs(!fullSizeImgs);
  };

  return (
    <div className={`marketplace-pdp-multi mx-auto text-mp-txt-neutralLight`}>
      <div className="mb-2">
        {props?.imageSet.length > 0 && (
          <div className="hero-banner-carusel sm:mb-0 md:mb-5">
            <div className="sm:mt-0 md:mt-4">
              <Carousel
                MaxItemsPerSlideOnDesktop="1"
                MaxItemsPerSlideOnTablet="1"
                MaxItemsPerSlideOnMobile="1"
                autoPlay={true}
                autoPlaySpeed={6000}
                infinite={false}
                buttonGroup={<></>}
                itemClass="justify-center"
                customDotsGroup={<MarketplaceCarouselDots />}
                carouselWrapperClass="pb-5"
              >
                {props.imageSet.map((item, index) => {
                  return (
                    <div key={index}>
                      <Image
                        alt=""
                        width={1480}
                        height={500}
                        src={item.url.url}
                        className={`animate-slide_in lg:pl-[2px] rounded object-contain object-center min-h-[130px] ${
                          fullSizeImgs
                            ? 'aspect-auto lg:w-5/6 m-auto'
                            : 'max-h-[32vh]  aspect-[148/34]'
                        } cursor-pointer mb-2`}
                        onClick={switchImgSize}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        )}
      </div>
      {props?.contextItem?.productDescription?.value.length > 0 && (
        <div className="">
          <RichText
            field={props?.contextItem?.productDescription}
            tag="div"
            className="rte py-2 px-2 text-base bg-mp-background-card"
          />
        </div>
      )}
      <div
        className={`my-5 xl:px-1 2xl:px-6 relative ${
          productVariations?.variants.length === 1
            ? 'sm:w-3/4 md:w-1/2 mx-auto px-2 sm:px-6 md:px-5'
            : 'px-6 sm:px-24 md:px-10'
        }`}
      >
        {productVariations?.variants && (
          <>
            <div className="relative mx-auto xl:max-w-content">
              <Carousel
                MaxItemsPerSlideOnDesktop="3"
                MaxItemsPerSlideOnTablet="2"
                MaxItemsPerSlideOnMobile={'1'}
                desktopSize={1199}
                tabletSize={767}
                buttonGroup={<></>}
                customDotsGroup={<MarketplaceCarouselDots />}
                carouselWrapperClass={`mx-auto w-full`}
                sliderClass={`flex justify-center flex-grow`}
              >
                {productItems !== null &&
                  productVariations.variants.map((variation, index) => (
                    <MarkeplaceSubscriptionPricingCard
                      key={variation.sku}
                      quantity={1}
                      productId={variation.productId}
                      sku={variation.sku}
                      sfProductName={variation.name}
                      productName={productItems[index]?.productName?.value}
                      brand={productItems[index]?.brand?.value}
                      productCategoryPaths={variation.productCategoryPaths}
                      unitPrice={variation.price.unitPrice}
                      listPrice={variation.price.listPrice}
                      currencyCode={variation.price.currencyIsoCode}
                      subscriptionType={variation.subscriptionType}
                      featuredTheme={productItems[index]?.featuredTheme?.targetItem?.value.value}
                      featuredText={productItems[index]?.featuredText}
                      badgeText={productItems[index]?.badgeText}
                      features={productItems[index]?.features?.targetItems}
                      className={`my-4 mx-2 border border-mp-txt-neutral w-80 min-w-[275px] max-w-[450px] rounded 
                      !overflow-hidden shadow-card xl:mx-auto flex items-center text-mp-txt-neutralLight`}
                      cartPagePath={cartPagePath}
                      marketplaceVariantDescription={
                        productItems[index]?.marketplaceVariantDescription
                      }
                    />
                  ))}
              </Carousel>
            </div>
          </>
        )}
      </div>
      <PickAPlanTextContent
        textSections={
          props?.contextItem?.children?.results.length <= 0
            ? props?.productSet?.textSections.targetItems
            : props?.contextItem?.children?.results
        }
      />
    </div>
  );
};

export default SelectYourPlanMulti;
