import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { PickPlanPricingCardDataSource, PricingCardProps } from './pricing-card-props';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import BasePricingCard from '../../components/pricing-card/BasePricingCard';

const Default = (props: PricingCardProps<PickPlanPricingCardDataSource>): JSX.Element => {
  const productItem = props.fields?.data?.contextItem?.product?.targetItems[0] ?? null;
  return (
    <BasePricingCard
      pricingCard={props}
      fullCta={
        <NextLink
          className="btn-primary ml-auto w-full px-8 relative text-lg"
          field={props.fields?.data?.datasource?.buttonLink?.jsonValue}
          hideText
        >
          <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
        </NextLink>
      }
      miniCta={
        <NextLink
          className="btn-primary ml-auto px-3 py-1 text-[1rem]"
          field={props.fields?.data?.datasource?.buttonLink?.jsonValue}
          hideText
        >
          <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
        </NextLink>
      }
      productItem={productItem}
      loading={false}
    />
  );
};

export default Default;
