import gql from 'graphql-tag';

const getRequirementsAndSpecificationsTableDetailsQuery = gql`
  query ProductOverviewRequirementsAndSpecificationsDetailsQuery(
    $datasource: String!
    $language: String!
  ) {
    datasource: item(path: $datasource, language: $language) {
      name
      details: children(first: 25) {
        results {
          ... on AttributeTextDetails {
            detail {
              jsonValue
            }
            link {
              jsonValue
            }
            theme {
              jsonValue
            }
          }

          ... on AttributeIconDetails {
            color {
              jsonValue
            }
            icon: field(name: "icon") {
              ... on LookupField {
                targetItem {
                  id
                  ... on ReferenceStringValue {
                    value {
                      jsonValue
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getRequirementsAndSpecificationsTableDetailsQuery;
