import { Variant } from 'lib/commerce/product/model/product-models';
import { ComparisonTableCardItem } from './comparison-table-card-item';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import BadgeSection from '../multi-tier-pricing-card/BadgeSection';
import { useI18n } from 'next-localization';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import SecondaryCtaSection from '../multi-tier-pricing-card/SecondaryCtaSection';

type ComparisonColumnHeaderProps = {
  ForceFeaturedLayout: boolean;
  CardItem: ComparisonTableCardItem;
  Variant: Variant;
  Dropdown: JSX.Element | JSX.Element[] | boolean;
  FrequencyLabel: string;
  Mini: boolean;
};
const ComparisonColumnHeader = (headerProps: ComparisonColumnHeaderProps): JSX.Element => {
  const { t } = useI18n();
  const { ForceFeaturedLayout, CardItem, Variant, Dropdown, FrequencyLabel, Mini } = headerProps;

  return (
    <div className="flex-1 bg-white">
      {ForceFeaturedLayout && (
        <div
          className={`rounded-tr-md rounded-tl-md text-center py-1 text-white accent-3 uppercase tracking-[2.6px] ${
            ForceFeaturedLayout && !CardItem?.fields.featuredText.value && 'invisible'
          } ${CardItem?.fields.featuredTheme?.fields.value.value == 'primary' && 'bg-secondary'} ${
            CardItem?.fields.featuredTheme?.fields.value.value == 'secondary' && 'bg-primary'
          }`}
        >
          <div>
            <Text field={CardItem?.fields.featuredText} />
            {!CardItem?.fields.featuredText.value && 'na'}
          </div>
        </div>
      )}
      <div className="px-3 py-4 text-center border border-neutral-lighter">
        {Dropdown}

        {!Mini && (
          <div className="flex justify-center">
            <BadgeSection
              className="mt-4 mb-3"
              anyVisibleCardHasBadge={true}
              field={CardItem?.fields.badgeText}
            />
          </div>
        )}

        {!Mini && (
          <div>
            <h5
              className={`text-base-dark line-through text-[1.25rem] font-normal tracking-[0.2em] ${
                Variant?.price.unitPrice === Variant?.price.listPrice && 'invisible'
              }`}
            >
              {t(`Currency_Symbol_${Variant?.price.currencyIsoCode}`)}
              {Variant?.price.listPrice}
            </h5>
            <h5
              className={`accent-1 tracking-[0.2em] ${
                CardItem?.fields.featuredText.value &&
                CardItem?.fields.featuredTheme?.fields.value.value === 'primary' &&
                'text-secondary'
              }`}
            >
              {t(`Currency_Symbol_${Variant?.price.currencyIsoCode}`)}
              {Variant?.price.unitPrice}
            </h5>
            <h5 className="accent-3 tracking-[0.2em] uppercase">
              {Variant?.price.currencyIsoCode}
              {FrequencyLabel}
            </h5>
          </div>
        )}

        <div className="flex justify-center mt-4">
          <div className="flex flex-col mb-2">
            <NextLink
              className={`${
                CardItem?.fields.featuredText.value &&
                CardItem?.fields.featuredTheme?.fields.value.value === 'primary'
                  ? 'btn-feature'
                  : 'btn-primary'
              } `}
              field={CardItem?.fields.primaryCta}
            ></NextLink>
            <SecondaryCtaSection
              field={CardItem?.fields.secondaryCta}
              anyVisibleCardHasSubLink={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonColumnHeader;
