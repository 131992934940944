import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import Head from 'next/head';
import React from 'react';

type SEOToolVerificationProps = ComponentProps & {
  fields: {
    googleMetaTagContent: Field<string>;
    bingMetaTagContent: Field<string>;
  };
};

const SEOToolVerification = (props: SEOToolVerificationProps): JSX.Element => {
  return (
    <Head>
      <meta name="google-site-verification" content={props?.fields?.googleMetaTagContent.value} />
      <meta name="msvalidate.01" content={props?.fields?.bingMetaTagContent.value} />
    </Head>
  );
};

export default SEOToolVerification;
