import { ComponentProps } from 'lib/component-props';
import { Field, Placeholder, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

type IconLinksProps = ComponentProps & {
  fields: {
    heading: Field<string>;
  };
};

const IconLinks = (props: IconLinksProps): JSX.Element => {
  const editorActive = isEditorActive();

  if (props?.fields == null) {
    if (editorActive) {
      return <div className="editor-message">Please select a valid datasource</div>;
    }
    return <></>;
  }

  return (
    <div className="icon-links my-10 px-6 lg:my-20 lg:px-8 xl:px-0 max-w-content mx-auto">
      <Text
        tag="h2"
        field={props.fields.heading}
        className="text-almost-black text-center uppercase tracking-[0.28em] mb-6 lg:mb-8"
      />
      <ul className="w-fit mx-auto flex flex-col space-y-4 lg:space-y-0 lg:w-full lg:flex-row lg:justify-around">
        <Placeholder name="jss-icon-links" rendering={props.rendering} />
      </ul>
    </div>
  );
};

export default withDatasourceCheck()<IconLinksProps>(IconLinks);
