import Link from 'next/link';

type BreadcrumbItem = {
  id: string;
  title: string;
  url: string;
  parent?: BreadcrumbItem | null;
};

type BreadcrumbProps = {
  fields: BreadcrumbItem;
  params: {
    overlayAndInvertColors: boolean;
  };
};

const Default = (props: BreadcrumbProps): JSX.Element => {
  const flattened: { id: string; title: string; url: string }[] = [];

  let current: BreadcrumbItem | null | undefined = props?.fields;

  while (current != null) {
    flattened.push({ id: current.id, title: current.title, url: current.url });
    current = current.parent;
  }

  const reversed = flattened.reverse();

  return (
    <div
      className={`breadcrumb w-full mt-4 md:mt-8 text-sm leading-normal mb-10 lg:mb-20 ${
        props.params?.overlayAndInvertColors == true
          ? 'absolute text-base-light z-10'
          : 'text-base-dark'
      }`}
    >
      <nav className="mx-auto px-6 lg:px-8 2xl:px-24 overflow-x-auto">
        <ul className="flex gap-2">
          {reversed.map((item, index) => {
            return (
              <li key={item.id} className="flex gap-1.5 whitespace-nowrap">
                {index !== reversed.length - 1 ? (
                  <>
                    <Link href={item.url} legacyBehavior>
                      <a className="underline">{item.title}</a>
                    </Link>
                    {`/`}
                  </>
                ) : (
                  item.title
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Default;
