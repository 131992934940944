import {
  AtomicResultSectionExcerpt,
  AtomicResultSectionTitle,
  AtomicResultSectionBadges,
  Result,
  AtomicResultSectionVisual,
  AtomicResultHtml,
} from '@coveo/atomic-react';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { absoluteMediaUrl } from 'lib/utils/sitecore-media-utils';
import { getYoutubeThumbnail } from 'lib/youtube-thumbnail-fetcher';
import { useState, useEffect } from 'react';
import BaseVideoModal from 'components/video/BaseVideoModal';
import { YoutubeVideoProps, FieldValue, Fields } from 'components/video/youtube-video-props';
import SearchTitle from 'components/atomic-components/SearchTitle';
import YoutubeVideoThumnail from '../../atomic-components/YoutubeVideoThumnail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = `
  .result-root.with-sections.display-list.image-small {
    grid-template-areas:
        "visual"
        "badges"
        "title"
        "excerpt"
        "video-info"
        "bottom-metadata"!important;
    grid-template-rows: repeat(6, auto)!important;
  }

  @media (min-width: 1024px) {
    .result-root.with-sections.display-list.image-small {
      grid-template-areas:
          "badges . actions"
          "title title visual"
          "excerpt excerpt visual"
          "video-info video-info visual"
          "bottom-metadata bottom-metadata visual"!important;
      grid-template-rows: repeat(5, auto)!important;
      grid-template-columns: 1fr 1fr 1fr!important;
    }
  }

  @media (min-width: 1200px) {
    .result-root.with-sections.display-list.image-small {
      grid-template-areas:
          "badges badges . actions"
          "title title title visual"
          "excerpt excerpt excerpt visual"
          "video-info video-info video-info visual"
          "bottom-metadata bottom-metadata bottom-metadata visual"!important;
      grid-template-rows: repeat(5, auto)!important;
      grid-template-columns: 1fr 1fr 1fr 1fr!important;
    }
  }

  atomic-result-section-visual {
    aspect-ratio: 16 / 9;
    height: auto!important;
    margin: 0 0 23.41px!important;
    width: 100%!important;
    position:relative;
    inset:0px !important;
  }

  atomic-result-section-visual img{
    inset:0px !important;
    object-fit:cover;
  }

  atomic-result-section-visual > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    color: white;
    border: 5px solid rgba(34, 34, 34, 0.7);
    border-radius: 100%;
    pointer-events: none;
}

  @media (min-width: 1024px) {
    atomic-result-section-visual {
      margin: 0 0 0 1rem!important;
    }
  }

  atomic-result-section-badges,
  atomic-result-section-title,
  atomic-result-section-excerpt {
    margin-top: 0!important;
    margin-bottom: .5rem!important;
  }

  atomic-result-section-title atomic-result-html atomic-html {
    color: #194BF5;
    font-family: var(--font-gtWalshiemAvidRegular);
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  atomic-result-section-excerpt {
    max-height: 3rem!important;
  }

  atomic-result-section-badges {
    overflow: visible !important;
    color: #222222;
    font-size: .75rem;
    letter-spacing: .2em;
    line-height: 1.125rem;
    text-transform: uppercase;
    grid-column: span 3 !important;
  }

  atomic-result-date {
    color: #222222;
    font-size: .75rem;
    letter-spacing: .2em;
    line-height: 1.125rem;
    text-transform: uppercase;
  }

  atomic-result-text,
  .intro-text {
    color: #194BF5;
    font-family: var(--font-gtWalshiemAvidRegular)
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  @media (min-width: 992px) {
    atomic-result-text,
    .intro-text {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  atomic-html,
  .body {
    color: #222222;
    font-family: var(--font-gtWalshiemAvidRegular)
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .video-data {
    display: none;
    grid-area: video-info / video-info / video-info;
    margin-bottom: 24px;
  }

  @media (min-width: 768px) {
    .video-data {
      display: flex;
    }
  }

  .video-data__item {
    border-right: 1px solid var(--atomic-on-background);
    font-size: 16px;
    line-height: 1em;
    margin-right: 8px;
    padding-right: 8px;
  }

  .video-data__item:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }

  .video-data__item-label {
    margin-right: 4px;
  }

  atomic-result-section-bottom-metadata {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: .5rem!important;
    max-height: none!important;
  }

  atomic-result-section-bottom-metadata > ul {
    flex-wrap: wrap;
  }
  atomic-result-section-title:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px
  }

`;
export function generateEventDateText(startDate?: Date, endDate?: Date): string | undefined {
  if (!startDate) return undefined;

  const startDateText = startDate.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  if (!endDate) return startDateText;

  const startMonth = startDate.toLocaleString('default', { month: 'long' });
  const startDay = startDate.getDate();
  const startYear = startDate.getFullYear();

  const endMonth = endDate.toLocaleString('default', { month: 'long' });
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();

  if (startMonth === endMonth && startYear === endYear) {
    return `${startMonth} ${startDay} - ${endDay}, ${startYear}`;
  }
  if (startYear === endYear) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
  }
  return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
}

export function extractYoutubeVideoId(url: string): string | null {
  const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regex);
  return match && match[7]?.length === 11 ? match[7] : null;
}

export type YouTubeVideoThumbnailProps = {
  value: {
    src: string;
  };
};

export const EventSearchTemplate: React.FC<{ result: Result }> = ({ result }) => {
  const [youtubeVideoThumbnail, setYoutubeVideoThumbnail] = useState<YouTubeVideoThumbnailProps>();
  let isOnDemandVideo = false;
  const imageUrlPart = '/sitecore-modules/web/coveo';

  const {
    eventstartdate,
    eventenddate,
    eventlocation,
    searchimageurl,
    eventtype,
    eventlink,
    searchtitle,
  } = result.raw;
  const startDate = eventstartdate ? new Date((eventstartdate as string).split('@')[0]) : undefined;
  const endDate = eventenddate ? new Date((eventenddate as string).split('@')[0]) : undefined;

  let absoluteSearchImageUrl = searchimageurl ? absoluteMediaUrl(searchimageurl as string) : null;
  if (absoluteSearchImageUrl?.includes(imageUrlPart))
    absoluteSearchImageUrl = absoluteSearchImageUrl.replace(imageUrlPart, '');

  const eventDateText = generateEventDateText(startDate, endDate);
  let badgeDisplayText =
    eventDateText && eventlocation
      ? `${eventDateText} | ${eventlocation}`
      : eventDateText || eventlocation;

  isOnDemandVideo = eventtype && eventtype?.[0] === 'On Demand' ? true : false;
  const youtubeSourceId = isOnDemandVideo ? extractYoutubeVideoId(eventlink as string) : null;
  badgeDisplayText = isOnDemandVideo ? (badgeDisplayText = 'On Demand') : badgeDisplayText;
  const video: YoutubeVideoProps = {
    fields: {
      sourceId: youtubeSourceId
        ? ({ value: youtubeSourceId } as FieldValue)
        : ({ value: '' } as FieldValue),
    } as Fields,
  } as YoutubeVideoProps;

  useEffect(() => {
    if (youtubeSourceId) {
      getYoutubeThumbnail(youtubeSourceId).then(setYoutubeVideoThumbnail);
    }
  }, [youtubeSourceId]);
  return (
    <>
      <style>{styles}</style>
      {absoluteSearchImageUrl && (
        <AtomicResultSectionVisual className="result-media">
          <NextImage
            layout="fill"
            field={{ value: { src: absoluteSearchImageUrl } }}
            className="w-full object-cover bg-base-lighter relative flex justify-center"
          />
        </AtomicResultSectionVisual>
      )}
      {youtubeSourceId && (
        <AtomicResultSectionVisual>
          <BaseVideoModal
            brightcoveVideo={false}
            youtubeVideo={true}
            video={video}
            openCtaClassName=""
            openCtaChildren={
              <YoutubeVideoThumnail
                src={youtubeVideoThumbnail?.value.src as string}
                alt={searchtitle as string}
                href=""
                title={searchtitle as string}
                videoId={youtubeSourceId as string}
                className="cursor-pointer absolute inset-0 h-full w-full"
              />
            }
          />
          <FontAwesomeIcon icon="circle-play" />
        </AtomicResultSectionVisual>
      )}

      {badgeDisplayText && (
        <AtomicResultSectionBadges>
          {(badgeDisplayText as string)?.toUpperCase()}
        </AtomicResultSectionBadges>
      )}
      {!isOnDemandVideo && (
        <AtomicResultSectionTitle>
          <SearchTitle searchTitle={searchtitle as string} href={eventlink as string} />
        </AtomicResultSectionTitle>
      )}
      {isOnDemandVideo && (
        <AtomicResultSectionTitle>
          <div className="cursor-pointer">
            <BaseVideoModal
              brightcoveVideo={false}
              youtubeVideo={true}
              video={video}
              openCtaClassName=""
              openCtaChildren={<AtomicResultHtml field="searchtitle" className="hover:underline" />}
            />
          </div>
        </AtomicResultSectionTitle>
      )}

      <AtomicResultSectionExcerpt>
        <AtomicResultHtml field="searchdescription" />
      </AtomicResultSectionExcerpt>
    </>
  );
};

export default EventSearchTemplate;
