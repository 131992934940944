import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState } from 'react';

export type ProductShortDescriptionProps = {
  description: Field<string>;
};

const ProductShortDescription = (props: ProductShortDescriptionProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={`${
        expanded ? '' : 'max-h-[30%] 2xl:max-h-[49%]'
      } bg-mp-background-card text-sm mp-rich-text-with-links mt-3 md:mt-0 z-10 relative overflow-hidden`}
    >
      <RichText
        field={props?.description}
        tag="div"
        className="rte bg-mp-background-card py-2 px-4 lg:px-5"
      />
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={(): void => {
          setExpanded(!expanded);
        }}
        className={`absolute bottom-0 right-0 cursor-pointer text-mp-txt-primary hidden md:block
                      ${expanded ? 'rotate-0' : 'rotate-180'}
                    `}
      />
    </div>
  );
};

export default ProductShortDescription;
