import {
  Field,
  Text,
  ImageField,
  withDatasourceCheck,
  isEditorActive,
  NextImage,
  RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React from 'react';
import Carousel from 'components/carousel/Carousel';
import InAppCarouselDots from 'components/carousel/inapp/InAppCarouselDots';

export type CarouselSlideProps = {
  buttonsText: Field<string>;
  slideImage: {
    jsonValue: ImageField;
    value: string;
  };
};

type InAppCarouselProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        title: Field<string>;
        links: Field<string>;
        socialMediaLinks: Field<string>;
        children: {
          results: CarouselSlideProps[];
        };
      };
    };
  };
};

const InAppCarousel = (props: InAppCarouselProps): JSX.Element => {
  const editorActive = isEditorActive();
  const datasource = props.fields?.data?.datasource;
  const carouselSlides = datasource?.children?.results;

  return (
    <div className="w-600 h-400 m-auto relative">
      <Carousel
        MaxItemsPerSlideOnDesktop="1"
        MaxItemsPerSlideOnTablet="1"
        MaxItemsPerSlideOnMobile="1"
        autoPlay={true}
        autoPlaySpeed={8000}
        infinite={true}
        buttonGroup={<></>}
        itemClass="justify-center"
        customDotsGroup={<InAppCarouselDots />}
        dotListClass="!bottom-5"
        renderDotsOutside={false}
      >
        {carouselSlides?.map((item, index) => {
          return (
            <div key={index} className="relative">
              {item.slideImage?.jsonValue && (
                <div>
                  {(item.slideImage?.value || editorActive) && (
                    <NextImage
                      priority={true}
                      field={item.slideImage.jsonValue}
                      className={`animate-slide_in lg:pl-[2px] rounded object-cover object-left min-h-[130px] cursor-pointer mb-2`}
                    />
                  )}
                  {(item.buttonsText?.value || editorActive) && (
                    <RichText
                      field={item.buttonsText}
                      tag="div"
                      className="rte absolute right-8 top-35p"
                    />
                  )}
                  <div className="absolute left-4 bottom-5">
                    {(datasource?.title?.value || editorActive) && (
                      <Text
                        tag="h1"
                        field={datasource?.title}
                        className="text-darkgray text-xl font-medium"
                      />
                    )}
                    {(datasource?.links?.value || editorActive) && (
                      <RichText
                        field={datasource?.links}
                        tag="div"
                        className="rte richTextBl text-base-darker p-1"
                      />
                    )}
                    {(datasource?.socialMediaLinks?.value || editorActive) && (
                      <RichText
                        field={datasource?.socialMediaLinks}
                        tag="div"
                        className="rte richTextBl text-base-darker p-1"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default withDatasourceCheck()<InAppCarouselProps>(InAppCarousel);
