import { useEffect } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { resetEditorChromes } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { ProductCategoryHandpickedListingProps } from '../../components/category-listing-search/product-category-listing-models';
import ProductsInCategory from '../../components/category-listing-search/ProductsInCategory';

const ProductCategoryHandpickedListing = (
  props: ProductCategoryHandpickedListingProps
): JSX.Element => {
  useEffect(() => {
    resetEditorChromes();
  }, []);

  const datasource = props?.fields?.data?.datasource;
  if (datasource == null) {
    return <div className="editor-message">Please select a datasource</div>;
  }

  const { title, description, products } = datasource;

  if (products.targetItems.length === 0) {
    return (
      <div className="editor-message">
        You must select product pages to display inside the handpicked listing.
      </div>
    );
  }

  return (
    <>
      <div>
        <ProductsInCategory
          productCategoryTitle={title?.jsonValue}
          productCategoryDescription={description?.jsonValue}
          products={products.targetItems}
        />
      </div>
      <hr className="border border-base-dark my-10 sm:my-12 lg:my-14"></hr>
    </>
  );
};

export default withDatasourceCheck()<ProductCategoryHandpickedListingProps>(
  ProductCategoryHandpickedListing
);
