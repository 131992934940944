import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductFeatureItem } from 'components/foundation/commerce/models/product-features';
import MultiProductTableCardFeatureModal from './MultiProductTableCardFeatureModel';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type MultiProductTableCardFeaturesProps = {
  features: ProductFeatureItem[];
  featureHeading: Field<string>;
  isFeatured?: boolean;
};

const MultiProductTableCardFeatures = (props: MultiProductTableCardFeaturesProps): JSX.Element => {
  return (
    <div className="w-full space-y-4 flex flex-col bg-base-lighter px-4 py-5 text-almost-black flex-grow">
      <Text tag="h5" field={props.featureHeading} className="text-center small-navigation-bold" />
      {props.features.map((feature, index) => (
        <div key={index} className="flex items-center">
          <FontAwesomeIcon
            icon={faCheck}
            className={`mr-1 ${props.isFeatured ? 'text-secondary' : 'text-base-darker'}`}
          />
          <p className="small-text pl-5px">
            {feature.fields.heading.value.split(/({modalLinkText})/g).map((featureText, index) => {
              return featureText === '{modalLinkText}' && feature.fields.modal != null ? (
                <MultiProductTableCardFeatureModal
                  key={index}
                  modalLinkText={feature.fields.modalLinkText}
                  modal={feature.fields.modal}
                />
              ) : (
                featureText
              );
            })}
          </p>
        </div>
      ))}
    </div>
  );
};

export default MultiProductTableCardFeatures;
