import { useI18n } from 'next-localization';
import { useEffect, useState } from 'react';
import {
  ColorClassesType,
  themeColors,
} from 'src/components/foundation/non-sitecore/themes/themes-options';

type BaseItemQuantityNudgerProps = {
  passValueToParentFunction: (value: number) => void;
  quantity: number;
  maxValue?: number;
  minValue?: number;
  disableButton?: boolean;
  colorClasses?: ColorClassesType;
  isReadOnly?: boolean;
  passHasWarningToParentFunc?: (value: boolean) => void;
  passWarningTextToParentFunc?: (value: string) => void;
};

const BaseItemQuantityNudger = (props: BaseItemQuantityNudgerProps): JSX.Element => {
  const colorClasses = props.colorClasses ?? themeColors.white;
  const [quantity, setQuantity] = useState<number>(props.quantity);
  const [hasWarning, setHasWarning] = useState(false);
  const [warningText, setWarningText] = useState<string | null>(null);
  const { t } = useI18n();

  const onClickIncreaseQuantityHandler = (): void => {
    onChangeQuantityInput(quantity + 1);
  };

  const onClickDecreaseQuantityHandler = (): void => {
    onChangeQuantityInput(quantity - 1);
  };
  const onChangeQuantityInput = (number: number): void => {
    setHasWarning(false);
    props.passHasWarningToParentFunc && props.passHasWarningToParentFunc(false);
    let val = number;

    if (props.minValue && val && val < props.minValue) {
      props.passHasWarningToParentFunc
        ? props.passHasWarningToParentFunc(true)
        : setHasWarning(true);
      props.passWarningTextToParentFunc
        ? props.passWarningTextToParentFunc(
            t('ProductMinQuantityWarn').replace('$$count$$', `${props.minValue}`)
          )
        : setWarningText(t('ProductMinQuantityWarn').replace('$$count$$', `${props.minValue}`));
    } else if (props.maxValue && val && val > props.maxValue) {
      props.passHasWarningToParentFunc
        ? props.passHasWarningToParentFunc(true)
        : setHasWarning(true);
      props.passWarningTextToParentFunc
        ? props.passWarningTextToParentFunc(
            t('ProductMaxQuantityWarn').replace('$$count$$', `${props.maxValue}`)
          )
        : setWarningText(t('ProductMaxQuantityWarn').replace('$$count$$', `${props.maxValue}`));
    }

    if (val < 1 || (props.minValue && val && val < props.minValue)) {
      val = props.minValue ?? 1;
    } else if ((props.maxValue && val && val > props.maxValue) || (!props.maxValue && val > 99)) {
      val = props.maxValue ?? 99;
    }
    setQuantity(val);
    props.passValueToParentFunction(val);
  };

  useEffect(() => {
    setQuantity(props.quantity);
  }, [props.quantity]);

  return (
    <>
      <div className="flex space-x-3">
        <div
          className={`custom-number-input flex border rounded-l-2xl rounded-r-2xl border-base-medium ${colorClasses.general.textMainColorClass}`}
        >
          <button
            onClick={onClickDecreaseQuantityHandler}
            disabled={
              props.quantity === (props.minValue || 1) ||
              props.quantity === 0 ||
              props.disableButton
            }
            className="group w-[24px] h-[24px] border-r border-base-medium rounded-l-2xl flex items-center justify-center focus-visible:outline-none focus-visible:bg-primary-light"
          >
            <span
              className={`w-[8px] h-[2px] ${colorClasses.quantityNudger.textMain} rounded group-disabled:bg-base-normal 
              ${colorClasses.quantityNudger.textHover} ${colorClasses.quantityNudger.textActive}`}
            />
          </button>
          <input
            type="number"
            step="1"
            max={props.maxValue || 999}
            min={props.minValue || 1}
            value={quantity > 0 ? quantity : ''}
            readOnly={props.isReadOnly ?? true}
            className={`appearance-none outline-none w-[44px] h-[24px] text-center align-middle ${colorClasses.general.pageBgMain}`}
            onChange={(e): void => setQuantity(Number(e.currentTarget.value))}
            onBlur={(e): void => onChangeQuantityInput(Number(e.currentTarget.value))}
          />
          <button
            onClick={onClickIncreaseQuantityHandler}
            disabled={props.quantity === (props.maxValue || 999) || props.disableButton}
            className="group w-[24px] h-[24px] border-l border-base-medium rounded-r-2xl flex items-center justify-center focus-visible:outline-none focus-visible:bg-primary-light"
          >
            <div className="w-[10px] h-[10px] flex items-center justify-center relative">
              <div
                className={`absolute w-[10px] h-[2px] ${colorClasses.quantityNudger.textMain} rounded inline-block group-disabled:bg-base-normal ${colorClasses.quantityNudger.textHover} ${colorClasses.quantityNudger.textActive}`}
              />
              <div
                className={`absolute w-[2px] h-[10px] ${colorClasses.quantityNudger.textMain} rounded inline-block group-disabled:bg-base-normal ${colorClasses.quantityNudger.textHover} ${colorClasses.quantityNudger.textActive}`}
              />
            </div>
          </button>
        </div>
      </div>
      {hasWarning && <div className="bg-info-light border-info text-info p-1">{warningText}</div>}
    </>
  );
};

export default BaseItemQuantityNudger;
