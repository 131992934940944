import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useEffect, useState } from 'react';
import { getCurrencyConversionRates, getWebstore } from './webstore-slice';
import { CurrencyCoversionRatesData } from './model/webstore-models';

export const useWebStoreCurrencies = (): string[] => {
  const [currencies, setCurrencies] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const supportedCurrencies = useAppSelector(
    (state) => state.webstore.webstoreData?.supportedCurrencies
  );

  useEffect(() => {
    if (
      currencies.length == 0 &&
      (supportedCurrencies == null || supportedCurrencies.length == 0)
    ) {
      dispatch(getWebstore());
    }
    setCurrencies(supportedCurrencies);
  }, [dispatch, supportedCurrencies, currencies]);

  return currencies;
};

export const useCurrencyConversionRates = (): CurrencyCoversionRatesData | null => {
  const [currencyRates, setCurrencyRates] = useState<CurrencyCoversionRatesData | null>(null);
  const dispatch = useAppDispatch();
  const currencyConversionRates = useAppSelector(
    (state) => state.webstore.currencyConversionRatesData
  );

  useEffect(() => {
    if (!currencyRates || !currencyConversionRates) {
      dispatch(getCurrencyConversionRates());
    }
    setCurrencyRates(currencyConversionRates);
  }, [dispatch, currencyRates, currencyConversionRates]);

  return currencyRates;
};
