import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { RenderTabbedPlaceholderProps } from './tabbed-container-props';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

const RenderTabbedPlaceholder = (props: RenderTabbedPlaceholderProps): JSX.Element => {
  const { name, rendering, activeTab, mobileIndex } = props;
  const editorActive = isEditorActive();
  const renderTabbedPlaceholderHandler = (
    components: JSX.Element[]
  ): (JSX.Element | '') | (JSX.Element | '')[] => {
    if (mobileIndex != undefined) {
      const component = components[mobileIndex];
      const tabContentCss = activeTab != null || editorActive ? 'xl:hidden' : 'hidden';
      if (!editorActive) {
        return (
          <div
            id={`tabpanel_${mobileIndex}`}
            key={mobileIndex}
            role="tabpanel"
            aria-labelledby={`tab_${mobileIndex}`}
            className={tabContentCss}
          >
            {component}
          </div>
        );
      }

      return '';
    }

    return components.map((component, index) => {
      if (component.props && component.props.type === 'text/sitecore') return component;

      const tabContentCss =
        activeTab === index || editorActive ? 'hidden xl:block mx-6 xl:mx-6 2xl:mx-24' : 'hidden';
      if (activeTab === index || editorActive) {
        return (
          <div
            id={`tabpanel_${index}`}
            key={index}
            role="tabpanel"
            aria-labelledby={`tab_${index}`}
            className={tabContentCss}
          >
            {component}
          </div>
        );
      }

      return (
        <div
          id={`tabpanel_${index}`}
          key={index}
          role="tabpanel"
          aria-labelledby={`tab_${index}`}
          className={tabContentCss}
        >
          {component}
        </div>
      );
    });
  };

  return (
    <Placeholder
      name={name}
      rendering={rendering}
      render={(components: JSX.Element[]): (JSX.Element | '') | (JSX.Element | '')[] =>
        renderTabbedPlaceholderHandler(components)
      }
    />
  );
};

export default RenderTabbedPlaceholder;
