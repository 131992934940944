import { getCartSummary } from 'components/cart/cart-slice';
import { getOrderDetails } from 'components/order/order-slice';
import { ComponentProps } from 'lib/component-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { stringFormat } from 'lib/utils/string-format';
import { useI18n } from 'next-localization';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Spinner from 'components/spinner/Spinner';
import { LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import NonProToolsOrderDetails from '../../components/order/shop/NonProToolsOrderDetails';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

type OrderProcessingProps = ComponentProps & {
  fields: {
    orderDetailsLink: LinkField;
  };
};

const MarketplaceOrderProcessing = (props: OrderProcessingProps): JSX.Element => {
  const router = useRouter();
  const { id } = router.query;

  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector((state) => state.order.orderDetailsData);
  const orderStatus = useAppSelector((state) => state.order.orderDetailsStatus);
  const notificationEmail = orderDetails?.notificationEmail;
  const userId = orderDetails?.userId;
  const dataExists = orderStatus === 'succeeded';
  const { data: session, status } = useSession();

  useEffect(() => {
    if (id == null || id.length == 0 || status === 'loading') {
      return;
    }

    const bearerToken = getBearerToken(session);

    dispatch(getOrderDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    dispatch(getCartSummary({ bearerToken: bearerToken }));
  }, [dispatch, id, session, status]);

  useEffect(() => {
    if (orderDetails?.orderItems == null) {
      return;
    }

    router.push(`${props.fields.orderDetailsLink.value.href}?id=${id}`);
  });

  const OrderDetailDescription = (): JSX.Element => {
    const description = t('Order_Processing_Description');
    const content = stringFormat({
      message: description,
      0: id as string,
      1: notificationEmail as string,
    });
    return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
  };

  return (
    <div id="checkout--order-processing" className={`mt-4 px-1 sm:px-7 text-sm`}>
      <div className="cart-totals w-full md:w-4/5 m-auto mt-5 mb-5 p-2.5 relative bg-mp-background-card text-mp-txt-neutralLight">
        {!dataExists && <Spinner color="#20BBD0" />}
        <div className="mb-4 lg:mb-5 text-3xl lg:text-4xl text-center text-mp-txt-bright capitalize font-bold">
          {t('Order_Processing_OrderProcessing')}
        </div>
        <div className="p-2 pb-0 mb-6 text-center">
          <OrderDetailDescription />
        </div>
        <div className="my-8 border-t border-mp-txt-neutralLight"></div>
        <div className="p-2 pb-0 mb-6 text-center">
          <NonProToolsOrderDetails userId={userId} orderId={id} loading={!dataExists} />
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()(MarketplaceOrderProcessing);
