import {
  ImageField,
  LinkField,
  Field,
  withDatasourceCheck,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { ComponentProps } from 'lib/component-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import ResultImage from 'components/atomic-components/ResultImage';
import { t14TemplateIdVal } from 'lib/constants';

type ImageProps = ComponentProps & {
  fields: {
    image: ImageField;
    link: LinkField;
    noFollow?: Field<boolean>;
    byPassNextJsImageOptimization?: Field<boolean>;
    imageCDNUrl?: Field<string>;
  };
};

const Image = (props: ImageProps): JSX.Element => {
  const editorActive = isEditorActive();
  const context = useSitecoreContext();
  const t14TemplateId = t14TemplateIdVal;
  const pageTemplateId = context?.sitecoreContext.route?.templateId;
  const marginClass = t14TemplateId === pageTemplateId ? 'lg:mb-10' : 'sm:my-5 lg:my-10';
  return (
    <div className={`nextMedia   px-6 max-w-content mx-auto ${marginClass}`}>
      {((props.fields?.link && props.fields?.link.value?.href) || editorActive) && (
        <Link field={props.fields.link} hideText={true} linkCustomizations={props.fields}>
          <NextImage field={props?.fields?.image} className="mx-auto" />
        </Link>
      )}
      {(!props.fields?.link || !props.fields?.link.value?.href) && !editorActive && (
        <>
          {props.fields?.byPassNextJsImageOptimization?.value ? (
            <div className="mx-auto">
              {props.fields.imageCDNUrl?.value ? (
                <ResultImage
                  src={props.fields.imageCDNUrl.value ?? ''}
                  alt={props.fields.image?.value?.alt as string}
                  className="mx-auto"
                />
              ) : (
                props.fields.image?.value?.src != null &&
                props.fields.image?.value?.src != '' && (
                  <ResultImage
                    src={props.fields.image?.value?.src ?? ''}
                    alt={props.fields.image?.value?.alt as string}
                    className="mx-auto"
                  />
                )
              )}
            </div>
          ) : (
            <NextImage field={props?.fields?.image} className="mx-auto" />
          )}
        </>
      )}
    </div>
  );
};

export default withDatasourceCheck()<ImageProps>(Image);
