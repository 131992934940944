import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type VideoPlayerIconProps = {
  className?: string;
};

const VideoPlayerIcon = (props: VideoPlayerIconProps): JSX.Element => {
  return (
    <div className="absolute top-0 left-0 w-full h-full z-20">
      <div className="w-full h-full"></div>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <FontAwesomeIcon icon="circle-play" className={props.className} />
      </div>
    </div>
  );
};

export default VideoPlayerIcon;
