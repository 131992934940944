import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type TwoColumnLayoutProps = ComponentProps & {
  params: {
    Layout: string;
    InvertOnMobile: boolean;
    BackgroundColor: string;
  };
};

const GetClassFromColor = (backgroundColor: string): string => {
  if (backgroundColor == null) {
    return '';
  }

  switch (backgroundColor.toLowerCase()) {
    case 'white':
      return 'bg-white';
    case 'base-lighter':
      return 'bg-base-lighter';
    default:
      return 'bg-white';
  }
};

const GetClassFromLayout = (layout: string): string => {
  switch (layout) {
    case '1|1':
      return 'xl:w-1/2';
    case '1|2':
      return 'xl:w-1/3';
    case '2|1':
      return 'xl:w-2/3';
    default:
      return 'xl:w-1/2';
  }
};

const TwoColumnLayout = (props: TwoColumnLayoutProps): JSX.Element => {
  const layoutClass = GetClassFromLayout(props.params.Layout);
  const BackgroundColorClass = GetClassFromColor(props.params.BackgroundColor);

  return (
    <div className={`${BackgroundColorClass}`}>
      <div
        className={`tc-layout w-full flex max-w-screen-2xl xl:flex-row px-6 py-16 m-auto ${
          props.params.InvertOnMobile ? 'flex-col-reverse' : 'flex-col'
        }`}
      >
        <div className={`w-full md:mr-8 ${layoutClass}`}>
          <Placeholder name="jss-left" rendering={props.rendering} />
        </div>
        <div className="w-full md:flex-1">
          <Placeholder name="jss-right" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

export default TwoColumnLayout;
