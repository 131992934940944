import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

function ContentIntro(props: ComponentProps): JSX.Element {
  const phName = 'content-intro';
  return (
    <div className="max-w-content mx-auto">
      <Placeholder name={phName} rendering={props.rendering} />
    </div>
  );
}

export default ContentIntro;
