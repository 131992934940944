import {
  ImageField,
  LinkField,
  Text,
  Field,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';

type FullWidthFeatureProps = ComponentProps & {
  fields: {
    image: ImageField;
    subtitle: Field<string>;
    heading: Field<string>;
    description: Field<string>;
    link: LinkField;
  };
};

const FullWidthFeature = (props: FullWidthFeatureProps): JSX.Element => {
  return (
    <section className="relative max-w-content mx-6 lg:mx-8   2xl:mx-auto">
      <div className="aspect-[2/1] relative">
        <NextImage field={props.fields?.image} className="rounded" layout="fill" />
      </div>
      <div className="bg-white md:bottom-10 lg:bottom-20 md:mx-10 lg:mx-20 rounded relative py-12 md:p-12">
        <Text
          tag="div"
          field={props.fields?.subtitle}
          className="accent-2 text-center mb-2 uppercase leading-5.4  lg:leading-7 tracking-[0.25em]  text-avidpurple"
        />
        <Text
          tag="h3"
          field={props.fields?.heading}
          className="text-center   text-lightalmostblack leading-7.3 lg:leading-8.5"
        />
        <Text
          tag="div"
          field={props.fields?.description}
          className="text-center my-6 text-almost-black intro-text"
        />
        <div className="text-center justify-center flex">
          <Link field={props.fields?.link} className="btn-primary w-max" />
        </div>
      </div>
    </section>
  );
};

export default withDatasourceCheck()<FullWidthFeatureProps>(FullWidthFeature);
