import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const IntroBlockContainer = (props: ComponentProps): JSX.Element => {
  return (
    <section className="max-w-content mx-auto sm:my-5 lg:my-10">
      <Placeholder name="jss-intro-blocks-container" rendering={props?.rendering} />
    </section>
  );
};

export default IntroBlockContainer;
