import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import { useI18n } from 'next-localization';
import { useAppDispatch } from 'lib/store/hooks';
import {
  setSelectedSource,
  CustomSource,
  PaymentAddress,
} from 'components/checkout/checkout-slice';
import { Source } from 'components/checkout/checkout-models';
import { CartItem } from 'components/cart/cart-models';
import {
  getSavedExpressPayment,
  removeSavedExpressPayment,
} from 'components/checkout/checkout-provider';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { appmanCommands } from 'lib/myavid/myavid-api';

import dynamic from 'next/dynamic';
import { isFeatureEnabled } from 'lib/feature-flags/feature-flags';
const MPSavedPaymentMethods = dynamic(() => import('./MPSavedPaymentMethods'));
const MarketplaceBillingAddress = dynamic(() => import('./MarketplaceBillingAddress'));
const DropinPayments = dynamic(() => import('components/checkout/shop/DropinPayments'));
const MPNewPaymentMethodSummary = dynamic(() => import('./MPNewPaymentMethodSummary'));
const MarketplacePayPalButton = dynamic(() => import('./MarketplacePayPalButton'));
const Spinner = dynamic(() => import('components/spinner/Spinner'));

export type MarketplacePaymentMethodsProps = {
  paymentMethods: Source[];
  paymentMethodStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  selectedSource: CustomSource | null;
  billingAddress: PaymentAddress | null;
  cartItems: CartItem[];
  continueShoppingLink: LinkField;
  checkoutStage: string;
  setHasWarning: (v) => void;
  setAlertMessage: (v) => void;
};

const MarketplacePaymentMethods = (props: MarketplacePaymentMethodsProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { paymentMethods, paymentMethodStatus, selectedSource, cartItems, billingAddress } = props;
  const [inNewPaymentFlow, setInNewPaymentFlow] = useState(false);
  const [renderDropIn, setRenderDropIn] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isAvidLinkDesktop, setIsAvidLinkDesktop] = useState(false);
  const includesSubscriptionProduct = cartItems?.some(
    (item) => item.cartItem.productDetails.fields.XC_SubscriptionType__c !== 'N/A'
  );
  const isMultiCurrenciesPaymentMethodsEnabled = isFeatureEnabled(
    'multi-currency-payment-methods-SITECORE-34'
  );

  useEffect(() => {
    if (selectedSource != null) {
      setInNewPaymentFlow(false);
      return;
    }

    if (
      paymentMethodStatus === 'succeeded' &&
      paymentMethods != null &&
      paymentMethods.length <= 0
    ) {
      setInNewPaymentFlow(true);
      return;
    }
  }, [selectedSource, paymentMethods, paymentMethodStatus]);

  useEffect(() => {
    const savedExpressPayment = getSavedExpressPayment();
    if (savedExpressPayment == null) {
      return;
    }

    dispatch(
      setSelectedSource({
        source: savedExpressPayment,
        isNew: true,
      })
    );

    removeSavedExpressPayment();
  }, [dispatch]);

  useEffect(() => {
    if (billingAddress == null || props.checkoutStage === 'mp-paypal-billing') {
      return;
    }

    setRenderDropIn(true);
  }, [billingAddress, props.checkoutStage]);

  useEffect(() => {
    setTimeout(() => {
      const docClasses = document.body.classList;
      if (docClasses.contains('avid-link-desktop')) {
        setIsAvidLinkDesktop(true);
      }
    }, 1000);
  }, []);

  const onClickNewPaymentMethodHandler = (): void => {
    dispatch(setSelectedSource(null));
    setInNewPaymentFlow(true);
  };

  const onClickCancelNewPaymentHandler = (): void => {
    setInNewPaymentFlow(false);
    isMultiCurrenciesPaymentMethodsEnabled && setRenderDropIn(false);
  };

  const googlePayInAvidLinkCallback = (): void => {
    if (!isAvidLinkDesktop) {
      return;
    }
    setTimeout(appmanCommands.closepopup, 1000);
  };

  return (
    <div className="relative w-full text-mp-txt-neutralLight m-auto p-2.5 bg-mp-background-card mb-20 md:mb-0">
      {showSpinner && <Spinner color="#20BBD0" />}
      {inNewPaymentFlow && (
        <div>
          {!renderDropIn && (
            <MarketplaceBillingAddress
              firstPaymentMethod={
                paymentMethods != null && paymentMethods.length > 0 ? false : true
              }
              onClickCancelNewPaymentHandler={
                paymentMethods != null && paymentMethods.length > 0
                  ? onClickCancelNewPaymentHandler
                  : undefined
              }
            />
          )}
          {renderDropIn && (
            <div className="text-center bg-white text-darkmostlyblack m-auto p-2.5">
              <h3 className="mb-10 text-2xl text-center xl:text-[1.75rem] xl:text-left">
                {paymentMethods != null && paymentMethods.length > 0
                  ? t('Billing_AddNewPaymentMethod')
                  : t('Billing_AddPaymentMethod')}
              </h3>
              <div className="text-left bg-white mb-3">
                <DropinPayments
                  address={billingAddress}
                  includesSubscriptionProduct={includesSubscriptionProduct as boolean}
                  onSuccessPaymentAddedHandler={googlePayInAvidLinkCallback}
                />
              </div>
              <button
                type="button"
                className="text-white text-sm px-3.5 py-1 rounded w-44 bg-darkgrayishblue hover:bg-darkbgrayishblue ml-3"
                onClick={onClickCancelNewPaymentHandler}
              >
                {t('Billing_Cancel')}
              </button>
            </div>
          )}
        </div>
      )}
      {!inNewPaymentFlow && (
        <div className="flex flex-row justify-between">
          <h3>{t('MP_Payment_Method')}</h3>
          <NextLink
            field={props.continueShoppingLink}
            className="text-mp-txt-neutral text-sm px-1 uppercase mt-2 sm:mt-0"
            onClick={(): void => {
              dispatch(setSelectedSource(null));
            }}
          >
            {t('mp_cancel')}
          </NextLink>
        </div>
      )}
      {!inNewPaymentFlow && selectedSource?.isNew != true && (
        <MPSavedPaymentMethods
          onClickNewPaymentHandler={onClickNewPaymentMethodHandler}
          setHasWarning={props.setHasWarning}
          setAlertMessage={props.setAlertMessage}
        />
      )}
      {!inNewPaymentFlow && selectedSource != null && selectedSource.isNew && (
        <MPNewPaymentMethodSummary />
      )}
      {selectedSource == null && isAvidLinkDesktop && (
        <MarketplacePayPalButton
          includesSubscriptionProduct={includesSubscriptionProduct as boolean}
          setSpinner={(state: boolean): void => {
            setShowSpinner(state);
          }}
          setNewPaymentFlow={(state: boolean): void => {
            setInNewPaymentFlow(state);
          }}
          checkoutStage={props.checkoutStage}
          inNewPaymentFlow={inNewPaymentFlow}
          renderDropIn={renderDropIn}
        />
      )}
    </div>
  );
};

export default MarketplacePaymentMethods;
