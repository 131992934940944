import { Field, ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { ComponentProps } from 'lib/component-props';

type ImageItemProps = ComponentProps & {
  fields: {
    caption: Field<string>;
    image: ImageField;
  };
};

const ImageItem = (props: ImageItemProps): JSX.Element => {
  return (
    <div>
      <figure className="relative grid w-full">
        <NextImage field={props?.fields?.image} className="object-cover rounded w-full" />
      </figure>
      <figcaption className="mt-2 text-sm italic">
        <Text field={props?.fields?.caption}></Text>
      </figcaption>
    </div>
  );
};

export default ImageItem;
