import { Field, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useExternalScript } from '../lib/hooks/use-external-script';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
type RawTextProps = ComponentProps & {
  fields: {
    html: Field<string>;
    script: Field<string>;
    scriptId: Field<string>;
  };
};

const RawText = (props: RawTextProps): JSX.Element => {
  const editorActive = isEditorActive();
  const externalScript = props.fields?.script?.value;
  const externalId = props.fields?.scriptId?.value;
  const state = useExternalScript(externalScript, externalId);
  if (editorActive)
    return (
      <p>Alert: RawScript component is disabled in Experience Editor, Please use Content Editor</p>
    );
  return <>{state !== 'error' && <RichText field={props.fields?.html} className="rte" />}</>;
};

export default withDatasourceCheck()<RawTextProps>(RawText);
