type SitecoreTaxonomyTagsProps = {
  taxonomyValues: string[];
  taxonomyName: string;
};

const SitecoreTaxonomyTags: React.FC<SitecoreTaxonomyTagsProps> = (props) => {
  const { taxonomyName, taxonomyValues } = props;

  return (
    <div>
      {taxonomyValues?.map((value, index) => (
        <div key={index} style={{ backgroundColor: 'lightblue', maxWidth: '200px' }}>
          <a href={`/search#f-${taxonomyName}=${value}`}>{value}</a>
        </div>
      ))}
    </div>
  );
};

export default SitecoreTaxonomyTags;
