import { ComponentProps } from 'lib/component-props';
import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComparisonTableCardItem } from './comparison-table-card-item';
import { DropdownItem } from '../inline-heading-and-dropdown/dropdown';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { ProductVariationsData, Variant } from 'lib/commerce/product/model/product-models';
import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import ComparisonColumnHeader from './ComparisonTableMobileHeader';
import { CategoryAttributes } from './table-models';
import CategorySection from './CategorySection';
import { useI18n } from 'next-localization';
import { generateCategoryViewModel } from 'lib/commerce/product/product-util';

type ComparisonTableMobileProps = ComponentProps & {
  fields: {
    productTableId: Field<string>;
    cards: Array<ComparisonTableCardItem>;
    frequencyTypeOptions: Array<DropdownItem>;
    defaultFrequencyType: Array<DropdownItem>;
    userTypeOptions: Array<DropdownItem>;
    defaultUserType: Array<DropdownItem>;
  };
  productIdsToCards: Map<string, ComparisonTableCardItem>;
  productTierDetails: ProductVariationsData;
};

const ComparisonTableMobile = (props: ComparisonTableMobileProps): JSX.Element => {
  const { t } = useI18n();
  const [categoryViewModel, setCategoryViewModel] = useState<Array<CategoryAttributes>>([]);
  const { productIdsToCards, productTierDetails } = props;
  const [selectedLeftVariant, setSelectLeftVariant] = useState<Variant>(
    productTierDetails.variants[0]
  );
  const [selectedRightVariant, setSelectRightVariant] = useState<Variant>(
    productTierDetails.variants[1]
  );

  const [selectedLeftCard, setSelectLeftCard] = useState<ComparisonTableCardItem>();
  const [selectedRightCard, setSelectRightCard] = useState<ComparisonTableCardItem>();

  const [ref, regularProductCardHeadersOnScreen] = useInView({
    threshold: 0.45,
  });

  useEffect(() => {
    setSelectLeftVariant(productTierDetails.variants[0]);
    setSelectRightVariant(productTierDetails.variants[1]);
  }, [productTierDetails]);

  useEffect(() => {
    if (selectedLeftVariant == null) {
      return;
    }

    const leftCard = productIdsToCards.get(selectedLeftVariant.productId);
    if (leftCard == null) {
      return;
    }

    setSelectLeftCard(leftCard);
  }, [productIdsToCards, selectedLeftVariant]);

  useEffect(() => {
    if (selectedRightVariant == null) {
      return;
    }

    const rightCard = productIdsToCards.get(selectedRightVariant.productId);
    if (rightCard == null) {
      return;
    }

    setSelectRightCard(rightCard);
  }, [productIdsToCards, selectedRightVariant]);

  const onChangeLeftVariantHandler = (productId: string): void => {
    const variant = productTierDetails?.variants.filter(
      (variant) => variant.productId == productId
    );
    if (variant == null || variant.length == 0) {
      return;
    }
    setSelectLeftVariant(variant[0]);
    const card = productIdsToCards.get(productId);
    if (card == null) {
      return;
    }

    setSelectLeftCard(card);
  };

  const onChangeRightVariantHandler = (productId: string): void => {
    const variant = productTierDetails?.variants.filter(
      (variant) => variant.productId == productId
    );
    if (variant == null || variant.length == 0) {
      return;
    }
    setSelectRightVariant(variant[0]);

    const card = productIdsToCards.get(productId);
    if (card == null) {
      return;
    }

    setSelectRightCard(card);
  };

  useEffect(() => {
    if (selectedLeftCard == null || selectedRightCard == null) {
      return;
    }

    setCategoryViewModel(
      generateCategoryViewModel(
        props.fields.cards,
        Array.from([selectedLeftCard, selectedRightCard])
      )
    );
  }, [selectedLeftCard, selectedRightCard, props.fields.cards]);

  const productVariantOptions = productTierDetails?.variants.map((variant) => {
    const card = productIdsToCards.get(variant.productId);
    return {
      value: variant.productId,
      label: card?.fields.product[0].fields.productName.value ?? '',
    };
  });

  const defaultProductVariantLeftOption =
    productVariantOptions != null
      ? {
          value: productVariantOptions[0]?.value,
          label: productVariantOptions[0]?.label,
        }
      : null;

  const defaultProductVariantRightOption =
    productVariantOptions != null
      ? {
          value: productVariantOptions[1]?.value,
          label: productVariantOptions[1]?.label,
        }
      : null;

  if (
    productTierDetails == null ||
    productVariantOptions == null ||
    defaultProductVariantLeftOption == null ||
    defaultProductVariantRightOption == null ||
    selectedLeftCard == null ||
    selectedRightCard == null
  ) {
    return <></>;
  }

  const StickyContainer = (): JSX.Element => {
    if (regularProductCardHeadersOnScreen) {
      return <></>;
    }

    return (
      <div className="sticky items-center top-20 lg:top-32 text-almost-black border-b border-base-normal shadow-[0_4px_16px_0px_rgba(0,0,0,0.12)] animate-slide_down z-10">
        <div className="flex">
          <ComparisonColumnHeader
            ForceFeaturedLayout
            CardItem={selectedLeftCard}
            Variant={selectedLeftVariant}
            Dropdown={
              <div>
                <DropdownMenu
                  defaultValue={defaultProductVariantLeftOption}
                  options={productVariantOptions}
                  passValueToParentFunction={onChangeLeftVariantHandler}
                  className="w-full md:w-fit"
                />
              </div>
            }
            FrequencyLabel={t(
              `MultiTierPricingCard_Card_Frequency_${selectedLeftVariant.subscriptionType.toUpperCase()}`
            )}
            Mini={true}
          />
          <ComparisonColumnHeader
            ForceFeaturedLayout
            CardItem={selectedRightCard}
            Variant={selectedRightVariant}
            Dropdown={
              <div>
                <DropdownMenu
                  defaultValue={defaultProductVariantRightOption}
                  options={productVariantOptions}
                  passValueToParentFunction={onChangeRightVariantHandler}
                  className="w-full md:w-fit"
                />
              </div>
            }
            FrequencyLabel={t(
              `MultiTierPricingCard_Card_Frequency_${selectedRightVariant.subscriptionType.toUpperCase()}`
            )}
            Mini={true}
          />
        </div>
      </div>
    );
  };

  return (
    <li>
      <div ref={ref} className="flex">
        <ComparisonColumnHeader
          ForceFeaturedLayout
          CardItem={selectedLeftCard}
          Variant={selectedLeftVariant}
          Dropdown={
            <div>
              <DropdownMenu
                defaultValue={defaultProductVariantLeftOption}
                options={productVariantOptions}
                passValueToParentFunction={onChangeLeftVariantHandler}
                className="w-full md:w-fit"
              />
            </div>
          }
          FrequencyLabel={t(
            `MultiTierPricingCard_Card_Frequency_${selectedLeftVariant.subscriptionType.toUpperCase()}`
          )}
          Mini={false}
        />
        <ComparisonColumnHeader
          ForceFeaturedLayout
          CardItem={selectedRightCard}
          Variant={selectedRightVariant}
          Dropdown={
            <div>
              <DropdownMenu
                defaultValue={defaultProductVariantRightOption}
                options={productVariantOptions}
                passValueToParentFunction={onChangeRightVariantHandler}
                className="w-full md:w-fit"
              />
            </div>
          }
          FrequencyLabel={t(
            `MultiTierPricingCard_Card_Frequency_${selectedRightVariant.subscriptionType.toUpperCase()}`
          )}
          Mini={false}
        />
      </div>

      <StickyContainer />
      <ul role="table">
        {categoryViewModel.map((category) => {
          return <CategorySection key={category.category.id} categoryAttributes={category} />;
        })}
      </ul>
    </li>
  );
};

export default withDatasourceCheck()(ComparisonTableMobile);
