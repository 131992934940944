import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { getCountries } from 'lib/commerce/countries/countries-slice';
import { setCheckoutStage } from 'components/checkout/checkout-slice';

import dynamic from 'next/dynamic';
const PaymentCollection = dynamic(() => import('components/checkout/shop/PaymentCollection'));

type PaymentProps = ComponentProps & {
  fields: {
    title: Field<string>;
  };
};

const Payment = (props: PaymentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const checkout = useAppSelector((state) => state.checkout);
  const paymentMethods = checkout.paymentMethods;
  const paymentMethodStatus = checkout.paymentMethodStatus;
  const selectedSource = checkout.selectedSource;

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    const checkPaymentMethods = async (): Promise<void> => {
      if (paymentMethodStatus === 'succeeded' && selectedSource === null) {
        if (paymentMethods.length > 0) {
          dispatch(setCheckoutStage('payment-method'));
        } else {
          dispatch(setCheckoutStage('payment-billing'));
        }
      }
    };

    checkPaymentMethods();
  }, [dispatch, paymentMethodStatus, paymentMethods, selectedSource]);

  return (
    <>
      <div>
        <PaymentCollection fields={props.fields} />
      </div>
    </>
  );
};

export default withDatasourceCheck()<PaymentProps>(Payment);
