/* eslint-disable @typescript-eslint/no-explicit-any */
import ExpressGooglePay from './ExpressGooglePay';
import ExpressPayPal from './ExpressPaypal';
import { useCartData } from 'components/cart/cart-hooks';
import useDigitalRiverInstance from 'components/checkout/dr-elements-hook';

export type ExpressPaymentProps = {
  digitalRiverInstance: any;
  includesSubscriptionProduct?: boolean;
};

const ExpressPayments = (): JSX.Element => {
  const cartData = useCartData();
  const includesSubscriptionProduct = cartData?.cartItems?.some(
    (item) => item.cartItem.productDetails.fields.XC_SubscriptionType__c !== 'N/A'
  );

  const drInstance = useDigitalRiverInstance();

  return (
    <div className="w-full mb-4">
      <div className="text-center my-4 body">or</div>
      <div>
        {drInstance != null && (
          <>
            <div className="mb-4">
              <ExpressPayPal
                digitalRiverInstance={drInstance}
                includesSubscriptionProduct={includesSubscriptionProduct}
              />
            </div>
            <div>
              <ExpressGooglePay
                digitalRiverInstance={drInstance}
                includesSubscriptionProduct={includesSubscriptionProduct}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpressPayments;
