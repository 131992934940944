import { ProductPricingDataUnit } from 'lib/commerce/product/model/product-models';

export type PriceDataItem = {
  pricesArray: ProductPricingDataUnit[];
  discountValue?: number;
} | null;

export const getDiscountValue = (productPricingData: ProductPricingDataUnit[]): number => {
  return productPricingData.reduce((discount, prices) => {
    const value = Math.round(100 - (prices.unitPrice / prices.listPrice) * 100);
    return value > discount ? value : discount;
  }, 0);
};

export const getPriceRange = (
  priceData: PriceDataItem | null
): {
  lowestPrice: number;
  highestPrice: number;
} => {
  let lowestPrice = 0;
  let highestPrice = 0;
  priceData?.pricesArray.forEach((option) => {
    if (option.unitPrice > highestPrice) {
      highestPrice = option.unitPrice;
    }
    if (
      (lowestPrice === 0 && option.unitPrice > 0) ||
      (lowestPrice > 0 && option.unitPrice < lowestPrice)
    ) {
      lowestPrice = option.unitPrice;
    }
  });
  return {
    lowestPrice,
    highestPrice,
  };
};
