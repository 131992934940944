import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const ContentBlockGrid = (props: ComponentProps): JSX.Element => {
  return (
    <ul className="grid grid-cols-1 my-6 md:grid-cols-2 md:gap-x-8 xl:mt-0 gap-y-10 md:gap-y-16 max-w-content mx-auto">
      <Placeholder name="jss-content-block-grid" rendering={props.rendering} />
    </ul>
  );
};

export default ContentBlockGrid;
