import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { useState } from 'react';
import { NavigationGroup, NavigationLink } from '../global-navigation/shop/navigation-props';

type LocalNavigationContentProps = {
  menuItems: NavigationGroup[];
  pageId: string;
  containerClassName?: string;
  LinkClassName?: string;
  IconClassName?: string;
};

const LocalNavigationContent = (props: LocalNavigationContentProps): JSX.Element => {
  const { menuItems, pageId, containerClassName, LinkClassName, IconClassName } = props;

  const isLinkCurrentPage = (linkId: string | undefined): boolean => {
    if (linkId === undefined) return false;
    return linkId.replace(/[{}-]/g, '') === pageId;
  };

  const [isOpenArray, setIsOpenArray] = useState(
    menuItems.map((menuItem) =>
      pageId ===
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((menuItem.link.jsonValue.value.id as any)?.replace(/[{}-]/g, '') as string)
        ? true
        : false
    )
  );

  const onClickToggleTabHandler = (index: number): void => {
    const newArray = [...isOpenArray];
    if (isOpenArray[index]) newArray[index] = false;
    else newArray[index] = true;

    setIsOpenArray(newArray);
  };

  const currentPageStyles = 'border-l-4 border-l-primary body-bold rounded-none';

  return (
    <ul className={`w-full ${containerClassName}`}>
      {menuItems.map((menuItem, index) => (
        <li key={index}>
          <details
            className={`group border-base-normal disabled:text-base-normal ${
              menuItem.children.results.length > 0 && 'open:border-b'
            }`}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            open={isLinkCurrentPage(menuItem.link.jsonValue.value.id as any as string)}
          >
            <summary
              onClick={(): void => onClickToggleTabHandler(index)}
              className={`group label flex justify-between items-center border-b border-base-normal hover:cursor-pointer focus-visible:outline-none body ${
                index === 0 && ' border-t'
              } `}
              tabIndex={menuItem.children.results.length > 0 ? 1 : -1}
            >
              <div
                onClick={(e): void => e.stopPropagation()}
                className="flex-grow rounded-none p-4 pr-0"
              >
                <NextLink
                  field={menuItem.link.jsonValue}
                  className={`text-left focus-visible:rounded-none focus-visible:outline-2 w-full hover:underline ${
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    isLinkCurrentPage(menuItem.link.jsonValue.value.id as any as string) &&
                    currentPageStyles
                  } ${
                    menuItem.children.results.length > 0 &&
                    'hover:bg-base-light focus-visible:outline-2'
                  } ${LinkClassName}`}
                />
              </div>
              {menuItem.children.results.length > 0 && (
                <div
                  className={`h-full bg-white hover:bg-base-light group-focus-visible:ring-2 group-focus-visible:bg-base-light ring-primary ${IconClassName}`}
                >
                  <FontAwesomeIcon
                    icon={isOpenArray[index] ? faChevronUp : faChevronDown}
                    className="text-xs text-primary"
                  />
                </div>
              )}
            </summary>

            {menuItem.children.results.length > 0 && (
              <div className="flex flex-col px-5 py-6 text-left space-y-4 body group-open:bg-base-lighter">
                {menuItem.children.results.map((child: NavigationLink, index) => (
                  <NextLink
                    key={index}
                    field={child.link.jsonValue}
                    className="text-left hover:underline focus-visible:outline-offset-1"
                  />
                ))}
              </div>
            )}
          </details>
        </li>
      ))}
    </ul>
  );
};

export default LocalNavigationContent;
