import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { toSlug } from 'lib/utils/string-format';

type AnchorLinkJumpToProps = {
  anchorLinkText: Field<string>;
  className: string;
};

const AnchorLinkJumpTo = (props: AnchorLinkJumpToProps): JSX.Element => {
  return (
    <>
      {props.anchorLinkText && (
        <div id={toSlug(props.anchorLinkText.value)} className={props.className} />
      )}
    </>
  );
};

export default AnchorLinkJumpTo;
