/* eslint-disable @typescript-eslint/no-explicit-any */
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import GeneralModalList from '../../components/general-modal/GeneralModalList';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
type GeneralModalProps = ComponentProps & {
  name: string;
  generalModal: JSX.Element;
};

const GeneralModalContainer = (props: GeneralModalProps): JSX.Element => {
  const editorActive = isEditorActive();

  return (
    <>
      <div className={editorActive ? '' : 'hidden'}>{props?.generalModal}</div>
      {!editorActive && <GeneralModalList>{props?.generalModal}</GeneralModalList>}
    </>
  );
};

export default withPlaceholder({ placeholder: 'jss-generalmodal-container', prop: 'generalModal' })(
  GeneralModalContainer
);
