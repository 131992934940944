/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CartSummary, GetCartItemsData } from 'components/cart/cart-models';
import { saveExpressPayment } from 'components/checkout/checkout-provider';
import { useAppSelector } from 'lib/store/hooks';
import { useEffect, useState, useMemo } from 'react';
import { ExpressPaymentProps } from './ExpressPayments';
import { getCheckoutAuthUrl } from 'lib/authentication/account-provider';

const ExpressGooglePay = (props: ExpressPaymentProps): JSX.Element => {
  const [button, setButton] = useState<any>(null);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.cartItemsData;
  const cartSummary = cart.cartSummaryData;

  const googlePaySourcePayload = useMemo(
    () => computedGooglePayPayload(cartSummary, cartItems),
    [cartSummary, cartItems]
  );

  const onSourceHandler = async (event: any): Promise<void> => {
    saveExpressPayment(event.source);

    event.complete('success');
    window.location.href = getCheckoutAuthUrl(`${window.location.origin}/checkout`);
  };

  useEffect(() => {
    const googleWrapper = document.getElementById('googlepay-element');
    if (
      button == null &&
      (cartItems?.cartItems.length || 0 > 0) &&
      googleWrapper?.hasChildNodes() === false
    ) {
      const paymentRequestData = props.digitalRiverInstance.paymentRequest(googlePaySourcePayload);
      const googlePayButton = props.digitalRiverInstance.createElement(
        'googlepay',
        paymentRequestData
      );

      if (props.includesSubscriptionProduct) {
        googlePayButton.usage = 'subscription';
        googlePayButton.futureUse = true;
        googlePayButton.options.usage = 'subscription';
        googlePayButton.options.futureUse = true;
      }

      googlePayButton.mount('googlepay-element');

      googlePayButton.on('source', function (event: any) {
        onSourceHandler(event);
      });

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      googlePayButton.on('shippingaddresschange', function (_event: any) {
        //TODO: Add logic to update address when shipping address is applicable
      });

      setButton(googlePayButton);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [button, cartItems, googlePaySourcePayload, props.digitalRiverInstance]);

  useEffect(() => {
    if (button != null) {
      button.update(googlePaySourcePayload);
    }
  }, [googlePaySourcePayload, button]);

  return (
    <>
      <div>
        <div id="googlepay-element"></div>
      </div>
    </>
  );
};

const computedGooglePayPayload = (
  summary: CartSummary | null,
  items: GetCartItemsData | null
): any => {
  return {
    env: process.env.NEXT_PUBLIC_DIGITALRIVER_ENVIRONMENT,
    country: 'US', //TODO: Pull from somewhere
    currency: summary?.currencyIsoCode || 'USD',
    total: {
      label: 'Order Total',
      amount: +(summary?.grandTotalAmount || 0),
    },
    displayItems: items?.cartItems.map((item) => {
      return {
        label: item.cartItem.name,
        amount: +item.cartItem.totalPrice,
      };
    }),
    shippingOptions: {},
    style: {
      buttonType: 'plain',
      buttonColor: 'dark',
      buttonLanguage: 'en',
      height: '44px',
      buttonSizeMode: 'fill',
    },
    requestShipping: false,
  };
};

export default ExpressGooglePay;
