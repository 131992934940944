import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { TargetItemFeaturesValue } from 'src/pages/api/product/models/product';
import PickAPlanPricingCardFeatureModal from './PickAPlanPricingCardFeatureModal';

type SelectYourPlanProps = {
  features: TargetItemFeaturesValue[];
};

const SelectYourPlanLearnMore = (props: SelectYourPlanProps): JSX.Element => {
  const [display, setDisplay] = useState(true);
  const handleDisplay = (): boolean => {
    return !display;
  };
  const showHide = (): void => {
    setDisplay(handleDisplay);
  };

  return (
    <>
      <div
        className={`text-mp-txt-bright bg-mp-background-darker cursor-pointer w-full text-center uppercase pt-2.5 pb-2.5 relative ${
          display
            ? 'font-normal border-solid border-b border-b-mp-txt-primary'
            : 'text-mp-txt-neutral border-solid border-b border-b-mp-txt-neutral border-opacity-10'
        }`}
        onClick={showHide}
      >
        <div className="">Key features include</div>
        <FontAwesomeIcon
          icon={faChevronUp}
          className={`absolute top-3 right-5 cursor-pointer text-mp-txt-primary
                        ${display ? 'rotate-0' : 'rotate-180'}
                      `}
        />
      </div>
      <div className={`${display ? 'block' : 'hidden'} p-5 bg-mp-background-card`}>
        {props.features.map((feature, index) => (
          <div key={index} className="flex items-center">
            <FontAwesomeIcon icon={faCheck} className={`mr-1 `} />
            <p className="small-text">
              {feature.heading.value.split(/({modalLinkText})/g).map((featureText, index) => {
                return featureText === '{modalLinkText}' ? (
                  <PickAPlanPricingCardFeatureModal
                    key={index}
                    modalLinkText={feature.modalLinkText}
                    {...feature.modal}
                  />
                ) : (
                  featureText
                );
                return featureText;
              })}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default SelectYourPlanLearnMore;
