import { ScaleLoader } from 'react-spinners';

export type SpinnerProps = {
  width?: number | undefined;
  height?: number | undefined;
  margin?: number | undefined;
  color?: string | undefined;
};

const Spinner = (props: SpinnerProps): JSX.Element => {
  return (
    <div className="absolute top-0 left-0 w-full h-full z-10">
      <div className="w-full h-full bg-almost-black opacity-20"></div>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rgb(139, 141, 143) text-bas">
        <ScaleLoader
          color={props.color ?? '#194BF5'}
          height={props.height ?? 47}
          margin={props.margin ?? 4}
          radius={0}
          width={props.width ?? 5}
        />
      </div>
    </div>
  );
};

export default Spinner;
