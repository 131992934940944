import { AtomicQueryError } from '@coveo/atomic-react';

type QueryErrorProps = React.ComponentProps<typeof AtomicQueryError>;

const QueryError: React.FC<QueryErrorProps> = (props) => {
  const styles = `
    atomic-query-error::part(icon) {
        display: none
    }

    atomic-query-error::part(more-info-btn) {
        display: none
    }

    atomic-query-error::part(doc-link) {
        display: none
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <AtomicQueryError>{props.children}</AtomicQueryError>
    </>
  );
};

export default QueryError;
