import { Field, withDatasourceCheck, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';
import { ComponentProps } from 'lib/component-props';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

export type BaseIconGridItemProps = ComponentProps & {
  fields: {
    icon: ReferenceStringItem;
    title: Field<string>;
    description: Field<string>;
  };
  params: {
    Layout: string;
  };
  children: JSX.Element | JSX.Element[] | boolean;
};

const Default = (props: BaseIconGridItemProps): JSX.Element => {
  const isThreeColumn = props.params?.Layout == 'three-column';
  const iconMap = {
    facebook: faFacebook,
    instagram: faInstagram,
    linkedin: faLinkedin,
    'x-twitter': faXTwitter,
  };
  const socialIcon = iconMap[props.fields.icon?.fields?.value?.value];
  return (
    <li className={`mt-6 lg:mt-14 px-4 ${isThreeColumn ? 'basis-1/3' : 'basis-1/2'}`}>
      <div className="flex items-center flex-col lg:items-center">
        {props.fields.icon?.fields?.value?.value && (
          <div className="w-16 h-16 lg:w-20 lg:h-20 flex items-center justify-center">
            {socialIcon ? (
              <FontAwesomeIcon
                className="text-almost-black text-[2rem] lg:text-[3rem] focus-visible:outline-none"
                icon={socialIcon as IconProp}
              />
            ) : (
              <FontAwesomeIcon
                icon={props.fields.icon.fields.value.value as IconProp}
                className="text-almost-black text-[2rem] lg:text-[3rem] focus-visible:outline-none"
              />
            )}
          </div>
        )}
        <div className="text-center">
          <Text tag="span" className="h4 mb-2 block" field={props.fields.title} />
          <div>
            <RichText field={props.fields.description} className="rte" />
          </div>
        </div>
        <div className="mt-4 text-center">{props.children}</div>
      </div>
    </li>
  );
};

export default withDatasourceCheck()<BaseIconGridItemProps>(Default);
