// import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { ComponentProps } from 'lib/component-props';
import { useState } from 'react';

type TopicProps = ComponentProps & {
  field: {
    jsonValue: LinkField;
  };
};

type RelatedTopicsProps = ComponentProps & {
  fields: {
    data: {
      contextItem: {
        field: {
          targetItems: TopicProps[];
        };
      };
    };
  };
};

const RelatedTopics = (props: RelatedTopicsProps): JSX.Element => {
  const relatedTopics = props.fields?.data?.contextItem?.field?.targetItems;
  const [islimitedDisplay, setislimitedDisplay] = useState(true);

  const limitedDisplayHandler = (): void => {
    setislimitedDisplay(false);
  };
  return (
    <>
      {relatedTopics && (
        <section className="max-w-content mx-auto my-10">
          <div className="text-base font-bold mb-3">Related Topics :</div>
          <ul className="flex flex-wrap gap-2  ">
            {relatedTopics.map((topic, index) => {
              return islimitedDisplay && index < 3 ? (
                <li
                  key={index}
                  className="bg-primary-lightest text-primary text-base font-normal px-4 py-1 rounded-4xl"
                >
                  <Link field={topic?.field?.jsonValue}></Link>
                </li>
              ) : (
                !islimitedDisplay && (
                  <li
                    key={index}
                    className="bg-primary-lightest text-primary text-base font-normal px-4 py-1 rounded-4xl"
                  >
                    <Link field={topic?.field?.jsonValue}></Link>
                  </li>
                )
              );
            })}
            {islimitedDisplay && relatedTopics && relatedTopics.length > 3 && (
              <li
                className="bg-primary-lightest text-primary text-base font-bold px-4 py-1 rounded-4xl"
                onClick={limitedDisplayHandler}
              >
                <button> + {relatedTopics?.length - 3} More</button>
              </li>
            )}
          </ul>
        </section>
      )}
    </>
  );
};

export default RelatedTopics;
