import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TargetItemFeaturesValue } from 'src/pages/api/product/models/product';
import SubscriptionPricingCardFeatureModal from './SubscriptionPricingCardFeatureModal';
import { ColorClassesType } from 'src/components/foundation/non-sitecore/themes/themes-options';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type SubscriptionPricingCardFeaturesProps = {
  features: TargetItemFeaturesValue[];
  featureHeading: Field<string>;
  colorClasses: ColorClassesType;
  isFeatured?: boolean;
};

const SubscriptionPricingCardFeatures = (
  props: SubscriptionPricingCardFeaturesProps
): JSX.Element => {
  const { colorClasses } = props;

  return (
    <div
      className={`w-full space-y-4 flex flex-col bg-base-lighter px-4 py-5 ${colorClasses.general.textMainColorClass} flex-grow`}
    >
      <Text tag="h5" field={props.featureHeading} className="text-center small-navigation-bold" />
      {props.features.map((feature, index) => (
        <div key={index} className="flex items-center">
          <FontAwesomeIcon
            icon={faCheck}
            className={`mr-1 ${
              props.isFeatured
                ? colorClasses.subscriptionPricingCard.textFeaturedPrimary
                : 'text-base-darker'
            }`}
          />
          <p className="small-text pl-5px">
            {feature.heading.value.split(/({modalLinkText})/g).map((featureText, index) => {
              return featureText === '{modalLinkText}' ? (
                <SubscriptionPricingCardFeatureModal
                  key={index}
                  modalLinkText={feature.modalLinkText}
                  colorClasses={colorClasses}
                  {...feature.modal}
                />
              ) : (
                featureText
              );
            })}
          </p>
        </div>
      ))}
    </div>
  );
};

export default SubscriptionPricingCardFeatures;
