import { useEffect, useState } from 'react';

export const AVID_LINK_MIN = 565;
export const SCREEN_SMALL = 640;
export const SCREEN_MEDIUM = 768;
export const SCREEN_LARGE = 1024;
export const SCREEN_XLARGE = 1280;
export const TAILWIND = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  '2xl': 1400,
  '3xl': 1700,
};

export const useScreenSize = (): { width: number; height: number } => {
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  function getCurrentDimension(): { width: number; height: number } {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimensions = (): void => {
      setScreenSize(getCurrentDimension());
    };

    window.addEventListener('resize', updateDimensions);

    updateDimensions();
    return (): void => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return screenSize;
};
