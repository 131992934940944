import { useEffect, useState } from 'react';
import {
  getMultiProductPricing,
  getSitecoreProductDetails,
} from 'lib/commerce/product/product-api';
import { Price } from 'lib/commerce/product/model/product-models';
import Carousel from 'components/carousel/Carousel';
import OptionalAddOnsCard from './OptionalAddOnsCard';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import Spinner from '../spinner/Spinner';

type OptionalAddOnsCarouselProps = {
  coveoRecomendationsIds: string[] | null;
  defaultRecomendationsIds: string[];
  currencyIsoCode: string;
};

export type OptionalAddonDetails =
  | (Price & {
      imgDetails?: {
        jsonValue: ImageField;
        value: string;
      };
      productUrl: string;
      productName: string;
      productSku: string;
      primaryCategory: string;
      additionalCategory1: string;
      additionalCategory2: string;
      additionalCategory3: string;
      term: string;
    })
  | null;

function OptionalAddOnsCarousel(props: OptionalAddOnsCarouselProps): JSX.Element {
  const products = props.coveoRecomendationsIds
    ? props.coveoRecomendationsIds
        .filter((id) => !props.defaultRecomendationsIds.includes(id))
        .concat(props.defaultRecomendationsIds)
    : props.defaultRecomendationsIds;
  const [productsArray, setProductArray] = useState<OptionalAddonDetails[] | null>(null);
  const contextLanguage = 'en';

  useEffect(() => {
    const fetchProductDataList = async (): Promise<void> => {
      const results = await getMultiProductPricing(products, props.currencyIsoCode);
      Promise.all(
        results?.data
          .filter((item) => item !== null)
          .map(async (priceData) => {
            const details = await getSitecoreProductDetails(priceData.productId, contextLanguage);
            if (
              details == null ||
              details.length == 0 ||
              details[0].productName?.value === undefined ||
              details[0].productName.value.length === 0
            ) {
              return Promise.resolve(null);
            }
            const output = {
              ...priceData,
              imgDetails: details[0].productImage,
              productUrl: details[0]?.productDetailsPageLink?.jsonValue?.value?.href as string,
              productName: details[0].productName.value,
              productSku: details[0]?.productCode.value,
              primaryCategory: details[0]?.primaryCategory?.targetItems[0]?.name?.value,
              additionalCategory1: details[0]?.additionalCategories?.targetItems[0]?.name?.value,
              additionalCategory2: details[0]?.additionalCategories?.targetItems[1]?.name?.value,
              additionalCategory3: details[0]?.additionalCategories?.targetItems[2]?.name?.value,
              term: details[0]?.term?.value ?? 'N/A',
            };
            return Promise.resolve(output);
          })
      )
        .then((dataListArray) => {
          setProductArray(dataListArray.filter((item) => item !== null));
        })
        .catch((error) => {
          console.log('An eror occured by fetching PriceList: ', error);
        });
    };
    fetchProductDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coveoRecomendationsIds]);

  return (
    <div className="flex flex-row justify-center realative">
      {productsArray === null && <Spinner />}
      {productsArray && (
        <div className="optional-addons-carusel w-full">
          <div className="sm:mt-0 md:mt-4 relative">
            <Carousel
              MaxItemsPerSlideOnDesktop="3"
              MaxItemsPerSlideOnTablet="2"
              MaxItemsPerSlideOnMobile="1"
              sliderClass={`flex flex-row justify-center flex-grow`}
              itemClass="justify-center w-1/3"
            >
              {productsArray.map((product, index) => {
                return <OptionalAddOnsCard key={index} product={product} />;
              })}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
}

export default OptionalAddOnsCarousel;
