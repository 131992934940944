import React, { useMemo } from 'react';
import { Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { default as NextLink } from 'components/foundation/non-sitecore/NextLink';
import { useI18n } from 'next-localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useCartData } from 'components/cart/cart-hooks';
import { useWebStoreCurrencies } from 'lib/commerce/webstore/webstore-hooks';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { applyCoupon, changeCurrency, removeCoupon } from 'components/cart/cart-slice';
import { deleteCartItem } from 'components/cart/cart-slice';

import dynamic from 'next/dynamic';
import { useSession } from 'next-auth/react';
import { getBearerToken, getCheckoutAuthUrl } from 'lib/authentication/account-provider';
const Spinner = dynamic(() => import('components/spinner/Spinner'));
const InlineSpinner = dynamic(() => import('components/spinner/InlineSpinner'));
const MarketplaceCartItem = dynamic(
  () => import('../../components/cart/marketplace/MarketplaceCartItem')
);
const MarketplaceModal = dynamic(() => import('../checkout/MarketplaceModal'));
const MarketplaceDropDown = dynamic(
  () => import('../../components/dropdown/marketplace/MarketplaceDropDown')
);

export type MarketplaceCartProps = {
  fields: {
    title: Field<string>;
    checkoutLink: LinkField;
    continueShoppingLink: LinkField;
    taxInfoText: Field<string>;
  };
};

const MarketplaceCart = (props: MarketplaceCartProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [promoCodeValue, setPromoCodeValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showCouponSpinner, setShowCouponSpinner] = useState<boolean>(false);
  const [showCurrencySpinner, setShowCurrencySpinner] = useState<boolean>(false);
  const [itemToRemove, setItemToRemove] = useState<string>('');
  const currencies = useWebStoreCurrencies();
  const cart = useAppSelector((state) => state.cart);
  const cartItemData = useCartData();
  const cartItems = cartItemData?.cartItems;
  const cartSummary = cartItemData?.cartSummary;
  const currencyIsoCode = cart?.currencyIsoCode;
  const hasCartItems = cartItems != null && cartItems.length > 0;
  const currencyOptions = currencies.map((currency) => {
    return { value: currency, label: `${currency} ${t(`Currency_Symbol_${currency}`)}` };
  });
  const cartHasCoupon = cartSummary?.coupons && cartSummary?.coupons.length > 0 ? true : false;

  const hasCheckoutLink = props.fields?.checkoutLink?.value?.href ? true : false;
  const returnUrl =
    hasCheckoutLink && props.fields.checkoutLink.value.href?.includes('http')
      ? props.fields.checkoutLink.value.href
      : `${process.env.MYAVID_RETURN_URL_HOST || process.env.VERCEL_URL || process.env.PUBLIC_URL}${
          props.fields.checkoutLink.value.href
        }`;
  const authCheckoutLink = getCheckoutAuthUrl(returnUrl);
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const onChangeCurrencyHandler = (currency: string): void => {
    setShowCurrencySpinner(true);
    dispatch(changeCurrency({ currencyIsoCode: currency, bearerToken: bearerToken }))
      .then(() => {
        setShowCurrencySpinner(false);
      })
      .catch((error) => {
        console.log(`Can't apply coupone ${promoCodeValue}: ${error.message}`);
        setShowCurrencySpinner(false);
      });
  };

  const applyPromoCode = (): void => {
    setShowCouponSpinner(true);
    setErrorMessage('');
    dispatch(applyCoupon({ couponCode: promoCodeValue, bearerToken: bearerToken }))
      .unwrap()
      .then((resp) => {
        if (!resp.success) {
          console.log(`Can't apply coupone ${promoCodeValue}`);
          setErrorMessage(t('Invalid_Coupon'));
        }
        setShowCouponSpinner(false);
      })
      .catch((error) => {
        console.log(`Can't apply coupone ${promoCodeValue}: ${error.message}`);
        setErrorMessage(t('Invalid_Coupon'));
        setShowCouponSpinner(false);
      });
  };

  const onClickRemoveCouponHandler = (cartCouponId: string): void => {
    setShowCouponSpinner(true);
    dispatch(removeCoupon({ cartCouponId, bearerToken: bearerToken }))
      .unwrap()
      .then(() => {
        setShowCouponSpinner(false);
        setPromoCodeValue('');
      })
      .catch((error) => {
        console.log(`Can't remove coupone ${promoCodeValue}: ${error.message}`);
        setErrorMessage(t('Invalid_Coupon'));
        setShowCouponSpinner(false);
      });
  };

  const removeItem = (): void => {
    setShowSpinner(true);
    closeModal();
    dispatch(deleteCartItem({ cartItemId: itemToRemove, bearerToken: bearerToken }))
      .then(() => {
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(`Can't delete from Cart cartItemId ${itemToRemove}: ${error.message}`);
        setShowSpinner(false);
      });
  };

  const cleanPromoData = (): void => {
    setErrorMessage('');
    setPromoCodeValue('');
  };

  const closeModal = (): void => {
    setItemToRemove('');
    setShowConfirmationModal(false);
  };

  const requestRemove = (cartItemId: string): void => {
    setItemToRemove(cartItemId);
    setShowConfirmationModal(true);
  };

  return (
    <div id="cart--marketplace" className="mt-4">
      {showConfirmationModal && (
        <MarketplaceModal
          title={t('Remove_Item_Modal_Title')}
          onCloseAction={closeModal}
          onOkAction={removeItem}
        >
          <span>{t(`Remove_Item_From_Cart_Confirm_text`)}</span>
        </MarketplaceModal>
      )}
      {showSpinner && <Spinner color="#20BBD0" />}
      <div className="cart-totals w-full m-auto mt-5 mb-5 p-2.5 relative bg-mp-background-card text-mp-txt-bright">
        <div className="flex flex-row justify-between w-full">
          <div className="text-lg text-mp-txt-neutralLight font-medium pt-1.5">
            {t('Marketplace_Cart_totals')}:
          </div>
          {cartSummary && cartSummary.totalProductAmountAfterAdjustments && (
            <div className="flex flex-row">
              {currencyIsoCode && !showCurrencySpinner && (
                <MarketplaceDropDown
                  key={currencyIsoCode}
                  defaultValue={{
                    value: currencyIsoCode,
                    label: `${currencyIsoCode} ${t(`Currency_Symbol_${currencyIsoCode}`)}`,
                  }}
                  options={currencyOptions}
                  passValueToParentFunction={onChangeCurrencyHandler}
                  className="md:w-32 text-left text-mp-txt-neutralLight mr-3"
                />
              )}
              {showCurrencySpinner && (
                <div className="pr-7 pt-1.5">
                  <InlineSpinner height={20} color="#20BBD0" />
                </div>
              )}
              <div className="pt-1.5 text-base text-end font-medium text-mp-txt-primary">
                {t(`Currency_Symbol_${currencyIsoCode}`)}
                {cartSummary != null ? cartSummary.totalProductAmountAfterAdjustments : 0}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between w-full">
          {cartSummary && cartSummary.totalPromotionalAdjustmentAmount.toString() !== '0.00' && (
            <>
              <div className="text-lg text-mp-txt-neutralLight font-medium">
                {t('Marketplace_Cart_Discount')}:
              </div>
              <div className="text-base text-end font-medium text-mp-txt-primary">
                {t(`Currency_Symbol_${currencyIsoCode}`)}
                {cartSummary.totalPromotionalAdjustmentAmount}
              </div>
            </>
          )}
        </div>
        <div className="flex flex-row justify-end mt-3">
          <div className="max-w-sm text-right">
            {showCouponSpinner && <InlineSpinner height={20} color="#20BBD0" />}
            {!cartHasCoupon && !showCouponSpinner && (
              <div className="mt-2.5 flex justify-between">
                <div className="border-b border-mp-txt-neutralLight mr-3 relative w-2/3">
                  {promoCodeValue && (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="inline-block text-mp-txt-neutralLight absolute left-0 top-2"
                      onClick={(): void => cleanPromoData()}
                    />
                  )}
                  <input
                    className="bg-mp-background-darker text-mp-txt-neutralLight w-full py-1 px-5 focus:outline-none focus-visible:outline-none uppercase placeholder:italic placeholder:capitalize"
                    placeholder="Enter Promo Code"
                    type="text"
                    value={promoCodeValue}
                    onChange={(e): void => {
                      setPromoCodeValue(e.currentTarget.value.toUpperCase());
                      if (!promoCodeValue) {
                        setErrorMessage('');
                      }
                    }}
                  />
                </div>
                <button
                  className={`${
                    !promoCodeValue
                      ? 'pointer-events-none bg-mp-btn-disabled text-mp-txt-neutralLight'
                      : 'bg-mp-btn-primary text-mp-txt-bright'
                  } hover:bg-mp-btn-light text-sm px-3.5 py-1 font-bold rounded w-1/3`}
                  onClick={(): void => applyPromoCode()}
                >
                  {t('Marketplace_Cart_Apply')}
                </button>
              </div>
            )}
            {cartHasCoupon && !showCouponSpinner && (
              <div className="inline-flex p-2 justify-between bg-mp-background-general">
                <div className="w-full text-end">
                  <p className="text-mp-txt-neutralLight">{cartSummary?.coupons[0].couponCode}</p>
                </div>
                <button
                  className="text-sm ml-2 text-mp-txt-primary hover:cursor-pointer underline"
                  onClick={(): void =>
                    onClickRemoveCouponHandler(cartSummary?.coupons[0].cartCouponId as string)
                  }
                >
                  {t('Cart_Remove')}
                </button>
              </div>
            )}
            <div className="w-full text-danger pl-3.5">{promoCodeValue ? errorMessage : ''}</div>
            <div className="mt-4 mb-2 flex justify-between">
              <NextLink
                field={props.fields.continueShoppingLink}
                className="bg-mp-background-general hover:bg-mp-background-darker text-sm px-8 py-2 rounded mr-4"
              >
                {t('Marketplace_Cart_Continue')}
              </NextLink>
              <a
                href={authCheckoutLink}
                onClick={(): void => {
                  setShowSpinner(true);
                }}
                className={`${
                  !hasCartItems
                    ? 'pointer-events-none bg-mp-btn-disabled text-mp-txt-neutralLight'
                    : 'bg-mp-btn-primary text-mp-txt-bright'
                } hover:bg-mp-btn-light text-sm font-bold px-4 py-2 rounded`}
              >
                {t('Marketplace_Cart_Checkout')}
              </a>
            </div>
          </div>
        </div>
        <RichText
          field={props.fields.taxInfoText}
          className="rte text-xs text-mp-txt-neutralLight mt-3 md:mt-0"
        />
      </div>
      {cartItems?.length !== undefined && cartItems?.length > 0 && (
        <div className="cart-items w-full m-auto mt-2">
          <div className="flex flex-row justify-between uppercase text-mp-txt-neutralLight border-b border-mp-txt-neutralLight">
            <div className="inline-block w-1/2 sm:w-2/3 lg:w-3/4">
              {t('Marketplace_Cart_Product')}
            </div>
            <div className="inline-block w-1/2 sm:w-1/3 lg:w-1/4">
              <div className="inline-block w-2/3 text-start">{t('Marketplace_Cart_Quantity')}</div>
              <div className="inline-block w-1/3 text-end">{t('Marketplace_Cart_Price')}</div>
            </div>
          </div>
          {cartItems.map((item) => (
            <MarketplaceCartItem
              key={item.cartItem.productId}
              name={item.cartItem.name}
              totalAmount={+item.cartItem.totalAmount}
              totalListPrice={+item.cartItem.totalListPrice}
              quantity={+item.cartItem.quantity}
              cartItemId={item.cartItem.cartItemId}
              productDetails={item.cartItem.productDetails}
              currencySymbol={t(`Currency_Symbol_${currencyIsoCode}`) || ''}
              requestRemove={requestRemove}
              actionsAllowed={true}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default MarketplaceCart;
