import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useState } from 'react';

import dynamic from 'next/dynamic';
const AlertBox = dynamic(() => import('components/alert/AlertBox'));

const GetClassFromAlertType = (alertType: string): string => {
  if (alertType == null) {
    return 'bg-info-light border-info text-info [&>svg]:text-almost-black';
  }

  switch (alertType.toLowerCase()) {
    case 'info':
      return 'bg-info-light border-info text-info [&>div>.dismisable-icon]:text-almost-black';
    case 'warning':
      return 'bg-warning-light border-warning text-warning [&>div>.dismisable-icon]:text-almost-black';
    case 'danger':
      return 'bg-danger-light border-danger text-danger [&>div>.dismisable-icon]:text-almost-black';
    case 'success':
      return 'bg-success-light border-success text-success [&>div>.dismisable-icon]:text-almost-black';
    case 'promotional':
      return 'bg-secondary border-secondary text-white [&>svg]:text-white';
    default:
      return 'bg-info-light border-info text-info [&>div>.dismisable-icon]:text-almost-black';
  }
};

export type AlertTextProps = ComponentProps & {
  fields: {
    alertMessage: Field<string>;
    link: LinkField;
    displayIcon: Field<boolean>;
    isDismissible: Field<boolean>;
    noFollow: Field<boolean>;
  };
  params: {
    alertType: string;
  };
};

const AlertText = (props: AlertTextProps): JSX.Element => {
  const alertTypeClass = GetClassFromAlertType(props.params?.alertType);
  const [showAlertText, setShowAlertText] = useState(true);

  const onClickDismissAlertHandler = (): void => {
    setShowAlertText(false);
  };

  return (
    <div className="flex justify-center">
      {showAlertText && (
        <div className="avid-grid-layout">
          <AlertBox
            containerClass={`${alertTypeClass} p-4 flex w-fit justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 col-span-12 lg:col-span-8 lg:col-start-3`}
            alertMessage={props.fields.alertMessage}
            showAlertBox={showAlertText}
            isDismissible={props.fields.isDismissible.value}
            displayIcon={props.fields.displayIcon.value}
            alertType={props.params.alertType}
            link={props.fields.link}
            onClickDismissAlertHandler={onClickDismissAlertHandler}
            noFollow={props.fields.noFollow}
          />
        </div>
      )}
    </div>
  );
};

export default withDatasourceCheck()<AlertTextProps>(AlertText);
