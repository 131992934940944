import { withPlaceholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ImageContainerProps = ComponentProps & {
  name: string;
  imageitems: JSX.Element;
  params: {
    imagelayout: string;
  };
};
const getImageLayout = (imageLayout: string): string => {
  switch (imageLayout) {
    case 'single':
      return 'md:grid-cols-1';
      break;
    case 'group':
      return 'md:grid-cols-2';
      break;
    default:
      return 'md:grid-cols-1';
  }
};
const ImageContainer = (props: ImageContainerProps): JSX.Element => {
  const dynamicGridClass = getImageLayout(props?.params?.imagelayout?.toLowerCase());

  return (
    <>
      <div className={`grid grid-cols-1 gap-8 my-10 ${dynamicGridClass}`}>{props.imageitems}</div>
    </>
  );
};

export default withPlaceholder({
  placeholder: 'jss-image-container',
  prop: 'imageitems',
})(ImageContainer);
