import React, { useMemo } from 'react';
import MobileTabs from '../../components/tab/MobileTabs';
import { TabProps, TabbedContainerProps } from '../../components/tab/tabbed-container-props';
import Tabs from '../../components/tab/Tabs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { GetStaticComponentProps, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { TabbedContainerDatasourceResponse } from '../../components/tab/tabbed-container-datasource-response';
import { GraphQLClient } from 'lib/common/graphql-client';
import getTabbedContainerDatasourceQuery from '../../components/tab/tabbed-container-datasource-query';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';

const TabbedContainer = (props: TabbedContainerProps): JSX.Element => {
  useSSRErrorsHandler(props.ssrErrors);
  const tabs = props?.fields?.data?.datasource?.children?.results;
  const editorActive = isEditorActive();

  const filteredTabs = useMemo(() => {
    if (tabs === undefined) return [];
    return tabs.filter((tab) => isTab(tab));
  }, [tabs]);

  function isTab(item: TabProps): item is TabProps {
    return (item as TabProps).heading !== undefined;
  }

  return (
    <div>
      {!editorActive && tabs != null && tabs.length > 0 && (
        <MobileTabs tabs={filteredTabs} placeholderRendering={props.rendering} />
      )}
      {tabs != null && tabs.length > 0 && (
        <Tabs tabs={filteredTabs} placeholderRendering={props.rendering} />
      )}
      {tabs == null && <div>Create tabs under the visual list component</div>}
      {(tabs == null || tabs.length === 0) && (
        <div className="my-10 mx-6 xl:mx-8">
          <Placeholder name="jss-tab-contents" rendering={props.rendering} />
        </div>
      )}
    </div>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore == undefined ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const language = layoutData?.sitecore?.context?.language;
  const tabbedContainerData = await getTabbedContainerData(rendering.dataSource, language);
  if (!tabbedContainerData) {
    ssrErrorLogger.logError({
      functionName: 'getTabbedContainerData; ',
      errorMessage: `Failed to get response; Datasource: ${JSON.stringify(rendering.dataSource)};
        Langusge: ${language};
        Response: ${JSON.stringify(tabbedContainerData)}`,
    });
  }

  const propsObj = {
    fields: { ...rendering.fields, data: { datasource: tabbedContainerData.datasource } },
  };
  return ssrErrorLogger.createProperty('TabbedContainer', propsObj);
};

export const getTabbedContainerData = async (
  datasource: string,
  language: string
): Promise<TabbedContainerDatasourceResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<TabbedContainerDatasourceResponse>(
    getTabbedContainerDatasourceQuery,
    {
      datasource: datasource,
      language: language,
    }
  );
};

export default TabbedContainer;
