import Link from 'next/link';
import { SitecorePageResult } from '../category-listing-search/product-category-listing-models';
import { useAppSelector } from 'lib/store/hooks';
import { usePrice } from 'lib/commerce/product/price-hook';
import { useI18n } from 'next-localization';
import NextImage from 'components/foundation/non-sitecore/NextImage';

const ProductListItem = (props: SitecorePageResult): JSX.Element => {
  const productId = props.product?.targetItems[0]?.productId;
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode) ?? 'USD';
  const price = usePrice(productId?.value, currencyIsoCode);
  const { t } = useI18n();

  return (
    <div className="product-item text-center ">
      <div className="w-full m-auto">
        {props.searchImage?.jsonValue?.value?.src && (
          <figure>
            <Link href={props.url?.path} passHref legacyBehavior>
              <NextImage
                className="w-full"
                field={props.searchImage.jsonValue}
                layout="responsive"
              />
            </Link>
          </figure>
        )}
      </div>

      <section className="w-auto p-3">
        <h3 className="capitalize font-normal">
          <Link href={props.url?.path} passHref legacyBehavior>
            <a className="text-primary text-2xl leading-9 mb-1 no-underline  intro-text-link">
              {props.product?.targetItems[0]?.productName.value}
            </a>
          </Link>
        </h3>
        {price != null && (
          <p className="text-almost-black accent-3 tracking-[0.25rem] text-xl">
            {t(`Currency_Symbol_${price?.currencyIsoCode}`)}
            {price.listPrice}
          </p>
        )}
      </section>
    </div>
  );
};

export default ProductListItem;
