// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Needed to add ts-nocheck because objectFit takes a special object that cannot be used here.
import { Image, ImageField, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { default as NextjsImage } from 'next/image';
import { AvidCdnImage } from './AvidCdnImage';

type NextImageProps = {
  field: ImageField;
  editable?: boolean;
  className?: string;
  width?: number;
  height?: number;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
  priority?: boolean;
  useAvidCdn?: boolean;
};

const NextImage = (props: NextImageProps): JSX.Element => {
  const editorActive = isEditorActive();
  // const relativeImage =
  //   props?.field?.value?.src != null && props?.field?.value?.src?.includes('http') ? false : true;

  const excludeDimentions = props.layout == 'fill';
  return (
    <>
      {editorActive && (
        <span
          className={`box-border max-w-full  ${
            props.layout == 'fill'
              ? 'block absolute object-cover min-w-full h-full'
              : 'inline-block relative'
          }`}
        >
          <Image
            field={props.field}
            className={`min-w-full min-h-full ${props.className}`}
            alt={props.field?.value?.alt}
            width={props.width ? props.width : props.field?.value?.width}
            height={props.height ? props.height : props.field?.value?.height}
          />
        </span>
      )}
      {!editorActive &&
        props.useAvidCdn &&
        process.env.NEXT_PUBLIC_AVIDCDN_URL &&
        props.field?.value?.src != null && (
          <AvidCdnImage
            field={props.field}
            className={props.className}
            alt={props.field?.value?.alt}
            width={
              excludeDimentions ? undefined : props.width ? props.width : props.field?.value?.width
            }
            height={
              excludeDimentions
                ? undefined
                : props.height
                ? props.height
                : props.field?.value?.height
            }
            layout={props.layout}
            priority={props.priority || false}
          />
        )}
      {!editorActive && !props.useAvidCdn && props.field?.value?.src != null && (
        <NextjsImage
          src={props.field.value.src}
          className={props.className}
          alt={props.field?.value?.alt}
          width={
            excludeDimentions ? undefined : props.width ? props.width : props.field?.value?.width
          }
          height={
            excludeDimentions ? undefined : props.height ? props.height : props.field?.value?.height
          }
          layout={props.layout}
          priority={props.priority || false}
        />
      )}
    </>
  );
};

export default NextImage;
