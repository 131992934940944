// import { NonOkResponse } from 'lib/common/model/common-models';
import { myAvidRequest } from './myavid-http-client';
import { isNonOkResponse } from 'lib/common/common-http-client';
import { MyAvidCountriesData, MyAvidCountriesPostalCodesData } from './model/myavid-models';

export const getCountriesPostalCodeRules = async (): Promise<MyAvidCountriesPostalCodesData[]> => {
  const response = await myAvidRequest<MyAvidCountriesData>(
    'GET',
    '/utility/GetCountriesWithRegions',
    null
  );

  if (isNonOkResponse(response)) {
    throw new Error('Failed to get countries data!');
  }

  return response.countries.map((country) => {
    return {
      countryCode: country.abbreviation,
      zipRegex: country.zipRegex,
    };
  });
};

export const appmanCommands = {
  closepopup: (): void => {
    myAvidRequest('GET', '/appmancommand/closepopup', null);
  },
};
