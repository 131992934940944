import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { IconName, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { getCartSummary } from 'components/cart/cart-slice';
import { getLicensingDetails, getOrderDetails } from 'components/order/order-slice';
import { ComponentProps } from 'lib/component-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { stringFormat } from 'lib/utils/string-format';
import { useI18n } from 'next-localization';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { ProToolsActivationStepperItem } from '../../components/order/shop/pro-tools-activation-steps';
import MPProToolsOrderDetails from '../../components/order/marketplace/MPProToolsOrderDetails';
import InlineSpinner from 'components/spinner/InlineSpinner';
import Spinner from 'components/spinner/Spinner';
import MPLicensingOrderDetails from '../../components/order/marketplace/MPLicensingOrderDetails';
import { sendEmail } from 'components/order/order-api';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

type OrderDetailsProps = ComponentProps & {
  fields: {
    proToolsActivationStepper: ProToolsActivationStepperItem;
  };
};

const MarketplaceOrder = (props: OrderDetailsProps): JSX.Element => {
  const router = useRouter();
  const { id } = router.query;

  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector((state) => state.order.orderDetailsData);
  const licensingDetails = useAppSelector((state) => state.order.orderLicensingDetailsData);
  const licensingStatus = useAppSelector((state) => state.order.orderLicensingDetailsStatus);
  const notificationEmail = orderDetails?.notificationEmail;
  const userId = orderDetails?.userId;
  const checkIcon = faCheckCircle as unknown as IconName;

  const [licensingLoadingTime, setLicensingLoadingTime] = useState(0);
  const [licensingTimedOut, setLicensingTimedOut] = useState(false);

  const [hasProTools, setHasProTools] = useState(false);
  const [resentMessageVisible, setResentMessageVisible] = useState(false);
  const [isAvidLinkMob, setIsAvidLinkMob] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const showLicensingSection =
    licensingStatus == 'loading' ||
    licensingStatus == 'failed' ||
    (licensingStatus == 'succeeded' &&
      licensingDetails != null &&
      licensingDetails.orderItems.filter(
        (x) => x.product.isSubscription && x.licensingComplete && x.redemptionCodes != null
      ).length > 0);

  const { data: session, status } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  useEffect(() => {
    if (id == null || id.length == 0 || status === 'loading') {
      return;
    }

    dispatch(getOrderDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    dispatch(getCartSummary({ bearerToken: bearerToken }));
  }, [dispatch, id, bearerToken, status]);

  useEffect(() => {
    if (orderDetails?.orderItems != null) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    const timeout = 5000;

    setTimeout(() => {
      dispatch(getOrderDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    }, timeout);
  }, [dispatch, id, orderDetails, bearerToken, status]);

  useEffect(() => {
    if (orderDetails?.orderItems == null) {
      return;
    }

    if (props.fields?.proToolsActivationStepper == null) {
      return;
    }

    const proToolsProductIds = props.fields.proToolsActivationStepper.fields.products.map(
      (item) => item.fields.productId.value
    );

    for (let i = 0; i < orderDetails.orderItems.length; i++) {
      const orderItem = orderDetails.orderItems[i];
      if (proToolsProductIds.includes(orderItem.product.productId)) {
        setHasProTools(true);
        return;
      }
    }
  }, [orderDetails, props.fields.proToolsActivationStepper]);

  useEffect(() => {
    if (orderDetails?.orderItems == null) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    const orderHasSubscriptionProducts =
      orderDetails.orderItems.filter((x) => x.product.isSubscription).length > 0;

    if (!orderHasSubscriptionProducts) {
      return;
    }
    if (licensingStatus == 'succeeded' || licensingStatus == 'loading') {
      return;
    }

    let timeout = 0;
    if (licensingStatus == 'failed') {
      timeout = 5000;
    }

    setTimeout(() => {
      if (licensingLoadingTime > 60000) {
        setLicensingTimedOut(true);
        return;
      }
      dispatch(getLicensingDetails({ orderSummaryId: id as string, bearerToken: bearerToken }));
    }, timeout);

    setLicensingLoadingTime(licensingLoadingTime + timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails, licensingDetails, licensingStatus, dispatch, id]);

  useEffect(() => {
    if (navigator && /gonative/gi.test(navigator.userAgent)) {
      setIsAvidLinkMob(true);
    }
  }, []);

  const OrderDetailDescription = (): JSX.Element => {
    const description = t('Order_Details_Description');
    const content = stringFormat({
      message: description,
      0: id as string,
      1: notificationEmail as string,
    });
    return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
  };

  const onClickResendEmailHandler = (): void => {
    sendEmail(id as string, bearerToken);
    setResentMessageVisible(true);
    setTimeout(() => {
      setResentMessageVisible(false);
    }, 5000);
  };

  const setShowSpinnerCallback = (state: boolean): void => {
    setShowSpinner(state);
  };

  return (
    <div id="checkout--order-processing" className={`mt-4 px-1 sm:px-7 text-sm`}>
      <div className="cart-totals w-full md:w-4/5 m-auto mt-5 mb-5 p-2.5 relative bg-mp-background-card text-mp-txt-neutralLight">
        {(orderDetails?.orderItems == null || showSpinner) && <Spinner color="#20BBD0" />}
        <div className="text-center pt-2">
          <div className="text-mp-txt-primary mb-5">
            <FontAwesomeIcon icon={checkIcon} size="3x" />
          </div>
          <div className="mb-4 lg:mb-5 text-3xl lg:text-4xl capitalize font-bold">
            {t('Order_Details_Complete')}
          </div>
          <div className="p-2 pb-0 mb-6">
            <OrderDetailDescription />
            <button
              className="text-mp-txt-primary underline"
              onClick={(): void => onClickResendEmailHandler()}
            >
              {t('Order_Details_ResendEmail')}
            </button>
            {resentMessageVisible && (
              <div className="text-sm">
                <span className="font-bold mr-3">{t('Order_Details_EmailResent')}</span>
                <span className="text-mp-txt-primary">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </div>
            )}
          </div>
          {hasProTools && (
            <MPProToolsOrderDetails
              orderId={id}
              userId={userId}
              isAccountIncomplete={orderDetails?.guestCheckout ?? true}
              proToolsActivationStepper={props.fields.proToolsActivationStepper}
              setShowSpinnerCallback={setShowSpinnerCallback}
            />
          )}
          {!hasProTools && !isAvidLinkMob && (
            <div className="py-3">
              <a
                className="rounded bg-mp-btn-primary hover:bg-mp-btn-light text-mp-txt-bright text-lg px-4 py-2 font-bold js-internal-redirect"
                data-tab-name="MyProducts"
                data-appman-link="#"
                href="#"
              >
                {t('Marketplace_Order_Continue')}
              </a>
            </div>
          )}
        </div>
        {showLicensingSection && (
          <>
            <div className="my-8 border-t border-mp-txt-neutral"></div>
            <div className="relative pb-4">
              {licensingTimedOut && <div></div>}
              {(licensingStatus == 'loading' || licensingStatus == 'failed') &&
                !licensingTimedOut && (
                  <div className="text-center">
                    <div className="font-bold mb-4">{t('MP_Order_Licensing_PleaseWait')}</div>
                    <InlineSpinner height={47} width={5} color="#20BBD0" />
                  </div>
                )}
              {licensingStatus == 'succeeded' &&
                licensingDetails?.orderItems
                  .filter(
                    (x) =>
                      x.product.isSubscription && x.licensingComplete && x.redemptionCodes != null
                  )
                  .map((orderItem, index) => {
                    return <MPLicensingOrderDetails key={index} orderItem={orderItem} />;
                  })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withDatasourceCheck()(MarketplaceOrder);
