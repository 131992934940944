import { getUserInterestRecommendationsListController } from 'components/coveo-recommendations/coveo-core/controllers-coveo-recommendations';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useMemo, useState } from 'react';
import {
  CoveoRecommendationsProps,
  CoveoRecommendedProductDetails,
  mapRecommendationsToProducts,
} from 'components/coveo-recommendations/coveo-recommendations';
import { useAppSelector } from 'lib/store/hooks';
import CoveoRecommendationsCarousel from 'components/coveo-recommendations/CoveoRecommendationsCarousel';

const CoveoUserInterest: React.FC<CoveoRecommendationsProps> = (props) => {
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const controller = useMemo(
    () => getUserInterestRecommendationsListController(props?.fields?.maxResultsToShow?.value ?? 3),
    [props?.fields?.maxResultsToShow?.value]
  );
  const [controllerState, setCartRecommendState] = useState(controller.state);
  const [productsArray, setProductsArray] = useState<CoveoRecommendedProductDetails[] | null>(null);

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => {
      setCartRecommendState(controller.state);
    });
    controller.refresh();
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [controller]);

  useEffect(() => {
    if (!currencyIsoCode) {
      return;
    }
    setProductsArray(
      mapRecommendationsToProducts(controllerState.recommendations, currencyIsoCode)
    );
  }, [controllerState.recommendations, currencyIsoCode]);

  if (controllerState.error) {
    return null;
  }

  return (
    <div id="coveo-user-interest-wrapper">
      {productsArray && productsArray.length > 0 && (
        <CoveoRecommendationsCarousel
          title={props?.fields?.title}
          description={props?.fields?.description}
          fetchPriceFromSalesforce={props?.fields?.fetchPriceFromSalesforce}
          productsArray={productsArray}
        />
      )}
    </div>
  );
};

export default withDatasourceCheck()<CoveoRecommendationsProps>(CoveoUserInterest);
