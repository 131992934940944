import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { ComponentProps } from 'lib/component-props';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useInView } from 'react-intersection-observer';

type StickOnScrollContainerProps = ComponentProps & {
  children: JSX.Element;
};

const StickOnScrollContainer = (props: StickOnScrollContainerProps): JSX.Element | null => {
  const [ref, regularContainerOnScreen, entry] = useInView({
    threshold: 0.25,
  });
  const [stickyContainerRoot, setStickyContainerRoot] = useState<HTMLElement | null>(null);
  const aboveScreen = (entry?.boundingClientRect?.top ?? 0) < 0;

  const editorActive = isEditorActive();

  useEffect(() => {
    setStickyContainerRoot(document.getElementById('sticky-navigation'));
  }, [regularContainerOnScreen]);

  const StickyContainer = (): JSX.Element | null =>
    !regularContainerOnScreen && aboveScreen && !editorActive ? (
      <div className="sticky-container flex justify-between items-center bg-white text-almost-black border-b border-base-normal shadow-[0_4px_16px_0px_rgba(0,0,0,0.12)] animate-slide_down -z-10">
        <div className="left-content flex-1 font-bold text-xl flex">
          <Placeholder
            name="sticky-first"
            rendering={props.rendering}
            render={(components): ReactNode[] => {
              return components.map((component) => {
                return React.cloneElement(component as ReactElement, {
                  mini: !regularContainerOnScreen,
                  stopEvents: true,
                });
              });
            }}
          />
        </div>
        <div className="right-content flex">
          <Placeholder
            name="sticky-second"
            rendering={props.rendering}
            render={(components): ReactNode[] => {
              return components.map((component) => {
                return React.cloneElement(component as ReactElement, {
                  mini: !regularContainerOnScreen,
                  stopEvents: true,
                });
              });
            }}
          />
        </div>
      </div>
    ) : null;

  const RegularContainer = useMemo(
    (): JSX.Element => (
      <>
        <div className="top-content text-center">
          <Placeholder name="sticky-first" rendering={props.rendering} />
        </div>
        <div className="bottom-content">
          <Placeholder name="sticky-second" rendering={props.rendering} />
        </div>
      </>
    ),
    [props.rendering]
  );

  return (
    <>
      {editorActive && (
        <div className="bg-white py-12 px-6 lg:px-28 xl:px-[8.0625rem] 2xl:px-0">
          <div>
            <div className="text-center">
              <Placeholder name="sticky-first" rendering={props.rendering} />
            </div>
            <div className="text-center">
              <Placeholder name="sticky-second" rendering={props.rendering} />
            </div>
          </div>
        </div>
      )}

      {!editorActive && (
        <div className="bg-white">
          <div ref={ref}>{RegularContainer}</div>
        </div>
      )}

      {!editorActive &&
        stickyContainerRoot != null &&
        ReactDOM.createPortal(<StickyContainer />, stickyContainerRoot)}
    </>
  );
};

export default StickOnScrollContainer;
