type CustomCheckboxProps = {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
};

const CustomCheckbox = (props: CustomCheckboxProps): JSX.Element => {
  return (
    <div className="checkbox-wrapper flex">
      <label className="flex">
        <input
          type="checkbox"
          checked={props.checked}
          className={`mr-2 ${props.checked ? 'checked' : ''}`}
          onChange={(e): void => props.onChange(e.target.checked)}
        />
        <span>{props.label}</span>
      </label>
    </div>
  );
};

export default CustomCheckbox;
