import { youtubeThumbnailProps } from '../components/video-grid/VideoCard';

export const getYoutubeThumbnail = (videoId: string): Promise<youtubeThumbnailProps> => {
  const fallbackUrl =
    process.env.NEXT_PUBLIC_YOUTUBE_THUMBNAIL_URL_ENDPOINT + videoId + '/' + 'hqdefault.jpg'; // high quality 480x360 image that stretches.
  const maxresdefaultUrl =
    process.env.NEXT_PUBLIC_YOUTUBE_THUMBNAIL_URL_ENDPOINT + videoId + '/' + 'maxresdefault.jpg'; // Not always available 1920x1080 image.
  return new Promise((resolve) => {
    const img = new Image();
    img.src = maxresdefaultUrl;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    img.onload = (): any => {
      const imgWidth = img.width;
      const imageSrc = imgWidth < 480 ? fallbackUrl : maxresdefaultUrl;

      resolve({
        value: {
          src: imageSrc,
        },
      });
    };
  });
};
