import gql from 'graphql-tag';

const getLanguageQuery = gql`
  query LanguageVersionQuery($pathId: String!) {
    results: item(path: $pathId, language: "en") {
      languages {
        language {
          displayName
          name
        }
      }
    }
  }
`;

export default getLanguageQuery;
