import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import dynamic from 'next/dynamic';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { useI18n } from 'next-localization';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { navigationActions } from 'lib/navigation/navigation-slice';
import Link from 'next/link';
import { UtilityNavigationProps } from 'components/utility-navigation/utility-navigation-props';
import { useScreenSize } from 'lib/hooks/use-screen-size';

const AccountNavigation = dynamic(
  () => import('../components/utility-navigation/AccountNavigation'),
  {
    ssr: false,
  }
);

const LanguageCurrency = dynamic(
  () => import('../components/global-navigation/shop/LanguageCurrency'),
  {
    ssr: false,
  }
);

const CartNavigation = dynamic(() => import('../components/utility-navigation/CartNavigation'), {
  ssr: true,
});

const ContactSalesNavigation = dynamic(
  () => import('../components/utility-navigation/ContactSalesNavigation'),
  {
    ssr: true,
  }
);

const SearchBox = dynamic(() => import('../components/utility-navigation/SearchBox'), {
  ssr: false,
});

const UtilityNavigation = (props: UtilityNavigationProps): JSX.Element => {
  const { data: session } = useSession();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isMenuOpen = useAppSelector((state) => state.navigation.menuVisible);
  const screenSize = useScreenSize();
  const { t } = useI18n();

  const onClickSearchButtonHandler = (): void => {
    setIsSearchOpen(!isSearchOpen);
  };

  const onClickGlobalNavigationHandler = (): void => {
    const bodyDiv = document.getElementById('body');
    bodyDiv?.classList.add('!overflow-hidden');
    dispatch(navigationActions.toggleMenuModal());
  };

  return (
    <>
      <nav className="sticky z-10 items-center justify-between flex-auto bg-neutral-darkest py-0 lg:py-4 ">
        <ul
          role="menubar"
          className="menu w-full lg:justify-between flex-grow flex items-center lg:w-auto px-2 lg:px-8 "
        >
          <li
            role="menuitem"
            aria-label="Navigate to Home"
            className="menuItem flex basis-full lg:basis-auto text-md font-bold text-white lg:flex-grow"
          >
            <div className=" lg:hidden flex cursor-pointer items-center flex-shrink-0 text-gray-800 mr-0 sm:mr-2">
              <Link href="/" aria-label={t('Avid Logo')} passHref prefetch={false} legacyBehavior>
                <img
                  src="https://cdn.avid.com/avidcom/images/shared/avid-logo-mobile.svg"
                  alt="avid"
                ></img>
              </Link>
            </div>
            <div className="hidden lg:flex cursor-pointer items-center flex-shrink-0 text-gray-800 mr-0 sm:mr-2">
              <Link href="/" aria-label={t('Avid Logo')} passHref prefetch={false} legacyBehavior>
                <img
                  src="https://cdn.avid.com/avidcom/images/shared/avid-logo-desktop.svg"
                  alt="avid"
                ></img>
              </Link>
            </div>
          </li>
          {screenSize.width >= 992 && (
            <li
              role="menuitem"
              className="menuItem relative lg:basis-[350px] 2xl:basis-[400px]  text-gray-600 lg:block hidden"
            >
              <SearchBox {...props} toggleSubSearch={onClickSearchButtonHandler} />
            </li>
          )}

          <li role="none" className="menuItem relative flex items-center lg:hidden mr-5 ">
            <button
              role="menuitem"
              className={` w-6 h-6
                bg-[url('https://cdn.avid.com/avidcom/images/shared/icons/search-icon.svg')]
                bg-[length:18px_18px] bg-no-repeat bg-center`}
              aria-label="Search"
              onClick={onClickSearchButtonHandler}
            ></button>
          </li>
          <li role="menuitem" className="menuItem lg:block hidden mr-5  py-2">
            <LanguageCurrency />
          </li>
          <li role="menuitem" className="menuItem flex mr-5  py-2 items-center">
            <ContactSalesNavigation {...props} />
          </li>

          <li role="none" className="menuItem flex items-center mr-5  text-avidgreen">
            {!session && (
              <div className={`flex lg:relative  py-1 nav-link`}>
                <Link
                  href="/api/auth/login/myavid"
                  role="menuitem"
                  className="leading-none flex flex-row flex-wrap"
                >
                  <span
                    className={`w-6 h-6 inline-block font-avidwalsheimbold
                      bg-[url('https://cdn.avid.com/avidcom/images/shared/icons/account-icon.svg')]
                      bg-[length:18px_18px] bg-no-repeat bg-center`}
                  ></span>
                  <span className="sr-only  font-avidwalsheimbold lg:not-sr-only !whitespace-nowrap leading-normal lg:ml-1">
                    {t('LogIn')}
                  </span>
                </Link>
              </div>
            )}

            {session?.user && <AccountNavigation {...props} />}
          </li>
          <li role="menuitem" className="menuItem flex items-center mr-5 lg:mr-0 ">
            <CartNavigation {...props} />
          </li>
          <li role="menuitem" className="menuItem lg:hidden text-white  py-2">
            <button
              className="btn-primary text-almost-black hover:text-white bg-avidgreen"
              onClick={onClickGlobalNavigationHandler}
            >
              {t('Menu')}
            </button>
            <div
              id="mobile-global-navigation"
              className={
                isMenuOpen
                  ? 'block absolute overflow-y-scroll h-screen container lg:hidden bg-neutral-medium top-0 right-0 w-11/12 z-10'
                  : 'hidden'
              }
            ></div>
            {isMenuOpen && (
              <div className="modal-backdrop absolute top-0 left-0 bg-base-darkest h-[100vh] opacity-[.5] w-full z-1"></div>
            )}
          </li>
        </ul>
        {isSearchOpen && (
          <div className="subMenu border-t-[1px] border-neutral-light p-2 mr-5 block lg:hidden">
            <SearchBox {...props} toggleSubSearch={onClickSearchButtonHandler} />
          </div>
        )}
      </nav>
    </>
  );
};

export default withDatasourceCheck()(UtilityNavigation);
