import getItemIdFromProductIdQuery from './graphql/item-id-from-product-id-query';
import { GraphQLClient } from 'lib/common/graphql-client';
import { ProductResults } from './graphql/models/product-query-results';
import { ComparisonTableCardItem } from 'components/comparison-table/comparison-table-card-item';
import { AttributeDetails, CategoryAttributes } from 'components/comparison-table/table-models';
import { Attribute, Category } from 'components/comparison-table/comparison-card-attribute-item';
import {
  ProductDetails,
  ProductDetailsQueryResponse,
} from 'components/foundation/commerce/graphql/product-details-query-response';
import getProductDetailsQuery from 'components/foundation/commerce/graphql/product-details-query';

export const queryProductData = async <T>(
  query: unknown,
  variables?: {
    [key: string]: unknown;
  }
): Promise<T> => {
  const graphQLClient = GraphQLClient();
  const result = await graphQLClient.request<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query as any,
    variables
  );

  return result;
};

export const getProductSitecoreIdFromProductId = async (
  productId: string,
  language: string
): Promise<string | null> => {
  const graphQLClient = GraphQLClient();

  const result = await graphQLClient.request<ProductResults>(getItemIdFromProductIdQuery, {
    language: language,
    productId: productId,
  });

  if (result == null || result.search.results.length == null) {
    return null;
  }

  return result.search.results[0].id;
};

export const generateCategoryViewModel = (
  allCards: ComparisonTableCardItem[],
  visibleCards: ComparisonTableCardItem[]
): CategoryAttributes[] => {
  const categories = allCards.flatMap((card) =>
    card.fields.cardAttributes.map(
      (cardAttribute) => cardAttribute.fields.attribute.fields.category
    )
  );

  const uniqueCategories = Array.from(new Set(categories.map((category) => category.id)))
    .map((id) => {
      return categories.find((category) => category.id == id);
    })
    .sort((a, b) => (a?.fields.sortOrder.value ?? 0) - (b?.fields.sortOrder.value ?? 0));

  const attributes = allCards.flatMap((card) =>
    card.fields.cardAttributes.map((cardAttribute) => cardAttribute.fields.attribute)
  );

  const uniqueAttributes = Array.from(new Set(attributes.map((attribute) => attribute.id))).map(
    (id) => {
      return attributes.find((attribute) => attribute.id == id);
    }
  );

  const cardAttributes = visibleCards.flatMap((card) => card.fields.cardAttributes);

  const finalCategories: CategoryAttributes[] = [];
  for (let i = 0; i < uniqueCategories.length; i++) {
    const categoryAttributes = uniqueAttributes
      .filter((attribute) => attribute?.fields.category.id == uniqueCategories[i]?.id)
      .sort((a, b) => (a?.fields.sortOrder.value ?? 0) - (b?.fields.sortOrder.value ?? 0));

    const attributeDetails = new Array<AttributeDetails>();
    for (let j = 0; j < categoryAttributes.length; j++) {
      const attributeToCardAttributes = cardAttributes.filter(
        (cardAttribute) => cardAttribute.fields.attribute.id == categoryAttributes[j]?.id
      );

      if (attributeToCardAttributes.length == 0) {
        continue;
      }

      attributeDetails.push({
        attribute: categoryAttributes[j] as Attribute,
        details: attributeToCardAttributes.map((attributeToCardAttribute) => {
          return {
            type: attributeToCardAttribute.fields.icon != null ? 'icon' : 'text',
            icon: attributeToCardAttribute.fields.icon?.fields.value.value,
            color: attributeToCardAttribute.fields.color?.fields.value.value,
            detail: attributeToCardAttribute.fields.detail,
          };
        }),
      });
    }

    if (attributeDetails.length == 0) {
      continue;
    }

    finalCategories.push({
      showHeader: i != 0,
      category: uniqueCategories[i] as Category,
      attributeDetails: attributeDetails,
    });
  }

  return finalCategories;
};

export const getProductDetailsFromProductId = async (
  productId: string,
  language: string
): Promise<ProductDetails[] | null> => {
  const graphQLClient = GraphQLClient();

  const result = await graphQLClient.request<ProductDetailsQueryResponse>(getProductDetailsQuery, {
    productId: productId,
    language: language,
  });

  if (result == null || result.search.results.length == null) {
    return null;
  }

  const updatedResults = result.search.results.map((product) => {
    return { ...product, productId: productId };
  });

  return updatedResults;
};
