import { useI18n } from 'next-localization';
import { ProToolsActivationStepperItem } from './pro-tools-activation-steps';
import ActivationStepper from './ActivationStepper';

type ProToolsOrderDetailsProps = {
  isAccountIncomplete: boolean;
  orderId: string | string[] | undefined;
  userId: string | undefined;
  proToolsActivationStepper: ProToolsActivationStepperItem;
};

const ProToolsOrderDetails = (props: ProToolsOrderDetailsProps): JSX.Element => {
  const { t } = useI18n();

  return (
    <>
      <div className="my-8 border-t border-base-dark"></div>
      <div>
        <h3>{t('Order_ProToolsActivationStep_NextSteps')}</h3>
        {!props.isAccountIncomplete && (
          <ActivationStepper
            steps={props.proToolsActivationStepper.fields.stepsForCompleteAccount}
            userId={props.userId}
            orderId={props.orderId}
          />
        )}
        {props.isAccountIncomplete && (
          <ActivationStepper
            steps={props.proToolsActivationStepper.fields.stepsForIncompleteAccount}
            userId={props.userId}
            orderId={props.orderId}
          />
        )}
      </div>
      <div className="mt-8">{t('Order_ProToolsActivationStep_InYourAvidAccount')}</div>
    </>
  );
};

export default ProToolsOrderDetails;
