import { ProductVariationsData, Variant } from 'lib/commerce/product/model/product-models';
import { Product } from 'src/pages/api/product/models/product';
import React, { useState, useEffect, useMemo } from 'react';
import { useI18n } from 'next-localization';
import { addCartItem } from 'components/cart/cart-slice';
import { useCartData } from 'components/cart/cart-hooks';
import { getProductCartIndex, pushAddToCart } from 'lib/google-analytics/commerce';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import {
  ProductPickAPlanContextItem,
  ProductPickAPlanImageSetItem,
  TargetItemProductSet,
} from './product-pick-a-plan-props';
import SelectYourPlanLearnMore from './SelectYourPlanLearnMore';
import { PickAPlanTextContent } from './PickAPlanTextContent';
import ProductShortDescription from './ProductShortDescription';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

type SelectYourPlanProps = {
  productVariations: ProductVariationsData | null;
  productItems: Product[] | null;
  contextItem: ProductPickAPlanContextItem;
  productSet: TargetItemProductSet | undefined;
  imageSet: ProductPickAPlanImageSetItem[];
};

const SelectYourPlanSingle = (props: SelectYourPlanProps): JSX.Element => {
  const { t } = useI18n();
  const [productID, setProductID] = useState<string>('');
  const [product, setProduct] = useState<Variant | null>(null);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart);
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const cartItemsIds = useCartData()?.cartItems.map((item): string => item.cartItem?.productId);
  const isInCart = productID.length > 0 && cartItemsIds?.includes(productID);
  const imageSetExists = props.imageSet.length > 0;
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const getSubscriptionTypeText = (subscriptionType: string | undefined = ''): string => {
    switch (subscriptionType.toLowerCase()) {
      case 'apu':
        return t('SubscriptionPricingCard_AnnualPaidUpfront');
      case 'apm':
        return t('SubscriptionPricingCard_AnnualPaidMonthly');
      case 'mpm':
        return t('SubscriptionPricingCard_MonthToMonth');
      case '2 year':
        return t('SubscriptionPricingCard_2Year');
      case '3 year':
        return t('SubscriptionPricingCard_3Year');
      default:
        return '';
    }
  };

  const onClickAddToCartHandler = (event): void => {
    if (productID.length) {
      const target = event.currentTarget as HTMLButtonElement;
      target.disabled = true;
      dispatch(addCartItem({ sku: productID, quantity: 1, bearerToken: bearerToken }));
      if (product && currencyIsoCode && cart.cartSummaryData) {
        const index = getProductCartIndex(product.productId, cart.cartItemsData);
        pushAddToCart(
          currencyIsoCode,
          (
            parseFloat(cart.cartSummaryData.totalProductAmountAfterAdjustments) +
            product.price.unitPrice
          ).toString(),
          {
            id: product.productId,
            sku: product.sku,
            name: product.name,
            brand: product.brand,
            price: product.price.unitPrice.toString(),
            discount: (product.price.listPrice - product.price.unitPrice).toString(),
            variant: product?.subscriptionType ?? 'N/A',
            index: index.toString(),
            itemCategory: product.productCategoryPaths.primary?.at(-1)?.name,
            itemCategory2: product.productCategoryPaths.path1?.at(-1)?.name,
            itemCategory3: product.productCategoryPaths.path2?.at(-1)?.name,
            itemCategory4: product.productCategoryPaths.path3?.at(-1)?.name,
            productSku: product.sku,
          }
        );
      }
    }
  };

  useEffect(() => {
    if (!props.productVariations || productID.length) {
      return;
    }
    setProductID(props.productVariations?.variants[0].productId);
    setProduct(props.productVariations?.variants[0]);
  }, [productID.length, props.productVariations]);

  return (
    <div className="mt-5">
      <div className="md:h-[270px] lg:min-h-[320px] xl:min-h-[350px] 2xl:min-h-[400px] flex flex-col md:flex-row text-mp-txt-neutralLight">
        {(!imageSetExists || props.imageSet.length === 1) && (
          <div
            className="bg-no-repeat bg-cover w-5/6 sm:w-4/5 md:w-7/12 md:m-0 m-auto aspect-[4/3] border-2 border-mp-background-card md:overflow-hidden"
            style={{
              backgroundImage: `url(${
                imageSetExists
                  ? props.imageSet[0].url.url + '?v=' + props.imageSet[0]?.blob?.value
                  : props.contextItem?.image?.jsonValue.value?.src
              })`,
            }}
          ></div>
        )}
        {imageSetExists && props.imageSet.length > 1 && (
          <div className="w-11/12 sm:w-4/5 md:w-7/12 md:m-0 m-auto border-b border-mp-background-card flex flex-col sm:flex-row justify-between md:overflow-hidden">
            <div className="flex flex-row sm:flex-col sm:w-1/5 min-h-[65px] sm:min-w-[65px] mb-3 sm:mb-0 sm:mr-3">
              {props.imageSet.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="bg-no-repeat bg-cover bg-center cursor-pointer w-1/5 sm:w-full sm:h-1/5"
                    style={{
                      backgroundImage: `url(${item.url.url}?v=${item?.blob?.value})`,
                    }}
                    onClick={(): void => {
                      setCurrentImgIndex(index);
                    }}
                  ></div>
                );
              })}
            </div>
            <div className="w-full">
              <div className="pb-[70%] 2xl:pb-[65%] relative">
                <div
                  className="absolute inset-0 bg-no-repeat bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${props.imageSet[currentImgIndex].url.url}?v=${props.imageSet[currentImgIndex].blob?.value})`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
        <div className="md:w-5/12 px-2 md:px-0 min-h-[250px] md:h-full mt-5 md:mt-0 md:pl-10 relative">
          <div className="mb-1">
            <h3 className="text-mp-txt-bright">{props.contextItem?.productName?.value}</h3>
            <h4>{getSubscriptionTypeText(product?.subscriptionType)}</h4>
          </div>
          {props?.contextItem?.productDescription?.value.length > 0 && (
            <ProductShortDescription description={props?.contextItem?.productDescription} />
          )}
          <div className="text-center md:text-end mt-2 min-h-[80px] md:absolute md:bottom-12 lg:bottom-14 md:left-10 md:right-0">
            <p
              className={`text-mp-txt-neutral line-through text-3xl md:text-2xl lg:text-3xl font-normal tracking-[0.2em] ${
                product?.price.unitPrice === product?.price.listPrice && 'invisible'
              }`}
            >
              {t(`Currency_Symbol_${product?.price.currencyIsoCode}`)}
              {product?.price.listPrice}
            </p>
            <p className={`accent-1 text-5xl md:text-4xl lg:text-5xl text-mp-txt-primary`}>
              {product?.price.unitPrice && product?.price.unitPrice > 0
                ? t(`Currency_Symbol_${product?.price.currencyIsoCode}`)
                : ''}
              {product?.price.unitPrice && product?.price.unitPrice > 0
                ? product?.price.unitPrice
                : t('MP_Free_Price')}
            </p>
          </div>
          <div className="mt-2 lg:mt-4 md:absolute md:left-10 md:bottom-0 md:right-0">
            <button
              onClick={(e): void => onClickAddToCartHandler(e)}
              className={`${
                isInCart
                  ? 'bg-mp-btn-disabled'
                  : 'bg-mp-btn-primary hover:bg-mp-btn-light hover:text-mp-txt-almostBlack'
              } align-middle text-mp-txt-bright py-3 w-full rounded`}
              disabled={isInCart}
            >
              {`${isInCart ? t('MP_In_Cart') : t('MP_Buy_Now')}`}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse mt-5 text-mp-txt-neutralLight">
        <div className="md:pl-10 min-h-[70px]">
          {props.productItems && props.productItems[0].features.targetItems.length > 1 && (
            <SelectYourPlanLearnMore
              features={props.productItems[0].features.targetItems}
            ></SelectYourPlanLearnMore>
          )}
        </div>
        <div className="mt-2 md:mt-0">
          <PickAPlanTextContent
            textSections={
              props?.contextItem?.children?.results.length <= 0
                ? props?.productSet?.textSections.targetItems
                : props?.contextItem?.children?.results
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SelectYourPlanSingle;
