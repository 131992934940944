import ItemQuantityNudger from './CartItemQuantityNudger';
import { CartItemDetails } from 'components/cart/cart-models';
import { roundToTwoDecimalPlaces } from 'lib/utils/decimal-util';
import { useI18n } from 'next-localization';
import { useState } from 'react';

type CartItemProps = {
  cartItem: CartItemDetails;
  currencyIsoCode: string;
  editable?: boolean;
  showPricingAndTerms?: boolean;
  quantityLimits?:
    | {
        productId: string;
        min: number;
        max: number;
      }
    | undefined;
};

const PricingAndTerms = (props: CartItemProps): JSX.Element => {
  const { t } = useI18n();
  const subscriptionType = props.cartItem.productDetails.fields.XC_SubscriptionType__c;
  if (subscriptionType === 'N/A' || subscriptionType == '') {
    return <></>;
  }

  const totalAmount = parseFloat(props.cartItem.totalAmount);

  let termsAndPricing = '';
  if (props.cartItem.hasPromotion) {
    switch (subscriptionType) {
      case '2YR':
        termsAndPricing = t('checkout_summary_line_item_2yr_with_promotion');
        break;
      case '3YR':
        termsAndPricing = t('checkout_summary_line_item_3yr_with_promotion');
        break;
      case 'APU':
        termsAndPricing = t('checkout_summary_line_item_apu_with_promotion');
        break;
      case 'APM':
        termsAndPricing = t('checkout_summary_line_item_apm_with_promotion');
        break;
      case 'MPM':
        termsAndPricing = t('checkout_summary_line_item_mpm_with_promotion');
        break;
    }
  } else {
    switch (subscriptionType) {
      case '2YR':
        termsAndPricing = t('checkout_summary_line_item_2yr');
        break;
      case '3YR':
        termsAndPricing = t('checkout_summary_line_item_3yr');
        break;
      case 'APU':
        termsAndPricing = t('checkout_summary_line_item_apu');
        break;
      case 'APM':
        termsAndPricing = t('checkout_summary_line_item_apm');
        break;
      case 'MPM':
        termsAndPricing = t('checkout_summary_line_item_mpm');
        break;
    }
  }

  const totalAmountLabel = `${t(`Currency_Symbol_${props.currencyIsoCode}`)}${totalAmount}`;
  termsAndPricing = termsAndPricing.replace('{sku_price}', totalAmountLabel);

  if (subscriptionType && props.cartItem.hasPromotion) {
    const totalListPrice = `${t(`Currency_Symbol_${props.currencyIsoCode}`)}${
      props.cartItem.totalListPrice
    }`;
    termsAndPricing = termsAndPricing.replace('{sku_price_original}', totalListPrice);
  }

  if (subscriptionType === 'MPM' && props.cartItem.hasPromotion && totalAmount == 0) {
    termsAndPricing = t('checkout_summary_line_item_mpm_1_month_free');
    const totalListPrice = `${t(`Currency_Symbol_${props.currencyIsoCode}`)}${
      props.cartItem.totalListPrice
    }`;
    termsAndPricing = termsAndPricing.replace('{sku_price}', totalListPrice);
  }

  const annualCost = `${t(`Currency_Symbol_${props.currencyIsoCode}`)}${
    Math.round(totalAmount * 12 * 100 + Number.EPSILON) / 100
  }`;

  const annualCostWithDiscount = `${t(`Currency_Symbol_${props.currencyIsoCode}`)}${
    Math.round(props.cartItem.totalListPrice * 11 * 100 + Number.EPSILON) / 100 +
    +props.cartItem.totalAmount
  }`;

  if (subscriptionType === 'APM' && props.cartItem.hasPromotion)
    termsAndPricing = termsAndPricing.replace('{yearly_price}', annualCostWithDiscount);
  else termsAndPricing = termsAndPricing.replace('{yearly_price}', annualCost);

  return <div className="caption italic mt-4 text-neutral-medium">{termsAndPricing}</div>;
};

const CartItem = (props: CartItemProps): JSX.Element => {
  const { t } = useI18n();
  const [hasWarning, setHasWarning] = useState(false);
  const [warningText, setWarningText] = useState<string | null>(null);

  const automaticPromotionalItem = (
    <div>
      <h3 className="font-bold text-base line-through">
        {t(`Currency_Symbol_${props.currencyIsoCode}`)}
        {props.cartItem.listPrice}
      </h3>
      <h3 className="font-bold text-base">
        {t(`Currency_Symbol_${props.currencyIsoCode}`)}

        {roundToTwoDecimalPlaces(
          parseFloat(props.cartItem.totalPrice) / parseInt(props.cartItem.quantity)
        )}
      </h3>
    </div>
  );

  return (
    <>
      <div key={props.cartItem.cartItemId} className="flex flex-col space-y-1">
        <div className="flex justify-between space-x-20">
          <h3 className="text-base font-bold">{props.cartItem.name}</h3>
          {props.cartItem.totalAdjustmentAmount &&
          !(parseFloat(props.cartItem.totalAdjustmentAmount) == 0) ? (
            automaticPromotionalItem
          ) : (
            <div>
              {props.cartItem.listPrice !== props.cartItem.salesPrice && (
                <h3 className="font-bold text-base line-through">
                  {t(`Currency_Symbol_${props.currencyIsoCode}`)}
                  {props.cartItem.listPrice}
                </h3>
              )}
              <h3 className="font-bold text-base">
                {t(`Currency_Symbol_${props.currencyIsoCode}`)}
                {props.cartItem.salesPrice}
              </h3>
            </div>
          )}
        </div>
        <div className="flex justify-end items-baseline">
          <div className="mr-auto text-sm text-neutral-medium">
            {props.cartItem.productDetails.fields.XC_SubscriptionType__c === 'N/A'
              ? 'Digital Download'
              : props.cartItem.productDetails.variationAttributes?.XC_SubscriptionTerm__c?.value &&
                `${props.cartItem.productDetails.variationAttributes.XC_SubscriptionTerm__c.value} Subscription`}
          </div>

          {props.editable === true && (
            <ItemQuantityNudger
              cartItem={props.cartItem}
              quantityLimits={props.quantityLimits}
              passHasWarningToParentFunc={setHasWarning}
              passWarningTextToParentFunc={setWarningText}
            />
          )}
          {props.editable === false && <p>Qty: {props.cartItem.quantity}</p>}
        </div>
        {hasWarning && (
          <div className="bg-info-light border-info text-info p-1 text-xs">{warningText}</div>
        )}
      </div>
      {props.showPricingAndTerms === true && <PricingAndTerms {...props} />}
      <span className="w-full h-[1px] bg-base-normal mt-[17.5px] mb-4 block" />
    </>
  );
};

export default CartItem;
