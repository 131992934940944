import gql from 'graphql-tag';

const getFooterQuery = gql`
  query FooterDatasourceQuery($datasource: String!, $language: String!) {
    # Datasource query
    # $datasource will always be set to the ID of the rendering's datasource item
    # (as long as the GraphQLData helper is used)
    datasource: item(path: $datasource, language: $language) {
      id
      name
      children(hasLayout: false) {
        results {
          ...FooterNavigationGroupFields
          ...FooterNavigationLinkFields
          ...FooterGeoRestrictedNavigationLinkFields
          ...FooterNavigationLinkWithSocialIconFields
          ...FooterNavigationTextFields
        }
      }
    }
  }

  fragment FooterBaseFields on Item {
    id
    name
  }

  fragment FooterNavigationGroupFields on NavigationGroup {
    ...FooterBaseFields
    children(hasLayout: false) {
      results {
        ...FooterNavigationLinkFields
        ...FooterGeoRestrictedNavigationLinkFields
        ...FooterNavigationLinkWithSocialIconFields
        ...FooterNavigationTextFields
      }
    }
  }

  fragment FooterNavigationLinkFields on C__NavigationLink {
    ...FooterBaseFields
    link {
      value
      jsonValue
    }
  }

  fragment FooterGeoRestrictedNavigationLinkFields on GeoRestrictedNavigationLink {
    ...FooterBaseFields
    link {
      value
      jsonValue
    }
    visibleForCountry {
      value
    }
  }

  fragment FooterNavigationTextFields on NavigationText {
    ...FooterBaseFields
    text {
      value
      jsonValue
    }
  }

  fragment FooterNavigationLinkWithSocialIconFields on NavigationLinkWithSocialIcon {
    ...FooterBaseFields
    link {
      value
      jsonValue
    }
    socialIconCharacter {
      value
      jsonValue
    }
  }
`;

export default getFooterQuery;
