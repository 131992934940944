import { OrderItem } from 'components/order/order-models';
import { useI18n } from 'next-localization';
import { useEffect } from 'react';

type OrderLicensingProps = {
  orderItem: OrderItem;
};

const MPLicensingOrderDetails = (props: OrderLicensingProps): JSX.Element => {
  const { orderItem } = props;
  const { t } = useI18n();

  useEffect(() => {
    const orderItemLinks = document.querySelector('#user-instructions')?.querySelectorAll('a');
    orderItemLinks?.forEach((element: HTMLElement) => {
      element?.classList.add('js-external-redirect', 'text-mp-txt-primary', 'underline');
    });
  });

  return (
    <div className="text-center border-b border-mp-txt-neutral pb-4">
      <div className="mb-4 font-bold">
        <div className="text-2xl text-mp-txt-bright mb-2">{orderItem.product.name}</div>
        <div>
          {orderItem.product.serialLabel ?? t('MP_Order_Licensing_ActivationCode')}
          {': '}
          <span className="text-mp-txt-bright">
            {orderItem.redemptionCodes ?? orderItem.serialNumber}
          </span>
        </div>
      </div>
      <div
        id="user-instructions"
        dangerouslySetInnerHTML={{
          __html: orderItem.product.instructionsForEndUser,
        }}
      ></div>
    </div>
  );
};

export default MPLicensingOrderDetails;
