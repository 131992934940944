export const GetClassFromTheme = (theme: string): string => {
  if (theme == null) {
    return '';
  }

  switch (theme.toLowerCase()) {
    case 'primary':
      return 'btn-primary';
    case 'primary on dark':
      return 'btn-primary-dark';
    case 'outline':
      return 'btn-outline';
    case 'outline on dark':
      return 'btn-outline-dark';
    case 'text':
      return 'btn-link';
    case 'text on dark':
      return 'btn-link-dark';
    case 'feature':
      return 'btn-feature';
    case 'feature on dark':
      return 'btn-feature-dark';
    case 'small primary':
      return 'btn-primary btn-small';
    case 'small primary on dark':
      return 'btn-primary-dark btn-small';
    case 'small outline':
      return 'btn-outline btn-small';
    case 'small outline on dark':
      return 'btn-outline-dark btn-small';
    case 'small text':
      return 'btn-link btn-small after:content-none';
    case 'small text on dark':
      return 'btn-link-dark btn-small after:content-none';
    case 'small feature':
      return 'btn-feature btn-small';
    case 'small feature on dark':
      return 'btn-feature-dark btn-small';
    default:
      return 'btn-primary';
  }
};
