import gql from 'graphql-tag';

const getLocalNavigationQuery = gql`
  query LocalNavigationDatasourceQuery(
    $datasource: String!
    $contextItem: String!
    $language: String!
  ) {
    # Datasource query
    # $datasource will always be set to the ID of the rendering's datasource item
    # (as long as the GraphQLData helper is used)
    datasource: item(path: $datasource, language: $language) {
      id
      name
      children(hasLayout: false) {
        results {
          ...NavigationGroupFields
        }
      }
    }
    contextItem: item(path: $contextItem, language: $language) {
      id
    }
  }

  fragment BaseFields on Item {
    id
    name
  }

  fragment NavigationLinkFields on C__NavigationLink {
    ...BaseFields
    link {
      value
      jsonValue
    }
  }

  fragment NavigationGroupFields on NavigationGroup {
    ...BaseFields
    text {
      value
      jsonValue
    }
    group {
      value
    }
    link {
      value
      jsonValue
    }
    children(hasLayout: false, first: 25) {
      results {
        ...NavigationLinkFields
      }
    }
  }
`;

export default getLocalNavigationQuery;
