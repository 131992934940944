import { GetStaticComponentProps, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Datasource } from '../components/video-grid/video-grid-response';
import dynamic from 'next/dynamic';
import getVideoGridQuery from '../components/video-grid/video-grid-query';
import { VideoGridResponse } from '../components/video-grid/video-grid-response';
import { GraphQLClient } from 'lib/common/graphql-client';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';

const VideoCard = dynamic(() => import('../components/video-grid/VideoCard'), {
  ssr: false,
});

type VideoGridProps = ComponentProps & {
  fields: Datasource;
};

const VideoGrid = (props: VideoGridProps): JSX.Element => {
  useSSRErrorsHandler(props.ssrErrors);
  const videos = props?.fields.selectedVideos?.targetItems;

  return (
    <div className="sm:my-5 lg:my-10 max-w-content px-6 sm:mb-10 md:mb-20 mx-auto">
      <ul className="grid grid-cols-1 gap-y-8 py-6 xl:py-0 md:grid-cols-2 md:gap-x-6 xl:grid-cols-3 xl:gap-x-8">
        {videos &&
          videos.length > 0 &&
          videos.map((video, idx) => (
            <li className="videoItem" key={`videoCard_${idx}`}>
              <VideoCard {...video} elementId={`videoCard_${idx}`} />
            </li>
          ))}
      </ul>
    </div>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const language = layoutData.sitecore.context.language;
  const videoGridData = await getVideoGridData(rendering.dataSource, language);
  if (!videoGridData) {
    ssrErrorLogger.logError({
      functionName: 'getVideoGridData; ',
      errorMessage: `Failed to get response; Datasource: ${JSON.stringify(
        rendering.dataSource
      )}; Langusge: ${language}; Response: ${JSON.stringify(videoGridData)}`,
    });
  }

  const propsObj = {
    fields: { ...rendering.fields, selectedVideos: videoGridData.datasource.selectedVideos },
  };

  return ssrErrorLogger.createProperty('VideoGrid', propsObj);
};

export const getVideoGridData = async (
  datasource: string,
  language: string
): Promise<VideoGridResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<VideoGridResponse>(getVideoGridQuery, {
    datasource: datasource,
    language: language,
  });
};

export default withDatasourceCheck()<VideoGridProps>(VideoGrid);
