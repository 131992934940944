import gql from 'graphql-tag';

const getManualCardBrightcoveVideoQuery = gql`
  query ManualCardBrightcoveVideoQuery($datasource: String!, $language: String!) {
    datasource: item(path: $datasource, language: $language) {
      id
      ... on BrightcoveVideoWithPlayer {
        player {
          targetItem {
            ID: field(name: "ID") {
              value
            }
          }
        }
        videoReference {
          targetItems {
            ID: field(name: "ID") {
              value
            }
            ThumbnailURL: field(name: "ThumbnailURL") {
              value
            }
          }
        }
        stillImage {
          jsonValue
        }
      }
      ... on BrightcoveExperience {
        videoReference {
          targetItems {
            ID: field(name: "ID") {
              value
            }
          }
        }
        stillImage {
          jsonValue
        }
      }
      ... on YoutubeVideo {
        sourceId {
          value
        }
        stillImage {
          jsonValue
        }
      }
    }
  }
`;
export default getManualCardBrightcoveVideoQuery;
