import { getFrequentlyBoughtTogetherListController } from 'components/coveo-recommendations/coveo-core/controllers-coveo-recommendations';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import {
  CoveoRecommendationsProps,
  CoveoRecommendedProductDetails,
  mapRecommendationsToProducts,
} from 'components/coveo-recommendations/coveo-recommendations';
import { useAppSelector } from 'lib/store/hooks';
import { useCartData } from 'components/cart/cart-hooks';
import CoveoRecommendationsCarousel from 'components/coveo-recommendations/CoveoRecommendationsCarousel';
import {
  FrequentlyBoughtTogetherList,
  FrequentlyBoughtTogetherListState,
} from '@coveo/headless/dist/definitions/controllers/product-recommendations/headless-frequently-bought-together';

const CoveoFrequentlyBoughtTogether: React.FC<CoveoRecommendationsProps> = (props) => {
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const targetProduct = useCartData()?.cartItems?.at(-1);
  const productID = targetProduct?.cartItem.productId;
  const [controllerState, setFrequentlyBoughtState] =
    useState<FrequentlyBoughtTogetherListState | null>(null);
  const [controller, setController] = useState<FrequentlyBoughtTogetherList | null>(null);
  const [productsArray, setProductsArray] = useState<CoveoRecommendedProductDetails[] | null>(null);
  const maxResultsToShow = props?.fields?.maxResultsToShow?.value ?? 3;

  useEffect(() => {
    if (!productID) {
      return;
    }
    setController(getFrequentlyBoughtTogetherListController(productID, maxResultsToShow));
  }, [maxResultsToShow, productID]);

  useEffect(() => {
    if (!controller || !currencyIsoCode || !productID) {
      return;
    }
    controller.subscribe(() => setFrequentlyBoughtState(controller.state));
    controller.refresh();
  }, [controller, productID, currencyIsoCode]);

  useEffect(() => {
    if (!controllerState || !currencyIsoCode || !productID) {
      return;
    }
    setProductsArray(
      mapRecommendationsToProducts(controllerState.recommendations, currencyIsoCode)
    );
  }, [controllerState, currencyIsoCode, productID]);

  if (controllerState?.error) {
    return null;
  }

  return (
    <div id="coveo-frequently-bought-together-wrapper">
      {productsArray && productsArray.length > 0 && (
        <CoveoRecommendationsCarousel
          title={props?.fields?.title}
          description={props?.fields?.description}
          fetchPriceFromSalesforce={props?.fields?.fetchPriceFromSalesforce}
          productsArray={productsArray}
        />
      )}
    </div>
  );
};

export default withDatasourceCheck()<CoveoRecommendationsProps>(CoveoFrequentlyBoughtTogether);
