import {
  Field,
  ImageField,
  LinkField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { ComponentProps } from 'lib/component-props';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type MediaBlockProps = ComponentProps & {
  fields: {
    image: ImageField;
    heading: Field<string>;
    subheading: Field<string>;
    introductoryText: Field<string>;
    text: Field<string>;
    showBadge: Field<boolean>;
    badgeTheme: Field<string>;
    ctaButton: LinkField;
    noFollow?: Field<boolean>;
  };
};

const MediaBlock = (props: MediaBlockProps): JSX.Element => {
  const badgeCss = props.fields?.showBadge
    ? props.fields?.badgeTheme && props.fields?.badgeTheme?.value === 'dark'
      ? 'text-sm text-left font-bold text-dark px-2 py-0.5 bg-info leading-[16.41px] rounded-[10px] ml-4 dark:bg-info-dark dark:text-info-light'
      : 'text-sm text-left font-bold text-white px-2 py-0.5 bg-info leading-[16.41px] rounded-[10px] ml-4 dark:bg-info-light dark:text-info'
    : '';
  return (
    <div className="sm:my-5 lg:my-10">
      <NextImage field={props.fields.image} />
      <Text
        tag="h5"
        field={props.fields.subheading}
        className="font-medium text-base text-secondary text-left align-top leading-[1.2rem] uppercase tracking-[5.04px] mb-2 lg:text-lg lg:leading-[1.35rem]"
      />
      <div className="flex items-center mb-10">
        <Text
          tag="h3"
          field={props.fields.heading}
          className="font-bold text-2xl text-almost-black text-left align-top leading-[1.8rem] lg:text-[1.75rem] lg:leading-[2.1rem]"
        />
        {badgeCss && <div className={badgeCss}>NEW!</div>}
      </div>
      <RichText
        field={props.fields.introductoryText}
        className="rte font-bold text-xl text-almost-black text-left align-top leading-[1.875rem] mb-3 lg:text-2xl lg:leading-9"
      />
      <RichText
        tag="div"
        field={props.fields.text}
        className="rte font-normal text-xl text-almost-black text-left align-top leading-[1.875rem] mb-3 lg:text-2xl lg:leading-9"
      />
      <Link
        field={props.fields.ctaButton}
        linkCustomizations={props.fields}
        className="btn-link-underline"
        hideText
      >
        <span>{props.fields?.ctaButton?.value?.text}</span>
        <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
      </Link>
    </div>
  );
};

export default withDatasourceCheck()<MediaBlockProps>(MediaBlock);
