import { useEffect, useState } from 'react';
import { Price } from './model/product-models';
import { priceQueue } from './price-queue';

export const usePrice = (
  productId: string | null,
  currencyIsoCode: string | null
): Price | null => {
  const [price, setPrice] = useState<Price | null>(null);
  const [results, setResults] = useState<Array<Price>>([]);

  const testCallback = (finalResults: Array<Price>): void => {
    setResults(finalResults);
  };

  useEffect(() => {
    setPrice(null);

    if (productId == null || productId.length == 0 || currencyIsoCode == null) {
      return;
    }

    priceQueue.queuePriceFetch(productId, currencyIsoCode, testCallback);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, currencyIsoCode]);

  useEffect(() => {
    if (!results || results.length == 0) {
      return;
    }

    const foundItems = results.filter((val) => val.productId == productId);
    if (foundItems == null || foundItems.length == 0) {
      return;
    }

    if (JSON.stringify(price) === JSON.stringify(foundItems[0])) {
      return;
    }

    setPrice(foundItems[0]);
  }, [results, productId, price]);

  return price;
};
