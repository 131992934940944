import { useI18n } from 'next-localization';

type NonProToolsOrderDetailsProps = {
  orderId: string | string[] | undefined;
  userId: string | undefined;
  loading: boolean;
};

const NonProToolsOrderDetails = (props: NonProToolsOrderDetailsProps): JSX.Element => {
  const { t } = useI18n();
  const { loading } = props;

  return (
    <>
      <div>
        <a
          className={`rounded  text-white text-lg px-4 py-2 font-bold ${
            loading ? 'pointer-events-none bg-base-normal' : 'bg-primary'
          }`}
          href={`${process.env.NEXT_PUBLIC_MYAVID_CHECKOUT_RETURN_URL}/completeaccount?orderId=${props.orderId}&verificationcode=${props.userId}`}
        >
          {loading ? t('Order_Details_AvidLinkLoading') : t('Order_Details_VisitAvidAccount')}
        </a>
      </div>
      <div className="mt-6 mb-4">{t('Order_Details_InYourAvidAccount')}</div>
    </>
  );
};

export default NonProToolsOrderDetails;
