import { Field, RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect } from 'react';

type VanityUrlProps = {
  fields: {
    data: {
      contextItem: {
        destinationUrl: Field<string>;
        downloadText: RichTextField;
      };
    };
  };
};

const VanityURLsRedirect = (props: VanityUrlProps): JSX.Element => {
  const urls = props?.fields?.data?.contextItem?.destinationUrl?.value ?? '#';

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      typeof window != undefined && window.location.replace(urls);
    };
    fetchData();
    const replacePhrase = (): void => {
      document.body.innerHTML = document.body.innerHTML.replace('{downloadUrl}', urls);
    };
    replacePhrase();
  }, [urls]);

  return (
    <>
      {props && (
        <section className="max-w-content px-10 mx-auto ">
          <div className="mt-24   text-almost-black font-normal [&_h2]:normal-case [&_h2]:font-bold [&_h2]:mb-4 [&_p]:leading-10 text-24  ">
            <RichText
              className="rte text-24   text-base-dark [&_a]:underline [&_a]:text-primary [&_a]:hover:text-primary-dark [&_a]:visited:text-purple"
              field={props?.fields?.data?.contextItem?.downloadText}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default VanityURLsRedirect;
