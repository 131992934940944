// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Needed to add ts-nocheck because objectFit takes a special object that cannot be used here.
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

type CdnImageProps = {
  field: ImageField;
  editable?: boolean;
  className?: string;
  width?: number;
  height?: number;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
  priority?: boolean;
};

export const AvidCdnImage = (props: CdnImageProps): JSX.Element => {
  const imgSource = props.field.value?.src?.startsWith('/-/media')
    ? props.field.value?.src?.replace('/-/media', process.env.NEXT_PUBLIC_AVIDCDN_URL) // all stg envs
    : props.field.value?.src?.replace(
        `${process.env.AVID_SITECORE_EDGE_MEDIA_PREFIX}/${process.env.AVID_SITECORE_EDGE_TENANTID}/media`,
        process.env.NEXT_PUBLIC_AVIDCDN_URL
      ); // prod

  return (
    <img
      src={imgSource}
      className={props.className}
      alt={props.field?.value?.alt}
      width={props.width ? props.width : props.field?.value?.width}
      height={props.height ? props.height : props.field?.value?.height}
    />
  );
};
